import moment from 'moment-timezone';

import settings from '@/config/settings';
import type { OverviewCage } from '@/services/types';
import { DISPLAY_DAY_FORMAT } from '@/utils/formattedDay';

export function calculateFishSpeedToolTip(alarm: string, cage: OverviewCage, t: Function) {
    const tooltipContent: {
        key: string;
        value: string;
    }[] = [];

    if (alarm !== '' && cage && cage.speed !== undefined) {
        tooltipContent.push(
            {
                key: t('Status'),
                value: alarm,
            },
            {
                key: t('Speed'),
                value: cage.speed
                    ? `${cage.speed?.toFixed(2)} ${t('swimspeed')}`
                    : t('No data found'),
            },
            {
                key: t('Date'),
                value: moment(cage.lastSpeedMeasurement).format(DISPLAY_DAY_FORMAT),
            },
            {
                key: t('Daypart'),
                value: t(`daypartTag.${cage?.daypart?.tag}`) ?? '---',
            }
        );
    } else {
        tooltipContent.push({
            key: t('Speed'),
            value: t('No data found'),
        });
    }
    return tooltipContent;
}

export const getSpeedStatus = (speed: number | undefined, t: Function) => {
    if (speed === undefined) {
        return t('No data found');
    }
    if (speed < settings.swimspeed.low_speed_threshold) {
        return `${t('Slower than')} ${settings.swimspeed.low_speed_threshold} ${t('swimspeed')}`;
    }
    if (speed > settings.swimspeed.high_speed_threshold) {
        return `${t('Faster than')} ${settings.swimspeed.high_speed_threshold} ${t('swimspeed')}`;
    }
    if (speed > settings.swimspeed.medium_speed_threshold) {
        return `${t('Faster than')} ${settings.swimspeed.medium_speed_threshold} ${t('swimspeed')}`;
    }
    return t('Normal');
};
