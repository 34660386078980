import VideoLoadingIcon from 'assets/InternalIcons/animatedSpinner.svg';
import LiceIcon from 'assets/InternalIcons/LiceIcon.svg';
import ArrowWithLineDown from 'assets/MingCute-main/svg/arrow/align_arrow_down_fill.svg';
import ArrowWithLineLeft from 'assets/MingCute-main/svg/arrow/align_arrow_left_fill.svg';
import ArrowWithLineRight from 'assets/MingCute-main/svg/arrow/align_arrow_right_fill.svg';
import ArrowWithLineUp from 'assets/MingCute-main/svg/arrow/align_arrow_up_fill.svg';
import ArrowDownCircleFill from 'assets/MingCute-main/svg/arrow/arrow_down_circle_fill.svg';
import ArrowRight from 'assets/MingCute-main/svg/arrow/arrow_right_line.svg';
import DownLine from 'assets/MingCute-main/svg/arrow/down_line.svg';
import WinchIcon from 'assets/MingCute-main/svg/arrow/move_fill.svg';
import RightLine from 'assets/MingCute-main/svg/arrow/right_line.svg';
import MoveBioscopeIcon from 'assets/MingCute-main/svg/arrow/transfer_4_fill.svg';
import CompareIcon from 'assets/MingCute-main/svg/arrow/transfer_fill.svg';
import TrendIconDown from 'assets/MingCute-main/svg/arrow/trending_down_fill.svg';
import TrendIconUp from 'assets/MingCute-main/svg/arrow/trending_up_fill.svg';
import UpLine from 'assets/MingCute-main/svg/arrow/up_line.svg';
import HomeIcon from 'assets/MingCute-main/svg/building/home_3_fill.svg';
import CalendarIcon from 'assets/MingCute-main/svg/business/calendar_month_line.svg';
import GrowthIcon from 'assets/MingCute-main/svg/business/chart_line_fill.svg';
import TargetLine from 'assets/MingCute-main/svg/business/target_line.svg';
import ChecvronLineIcon from 'assets/MingCute-main/svg/chevron/chevron_line.svg';
import FeedBackIcon from 'assets/MingCute-main/svg/contact/message_4_line.svg';
import LayerIcon from 'assets/MingCute-main/svg/design/layer_line.svg';
import DashboardIcon from 'assets/MingCute-main/svg/design/layout_grid_fill.svg';
import AlarmIcon from 'assets/MingCute-main/svg/device/alarm_2_line.svg';
import Online from 'assets/MingCute-main/svg/device/wifi_line.svg';
import OfflineIcon from 'assets/MingCute-main/svg/device/wifi_off_line.svg';
import WifiOffIcon from 'assets/MingCute-main/svg/device/wifi_off_line.svg';
import Edit4Fill from 'assets/MingCute-main/svg/editor/edit_4_fill.svg';
import BalanceFill from 'assets/MingCute-main/svg/education/balance_fill.svg';
import CopyIcon from 'assets/MingCute-main/svg/file/copy_fill.svg';
import DownloadFile from 'assets/MingCute-main/svg/file/download_2_line.svg';
import SearchIcon from 'assets/MingCute-main/svg/file/search_2_fill.svg';
import ZoomInIcon from 'assets/MingCute-main/svg/file/zoom_in_line.svg';
import ResetZoom from 'assets/MingCute-main/svg/file/zoom_out_line.svg';
import FishFill from 'assets/MingCute-main/svg/food/fish_fill.svg';
import ExitFullScreen from 'assets/MingCute-main/svg/media/fullscreen_exit_line.svg';
import FullScreen from 'assets/MingCute-main/svg/media/fullscreen_line.svg';
import VideoCameraIcon from 'assets/MingCute-main/svg/media/video_camera_2_fill.svg';
import FirstAidKit from 'assets/MingCute-main/svg/other/first_aid_kit_fill.svg';
import FirstAidIcon from 'assets/MingCute-main/svg/other/first_aid_kit_fill.svg';
import HeartBeat2Fill from 'assets/MingCute-main/svg/part/heartbeat_2_fill.svg';
import AlertOctagon from 'assets/MingCute-main/svg/system/alert_octagon_fill.svg';
import CheckMarkIcon from 'assets/MingCute-main/svg/system/check_fill.svg';
import CloseCircleIcon from 'assets/MingCute-main/svg/system/close_circle_line.svg';
import CloseIcon from 'assets/MingCute-main/svg/system/close_line.svg';
import InformationLine from 'assets/MingCute-main/svg/system/information_line.svg';
import ElipsisIcon from 'assets/MingCute-main/svg/system/more_2_fill.svg';
import HelpIcon from 'assets/MingCute-main/svg/system/question_fill.svg';
import LaksvelShield from 'assets/MingCute-main/svg/system/safe_shield_2_fill.svg';
import SettingsIcon from 'assets/MingCute-main/svg/system/settings_3_fill.svg';
import ShareIcon from 'assets/MingCute-main/svg/system/share_2_line.svg';
import TimeDurationIcon from 'assets/MingCute-main/svg/system/time_duration_line.svg';
import OnTheMoveIcon from 'assets/MingCute-main/svg/transport/rudder_line.svg';
import UserIcon from 'assets/MingCute-main/svg/user/group_3_fill.svg';
import MailIcon from 'assets/MingCute-main/svg/contact/mail_line.svg';
import LockIcon from 'assets/MingCute-main/svg/system/lock_line.svg';
export const ArrowWithLineDownIconSVG = ArrowWithLineDown;
export const Edit4FillSVG = Edit4Fill;

export const ArrowWithLineUpIconSVG = ArrowWithLineUp;
export const ArrowWithLineLeftIconSVG = ArrowWithLineLeft;
export const ArrowWithLineRightIconSVG = ArrowWithLineRight;
export const HomeIconSVG = HomeIcon;
export const UserIconSVG = UserIcon;
export const HelpIconSVG = HelpIcon;
export const MoveBioscopeIconSVG = MoveBioscopeIcon;
export const LiceIconSVG = LiceIcon;
export const DashboardIconSVG = DashboardIcon;
export const CompareIconSVG = CompareIcon;
export const SearchIconSVG = SearchIcon;
export const CalendarIconSVG = CalendarIcon;
export const CheckMarkIconSVG = CheckMarkIcon;
export const ArrowRightSVG = ArrowRight;
export const AlertOctagonSVG = AlertOctagon;
export const TrendUpSVG = TrendIconUp;
export const TrendDownSVG = TrendIconDown;
export const VideoCameraIconSVG = VideoCameraIcon;
export const WinchIconSVG = WinchIcon;
export const SettingsIconSVG = SettingsIcon;

export const LaksvelShieldSVG = LaksvelShield;
export const GrowthIconSVG = GrowthIcon;
export const FishHealthIconSVG = FirstAidIcon;
export const ElipsisIconSVG = ElipsisIcon;
export const CopyIconSVG = CopyIcon;
export const ResetZoomIconSVG = ResetZoom;
export const DownloadFileIconSVG = DownloadFile;
export const FullScreenIconSVG = FullScreen;
export const FullScreenExitconSVG = ExitFullScreen;
export const CloseIconSVG = CloseIcon;
export const OnTheMoveIconSVG = OnTheMoveIcon;
export const OfflineIconSVG = OfflineIcon;
export const OnlineIconSVG = Online;
export const ShareIconSVG = ShareIcon;
export const FeedBackIconSVG = FeedBackIcon;
export const LayerIconSVG = LayerIcon;
export const ZoomInIconSVG = ZoomInIcon;
export const ChecvronLineIconSVG = ChecvronLineIcon;
export const BalanceFillSVG = BalanceFill;
export const HeartBeat2FillSVG = HeartBeat2Fill;
export const FirstAidKitSVG = FirstAidKit;
export const FishFillSVG = FishFill;
export const UpLineSVG = UpLine;
export const DownLineSVG = DownLine;
export const RightLineSVG = RightLine;
export const TargetLineSVG = TargetLine;
export const InformationLineSVG = InformationLine;
export const ArrowDownCircleFillSVG = ArrowDownCircleFill;
export const VideoLoadingIconSVG = VideoLoadingIcon;
export const CloseCircleIconSVG = CloseCircleIcon;
export const AlarmIconSVG = AlarmIcon;
export const WifiOffIconSVG = WifiOffIcon;
export const TimeDurationIconSVG = TimeDurationIcon;
export const MailIconSVG = MailIcon;
export const LockIconSVG = LockIcon;
