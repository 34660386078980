import type { RouteComponentProps } from 'react-router-dom';
import LICENSES from 'config/licenses';

import {
    BalanceFillSVG,
    DashboardIconSVG,
    Edit4FillSVG,
    FirstAidKitSVG,
    HeartBeat2FillSVG,
    HelpIconSVG,
    LiceIconSVG,
    MoveBioscopeIconSVG,
    SettingsIconSVG,
    UserIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { GenericPage, SidebarType } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import UserPage from '@/components/UserManagmentPage';
import CageBioscopeInspectionContainer from '@/routes/Client/Cage/pages/CageDashboard/CageBioscopeStatus/CageBioscopeInspectionContainer';
import CageImagePageContainer from '@/routes/Client/Cage/pages/CageDashboard/CageImages/CageImagePageContainer';
import CageWeightDashboardContainer from '@/routes/Client/Cage/pages/CageWeight/CageWeightDashboard/CageWeightDashboardContainer';
import CageWelfareDashboardContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareDashboard/CageWelfareDashboardContainer';
import CageWelfareMaturationContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareMaturation/CageWelfareMaturationContainer';
import CageWelfareMaturationDetectionsContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareMaturationDetections/CageWelfareMaturationDetectionsContainer';
import CageWelfareScalelossContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareScaleLoss/CageWelfareScalelossContainer';
import HelpPageContainer from '@/routes/Client/components/Help/HelpPageContainer';
import { MoveBioscopeContainer } from '@/routes/Client/MoveBioScope/MoveBioscopeContainer';

import CageGraph from './CageDashboard/CageAllGraphs';
import CageDashboard from './CageDashboard/CageDashboard';
import CageLiceCaligus from './CageLice/CageLiceCaligus';
import CageLiceDashboard from './CageLice/CageLiceDashboard';
import CageLiceDetections from './CageLice/CageLiceDetections';
import CageLiceFemaleLice from './CageLice/CageLiceFemaleLice';
import CageLiceGraphs from './CageLice/CageLiceGraphs';
import CageLiceMovableLice from './CageLice/CageLiceMovable';
import CageWeightDistribution from './CageWeight/CageWeightDistribution';
import CageWeightGraphsProviderContainer from './CageWeight/CageWeightGraphs';
import CageWeightGrowthProviderContainer from './CageWeight/CageWeightGrowth';
import CageWelfareFishSpeedProviderContainer from './CageWelfare/CageWelfareFishspeed';
// Adjust the import path as necessary
import CageBioscopeDetailContainer from './CageBioscopeDetail';
import { ReportDetailContainer, ReportListContainer } from './ReportListImproved';
import Settings from './Settings';

/** Convenience export of the H.Location type for use with useLocation */
export type UseLocationType = RouteComponentProps['location'];

/** Convenience export of the H.History type for use with useHistory */
export type UseHistoryType = RouteComponentProps['history'];

export type Pages = GenericPage[];

const pages: Pages = [
    {
        id: 1,
        path: '',
        label: 'Dashboard',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageDashboard,
        image: DashboardIconSVG,
    },
    {
        parentId: 1,
        id: 4,
        path: '/dashboard/images',
        label: 'Images',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageImagePageContainer,
    },
    {
        parentId: 1,
        id: 2,
        path: '/dashboard/graphs',
        label: 'Graphs',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageGraph,
        exact: true,
    },
    {
        parentId: 1,
        id: 3,
        path: '/dashboard/bioscope',
        label: 'Bioscope status',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageBioscopeInspectionContainer,
    },
    {
        id: 50,
        path: '/weight',
        label: 'Weight',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWeightDashboardContainer,
        image: BalanceFillSVG,
        exact: true,
    },
    {
        parentId: 50,
        id: 51,
        path: '/weight/distribution',
        label: 'Weight distribution',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWeightDistribution,
        exact: true,
    },
    {
        parentId: 50,
        id: 52,
        path: '/weight/growth',
        label: 'Growth',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWeightGrowthProviderContainer,
        exact: true,
    },
    {
        parentId: 50,
        id: 53,
        path: '/weight/graphs',
        label: 'Graphs',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWeightGraphsProviderContainer,
        exact: true,
    },

    {
        id: 10,
        path: '/lice',
        label: 'Lice',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageLiceDashboard,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
        image: LiceIconSVG,
        exact: true,
    },
    {
        parentId: 10,
        id: 11,
        path: '/lice/detections',
        label: 'Detections',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageLiceDetections,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
        image: LiceIconSVG,
        exact: true,
    },
    {
        parentId: 10,
        id: 12,
        path: '/lice/female',
        label: 'Mature female lice',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageLiceFemaleLice,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
        image: LiceIconSVG,
        exact: true,
    },
    {
        parentId: 10,
        id: 13,
        path: '/lice/mobile',
        label: 'Mobile Lice',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageLiceMovableLice,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
        image: LiceIconSVG,
        exact: true,
    },
    {
        parentId: 10,
        id: 14,
        path: '/lice/caligus',
        label: 'caligusLice',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageLiceCaligus,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
        image: LiceIconSVG,
        exact: true,
    },
    {
        parentId: 10,
        id: 15,
        path: '/lice/graphs',
        label: 'Graphs',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageLiceGraphs,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
        image: LiceIconSVG,
        exact: true,
    },
    {
        id: 20,
        path: '/welfare',
        label: 'Welfare',
        exact: true,
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareDashboardContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        image: HeartBeat2FillSVG,
    },

    {
        parentId: 20,
        id: 30,
        path: '/welfare/laksvel',
        label: 'Laksvel',
        exact: false,
        sidebarToShowFor: SidebarType.cageLevel,
        hidden: false,
        licenses: [LICENSES.LAKSVEL],
        image: FirstAidKitSVG,
        showEntryChevron: true,
    },
    {
        parentId: 20,
        id: 21,
        path: '/welfare/wounds',
        label: 'Wound insight',
        sidebarToShowFor: SidebarType.cageLevel,
        showEntryChevron: true,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        exact: false,
    },
    {
        parentId: 20,
        id: 25,
        path: '/welfare/scaleloss',
        label: 'Scaleloss',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareScalelossContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        exact: true,
    },
    {
        parentId: 20,
        id: 26,
        path: '/welfare/maturation',
        label: 'Maturation',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareMaturationContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        exact: true,
    },
    {
        parentId: 20,
        id: 27,
        path: '/welfare/maturation/detections',
        label: 'Detections maturation',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareMaturationDetectionsContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        exact: true,
    },
    {
        parentId: 20,
        id: 24,
        path: '/welfare/speed',
        label: 'Fish Speed',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareFishSpeedProviderContainer,
        licenses: [LICENSES.FISH_SPEED],
        exact: true,
    },

    // Laksvel subpages
    // ...LaksvelRoutes.map((item, index) => ({
    //     id: item.path === '/laksvel' ? 10 : index + 1000, // base page is covered twice
    //     parentId: 30,
    //     exact: true,
    //     label: item.label,
    //     sidebarToShowFor: SidebarType.cageLevel,
    //     page: LaksvelContainerRouter,
    //     path: `/laksvel${item.path}`,
    //     hidden: false,
    //     licenses: [LICENSES.LAKSVEL],
    // })),

    {
        id: 60,
        exact: true, // because we have children
        path: '/reports/v2',
        label: 'Reporting.Reporting',
        sidebarToShowFor: SidebarType.cageLevel,
        page: ReportListContainer,
        image: Edit4FillSVG,
    },

    {
        id: 61,
        path: '/reports/v2/:reportId',
        sidebarToShowFor: SidebarType.cageLevel,
        page: ReportDetailContainer,
        hidden: true,
    },

    {
        id: 70,
        path: '/settings',
        label: 'Settings',
        sidebarToShowFor: SidebarType.cageLevel,
        page: Settings,
        image: SettingsIconSVG,
    },

    {
        id: 80,
        path: '/bioscope/:bioscopeId',
        label: 'Bioscope',
        exact: true,
        hidden: true,
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageBioscopeDetailContainer,
    },
    // DEPRECATED url / paths, but available earlier and still has the same page
    {
        id: 1001,
        path: '/weight-distribution',
        label: 'Weight distribution',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWeightDistribution,
        hidden: true,
    },
    {
        id: 10,
        path: '/lice-count',
        label: 'Lice',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageLiceDashboard,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
        hidden: true,
    },
    // {
    //     id: 28,
    //     path: '/welfare/health/:healthType',
    //     label: 'Welfare',
    //     exact: true,
    //     hidden: true,
    //     sidebarToShowFor: SidebarType.cageLevel,
    //     page: CageWelfareWoundsDashboardContainer,
    //     licenses: [LICENSES.FISH_HEALTH.MIN],
    // },
    {
        id: 101,
        path: '/move-bioscope',
        label: 'Move bioscope',
        page: MoveBioscopeContainer,
        sidebarToShowFor: SidebarType.cageLevel,
        exact: true,
        image: MoveBioscopeIconSVG,
        staticOption: true,
    },
    {
        id: 100,
        path: '/users',
        label: 'Users',
        page: UserPage,
        sidebarToShowFor: SidebarType.cageLevel,
        exact: true,
        image: UserIconSVG,
        staticOption: true,
    },
    {
        id: 102,
        path: '/help',
        label: 'Help',
        page: HelpPageContainer,
        sidebarToShowFor: SidebarType.cageLevel,
        exact: true,
        image: HelpIconSVG,
        staticOption: true,
    },
].map((page, index) => ({ key: index, ...page }));

export const cageSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});

export default pages;
export { pages };
