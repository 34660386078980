import { useEffect, useState } from 'react';
import { t } from 'i18next';

import { CompareIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Loader } from '@/components/atoms/Loader';
import { UmerBox } from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { DatePicker } from '@/components/molecules/DatePicker';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import SomethingHappened from '@/components/SomethingHappened';
import { useMe } from '@/contexts/meContext';
import LocationComparePageContent from '@/routes/Client/Location/pages/LocationCompare/LocationComparePageContent';
import { useClientController_Overview } from '@/services/hooks';
import { OverviewCage, OverviewLocation } from '@/services/types';

import LocationCompareGraphContainer from './LocationCompareGraph';

import styles from './LocationCompare.module.scss';

const getUrlParameters = (search: string) => {
    const params = new URLSearchParams(search);
    const dataParam = params.get('data');

    if (!dataParam) {
        return [];
    }
    const dataArray = dataParam.split(',');
    const result = dataArray.reduce((result, item) => {
        const [key, value] = item.split('~');
        result[value] = key;
        return result;
    }, {});
    return result;
};

const LocationCompareContainer = ({
    clientId,
    locationId,
}: {
    clientId: number;
    locationId: number;
}) => {
    const { isOptoscaleAdmin } = useMe();

    const {
        isLoading: isLoadingClientLocations,
        data: clientLocationsData,
        error: clientLocationsDataError,
        refetch: clientLocationRefetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const [currentLocation, setCurrentLocation] = useState<OverviewLocation>(null);
    const [filteredCages, setFilteredCages] = useState<OverviewCage[]>([]);
    const [currentCages, setCurrentCages] = useState<OverviewCage[]>([]);

    const [from, setFrom] = useState<string>(null);
    const [to, setTo] = useState<string>(null);
    const [daypartTag, setDaypartTag] = useState('day');

    useEffect(() => {
        const location = clientLocationsData?.data?.locations?.find(
            (x) => x.id === locationId
        ) as OverviewLocation;
        if (location) {
            setCurrentLocation(location);
            setFilteredCages(location.cages.filter((x) => x.weight && !x.historical));
            setCurrentCages(location.cages.filter((x) => x.weight && !x.historical));
        } else {
            setCurrentLocation(null);
            setCurrentCages([]);
        }
    }, [clientLocationsData]);

    const handleCagesChanged = (cages: OverviewCage[]) => {
        setCurrentCages(cages);
    };

    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        console.log('dateReturn');
        console.log(dateReturn);
        if (dateReturn.dateFrom) {
            setFrom(dateReturn.dateFrom);
        } else {
            setFrom(null);
        }

        if (dateReturn.dateTo) {
            setTo(dateReturn.dateTo);
        } else {
            setTo(null);
        }
    };

    return (
        <div className={styles.pageContent}>
            {(isLoadingClientLocations || !currentLocation) && <Loader />}
            {clientLocationsDataError && <SomethingHappened />}
            {currentLocation && (
                <div>
                    <LocationComparePageContent
                        fullFilteredCagesList={filteredCages}
                        currentCages={currentCages}
                        onCagesChanged={handleCagesChanged}
                        location={currentLocation}
                    />
                    <div className={styles.datePickerContainer}>
                        <DatePicker
                            onDateChange={handleSetCageFilter}
                            fromDefault={from}
                            toDefault={to}
                        />
                    </div>
                    <div className={styles.pageBoxLayout}>
                        <UmerBox>
                            <LocationCompareGraphContainer
                                clientId={clientId}
                                locationId={locationId}
                                cages={currentCages}
                                toDate={to}
                                fromDate={from}
                                dayartTagParam={daypartTag}
                                containerIsLoading={isLoadingClientLocations}
                                containerError={clientLocationsDataError}
                            />
                        </UmerBox>

                        <UmerBox>
                            <UmerBoxElementHeader
                                headerText={t('Compare')}
                                headerIcon={CompareIconSVG}
                                headerIconColor={'#5BB784'}
                            />
                        </UmerBox>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LocationCompareContainer;
export { LocationCompareContainer };
