import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Buffer } from 'buffer';
import i18n from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import CalendarIcon from '@/components/atoms/icons/CalendarIcon';
import OptoTooltip from '@/components/atoms/OptoTooltip';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import UmerBoxElementText from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { useMqtt } from '@/contexts/mqtt-provider-context';
import { localizedDateFormat } from '@/helpers/date';
import { CageStatus } from '@/services/types';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';

import styles from './BioscopeStatusBox.module.scss';

interface BioscopeStatusBoxContentProps {
    cageStatus: CageStatus;
    timezone: string;
    measurementCount: number;
    isOptoscaleAdmin?: boolean;
    toolTipNubDirection?:
        | 'up-left'
        | 'up-right'
        | 'up-center'
        | 'down-center'
        | 'down-left'
        | 'left-center'
        | 'right-center'
        | 'down-right';
    showBioscopeInspectionLink?: boolean;
    bioscopeInspectionLink?: string;
}

interface HistoricalBioscope {
    from: string;
    to: string | null;
    bioscopeId: number;
}

function BioscopeStatusBox({
    cageStatus,
    timezone,
    measurementCount,
    isOptoscaleAdmin = false,
    toolTipNubDirection = 'up-right',
    showBioscopeInspectionLink,
    bioscopeInspectionLink,
}: BioscopeStatusBoxContentProps) {
    const { t } = useTranslation();
    const history = useHistory();

    const totalCount = measurementCount;

    const [depth, setDepth] = useState<string>('');
    const [pitchAndRoll, setpitchAndRoll] = useState<string>('');
    const [numberOfMeasuredFish, setNumberOfMeasuredFish] = useState<string>('');
    const [lastMeasurement, setLastMeasurement] = useState<string>('');
    const [activeBioscopeId, setActiveBioscopeId] = useState<string>('');
    const [historicalBioscope, setHistoricalBioscope] = useState<HistoricalBioscope[]>([]);
    const [lastActivity, setLastActivity] = useState<string>('');

    const { client, isConnected, subscribe } = useMqtt();
    const [bioscopeMqttData, setbioscopeMqttData] = useState<any>(null);
    const [lastMqttMessage, setLastMqttMessage] = useState<string>('');

    const calculateBioscopeBoxTexts = () => {
        if (cageStatus && bioscopeMqttData) {
            if (cageStatus.lastData?.depthLast) {
                setDepth(
                    `${localeFormatNumber(
                        bioscopeMqttData.Depth.replace('m', ''),
                        1,
                        i18n.language
                    )} m`
                );
            } else {
                setDepth('-');
            }

            if (cageStatus?.lastData?.timestampLast) {
                setLastMeasurement(
                    `${localizedDateFormat({
                        dateString: cageStatus.lastData.timestampLast,
                        locale: i18n.language,
                    })}`
                );
            }

            if (cageStatus?.lastData?.lastAlive) {
                setLastActivity(
                    `${localizedDateFormat({
                        dateString: cageStatus.lastData.lastAlive,
                        locale: i18n.language,
                    })}`
                );
            }

            const pitchText = `${localeFormatNumber(
                bioscopeMqttData.Pitch.replace('°', ''),
                1,
                i18n.language,
                '-'
            )}°`;
            const rollText = `${localeFormatNumber(
                bioscopeMqttData.Roll.replace('°', ''),
                1,
                i18n.language,
                '-'
            )}°`;

            setpitchAndRoll(`${pitchText} , ${rollText}`);
        }

        if (bioscopeMqttData?.timestamp) {
            setLastMqttMessage(
                `${localizedDateFormat({
                    dateString: bioscopeMqttData.timestamp,
                    locale: i18n.language,
                })}`
            );
        }

        setNumberOfMeasuredFish(`${totalCount}`);

        if (cageStatus?.currentBioscope?.id) {
            setActiveBioscopeId(`${cageStatus.currentBioscope.id}`);
        } else {
            setActiveBioscopeId('-');
        }

        if (cageStatus?.historicalBioscopes) {
            setHistoricalBioscope(cageStatus.historicalBioscopes);
        }
    };
    useEffect(() => {
        calculateBioscopeBoxTexts();
    }, []);
    useEffect(() => {
        calculateBioscopeBoxTexts();
    }, [measurementCount, timezone, cageStatus, bioscopeMqttData]);

    const messageHandler = useCallback((topic: string, message: Buffer) => {
        try {
            const parsedMessage = JSON.parse(message.toString());
            if (topic.includes('data')) {
                setbioscopeMqttData(parsedMessage);
                console.log(`Received message from ${topic}:`, parsedMessage);
            } else {
                console.log('unknown message received');
            }
        } catch (error) {
            console.error(`Error parsing message from ${topic}:`, error);
        }
    }, []);

    useEffect(() => {
        if (isConnected && activeBioscopeId) {
            const topic = `bioscope/data/response/${activeBioscopeId}`;
            subscribe(topic, { qos: 1 });

            client?.on('message', messageHandler);

            return () => {
                client?.off('message', messageHandler);
            };
        }
    }, [isConnected, activeBioscopeId, subscribe, client, messageHandler]);

    return (
        <div className={styles.boxContainer}>
            <div className={styles.informationSection}>
                <div className={styles.twoColumns}>
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Depth')}
                        info={depth}
                        toolTipContent={t('descriptions.bioscopeStatus.depth')}
                        toolTipNubDirection={toolTipNubDirection}
                    />
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Pitch, Roll')}
                        info={pitchAndRoll}
                        toolTipContent={t('descriptions.bioscopeStatus.pitchAndRoll')}
                        toolTipNubDirection={toolTipNubDirection}
                    />
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Measured fish total')}
                        info={`${totalCount}`}
                        toolTipContent={t('descriptions.bioscopeStatus.measurementCount')}
                        toolTipNubDirection={toolTipNubDirection}
                    />
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Active Bioscope')}
                        info={activeBioscopeId}
                    />
                </div>
                <UmerBoxElementStatusInfoWithTrend
                    title={t('Last measurement')}
                    info={lastMeasurement}
                    toolTipContent={t('descriptions.bioscopeStatus.lastMeasurement')}
                    toolTipNubDirection={toolTipNubDirection}
                />
            </div>

            {isOptoscaleAdmin && (
                <>
                    <UmerBoxElementDivider />
                    <div className={styles.optoscaleAdminSection}>
                        <img src="/favicon.ico" alt="favicon" className={styles.favicon} />
                        <UmerBoxElementText
                            text={t('Only visible for optoscale admin users')}
                            variant={'normal'}
                        />
                    </div>
                    <UmerBoxElementStatusInfoWithTrend title={t('Historical bioscopes')} />
                    <div className={styles.historicalBioscope}>
                        {historicalBioscope.map((bioscope) => (
                            <OptoTooltip
                                key={bioscope.bioscopeId}
                                content={`${t('From')}: ${localizedDateFormat({
                                    dateString: bioscope.from,
                                    locale: i18n.language,
                                })} ${t('To')}: ${
                                    bioscope.to
                                        ? localizedDateFormat({
                                              dateString: bioscope.to,
                                              locale: i18n.language,
                                          })
                                        : t('Present')
                                }`}
                                nub={toolTipNubDirection}>
                                <div className={styles.linkAndIcon}>
                                    <a
                                        href={`/internal/bioscope/${bioscope.bioscopeId}`}
                                        className={`${styles.link}`}>
                                        {bioscope.bioscopeId}
                                    </a>
                                    <CalendarIcon
                                        color={bioscope.to ? 'var(--neutral-700)' : 'var(--green)'}
                                    />
                                </div>
                            </OptoTooltip>
                        ))}
                    </div>
                    <div className={styles.twoColumns}>
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Last activity')}
                            info={lastActivity}
                            toolTipContent={t('descriptions.bioscopeStatus.lastLifesign')}
                            toolTipNubDirection={toolTipNubDirection}
                        />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Last MQTT message')}
                            info={lastMqttMessage}
                            toolTipContent={t('descriptions.bioscopeStatus.lastMqttMessage')}
                            toolTipNubDirection={toolTipNubDirection}
                        />
                    </div>
                </>
            )}

            {(isOptoscaleAdmin || showBioscopeInspectionLink) && bioscopeInspectionLink && (
                <IconButton
                    variant={'secondary'}
                    buttonText={t('Bioscope inspection')}
                    onClick={() => history.push(bioscopeInspectionLink)}
                />
            )}
        </div>
    );
}

export default BioscopeStatusBox;
