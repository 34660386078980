import React, { useState } from 'react';
import { LICENSES } from 'config/licenses';
import useCage from 'contexts/cage-context';
import { useQueryParams } from 'hooks/useQueryParams';
import { t } from 'i18next';
import { useLiceController_DailyLice, useLiceController_GetTemperature } from 'services/hooks';
import dayToString from 'utils/dayToString';

import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import SwitchButtons from '@/components/molecules/SwitchButtons';
import { TroutNotice } from '@/components/organisms/TroutNotice/TroutNotice';
import useCageFilter from '@/contexts/cage-filter-context';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import CageLiceMovableLicePageContent from './CageLiceMovableLicePageContent';

import styles from './CageLiceMovableLice.module.scss';

const CageLiceMovableLiceContainer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const { client, hasCageLicense, cage } = useCage();

    const { from, to, daypartTag, setFilter } = useCageFilter();

    const defaultMeasurmentSource = client.featureFlags.includes('LICE_LEGACY_VIEW')
        ? 'raw'
        : 'legacy';

    const showToggle = true;

    const [measurementSource, setMeasurementSource] = useState<'raw' | 'legacy'>(
        defaultMeasurmentSource
    );

    const dayFrom = from ? dayToString(from) : undefined;
    const dayTo = to ? dayToString(to) : undefined;

    const queryParams = useQueryParams();

    const dailyLice = useLiceController_DailyLice(
        cageId,
        { dayFrom, dayTo, dayPart: daypartTag },
        { staleTime: 5 * 60000 }
    );

    const { data: temperature } = useLiceController_GetTemperature(cageId, {
        daypartTag: daypartTag,
        weightUnitTag: 'g',
    });

    const loading = dailyLice.isLoading;
    const error = dailyLice.error;

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <SomethingHappened />;
    }
    function mapData<T extends (typeof dailyLice.data.data)[0]>(data: T): T {
        return {
            ...data,
            caligusElongatusAvg:
                measurementSource === 'legacy' // Now reporting / simulated
                    ? data.caligusElongatusLegacyAvg
                    : data.caligusElongatusAvg,
            movingStageAvg:
                measurementSource === 'legacy' ? data.movingStageLegacyAvg : data.movingStageAvg,
            femaleAvg: measurementSource === 'legacy' ? data.femaleLegacyAvg : data.femaleAvg,
        };
    }

    const getLiceCountDataWithTemperature = () => {
        return dailyLice?.data?.data?.map((el) => ({
            ...el,
            temperature: temperature?.data?.find((e) => e.day === el.day)?.temperature ?? null,
        }));
    };

    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        setFilter({
            from: dateReturn.dateFrom,
            to: dateReturn.dateTo,
        });
    };

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Mobile Lice')}>
                <div className={styles.pageTitleRowButtonContainer}>
                    <div>
                        {showToggle && (
                            <SwitchButtons
                                onClick={(ev) =>
                                    setMeasurementSource(ev.currentTarget.value as 'raw' | 'legacy')
                                }
                                size="md"
                                switches={[
                                    {
                                        label: t('Raw data'),
                                        value: 'raw',
                                        selected: measurementSource === 'raw',
                                        tooltipKey: 'rawdataexplain',
                                    },
                                    {
                                        label: t('Simulated manual count'),
                                        value: 'legacy',
                                        selected: measurementSource === 'legacy',
                                        tooltipKey: 'reportdataexplain',
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </PageTitleRow>

            <CageLiceMovableLicePageContent
                liceCount={getLiceCountDataWithTemperature()?.map(mapData)}
                showMaximumLiceInformation={hasCageLicense(LICENSES.LICE_COUNT.MAX)}
                cageFilterHandler={handleSetCageFilter}
                inputDateObject={{
                    dateFrom: from,
                    dateTo: to,
                    shouldStick: false,
                }}
                temperature={temperature?.data ? temperature.data : []}
            />
        </>
    );
};

export default CageLiceMovableLiceContainer;
