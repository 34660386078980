import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurmentOverviewResult } from 'services/types';

import { Loader } from '@/components/atoms/Loader';
import Options, {
    autoTimeScale,
    formatAxsis,
    TypeNames,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import { defaultLineChartOptions } from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { OverViewToLivingWeightChartMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { ALL_DAYPART_TAGS, DEFAULT_DAYPART_TAG } from '@/contexts/cage-filter-context';
import useGraphData from '@/routes/Client/Cage/components/cage-graph-data-context';
import { OptoMixedChart } from 'components/organisms/OptoGraphs';
import { dayTagValues } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';

interface LivingWeightGraphProps {
    isOptoscaleAdmin?: boolean;
    hasLivingWeightAllTagsEnabled?: boolean;
}

function LivingWeightGraph({
    isOptoscaleAdmin = false,
    hasLivingWeightAllTagsEnabled = false,
}: LivingWeightGraphProps) {
    const { t, i18n } = useTranslation();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    const { data, weightUnitTag, daypartTag, loading: isLoading } = useGraphData();
    const disabeledDayTags = dayTagValues.filter((dayTag) => dayTag !== daypartTag);

    return (
        <div>
            {isLoading && <Loader />}
            {!isLoading && (
                <OptoMixedChart
                    chartName={t('Living weight')}
                    chartData={OverViewToLivingWeightChartMapper({
                        overview: data as MeasurmentOverviewResult,
                        dayTags: [daypartTag],
                        inactiveSetLegend: [...disabeledDayTags],
                        graphedDayPartTags:
                            isOptoscaleAdmin || hasLivingWeightAllTagsEnabled
                                ? ALL_DAYPART_TAGS
                                : [DEFAULT_DAYPART_TAG],
                    })}
                    actionButtons={{
                        copyAsPng: true,
                        resetZoom: true,
                        downloadAsPng: true,
                        fullScreen: true,
                        timeScaleSwitch: false,
                    }}
                    chartOptions={Options(defaultLineChartOptions).modify(
                        autoTimeScale({}),
                        weightAndLengthUnitConverter({
                            locale: locale,
                            type: TypeNames.weight,
                            fromUnit: weightUnitTag,
                            toUnit: weightUnitTag,
                        }),
                        formatAxsis({
                            axsisID: 'y',
                            tickType: TypeNames.weight,
                            descimals: 0,
                        })
                    )}
                    loading={false}
                />
            )}
        </div>
    );
}

export default LivingWeightGraph;
