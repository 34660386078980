import { CageProvider } from '@/contexts/cage-context';
import { CageFilterProvider } from '@/contexts/cage-filter-context';
import CageLayer from '@/routes/Client/Cage/CageLayer';

interface CageLayerContainerProps {
    clientId: number;
    locationId: number;
    cageId: number;
}

const CageLayerContainer = ({ clientId, locationId, cageId }: CageLayerContainerProps) => {
    return (
        <CageProvider cageId={cageId}>
            <CageFilterProvider>
                <CageLayer clientId={clientId} locationId={locationId} cageId={cageId} />
            </CageFilterProvider>
        </CageProvider>
    );
};

export default CageLayerContainer;
export { CageLayerContainer };
