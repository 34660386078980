import moment from 'moment-timezone';

import { OverrideObject } from '@/components/molecules/DatePicker/DatePicker';
import { INTERNAL_DATE_FORMAT } from '@/utils/formattedDay';

export interface DatePickerListItemsProps {
    selected: boolean;
    leftSideText: string;
    rightSideText: string;
    onClick: () => void;
    overrideDateObject: OverrideObject;
}

export const DatePickerListItems: DatePickerListItemsProps[] = [
    {
        selected: false,
        leftSideText: 'Last 7 days',
        rightSideText: (() => {
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            return `${moment(sevenDaysAgo).format('DD-MM-YYYY')} - ${moment(new Date()).format(
                'DD-MM-YYYY'
            )}`;
        })(),
        onClick: () => {},
        overrideDateObject: {
            dateFrom: (() => {
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

                return `${moment(sevenDaysAgo).format(INTERNAL_DATE_FORMAT)}`;
            })(),
            dateTo: `${moment(new Date()).format(INTERNAL_DATE_FORMAT)}`,
        },
    },
    {
        selected: false,
        leftSideText: 'Last 4 weeks',
        rightSideText: (() => {
            const lastFourWeeks = new Date();
            lastFourWeeks.setDate(lastFourWeeks.getDate() - 28);

            return `${moment(lastFourWeeks).format('DD-MM-YYYY')} - ${moment(new Date()).format(
                'DD-MM-YYYY'
            )}`;
        })(),
        onClick: () => {},
        overrideDateObject: {
            dateFrom: (() => {
                const lastFourWeeks = new Date();
                lastFourWeeks.setDate(lastFourWeeks.getDate() - 28);

                return `${moment(lastFourWeeks).format(INTERNAL_DATE_FORMAT)}`;
            })(),
            dateTo: `${moment(new Date()).format(INTERNAL_DATE_FORMAT)}`,
        },
    },
    {
        selected: false,
        leftSideText: 'Last 3 months',
        rightSideText: (() => {
            const lastThreeMonths = new Date();
            lastThreeMonths.setDate(lastThreeMonths.getDate() - 84);

            return `${moment(lastThreeMonths).format('DD-MM-YYYY')} - ${moment(new Date()).format(
                'DD-MM-YYYY'
            )}`;
        })(),
        onClick: () => {},
        overrideDateObject: {
            dateFrom: (() => {
                const lastThreeMonths = new Date();
                lastThreeMonths.setDate(lastThreeMonths.getDate() - 84);

                return `${moment(lastThreeMonths).format(INTERNAL_DATE_FORMAT)}`;
            })(),
            dateTo: `${moment(new Date()).format(INTERNAL_DATE_FORMAT)}`,
        },
    },
    {
        selected: false,
        leftSideText: 'All',
        rightSideText: '...',
        onClick: () => {},
        overrideDateObject: {
            dateFrom: `${moment(0).format(INTERNAL_DATE_FORMAT)}`,
            dateTo: `${moment(new Date()).format(INTERNAL_DATE_FORMAT)}`,
        },
    },
];
