import {
    useClientController_GetClient,
    useLicenseController_GetLocationsLicense,
} from '@/services/hooks';
import { ClientInfo } from '@/services/types';

import LocationLayer from './LocationLayer';

interface LocationLayerContainerProps {
    clientId: number;
    locationId: number;
}

const LocationLayerContainer = ({ clientId, locationId }: LocationLayerContainerProps) => {
    const client = useClientController_GetClient(clientId, {
        enabled: clientId !== null,
    });

    const {
        isLoading: isloadingLocationLicense,
        data: dataLocationLicense,
        error: errorLocationLicense,
    } = useLicenseController_GetLocationsLicense(clientId);

    const clientInfo: ClientInfo = client.data?.data ? client.data.data : null;

    return (
        <LocationLayer
            clientId={clientId}
            locationId={locationId}
            clientName={clientInfo?.name}
            clientInfo={clientInfo}
            locationLicenseData={
                isloadingLocationLicense || errorLocationLicense
                    ? null
                    : dataLocationLicense.data.find((x) => x.locationId === locationId)
            }
        />
    );
};

export default LocationLayerContainer;
export { LocationLayerContainer };
