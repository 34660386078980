import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { Last3DaysResult } from 'services/types';
import { v4 as uuidv4 } from 'uuid';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { ArrowRightSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import UmerBoxElementStatusInfoWithTrend from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { TrendIconEnum } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend/TrendIconUtils';
import { UmerBoxElementText } from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { localizedDateFormat } from '@/helpers/date';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';

import styles from './LiceStatus.module.scss';

interface LiceStatusBoxMetadata {
    label: string;
    value200: string;
    hasValue: boolean;
    trend: TrendIconEnum;
    toolTipBlock?: string | React.ReactNode;
}

interface LiceStatusProps {
    liceInMin200Fish?: Last3DaysResult & {
        count?: number;
        countNoLice?: number;
        dayPart?: string;
        daypartId?: number;
        total?: number;
    };
    licePageUrl: string;
    hasLiceLicense: boolean;
}
const LiceStatus = ({ liceInMin200Fish, licePageUrl = '', hasLiceLicense }: LiceStatusProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const liceTypeToLiceBoxStatusMapper: { [key: string]: LiceStatusBoxMetadata } = {};
    let lastMeasurement = '';
    const getTrendTypeFromAverageDiff = (liceAverageDiff: number) => {
        if (liceAverageDiff > 0) {
            return TrendIconEnum.TrendNegativeUp;
        } else if (liceAverageDiff < 0) {
            return TrendIconEnum.TrendPositiveDown;
        }
        return TrendIconEnum.TrendNone;
    };

    const getLabelForLiceType = (liceType: string) => {
        if (liceType === 'female') {
            return t('Mature female lice');
        } else if (liceType === 'movingStage') {
            return t('Mobile Lice');
        } else if (liceType === 'caligusElongatus') {
            return t('caligusLice');
        }
        return '';
    };

    for (const liceTypeString of ['female', 'movingStage', 'caligusElongatus']) {
        const value = liceInMin200Fish?.[`${liceTypeString}Avg`];
        const valueAvgDiff = liceInMin200Fish?.[`${liceTypeString}AvgDiff`];

        let trend = TrendIconEnum.TrendNone;
        if (valueAvgDiff !== null && valueAvgDiff !== undefined) {
            trend = getTrendTypeFromAverageDiff(valueAvgDiff);
        }

        const hasValue = !(value === null || value === undefined);

        // const indicatorColor: getLiceColor(value);

        let helpTextMain = '';
        let helpText = '';
        let stringValue = '';

        if (!hasValue) {
            helpText = 'Not enough measurements';
            stringValue = '-';
        } else {
            if (liceTypeString === 'female') {
                if (value > 10) {
                    helpText = 'moreThan10AdultFemale';
                    stringValue = '>10';
                }
                helpTextMain = 'liceNumberHelpText.adult';
            }
            if (liceTypeString === 'movingStage') {
                if (value > 10) {
                    helpText = 'moreThan10Mobile';
                    stringValue = '>10';
                }
                helpTextMain = 'liceNumberHelpText.mobile';
            }
            if (liceTypeString === 'caligusElongatus') {
                if (value > 10) {
                    helpText = 'moreThan10Caligus';
                    stringValue = '>10';
                }
                helpTextMain = 'liceNumberHelpText.caligus';
            }
        }
        if (!stringValue) {
            stringValue = localeFormatNumber(value, 2, i18n.language, '-');
        }

        const heltTextAsHtml = [];
        if (helpTextMain) {
            const translatedMainText = t(helpTextMain);

            for (const para of translatedMainText.split('. ')) {
                const id = uuidv4();
                heltTextAsHtml.push(<p key={id}>{para}.</p>);
            }
        }
        if (helpText) {
            const translatedHelpText = t(helpText);
            heltTextAsHtml.push(<p key={'helptext'}>{translatedHelpText}.</p>);
        }
        let tootipBlock: React.ReactNode = null;
        if (heltTextAsHtml?.length > 0) {
            tootipBlock = <div className={styles.tooltipBlock}>{heltTextAsHtml}</div>;
        }

        liceTypeToLiceBoxStatusMapper[liceTypeString] = {
            label: getLabelForLiceType(liceTypeString),
            value200: stringValue,

            hasValue,
            trend: trend,
            toolTipBlock: tootipBlock,
        };
    }

    if (liceInMin200Fish?.day) {
        const lastMeasurementDate = `${localizedDateFormat({
            dateString: liceInMin200Fish?.day,
            onlydate: true,
            locale: i18n.language,
        })}`;

        lastMeasurement = `${t('Last measurement day')}: ${lastMeasurementDate}`;
    }

    const handleGoToLiceDetailPage = (liceType: string) => {
        if (liceType === 'female') {
            history.push(`${licePageUrl}/female`);
        } else if (liceType === 'movingStage') {
            history.push(`${licePageUrl}/mobile`);
        } else if (liceType === 'caligusElongatus') {
            history.push(`${licePageUrl}/caligus`);
        }
        return '';
    };

    return (
        <div className={styles.countValue}>
            <UmerBoxElementStatusInfoWithTrend
                title={liceTypeToLiceBoxStatusMapper.female.label}
                info={liceTypeToLiceBoxStatusMapper.female.value200}
                trend={liceTypeToLiceBoxStatusMapper.female.trend}
                toolTipContent={liceTypeToLiceBoxStatusMapper.female.toolTipBlock}>
                {hasLiceLicense && (
                    <IconButton
                        onClick={() => handleGoToLiceDetailPage('female')}
                        onlyIcon={true}
                        variant={'tertiary'}>
                        <GenericIcon icon={ArrowRightSVG} />
                    </IconButton>
                )}
            </UmerBoxElementStatusInfoWithTrend>
            <UmerBoxElementDivider />
            <UmerBoxElementStatusInfoWithTrend
                title={liceTypeToLiceBoxStatusMapper.movingStage.label}
                info={liceTypeToLiceBoxStatusMapper.movingStage.value200}
                trend={liceTypeToLiceBoxStatusMapper.movingStage.trend}
                toolTipContent={liceTypeToLiceBoxStatusMapper.movingStage.toolTipBlock}>
                {hasLiceLicense && (
                    <IconButton
                        onClick={() => handleGoToLiceDetailPage('movingStage')}
                        onlyIcon={true}
                        variant={'tertiary'}>
                        <GenericIcon icon={ArrowRightSVG} />
                    </IconButton>
                )}
            </UmerBoxElementStatusInfoWithTrend>
            <UmerBoxElementDivider />
            <UmerBoxElementStatusInfoWithTrend
                title={liceTypeToLiceBoxStatusMapper.caligusElongatus.label}
                info={liceTypeToLiceBoxStatusMapper.caligusElongatus.value200}
                trend={liceTypeToLiceBoxStatusMapper.caligusElongatus.trend}
                toolTipContent={liceTypeToLiceBoxStatusMapper.caligusElongatus.toolTipBlock}>
                {hasLiceLicense && (
                    <IconButton
                        onClick={() => handleGoToLiceDetailPage('caligusElongatus')}
                        onlyIcon={true}
                        variant={'tertiary'}>
                        <GenericIcon icon={ArrowRightSVG} />
                    </IconButton>
                )}
            </UmerBoxElementStatusInfoWithTrend>
            <UmerBoxElementDivider />

            <UmerBoxElementText variant={'footer'} text={lastMeasurement}></UmerBoxElementText>
        </div>
    );
};

export default LiceStatus;
export { LiceStatus };
