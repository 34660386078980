import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import cn from 'classnames';

import { BackIcon } from '@/components/atoms/icons/Back';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { LeftColumn, Navigation } from '@/components/Layout';
import {
    GenericPage,
    GenericSideMenuBackOption,
} from '@/components/molecules/GenericSidebar/index';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import { GenericSidebarItem } from '@/components/molecules/GenericSidebarItem';
import { joinUrlPath } from '@/utils/urlPath';

import styles from './GenericSidebar.module.scss';

export interface LocationSidebarProps {
    children?: React.ReactNode;
    sideBarOptions?: SidebarPageOptionObject[];
    layerUrlPrefix?: string; // if the use route does not work for this level, inject the url prefix instead
    backOption?: GenericSideMenuBackOption;
    checkIsPageAvailable?: (page: GenericPage) => boolean;
}

const GenericSidebar = ({
    children,
    sideBarOptions,
    layerUrlPrefix = null,
    backOption = null,
    checkIsPageAvailable = null,
}: LocationSidebarProps) => {
    const { t } = useTranslation();

    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    const [currentSideBarOptions, setCurrentSideBarOptions] = useState<
        SidebarPageOptionObject[] | undefined
    >([]);

    const [openSidebarOptions, setOpenSidebarOptions] = useState<SidebarPageOptionObject[]>([]);
    const [staticSidebarOptions, setStaticSidebarOptions] = useState<SidebarPageOptionObject[]>([]);

    const filterAndConstructSidebarOptions = (sideBarOptions: SidebarPageOptionObject[]) => {
        let compareActiveUrl = location.pathname;
        if (compareActiveUrl.endsWith('/')) {
            compareActiveUrl = compareActiveUrl.slice(0, -1);
        }

        // check for licenses
        let filteredSideBarOptions = [];
        if (checkIsPageAvailable) {
            filteredSideBarOptions = sideBarOptions.filter(
                (sidebarOption) =>
                    !sidebarOption.page.hidden && checkIsPageAvailable(sidebarOption.page)
            );
        } else {
            filteredSideBarOptions = sideBarOptions.filter(
                (sidebarOption) => !sidebarOption.page.hidden
            );
        }

        const firstIterationMappedSidebarOptions = filteredSideBarOptions.map((sideBarOption) => {
            let url = '';
            if (layerUrlPrefix) {
                if (sideBarOption.page?.path) {
                    url = `${layerUrlPrefix}/${sideBarOption.page?.path}`;
                    url = joinUrlPath(layerUrlPrefix, sideBarOption.page?.path);
                } else {
                    url = layerUrlPrefix;
                }
            } else {
                url = joinUrlPath(match?.url, sideBarOption.page?.path);
            }

            return {
                ...sideBarOption,
                url: url,
                active: url === compareActiveUrl,
            };
        });

        // Set static options
        const staticOptions = firstIterationMappedSidebarOptions.filter(
            (item) => item.page?.staticOption
        );
        setStaticSidebarOptions(staticOptions);

        const activeParentIds = firstIterationMappedSidebarOptions
            .filter((x) => x.page.parentId && x.active)
            .map((x) => x.page.parentId);

        return firstIterationMappedSidebarOptions.map((sideBarOption) => {
            if (activeParentIds.findIndex((x) => x === sideBarOption.page.id) > -1) {
                return {
                    ...sideBarOption,
                    active: true,
                };
            }
            return sideBarOption;
        });
    };

    const handleSetOpenSideOptions = (sideOption: SidebarPageOptionObject) => {
        let tempSideBarOptions: SidebarPageOptionObject[] = [...openSidebarOptions];

        if (sideOption.page.parentId === undefined) {
            tempSideBarOptions = [];
        }

        const shouldAdd = !tempSideBarOptions.some((item) => {
            return item.page === sideOption.page;
        });

        if (shouldAdd) {
            tempSideBarOptions.push(sideOption);
        }

        setOpenSidebarOptions(tempSideBarOptions);
    };

    useEffect(() => {
        setCurrentSideBarOptions(filterAndConstructSidebarOptions(sideBarOptions));
    }, []);

    useEffect(() => {
        setCurrentSideBarOptions(filterAndConstructSidebarOptions(sideBarOptions));
    }, [sideBarOptions, location.pathname, match, checkIsPageAvailable]);

    const handleGoBack = () => {
        if (backOption) {
            history.push(backOption.url);
        }
    };
    return (
        <LeftColumn>
            <nav className={styles.leftNavigationPanel}>
                <NavbarBrand tag={Link} to="/">
                    <img src="/logo.png" alt="" className={styles.logo} />
                </NavbarBrand>
                <div className={styles.marginadd} />
                <div className={styles.locationsidebar}>
                    <Navigation>
                        {backOption && (
                            <div
                                key="navigationBack"
                                className={cn(styles.backSidebaritem)}
                                onClick={() => handleGoBack()}>
                                <BackIcon color={'#5bb784'} />
                                <div className={styles.backButtonTextWrapper}>
                                    {backOption.title}
                                </div>
                            </div>
                        )}
                        {currentSideBarOptions
                            ?.filter((item) => !item.page.parentId)
                            ?.filter((filteredItem) => !filteredItem.page?.staticOption)
                            .map((page) => {
                                return (
                                    <GenericSidebarItem
                                        key={`${page.page.id}_${page.page.label}`}
                                        sidebarObject={page}
                                        subOptions={currentSideBarOptions.filter(
                                            (item) =>
                                                page.page.id === item.page.parentId &&
                                                page.page.id !== item.page.id
                                        )}
                                        allSidebarObjects={currentSideBarOptions}
                                        handleSetOpenSidebarOptions={handleSetOpenSideOptions}
                                        openSidebarOptions={
                                            openSidebarOptions
                                        }></GenericSidebarItem>
                                );
                            })}
                        {staticSidebarOptions.length > 0 && <UmerBoxElementDivider />}
                        {staticSidebarOptions?.map((page) => {
                            return (
                                <GenericSidebarItem
                                    key={`${page.page.id}-static`}
                                    sidebarObject={page}
                                    subOptions={staticSidebarOptions.filter(
                                        (item) =>
                                            page.page.id === item.page.parentId &&
                                            page.page.id !== item.page.id
                                    )}
                                    allSidebarObjects={staticSidebarOptions}
                                    handleSetOpenSidebarOptions={handleSetOpenSideOptions}
                                    openSidebarOptions={openSidebarOptions}
                                />
                            );
                        })}
                    </Navigation>
                </div>
            </nav>
        </LeftColumn>
    );
};

export default GenericSidebar;
export { GenericSidebar };
