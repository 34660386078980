import React from 'react';

import styles from './UmerBoxElementDivider.module.scss';

const UmerBoxElementDivider = () => {
    return <hr className={styles.divider} />;
};

export default UmerBoxElementDivider;
export { UmerBoxElementDivider };
