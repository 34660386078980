const ADMIN_IS_SUPER = true;

const LICENSES = {
    FISH_HEALTH: {
        MIN: '^FISH_HEALTH\\.MIN$',
        INSPECTION: '^FISH_HEALTH\\.INSPECTION$',
    },
    LICE_COUNT: {
        MIN: '^LICE_COUNT\\.MIN$',
        MAX: '^LICE_COUNT\\.MAX$',
    },
    LAKSVEL: '^LAKSVEL$',
    FISH_SPEED: '^FISH_SPEED$',
    LIVE_VIDEO_STREAM: '^LIVE_VIDEO_STREAM$',
};

export default LICENSES;
export { ADMIN_IS_SUPER, LICENSES };
