import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dateUtils from 'helpers/date';

import { IconButton } from '@/components/atoms/Buttons';
import FormInputElement from '@/components/atoms/FormInput';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { CloseIconSVG, SettingsIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import UmerBox from '@/components/atoms/UmerBox';
import {
    WinchFrontendData,
    WinchSettings,
} from '@/routes/Client/Location/pages/LocationWinch/MqttTopics';

import styles from './OptoModal.module.scss';

export interface OptoModalProps {
    children?: React.ReactNode;
    open: boolean;
    asModal?: boolean;
    cancelHandler?: (modalName: string) => void;
    confirmHandler?: (payload) => void;
    title?: string;
    icon?: string; // Generic icon url
    confirmButtonText?: string;
}

const OptoModal = ({
    children,
    open = false,
    title = 'OptoModal',
    confirmButtonText = 'Save',
    icon = SettingsIconSVG,
    confirmHandler = () => {},
    cancelHandler = () => {},
}: OptoModalProps) => {
    const { t } = useTranslation();
    const dialogElementRef = useRef<HTMLDialogElement>(null);
    const [formValues, setFormValues] = useState<Record<string, string | number>>({});
    const [disableSubmit, setDisableSubmit] = useState<{ isValid: boolean; id: string }[]>([]);
    const mainElementCallback = useCallback((node: HTMLDivElement) => {
        if (node !== null) {
            // Listening to change, input and custom events on input elements in the body of the dialog
            // This decouples the form elements from the dialog and allows for more flexibility
            // it also allows to have standardized primary and secondary button in the footer of the dialog.

            node.addEventListener('change', (e) => {
                const target = e.target as HTMLInputElement;
                setFormValues((prevState) => {
                    return {
                        ...prevState,
                        [target.name]: target.value,
                    };
                });
            });

            node.addEventListener('input', (e) => {
                const target = e.target as HTMLInputElement;
                setFormValues((prevState) => {
                    return {
                        ...prevState,
                        [target.name]: target.value,
                    };
                });
            });

            node.addEventListener(
                'disableSubmit',
                (e) => {
                    const customEvent = e as CustomEvent<{
                        isValid: boolean;
                        message: string;
                        id: string;
                    }>;
                    const { isValid, message, id } = customEvent.detail;

                    setDisableSubmit((prevState) => {
                        const index = prevState.findIndex((item) => item.id === id);
                        if (index !== -1) {
                            prevState[index] = { isValid, id };
                            return [...prevState];
                        }
                        return [...prevState, { isValid, id }];
                    });
                    console.error(`ID: ${id}`, message);
                },
                false
            );
        }
    }, []);

    const closeDialogHandler = () => {
        if (dialogElementRef.current) {
            dialogElementRef.current.close();
            cancelHandler(title);
            open = false;
        }
    };

    useEffect(() => {
        if (dialogElementRef.current) {
            if (open === true) {
                dialogElementRef.current.showModal();
            } else {
                dialogElementRef.current.close();
            }
        } else {
            console.error('Dialog element is not available');
        }
    }, [open]);

    return (
        <div className={styles.optomodal}>
            <dialog ref={dialogElementRef}>
                <UmerBox>
                    <div data-type="close">
                        <IconButton
                            variant="tertiary"
                            onlyIcon={true}
                            tooltip={t('Close')}
                            onClick={() => {
                                closeDialogHandler();
                            }}>
                            <GenericIcon icon={CloseIconSVG} size={20} />
                        </IconButton>
                    </div>
                    <header>
                        <h2>
                            <GenericIcon color="#5BB784" icon={icon} />
                            <span>{t(title)}</span>
                        </h2>
                    </header>
                    <main ref={mainElementCallback}>{children}</main>
                    <footer>
                        <div className={styles.defaultButtons}>
                            <IconButton
                                variant="primary"
                                disabled={disableSubmit.some((item) => item.isValid === false)}
                                buttonText={t(confirmButtonText)}
                                onClick={() => {
                                    confirmHandler({ ...formValues });

                                    setTimeout(() => {
                                        closeDialogHandler();
                                    }, 500);
                                }}
                            />
                            <IconButton
                                variant="secondary"
                                buttonText={t('Cancel')}
                                onClick={() => {
                                    closeDialogHandler();
                                }}
                            />
                        </div>
                    </footer>
                </UmerBox>
            </dialog>
        </div>
    );
};

export interface OptoModalTemplateMoveBioscopeProps extends HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    position?: WinchFrontendData['currentWinchPosition']['position'];
    settings?: WinchSettings;
    cageId: string;
    cageName: string;
    winchId?: string;
    bioscopeId?: number;
    timestamp: WinchFrontendData['timestamp'];
}

const OptoModalTemplateMoveBioscope = ({
    settings = {
        min: { x: 0, y: 0 },
        max: { x: 0, y: 0 },
        distanceToPulley: 0,
    },
    position = { x: 0, y: 0 },
    cageId = '0',
    cageName = '',
    winchId = '0',
    timestamp,
}: OptoModalTemplateMoveBioscopeProps) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={styles.modaltemplateTwoRow} data-cageid={cageId} data-winchid={winchId}>
            <div>
                <KeyValueFormPair
                    type="default"
                    label={t('Cage')}
                    value={cageName}
                    id="winch_cagename"
                />
                <KeyValueFormPair
                    id="winch_current_distance"
                    label={t('current_distance_pen')}
                    value={position.x}
                    type="default"
                />
                <KeyValueFormPair
                    label={t('present_depth')}
                    value={position.y}
                    type="default"
                    id="current_depth"
                />

                <div className={styles.keyValueFormPair} data-type="time">
                    <span>{t('dateandtime')}</span>

                    <time dateTime={timestamp}>
                        {dateUtils.localizedDateTime(timestamp, i18n.language)}
                    </time>
                </div>
            </div>
            <div>
                <KeyValueFormPair
                    type="default"
                    label={`${t('winch')} ID`}
                    value={`${winchId}`}
                    id="winch_target_winchid"
                />
                <KeyValueFormPair
                    id="winch_target_distance"
                    label={t('distance_to_pen_edge')} //
                    value={position.x}
                    step={0.1}
                    type="number"
                    name="positionX"
                    minLength={settings.min.x}
                    maxLength={settings.max.x}
                    invalidMessage={`Distance to pen edge must be between ${settings.min.x} and ${
                        settings.max.x
                    } ${settings.max.unit} and smallest step is ${0.1}`}
                />
                <KeyValueFormPair
                    id="winch_target_depth"
                    label={`${t('Depth')} (m)`}
                    value={position.y}
                    step={0.1}
                    name="positionY"
                    type="number"
                    minLength={settings.min.y}
                    maxLength={settings.max.y}
                    invalidMessage={`The winch depth must be between ${settings.min.x} and ${
                        settings.max.x
                    } ${settings.max.unit} and smallest step is ${0.1}`}
                />
            </div>
        </div>
    );
};

export interface OptoModalTemplateSettingsBioscopeProps extends HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    settings?: WinchSettings;
    cageId: string;
    cageName: string;
    winchId?: string;
    bioscopeId?: number;
}

const OptoModalTemplateSettingsBioscope = ({
    settings = {
        min: { x: 0, y: 0 },
        max: { x: 0, y: 0 },
        distanceToPulley: 0,
    },
    cageId = '0',
    cageName = '',
    winchId = '0',
}: OptoModalTemplateSettingsBioscopeProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.modaltemplateTwoRow} data-cageid={cageId} data-winchid={winchId}>
            <form>
                <div>
                    <KeyValueFormPair
                        type="default"
                        label={t('Cage')}
                        value={cageName}
                        id="winch_settings_cagename"
                    />

                    <KeyValueFormPair
                        id="winch_settings_distance_to_pulley"
                        label={t('DistanceToPulley')}
                        value={settings.distanceToPulley}
                        type="number"
                        step={0.1}
                        name="distanceToPulley"
                        invalidMessage={t('Please enter a valid number')}
                    />
                    <KeyValueFormPair
                        id="winch_settings_min_distance"
                        label={t('minimum_distance_pen')}
                        value={settings.min.x}
                        step={0.1}
                        type="number"
                        name="minDistanceX"
                    />
                    <KeyValueFormPair
                        id="winch_settings_min_depth"
                        label={t('minimum_depth')} //
                        value={settings.min.y}
                        step={0.1}
                        type="number"
                        name="minDepthY"
                    />
                </div>
                <div>
                    <KeyValueFormPair
                        type="default"
                        label={`${t('winch')} ID`}
                        value={`${winchId}`}
                        id="winch_settings_whichid"
                    />
                    <KeyValueFormPair
                        id="winch_settings_max_distance"
                        label={t('maxpenddistance')}
                        value={settings.max.x}
                        step={0.1}
                        type="number"
                        name="maxDistanceX"
                    />
                    <KeyValueFormPair
                        id="winch_settings_max_depth"
                        label={t('Max depth (m)')}
                        step={0.1}
                        value={settings.max.y}
                        type="number"
                        name="maxDepthY"
                    />
                </div>
            </form>
        </div>
    );
};

const KeyValueFormPair = ({
    label,
    value,
    type = 'number',
    name = 'noname',
    readOnly = false,
    maxLength = 255,
    step = 1,
    minLength = 0,
    validationRegex = '.*',
    invalidMessage = '',
    id,
    onChangeHandler = () => {},
}: {
    id: string | undefined;
    label: string;
    value: string | number;
    type?: 'number' | 'text' | 'default';
    name?: string;
    readOnly?: boolean;
    step?: number;
    maxLength?: number;
    minLength?: number;
    validationRegex?: string;
    invalidMessage?: string;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const { t } = useTranslation();
    let element = <></>;
    if (type === 'number' || type === 'text') {
        element = (
            <FormInputElement
                id={id}
                label={label}
                name={name}
                type={type}
                step={step}
                value={value as string}
                readonly={readOnly}
                onChange={onChangeHandler}
                maxLength={maxLength}
                minLength={minLength}
                pattern={validationRegex}
                validationMessage={invalidMessage}
            />
        );
    }

    return (
        <div className={styles.keyValueFormPair} data-type={type}>
            {type === 'default' ? (
                <>
                    <span>{t(label)}</span>
                    <span>{value}</span>
                </>
            ) : (
                element
            )}
        </div>
    );
};

export default OptoModal;
export {
    OptoModal,
    OptoModalTemplateMoveBioscope,
    KeyValueFormPair,
    OptoModalTemplateSettingsBioscope,
};
