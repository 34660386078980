import React, { useCallback, useState } from 'react';
import useCage from 'contexts/cage-context';
import { t } from 'i18next';
import {
    useFeedbackController_ImageFeedback,
    useLiceController_DailyLice,
    useLiceController_GetTemperature,
    useLiceController_Last200Lice,
    useLiceController_WeeklyLice,
    useWelfareController_allImages,
} from 'services/hooks';
import { ImageFeedbackDto } from 'services/types';
import dayToString from 'utils/dayToString';

import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import { TroutNotice } from '@/components/organisms/TroutNotice/TroutNotice';
import useCageFilter from '@/contexts/cage-filter-context';
import { useMe } from '@/contexts/meContext';
import { fullSizeImageMapper, useImageState } from '@/hooks/image-state';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import CageLiceDetectionsPageContent from './CageLiceDetectionsPageContent';

const CageLiceDetectionsContainer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const { client, hasCageLicense, timezone, cage } = useCage();
    const { isOptoscaleAdmin, state } = useMe();

    const { from, to, daypartTag } = useCageFilter();

    const defaultMeasurmentSource = client.featureFlags.includes('LICE_LEGACY_VIEW')
        ? 'raw'
        : 'legacy';

    const showToggle = true;

    const [measurementSource, setMeasurementSource] = useState<'raw' | 'legacy'>(
        defaultMeasurmentSource
    );

    const dayFrom = from ? dayToString(from) : undefined;
    const dayTo = to ? dayToString(to) : undefined;

    const dailyLice = useLiceController_DailyLice(
        cageId,
        { dayFrom, dayTo, dayPart: daypartTag },
        { staleTime: 5 * 60000 }
    );
    const weeklyLice = useLiceController_WeeklyLice(
        cageId,
        { dayFrom, dayTo, dayPart: daypartTag },
        { staleTime: 5 * 60000 }
    );
    const last200 = useLiceController_Last200Lice(cageId, {
        staleTime: 5 * 60000,
    });

    const { data: temperature } = useLiceController_GetTemperature(cageId, {
        daypartTag: daypartTag,
        weightUnitTag: 'g',
    });

    const loading = last200.isLoading || dailyLice.isLoading || weeklyLice.isLoading;
    const error = last200.error || dailyLice.error || weeklyLice.error;

    /********** Loce detection images *********/

    const { data: imagesdata, isLoading: imagesIsLoading } = useWelfareController_allImages(
        cageId,
        { type: 'louse-image', tags: ['day'] },
        { enabled: !!cageId, keepPreviousData: true }
    );

    const imageState = useImageState({
        data: imagesdata?.data,
        mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
        loading: imagesIsLoading,
        timezone: timezone,
        queryParam: 'imageId',
    });

    const getLiceCountDataWithTemperature = () => {
        return dailyLice?.data?.data?.map((el) => ({
            ...el,
            temperature: temperature?.data?.find((e) => e.day === el.day)?.temperature ?? null,
        }));
    };

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <SomethingHappened />;
    }

    /**********************************************/

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }
        if (cageId) {
            feedbackDto = { ...feedbackDto, cageId: cageId };
        }

        const { status, data } = await reportFeedbackAsync({ requestBody: feedbackDto });
        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    function mapData<
        T extends
            | typeof last200.data.data
            | (typeof weeklyLice.data.data)[0]
            | (typeof dailyLice.data.data)[0],
    >(data: T): T {
        return {
            ...data,
            caligusElongatusAvg:
                measurementSource === 'legacy' // Now reporting / simulated
                    ? data.caligusElongatusLegacyAvg
                    : data.caligusElongatusAvg,
            movingStageAvg:
                measurementSource === 'legacy' ? data.movingStageLegacyAvg : data.movingStageAvg,
            femaleAvg: measurementSource === 'legacy' ? data.femaleLegacyAvg : data.femaleAvg,
        };
    }

    const mapDataLast200 = (data: typeof last200.data.data): typeof last200.data.data => {
        const initalMap = mapData(data);

        return {
            ...initalMap,
            caligusElongatusAvgDiff:
                measurementSource === 'legacy'
                    ? data.caligusElongatusLegacyAvgDiff
                    : data.caligusElongatusAvgDiff,
            femaleAvgDiff:
                measurementSource === 'legacy' ? data.femaleLegacyAvgDiff : data.femaleAvgDiff,
            movingStageAvgDiff:
                measurementSource === 'legacy'
                    ? data.movingStageLegacyAvgDiff
                    : data.movingStageAvgDiff,
        };
    };

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Detections')}></PageTitleRow>

            <CageLiceDetectionsPageContent
                liceCount={getLiceCountDataWithTemperature()?.map(mapData)}
                imageState={imageState}
                isOptoscaleAdmin={isOptoscaleAdmin}
                last200={last200?.data?.data ? mapDataLast200(last200?.data?.data) : undefined}
                onCommitImageFeedbackDto={onCommitImageFeedbackDto}
            />
        </>
    );
};

export default CageLiceDetectionsContainer;
