import { useUserController_ResetPasswordRequest } from 'services/hooks';

import { ResetPasswordRequest2 } from './ResetPasswordRequest2';

export const ResetPasswordRequest2Container = () => {
    const { mutateAsync: newPasswordRequest } = useUserController_ResetPasswordRequest();
    return (
        <ResetPasswordRequest2
            newPasswordRequest={({ email }) => newPasswordRequest({ requestBody: { email } })}
        />
    );
};
