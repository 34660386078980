import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { t } from 'i18next';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { DownLineSVG, RightLineSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';

import styles from './GenericSidebarItem.module.scss';

export interface GenericSidebarItemProps {
    children?: React.ReactNode;
    sidebarObject?: SidebarPageOptionObject;
    subOptions?: SidebarPageOptionObject[];
    allSidebarObjects?: SidebarPageOptionObject[];
    handleSetOpenSidebarOptions?: (sidebarObject: SidebarPageOptionObject) => void;
    openSidebarOptions?: SidebarPageOptionObject[];
}

const GenericSidebarItem = ({
    children,
    sidebarObject = new SidebarPageOptionObject(),
    subOptions = [],
    allSidebarObjects = [],
    handleSetOpenSidebarOptions = () => {},
    openSidebarOptions = [],
}: GenericSidebarItemProps) => {
    const history = useHistory();

    const size: number = 24;

    const chevronColor = '#B9C0DF';

    const isOpen = useRef<boolean>(false);

    const [animationToPlay, setAnimationToPlay] = useState<string>('');

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleChangePage = () => {
        history.push(sidebarObject.url);
    };

    const handleOpenOption = () => {
        const isAlreadyOpen = openSidebarOptions.some((item) => {
            return item.page === sidebarObject.page;
        });

        if (isAlreadyOpen && isOpen.current) {
            isOpen.current = false;
            setAnimationToPlay(styles.closeSubOptions);

            return;
        }

        isOpen.current = true;

        setAnimationToPlay(styles.openSubOptions);

        handleSetOpenSidebarOptions(sidebarObject);
    };

    useEffect(() => {
        const prevOpen = isOpen.current;

        isOpen.current = openSidebarOptions.some((item) => {
            return item.page === sidebarObject.page;
        });

        if (!isOpen.current && prevOpen) {
            setAnimationToPlay(styles.closeSubOptions);
        }
    }, [openSidebarOptions]);

    useEffect(() => {
        if (sidebarObject.active) {
            handleOpenOption();
        }
    }, [sidebarObject.active]);

    const imageColor = sidebarObject.active || isHovered ? '#5bb784' : 'white';

    return (
        <>
            <div
                className={`${
                    sidebarObject?.page?.parentId === undefined
                        ? styles.subOptionsContainer
                        : styles.optionContainer
                } ${
                    (sidebarObject?.page?.parentId === undefined && sidebarObject?.active) ||
                    (sidebarObject?.page?.parentId === undefined &&
                        sidebarObject?.active &&
                        isHovered)
                        ? styles.optionContainerHighlight
                        : ''
                } ${
                    sidebarObject?.page?.parentId !== undefined && sidebarObject?.active
                        ? styles.highlightingline
                        : ''
                } ${
                    sidebarObject?.page?.parentId === undefined && sidebarObject?.active
                        ? styles.optionContainerHighlightLine
                        : ''
                }`}
                onClick={() => handleChangePage()}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <div
                    className={cn(styles.locationsidebaritem, {
                        [styles.active]: sidebarObject.active,
                    })}>
                    <div
                        className={`${styles.textWrapper} ${
                            sidebarObject.page?.parentId
                                ? !sidebarObject.active
                                    ? styles.locationsubsidebaritem
                                    : styles.locationsubsidebaritemactive
                                : styles.locationsidebaritem
                        } ${
                            sidebarObject.page?.parentId && isHovered
                                ? styles.locationsubsidebaritemactive
                                : ''
                        }`}>
                        {sidebarObject?.page?.parentId == undefined && (
                            <div className={styles.iconContainer}>
                                <GenericIcon
                                    icon={sidebarObject?.page?.image ?? ''}
                                    size={size}
                                    color={imageColor}
                                />
                            </div>
                        )}
                        {t(sidebarObject.page?.label)}
                    </div>
                </div>
                {subOptions.length > 0 && (
                    <div
                        className={`${styles.openIcon} ${animationToPlay}`}
                        onClick={() => handleChangePage()}>
                        <GenericIcon icon={DownLineSVG} size={16} color={chevronColor} />
                    </div>
                )}
                {subOptions.length === 0 && sidebarObject.page?.showEntryChevron && (
                    <div
                        className={`${styles.openIcon} ${animationToPlay}`}
                        onClick={() => handleChangePage()}>
                        <GenericIcon icon={RightLineSVG} size={16} color={chevronColor} />
                    </div>
                )}
            </div>
            {subOptions.length > 0 && (
                <div
                    className={
                        isOpen.current && sidebarObject?.page?.parentId === undefined
                            ? styles.sidebarItemSubOptionsOpen
                            : styles.sidebarItemSubOptionsClose
                    }>
                    <div className={styles.lineContainer}>
                        <div className={styles.line} />
                        {subOptions.map((subOption) => (
                            <GenericSidebarItem
                                key={subOption.page.id}
                                sidebarObject={subOption}
                                subOptions={allSidebarObjects.filter(
                                    (item) => subOption?.page?.id === item?.page?.parentId
                                )}
                                allSidebarObjects={allSidebarObjects}
                                openSidebarOptions={openSidebarOptions}
                                handleSetOpenSidebarOptions={
                                    handleSetOpenSidebarOptions
                                }></GenericSidebarItem>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default GenericSidebarItem;
export { GenericSidebarItem };
