import React from 'react';

import styles from './VideoIcon.module.scss';

export interface VideoIconProps {
    children?: React.ReactNode;
    height: number;
    width: number;
    color: string;
}

const VideoIcon = ({ height = 24, color }: VideoIconProps) => {
    const colorStyle = color ? color : 'currentColor';
    const widthValue = height;
    return (
        <div className={styles.videoicon}>
            <title>VideoIcon</title>
            <svg
                height={height}
                width={widthValue}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5165_29781)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 7.0376C16.0013 7.75273 15.81 8.45501 15.446 9.0706C16.1564 9.1774 16.805 9.53561 17.2737 10.0801C17.7423 10.6246 18.0001 11.3192 18 12.0376V12.2476L19.713 11.1916C19.9402 11.0516 20.2006 10.9747 20.4674 10.9689C20.7343 10.9631 20.9978 11.0286 21.2308 11.1587C21.4639 11.2887 21.658 11.4785 21.7933 11.7086C21.9285 11.9387 21.9998 12.2007 22 12.4676V17.6056C22 17.8726 21.9288 18.1347 21.7936 18.3649C21.6585 18.5952 21.4644 18.7852 21.2313 18.9153C20.9982 19.0455 20.7346 19.111 20.4677 19.1053C20.2008 19.0995 19.9403 19.0227 19.713 18.8826L18 17.8276V18.0376C18 18.8332 17.6839 19.5963 17.1213 20.1589C16.5587 20.7215 15.7956 21.0376 15 21.0376H5C4.20435 21.0376 3.44129 20.7215 2.87868 20.1589C2.31607 19.5963 2 18.8332 2 18.0376V12.0376C1.99992 11.5021 2.14315 10.9764 2.41483 10.515C2.68651 10.0536 3.07674 9.6733 3.545 9.4136C3.10085 8.71448 2.91903 7.8805 3.03187 7.05995C3.14472 6.23939 3.5449 5.48545 4.16128 4.93217C4.77766 4.3789 5.57028 4.06214 6.39821 4.03823C7.22614 4.01433 8.03572 4.28481 8.683 4.8016C9.16186 4.09108 9.85621 3.5531 10.6638 3.26688C11.4714 2.98067 12.3496 2.96134 13.169 3.21174C13.9884 3.46214 14.7058 3.96905 15.2154 4.65781C15.7251 5.34657 16.0001 6.18078 16 7.0376ZM12 5.0376C11.4696 5.0376 10.9609 5.24831 10.5858 5.62338C10.2107 5.99846 10 6.50717 10 7.0376C10 7.56803 10.2107 8.07674 10.5858 8.45181C10.9609 8.82688 11.4696 9.0376 12 9.0376C12.5304 9.0376 13.0391 8.82688 13.4142 8.45181C13.7893 8.07674 14 7.56803 14 7.0376C14 6.50717 13.7893 5.99846 13.4142 5.62338C13.0391 5.24831 12.5304 5.0376 12 5.0376ZM6.5 9.0376C6.89782 9.0376 7.27936 8.87956 7.56066 8.59826C7.84196 8.31695 8 7.93542 8 7.5376C8 7.13977 7.84196 6.75824 7.56066 6.47694C7.27936 6.19563 6.89782 6.0376 6.5 6.0376C6.10218 6.0376 5.72064 6.19563 5.43934 6.47694C5.15804 6.75824 5 7.13977 5 7.5376C5 7.93542 5.15804 8.31695 5.43934 8.59826C5.72064 8.87956 6.10218 9.0376 6.5 9.0376Z"
                        fill={colorStyle}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_5165_29781">
                        <rect
                            height={height}
                            width={widthValue}
                            fill="white"
                            transform="translate(0 0.0375977)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default VideoIcon;
export { VideoIcon };
