import {
    useCageController_FishTypes,
    useLocationController_DeleteCage,
    useCageController_LiceTypes,
} from '@/services/hooks';

import LocationCageGroup, { LocationCageGroupProps } from './LocationCageGroup';

interface LocationCageGroupContainerProps {
    refetch: () => unknown;
    cages: [
        {
            id: number;
        },
    ];
}

const LocationCageGroupContainer = ({
    refetch,
    ...props
}: Omit<LocationCageGroupProps & LocationCageGroupContainerProps, 'deleteCage'>) => {
    const { mutateAsync: deleteCage } = useLocationController_DeleteCage({ onSettled: refetch });
    const { data: fishTypes, error, isFetching } = useCageController_FishTypes();
    const { data: liceTypes } = useCageController_LiceTypes();

    return (
        <>
            <LocationCageGroup
                {...props}
                fishTypesList={fishTypes?.data}
                liceTypesList={liceTypes?.data}
                deleteCage={(id) => deleteCage({ cageId: id })}
            />
        </>
    );
};

export default LocationCageGroupContainer;
