import { Redirect } from 'react-router-dom';
import { useMe } from '@/contexts/meContext';
import { useLicenseController_GetLocationsLicense } from '@/services/hooks';
import React from 'react';

/**
 * Props for the ProtectedRoute component
 */
interface ProtectedRouteProps {
    /** The child components to render if access is granted */
    children?: React.ReactNode;
    /** Array of license regex patterns required to access this route */
    requiredLicenses?: string[];
}

/**
 * A component that protects routes based on user licenses and admin status.
 * - Allows access for Optoscale admins
 * - Checks for required licenses for non-admin users
 * - Redirects to home page if license requirements aren't met
 */
const ProtectedRoute = ({ children, requiredLicenses = [] }: ProtectedRouteProps) => {
    const { isOptoscaleAdmin, state } = useMe();

    // Extract IDs from URL path
    const pathSegments = window.location.pathname.split('/');
    const clientId = parseInt(pathSegments[2]);
    const locationId = parseInt(pathSegments[4]);

    // Fetch license data for the client
    const { data: locationLicenseData } = useLicenseController_GetLocationsLicense(clientId);

    /**
     * Checks if the current location has a valid license
     */
    const getLocationLicense = () => {
        return locationLicenseData?.data.find((location) => location.locationId === locationId)
            ?.licenses;
    };

    const locationLicense = getLocationLicense();

    /**
     * Checks if a specific license matches any of the required license patterns
     * @param licenseToCheck The license string to validate
     * @returns boolean indicating if license is valid
     */
    const hasRequiredLicense = (licenseToCheck: string) => {
        return requiredLicenses.some((regexPattern) => {
            try {
                const regex = new RegExp(regexPattern);
                return regex.test(licenseToCheck);
            } catch (error) {
                console.error(`Invalid regex pattern: ${regexPattern}`, error);
                return false;
            }
        });
    };

    // Grant immediate access to Optoscale admins
    if (isOptoscaleAdmin) {
        return children;
    }

    // Check license requirements for non-admins
    if (requiredLicenses.length > 0) {
        const needsLiveStreamLicense = hasRequiredLicense('LIVE_VIDEO_STREAM');
        const hasValidLiveStreamLicense = locationLicense?.liveStreamLicense?.hasValidLicense;

        if (needsLiveStreamLicense && !hasValidLiveStreamLicense) {
            return <Redirect to="/" />;
        }
    }

    return children;
};

export default ProtectedRoute;
export { ProtectedRoute };
