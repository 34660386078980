import type React from 'react';
import { HTMLAttributes, useCallback } from 'react';

import styles from './ImageViewerActionMenu.module.scss';

export type menuButtonTypes =
    | 'feedback'
    | 'share'
    | 'help'
    | 'pois'
    | 'magnifyer'
    | 'download'
    | 'fullscreen';

export interface ImageViewerActionMenuProps {
    children?: React.ReactNode;
    initialActiveButtons?: menuButtonTypes[];
}
const ImageViewerActionMenu = ({
    children,
    initialActiveButtons = ['magnifyer'],
}: ImageViewerActionMenuProps) => {
    const menuCallBackRef = useCallback((node: HTMLDivElement) => {
        if (node === null) {
            return;
        }

        for (const button of node.querySelectorAll('button')) {
            if (button.name !== '') {
                button.setAttribute('aria-pressed', 'false');
                if (initialActiveButtons?.includes(button?.name as menuButtonTypes)) {
                    button.setAttribute('aria-pressed', 'true');
                }
            }
        }

        node.addEventListener('click', (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const menuButton = target.closest('button');

            if (menuButton && menuButton.name !== '') {
                menuButton.setAttribute(
                    'aria-pressed',
                    menuButton.getAttribute('aria-pressed') === 'true' ? 'false' : 'true'
                );
            }
        });
    }, []);

    return (
        <div ref={menuCallBackRef} className={styles.imageViewerActionMenu}>
            {children}
        </div>
    );
};

export interface ImageViewerActionMenuPanelProps extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

const ImageViewerActionMenuPanel = ({ children }: ImageViewerActionMenuPanelProps) => {
    return <div className={styles.imageViewerActionMenuPanel}>{children}</div>;
};

export default ImageViewerActionMenu;
export { ImageViewerActionMenu, ImageViewerActionMenuPanel };
