import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Button,
    InputGroup,
    InputGroupText,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import cn from 'classnames';
import GenericIcon from '@/components/atoms/icons/GenericIcon';
import { MailIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import ChangeLanguage from 'components/molecules/ChangeLanguage/ChangeLanguage';

import '../public.scss';

interface ResetPasswordRequest2Props {
    newPasswordRequest: (props: { email: string }) => Promise<any>;
}

export function ResetPasswordRequest2({ newPasswordRequest }: ResetPasswordRequest2Props) {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    const onSubmit = async () => {
        try {
            await newPasswordRequest({ email });
            setSuccess(true);
        } catch (e: any) {
            console.log(e);
            setError(e?.message?.message ?? 'Something went wrong');
        }
    };

    return (
        <div className="loginContainer">
            <video autoPlay loop muted className="backgroundVideo">
                <source src="./loginVideoBg.mp4" type="video/mp4" />
            </video>

            <div className="loginRow">
                <div className="leftColumn">
                    <div className="overlayContent">
                        <h1>{t('Discover Precision')}</h1>
                        <p>{t('Get full control of fish weight...')}</p>
                    </div>
                </div>
                <div className="rightColumn">
                    <div className="topSection">
                        <img src="/logo.png" alt="" className="logo" />
                        <div className="languageBox">
                            <ChangeLanguage />
                        </div>
                    </div>
                    <div className="loginBox">
                        <h2>{t('Forgot password?')}</h2>
                        <h3>{t('No worries, we’ll send you reset instructions.')}</h3>
                        <FormGroup>
                            <Label className="text-white">{t('Email')}</Label>
                            <InputGroup className="inputGroup">
                                <InputGroupText className="inputGroupText">
                                    <GenericIcon icon={MailIconSVG} />
                                </InputGroupText>
                                <Input
                                    type="email"
                                    data-testid="email"
                                    name="email"
                                    placeholder={t('Email')}
                                    className="formControl"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputGroup>
                        </FormGroup>
                        {success ? (
                            <Alert color="success">
                                {t('Please check your email for the reset link')}.
                            </Alert>
                        ) : (
                            ''
                        )}
                        {error ? <Alert color="danger">{error}</Alert> : ''}
                        <div className="mt-2 d-flex">
                            <Button
                                data-testid="submitButton"
                                onClick={onSubmit}
                                className="btn-block">
                                {t('Reset password')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
