// norwegian decimal formatting

const norwegian1DecimalsFormatter = new Intl.NumberFormat('nb', {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
});

const norwegian2DecimalsFormatter = new Intl.NumberFormat('nb', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

// english and default formatting
const english1DecimalsFormatter = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
});

const english2DecimalsFormatter = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const localeFormatNumber = (value?: number, decimals = 2, locale = 'en', fallback = '') => {
    if (value === undefined || value === null || Number.isNaN(value)) {
        return fallback;
    }

    if (decimals == 0) {
        return value.toFixed(0);
    }

    if (decimals == 1) {
        if (locale === 'no' || locale === 'nb' || locale === 'nb-NO') {
            return norwegian1DecimalsFormatter.format(value);
        } else {
            return english1DecimalsFormatter.format(value);
        }
    }

    if (decimals == 2) {
        if (locale === 'no' || locale === 'nb' || locale === 'nb-NO') {
            return norwegian2DecimalsFormatter.format(value);
        } else {
            return english2DecimalsFormatter.format(value);
        }
    }
    return value.toFixed(decimals);
};
