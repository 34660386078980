//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import { AxiosRequestConfig } from 'axios';
import {
    UseQueryOptions,
    useQuery,
    useMutation,
    UseMutationOptions,
    QueryClient,
    QueryKey,
} from '@tanstack/react-query';
import { RequestError, SwaggerResponse } from './config';

import type {
    AcknowledgeAlert,
    ActiveBioscope,
    Alert,
    AlertAcknowledgement,
    AllWeeklyReportResult,
    ApiUserResponse,
    AuthOptionsDto,
    BiomassController_GetFeedingConversionRateQueryParams,
    BiomassResult,
    BioscopeConfigParams,
    BioscopeDetail,
    BioscopeDetails,
    BioscopeImage,
    BioscopeRestart,
    BioscopeRouterRestart,
    BioscopeVersion,
    CageContext,
    CageController_DistributionsQueryParams,
    CageController_OverviewQueryParams,
    CageDetail,
    CageImage,
    CageStatus,
    CageType,
    Cages,
    CagesWithActiveBioscopesByLocationDto,
    CheckEmailDto,
    ClientInfo,
    ClientWithLocationsInfo,
    CombinedWelfareController_FullWelfareQueryParams,
    CombinedWelfareResult,
    Comment,
    CompareInputs,
    Contract,
    CreateApiUserResponse,
    CreateBioscope,
    CreateComment,
    CreateNewClientInput,
    CreateNewContractInput,
    CreateNewContractLicenseInput,
    CreateNewLicenseForClientInput,
    CreateNewLicenseForUserInput,
    DailyLaksvelData,
    DailyLiceCount,
    DaysWithImages,
    DistributionResult,
    EntraAuthController_callbackQueryParams,
    EntraAuthController_loginQueryParams,
    EntraAuthController_logoutQueryParams,
    ExternalClientController_BiomassQueryParams,
    ExternalClientController_LiceDataQueryParams,
    ExternalClientController_WelfareDailyDataQueryParams,
    ExternalMeasurementsController_GetMeasurementsQueryParams,
    FeedingConversionRateResult,
    FishType,
    FullSizeImage,
    FullWelfareColorResult,
    FullWelfareResult,
    ImageFeedbackDto,
    InviteForm,
    JwtPayloadDto,
    LaksvelController_laksvelDailyQueryParams,
    Last3DaysResult,
    LiceController_DailyLiceQueryParams,
    LiceController_GetTemperatureQueryParams,
    LiceController_ImagesQueryParams,
    LiceController_WeeklyLiceQueryParams,
    LiceImageResult,
    LiceResult,
    LiceType,
    LicenseInfo,
    LicenseResponse,
    LocationBioscopeStatusDto,
    LocationController_MarkHistoricalQueryParams,
    LocationDetail,
    LocationWeightStatusDto,
    LocationsLicenseOverview,
    Me,
    MeasurmentOverviewResult,
    MoveBioscopeQueryParams,
    NewCage,
    NewLocation,
    NewReport,
    Notice,
    OverviewResult,
    QuotaCheckResponseDto,
    RegisterUserForm,
    ReportDetail,
    ReportList,
    ResetPassword,
    ResetPasswordRequest,
    SmsInput,
    StreamResponseDto,
    TemperatureResult,
    UnacknowledgeAlert,
    UpdateClientLicenseInput,
    UpdateLocation,
    UpdateName,
    UpdateType,
    UserController_LoginQueryParams,
    UserLicenseResponse,
    WeeklyLiceCount,
    WelfareController_FullWelfareColorQueryParams,
    WelfareController_FullWelfareQueryParams,
    WelfareController_allImagesQueryParams,
    WelfareController_dayWithImagesQueryParams,
    WelfareController_imagesByDayQueryParams,
    WelfareResult,
    Last500fishResult,
} from './types';
import {
    AcknowledgedAlertsController_acknowledgeAlert,
    AcknowledgedAlertsController_getAcknowledgements,
    AcknowledgedAlertsController_unacknowledgeAlert,
    AlertsController_allAlerts,
    AuthController_checkEmail,
    BiomassController_GetFeedingConversionRate,
    BioscopeConfigController_getConfigBioscope,
    BioscopeConfigController_setConfigBioscope,
    BioscopeController_ActiveBioscopes,
    BioscopeController_ActiveBioscopesForClient,
    BioscopeController_CreateApiKey,
    BioscopeController_CreateBioscope,
    BioscopeController_DetailBioscope,
    BioscopeController_Status,
    BioscopeDetailController_BioscopeDetailNew,
    BioscopeImageController_GetImageList,
    BioscopeImageController_Image,
    BioscopeRestartController_BioscopeRestarts,
    BioscopeRestartController_BioscopeRouterRestarts,
    BioscopeRestartController_RestartBioscope,
    BioscopeRestartController_RestartRouter,
    CageController_Context,
    CageController_DeleteCage,
    CageController_Distributions,
    CageController_FishTypes,
    CageController_LiceTypes,
    CageController_Overview,
    CageController_Status,
    CageController_UpdateName,
    CageController_UpdateType,
    CageImageController_GetImageList,
    CageImageController_Image,
    ClientController_AllLiceWeeklyReport,
    ClientController_ApiUsers,
    ClientController_Compare,
    ClientController_CreateApiUser,
    ClientController_CreateNewClient,
    ClientController_GetAllClientsWithLocations,
    ClientController_GetClient,
    ClientController_GetClientWithLocations,
    ClientController_GetClients,
    ClientController_Overview,
    CombinedWelfareController_FullWelfare,
    CommentsController_CreateComment,
    CommentsController_GetCageComments,
    ContentController_Notices,
    EntraAuthController_callback,
    EntraAuthController_checkSession,
    EntraAuthController_login,
    EntraAuthController_logout,
    ExternalClientController_Biomass,
    ExternalClientController_Bioscopes,
    ExternalClientController_Cages,
    ExternalClientController_LiceData,
    ExternalClientController_Locations,
    ExternalClientController_WelfareDailyData,
    ExternalMeasurementsController_GetMeasurements,
    FeedbackController_ImageFeedback,
    JwksController_getJwks,
    LaksvelController_GetLaksvelLast500Fish,
    LaksvelController_laksvelDaily,
    LiceController_DailyLice,
    LiceController_GetImage,
    LiceController_GetTemperature,
    LiceController_Images,
    LiceController_Last200Lice,
    LiceController_WeeklyLice,
    LicenseController_GetLocationsLicense,
    LicenseController_addContractLicense,
    LicenseController_availableLicenses,
    LicenseController_createContract,
    LicenseController_createNewLicenseForClient,
    LicenseController_createNewLicenseForUser,
    LicenseController_deleteClientLicenses,
    LicenseController_deleteContract,
    LicenseController_deleteContractLicense,
    LicenseController_deleteUserLicenses,
    LicenseController_getAllClientUser,
    LicenseController_getAllClientWithUser,
    LicenseController_getAllUser,
    LicenseController_getClientContracts,
    LicenseController_getClientLicenses,
    LicenseController_getLicense,
    LicenseController_getUserLicenses,
    LicenseController_updateClientLicense,
    LicenseController_updateContract,
    LicenseController_updateContractLicense,
    LocationController_CageTypes,
    LocationController_CagesWithHistoricalBioscope,
    LocationController_CreateCage,
    LocationController_CreateLocation,
    LocationController_DeleteCage,
    LocationController_DeleteLocation,
    LocationController_LocationBioscopeStatus,
    LocationController_LocationBioscopes,
    LocationController_LocationWeightStatus,
    LocationController_MarkHistorical,
    LocationController_UpdateLocation,
    MoveBioscopeController_DeleteLastMove,
    MoveBioscopeController_MoveBioscope,
    MqttJwtController_generateToken,
    ReportsController_CreateReport,
    ReportsController_DeleteReport,
    ReportsController_Report,
    ReportsController_ReportList,
    SmsController_SmsWebhook,
    StreamQuotaCheckController_checkQuotas,
    UserController_DeleteUser,
    UserController_EndImpersonate,
    UserController_ImpersonateUser,
    UserController_InviteUser,
    UserController_Login,
    UserController_Logout,
    UserController_Me,
    UserController_RegisterUser,
    UserController_ResetPassword,
    UserController_ResetPasswordRequest,
    VideoStreamController_getBioscopeQuotaCheck,
    VideoStreamController_getBioscopeStream,
    VideoStreamController_startStream,
    VideoStreamController_stopStream,
    VideoStreamController_updateActivity,
    VideoStreamWebhookController_handleMuxWebhook,
    VideoStreamWebhookNewController_handleMuxWebhook,
    WelfareController_FullWelfare,
    WelfareController_FullWelfareColor,
    WelfareController_GetFullSizeImage,
    WelfareController_allImages,
    WelfareController_dayWithImages,
    WelfareController_imagesByDay,
} from './services';

export type SwaggerTypescriptMutationDefaultParams<TExtra> = {
    _extraVariables?: TExtra;
    configOverride?: AxiosRequestConfig;
};
type SwaggerTypescriptUseQueryOptions<TData> = Omit<
    UseQueryOptions<SwaggerResponse<TData>, RequestError | Error>,
    'queryKey'
>;

type SwaggerTypescriptUseMutationOptions<TData, TRequest, TExtra> = UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    TRequest & SwaggerTypescriptMutationDefaultParams<TExtra>
>;

type SwaggerTypescriptUseMutationOptionsVoid<TData, TExtra> = UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    SwaggerTypescriptMutationDefaultParams<TExtra> | void
>;

/**
 *
 * Acknowledge an alert
 */
export const useAcknowledgedAlertsController_acknowledgeAlert = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        AlertAcknowledgement,
        { requestBody: AcknowledgeAlert },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return AcknowledgedAlertsController_acknowledgeAlert(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

/**
 *
 * Get all acknowledgements
 */
export const useAcknowledgedAlertsController_getAcknowledgements = (
    options?: SwaggerTypescriptUseQueryOptions<AlertAcknowledgement[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useAcknowledgedAlertsController_getAcknowledgements.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useAcknowledgedAlertsController_getAcknowledgements.info = (
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [AcknowledgedAlertsController_getAcknowledgements.key] as QueryKey,
        fun: () => AcknowledgedAlertsController_getAcknowledgements(configOverride),
    };
};
useAcknowledgedAlertsController_getAcknowledgements.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<AlertAcknowledgement[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useAcknowledgedAlertsController_getAcknowledgements.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 *
 * Unacknowledge an alert
 */
export const useAcknowledgedAlertsController_unacknowledgeAlert = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        AlertAcknowledgement,
        { requestBody: UnacknowledgeAlert },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return AcknowledgedAlertsController_unacknowledgeAlert(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useAlertsController_allAlerts = (
    options?: SwaggerTypescriptUseQueryOptions<Alert[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useAlertsController_allAlerts.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useAlertsController_allAlerts.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [AlertsController_allAlerts.key] as QueryKey,
        fun: () => AlertsController_allAlerts(configOverride),
    };
};
useAlertsController_allAlerts.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<Alert[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useAlertsController_allAlerts.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 *
 * Check email domain and get available auth providers
 */
export const useAuthController_checkEmail = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        AuthOptionsDto,
        { requestBody: CheckEmailDto },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return AuthController_checkEmail(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useBiomassController_GetFeedingConversionRate = (
    cageId: number,
    queryParams: BiomassController_GetFeedingConversionRateQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FeedingConversionRateResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBiomassController_GetFeedingConversionRate.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBiomassController_GetFeedingConversionRate.info = (
    cageId: number,
    queryParams: BiomassController_GetFeedingConversionRateQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BiomassController_GetFeedingConversionRate.key, cageId, queryParams] as QueryKey,
        fun: () =>
            BiomassController_GetFeedingConversionRate(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useBiomassController_GetFeedingConversionRate.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: BiomassController_GetFeedingConversionRateQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FeedingConversionRateResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBiomassController_GetFeedingConversionRate.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeConfigController_getConfigBioscope = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeConfigController_getConfigBioscope.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeConfigController_getConfigBioscope.info = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeConfigController_getConfigBioscope.key, bioscopeId] as QueryKey,
        fun: () =>
            BioscopeConfigController_getConfigBioscope(
                bioscopeId,

                configOverride
            ),
    };
};
useBioscopeConfigController_getConfigBioscope.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeConfigController_getConfigBioscope.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeConfigController_setConfigBioscope = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { bioscopeId: number; requestBody: BioscopeConfigParams },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,
                requestBody,

                configOverride,
            } = _o || {};

            return BioscopeConfigController_setConfigBioscope(
                bioscopeId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useBioscopeController_ActiveBioscopes = (
    options?: SwaggerTypescriptUseQueryOptions<ActiveBioscope[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_ActiveBioscopes.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeController_ActiveBioscopes.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [BioscopeController_ActiveBioscopes.key] as QueryKey,
        fun: () => BioscopeController_ActiveBioscopes(configOverride),
    };
};
useBioscopeController_ActiveBioscopes.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<ActiveBioscope[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_ActiveBioscopes.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeController_ActiveBioscopesForClient = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ActiveBioscope[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_ActiveBioscopesForClient.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeController_ActiveBioscopesForClient.info = (
    clientId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeController_ActiveBioscopesForClient.key, clientId] as QueryKey,
        fun: () =>
            BioscopeController_ActiveBioscopesForClient(
                clientId,

                configOverride
            ),
    };
};
useBioscopeController_ActiveBioscopesForClient.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ActiveBioscope[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_ActiveBioscopesForClient.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeController_CreateApiKey = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { bioscopeId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return BioscopeController_CreateApiKey(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

export const useBioscopeController_CreateBioscope = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: CreateBioscope }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return BioscopeController_CreateBioscope(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useBioscopeController_DetailBioscope = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeVersion>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_DetailBioscope.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeController_DetailBioscope.info = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeController_DetailBioscope.key, bioscopeId] as QueryKey,
        fun: () =>
            BioscopeController_DetailBioscope(
                bioscopeId,

                configOverride
            ),
    };
};
useBioscopeController_DetailBioscope.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeVersion>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_DetailBioscope.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeController_Status = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_Status.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeController_Status.info = (bioscopeId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [BioscopeController_Status.key, bioscopeId] as QueryKey,
        fun: () =>
            BioscopeController_Status(
                bioscopeId,

                configOverride
            ),
    };
};
useBioscopeController_Status.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeController_Status.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeDetailController_BioscopeDetailNew = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeDetail>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeDetailController_BioscopeDetailNew.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeDetailController_BioscopeDetailNew.info = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeDetailController_BioscopeDetailNew.key, bioscopeId] as QueryKey,
        fun: () =>
            BioscopeDetailController_BioscopeDetailNew(
                bioscopeId,

                configOverride
            ),
    };
};
useBioscopeDetailController_BioscopeDetailNew.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeDetail>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeDetailController_BioscopeDetailNew.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeImageController_GetImageList = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeImageController_GetImageList.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeImageController_GetImageList.info = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeImageController_GetImageList.key, bioscopeId] as QueryKey,
        fun: () =>
            BioscopeImageController_GetImageList(
                bioscopeId,

                configOverride
            ),
    };
};
useBioscopeImageController_GetImageList.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeImageController_GetImageList.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeImageController_Image = (
    bioscopeId: number,
    id: string,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeImageController_Image.info(
        bioscopeId,
        id,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeImageController_Image.info = (
    bioscopeId: number,
    id: string,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeImageController_Image.key, bioscopeId, id] as QueryKey,
        fun: () =>
            BioscopeImageController_Image(
                bioscopeId,
                id,

                configOverride
            ),
    };
};
useBioscopeImageController_Image.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    id: string,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeImageController_Image.info(
        bioscopeId,
        id,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeRestartController_BioscopeRestarts = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeRestart[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeRestartController_BioscopeRestarts.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeRestartController_BioscopeRestarts.info = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeRestartController_BioscopeRestarts.key, bioscopeId] as QueryKey,
        fun: () =>
            BioscopeRestartController_BioscopeRestarts(
                bioscopeId,

                configOverride
            ),
    };
};
useBioscopeRestartController_BioscopeRestarts.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeRestart[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeRestartController_BioscopeRestarts.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeRestartController_BioscopeRouterRestarts = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeRouterRestart[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeRestartController_BioscopeRouterRestarts.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useBioscopeRestartController_BioscopeRouterRestarts.info = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [BioscopeRestartController_BioscopeRouterRestarts.key, bioscopeId] as QueryKey,
        fun: () =>
            BioscopeRestartController_BioscopeRouterRestarts(
                bioscopeId,

                configOverride
            ),
    };
};
useBioscopeRestartController_BioscopeRouterRestarts.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeRouterRestart[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useBioscopeRestartController_BioscopeRouterRestarts.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useBioscopeRestartController_RestartBioscope = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { bioscopeId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return BioscopeRestartController_RestartBioscope(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

export const useBioscopeRestartController_RestartRouter = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { bioscopeId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return BioscopeRestartController_RestartRouter(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

export const useCageController_Context = (
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<CageContext>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Context.info(
        cageId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageController_Context.info = (cageId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [CageController_Context.key, cageId] as QueryKey,
        fun: () =>
            CageController_Context(
                cageId,

                configOverride
            ),
    };
};
useCageController_Context.prefetch = (
    client: QueryClient,
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<CageContext>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Context.info(
        cageId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCageController_DeleteCage = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { cageId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,

                configOverride,
            } = _o || {};

            return CageController_DeleteCage(
                cageId,

                configOverride
            );
        },
        ...options,
    });
};

export const useCageController_Distributions = (
    cageId: number,
    queryParams: CageController_DistributionsQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DistributionResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Distributions.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageController_Distributions.info = (
    cageId: number,
    queryParams: CageController_DistributionsQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [CageController_Distributions.key, cageId, queryParams] as QueryKey,
        fun: () =>
            CageController_Distributions(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useCageController_Distributions.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: CageController_DistributionsQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DistributionResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Distributions.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCageController_FishTypes = (
    options?: SwaggerTypescriptUseQueryOptions<FishType[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_FishTypes.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageController_FishTypes.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [CageController_FishTypes.key] as QueryKey,
        fun: () => CageController_FishTypes(configOverride),
    };
};
useCageController_FishTypes.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<FishType[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_FishTypes.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCageController_LiceTypes = (
    options?: SwaggerTypescriptUseQueryOptions<LiceType[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_LiceTypes.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageController_LiceTypes.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [CageController_LiceTypes.key] as QueryKey,
        fun: () => CageController_LiceTypes(configOverride),
    };
};
useCageController_LiceTypes.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<LiceType[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_LiceTypes.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCageController_Overview = (
    cageId: number,
    queryParams: CageController_OverviewQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<MeasurmentOverviewResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Overview.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageController_Overview.info = (
    cageId: number,
    queryParams: CageController_OverviewQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [CageController_Overview.key, cageId, queryParams] as QueryKey,
        fun: () =>
            CageController_Overview(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useCageController_Overview.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: CageController_OverviewQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<MeasurmentOverviewResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Overview.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCageController_Status = (
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<CageStatus>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Status.info(
        cageId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageController_Status.info = (cageId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [CageController_Status.key, cageId] as QueryKey,
        fun: () =>
            CageController_Status(
                cageId,

                configOverride
            ),
    };
};
useCageController_Status.prefetch = (
    client: QueryClient,
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<CageStatus>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageController_Status.info(
        cageId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCageController_UpdateName = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { cageId: number; requestBody: UpdateName },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,
                requestBody,

                configOverride,
            } = _o || {};

            return CageController_UpdateName(
                cageId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useCageController_UpdateType = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { cageId: number; requestBody: UpdateType },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,
                requestBody,

                configOverride,
            } = _o || {};

            return CageController_UpdateType(
                cageId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useCageImageController_GetImageList = (
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<CageImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageImageController_GetImageList.info(
        cageId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageImageController_GetImageList.info = (
    cageId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [CageImageController_GetImageList.key, cageId] as QueryKey,
        fun: () =>
            CageImageController_GetImageList(
                cageId,

                configOverride
            ),
    };
};
useCageImageController_GetImageList.prefetch = (
    client: QueryClient,
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<CageImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageImageController_GetImageList.info(
        cageId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCageImageController_Image = (
    cageId: number,
    id: string,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageImageController_Image.info(
        cageId,
        id,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCageImageController_Image.info = (
    cageId: number,
    id: string,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [CageImageController_Image.key, cageId, id] as QueryKey,
        fun: () =>
            CageImageController_Image(
                cageId,
                id,

                configOverride
            ),
    };
};
useCageImageController_Image.prefetch = (
    client: QueryClient,
    cageId: number,
    id: string,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCageImageController_Image.info(
        cageId,
        id,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_AllLiceWeeklyReport = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<AllWeeklyReportResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_AllLiceWeeklyReport.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_AllLiceWeeklyReport.info = (
    clientId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ClientController_AllLiceWeeklyReport.key, clientId] as QueryKey,
        fun: () =>
            ClientController_AllLiceWeeklyReport(
                clientId,

                configOverride
            ),
    };
};
useClientController_AllLiceWeeklyReport.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<AllWeeklyReportResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_AllLiceWeeklyReport.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_ApiUsers = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ApiUserResponse[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_ApiUsers.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_ApiUsers.info = (clientId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [ClientController_ApiUsers.key, clientId] as QueryKey,
        fun: () =>
            ClientController_ApiUsers(
                clientId,

                configOverride
            ),
    };
};
useClientController_ApiUsers.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ApiUserResponse[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_ApiUsers.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_Compare = (
    requestBody: CompareInputs,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_Compare.info(
        requestBody,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_Compare.info = (
    requestBody: CompareInputs,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ClientController_Compare.key, requestBody] as QueryKey,
        fun: () =>
            ClientController_Compare(
                requestBody,

                configOverride
            ),
    };
};
useClientController_Compare.prefetch = (
    client: QueryClient,
    requestBody: CompareInputs,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_Compare.info(
        requestBody,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_CreateApiUser = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        CreateApiUserResponse,
        { clientId: number },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                clientId,

                configOverride,
            } = _o || {};

            return ClientController_CreateApiUser(
                clientId,

                configOverride
            );
        },
        ...options,
    });
};

export const useClientController_CreateNewClient = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { requestBody: CreateNewClientInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return ClientController_CreateNewClient(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useClientController_GetAllClientsWithLocations = (
    options?: SwaggerTypescriptUseQueryOptions<ClientWithLocationsInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetAllClientsWithLocations.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_GetAllClientsWithLocations.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [ClientController_GetAllClientsWithLocations.key] as QueryKey,
        fun: () => ClientController_GetAllClientsWithLocations(configOverride),
    };
};
useClientController_GetAllClientsWithLocations.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<ClientWithLocationsInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetAllClientsWithLocations.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_GetClient = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ClientInfo>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetClient.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_GetClient.info = (clientId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [ClientController_GetClient.key, clientId] as QueryKey,
        fun: () =>
            ClientController_GetClient(
                clientId,

                configOverride
            ),
    };
};
useClientController_GetClient.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ClientInfo>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetClient.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_GetClientWithLocations = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ClientWithLocationsInfo>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetClientWithLocations.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_GetClientWithLocations.info = (
    clientId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ClientController_GetClientWithLocations.key, clientId] as QueryKey,
        fun: () =>
            ClientController_GetClientWithLocations(
                clientId,

                configOverride
            ),
    };
};
useClientController_GetClientWithLocations.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<ClientWithLocationsInfo>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetClientWithLocations.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_GetClients = (
    options?: SwaggerTypescriptUseQueryOptions<ClientInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetClients.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_GetClients.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [ClientController_GetClients.key] as QueryKey,
        fun: () => ClientController_GetClients(configOverride),
    };
};
useClientController_GetClients.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<ClientInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_GetClients.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useClientController_Overview = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<OverviewResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_Overview.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useClientController_Overview.info = (clientId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [ClientController_Overview.key, clientId] as QueryKey,
        fun: () =>
            ClientController_Overview(
                clientId,

                configOverride
            ),
    };
};
useClientController_Overview.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<OverviewResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useClientController_Overview.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCombinedWelfareController_FullWelfare = (
    cageId: number,
    queryParams: CombinedWelfareController_FullWelfareQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<CombinedWelfareResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCombinedWelfareController_FullWelfare.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCombinedWelfareController_FullWelfare.info = (
    cageId: number,
    queryParams: CombinedWelfareController_FullWelfareQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [CombinedWelfareController_FullWelfare.key, cageId, queryParams] as QueryKey,
        fun: () =>
            CombinedWelfareController_FullWelfare(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useCombinedWelfareController_FullWelfare.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: CombinedWelfareController_FullWelfareQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<CombinedWelfareResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCombinedWelfareController_FullWelfare.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useCommentsController_CreateComment = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { cageId: number; requestBody: CreateComment },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,
                requestBody,

                configOverride,
            } = _o || {};

            return CommentsController_CreateComment(
                cageId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useCommentsController_GetCageComments = (
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<Comment[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCommentsController_GetCageComments.info(
        cageId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useCommentsController_GetCageComments.info = (
    cageId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [CommentsController_GetCageComments.key, cageId] as QueryKey,
        fun: () =>
            CommentsController_GetCageComments(
                cageId,

                configOverride
            ),
    };
};
useCommentsController_GetCageComments.prefetch = (
    client: QueryClient,
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<Comment[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useCommentsController_GetCageComments.info(
        cageId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useContentController_Notices = (
    options?: SwaggerTypescriptUseQueryOptions<Notice[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useContentController_Notices.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useContentController_Notices.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [ContentController_Notices.key] as QueryKey,
        fun: () => ContentController_Notices(configOverride),
    };
};
useContentController_Notices.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<Notice[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useContentController_Notices.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 *
 * Handle Entra ID callback with MSAL
 */
export const useEntraAuthController_callback = (
    queryParams: EntraAuthController_callbackQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_callback.info(queryParams, configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useEntraAuthController_callback.info = (
    queryParams: EntraAuthController_callbackQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [EntraAuthController_callback.key, queryParams] as QueryKey,
        fun: () =>
            EntraAuthController_callback(
                queryParams,

                configOverride
            ),
    };
};
useEntraAuthController_callback.prefetch = (
    client: QueryClient,
    queryParams: EntraAuthController_callbackQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_callback.info(queryParams, configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 *
 * Check Microsoft session status
 */
export const useEntraAuthController_checkSession = (
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_checkSession.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useEntraAuthController_checkSession.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [EntraAuthController_checkSession.key] as QueryKey,
        fun: () => EntraAuthController_checkSession(configOverride),
    };
};
useEntraAuthController_checkSession.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_checkSession.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 *
 * Initiate Entra ID login flow using MSAL library
 */
export const useEntraAuthController_login = (
    queryParams: EntraAuthController_loginQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_login.info(queryParams, configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useEntraAuthController_login.info = (
    queryParams: EntraAuthController_loginQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [EntraAuthController_login.key, queryParams] as QueryKey,
        fun: () =>
            EntraAuthController_login(
                queryParams,

                configOverride
            ),
    };
};
useEntraAuthController_login.prefetch = (
    client: QueryClient,
    queryParams: EntraAuthController_loginQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_login.info(queryParams, configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 *
 * Handle Microsoft Entra ID logout
 */
export const useEntraAuthController_logout = (
    queryParams: EntraAuthController_logoutQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_logout.info(queryParams, configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useEntraAuthController_logout.info = (
    queryParams: EntraAuthController_logoutQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [EntraAuthController_logout.key, queryParams] as QueryKey,
        fun: () =>
            EntraAuthController_logout(
                queryParams,

                configOverride
            ),
    };
};
useEntraAuthController_logout.prefetch = (
    client: QueryClient,
    queryParams: EntraAuthController_logoutQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useEntraAuthController_logout.info(queryParams, configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useExternalClientController_Biomass = (
    queryParams?: ExternalClientController_BiomassQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<BiomassResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Biomass.info(queryParams, configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useExternalClientController_Biomass.info = (
    queryParams?: ExternalClientController_BiomassQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ExternalClientController_Biomass.key, queryParams] as QueryKey,
        fun: () =>
            ExternalClientController_Biomass(
                queryParams,

                configOverride
            ),
    };
};
useExternalClientController_Biomass.prefetch = (
    client: QueryClient,
    queryParams?: ExternalClientController_BiomassQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<BiomassResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Biomass.info(queryParams, configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useExternalClientController_Bioscopes = (
    options?: SwaggerTypescriptUseQueryOptions<BioscopeDetails[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Bioscopes.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useExternalClientController_Bioscopes.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [ExternalClientController_Bioscopes.key] as QueryKey,
        fun: () => ExternalClientController_Bioscopes(configOverride),
    };
};
useExternalClientController_Bioscopes.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<BioscopeDetails[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Bioscopes.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useExternalClientController_Cages = (
    options?: SwaggerTypescriptUseQueryOptions<CageDetail[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Cages.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useExternalClientController_Cages.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [ExternalClientController_Cages.key] as QueryKey,
        fun: () => ExternalClientController_Cages(configOverride),
    };
};
useExternalClientController_Cages.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<CageDetail[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Cages.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useExternalClientController_LiceData = (
    queryParams?: ExternalClientController_LiceDataQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<LiceResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_LiceData.info(queryParams, configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useExternalClientController_LiceData.info = (
    queryParams?: ExternalClientController_LiceDataQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ExternalClientController_LiceData.key, queryParams] as QueryKey,
        fun: () =>
            ExternalClientController_LiceData(
                queryParams,

                configOverride
            ),
    };
};
useExternalClientController_LiceData.prefetch = (
    client: QueryClient,
    queryParams?: ExternalClientController_LiceDataQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<LiceResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_LiceData.info(queryParams, configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useExternalClientController_Locations = (
    options?: SwaggerTypescriptUseQueryOptions<LocationDetail[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Locations.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useExternalClientController_Locations.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [ExternalClientController_Locations.key] as QueryKey,
        fun: () => ExternalClientController_Locations(configOverride),
    };
};
useExternalClientController_Locations.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<LocationDetail[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_Locations.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useExternalClientController_WelfareDailyData = (
    queryParams?: ExternalClientController_WelfareDailyDataQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<WelfareResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_WelfareDailyData.info(
        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useExternalClientController_WelfareDailyData.info = (
    queryParams?: ExternalClientController_WelfareDailyDataQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ExternalClientController_WelfareDailyData.key, queryParams] as QueryKey,
        fun: () =>
            ExternalClientController_WelfareDailyData(
                queryParams,

                configOverride
            ),
    };
};
useExternalClientController_WelfareDailyData.prefetch = (
    client: QueryClient,
    queryParams?: ExternalClientController_WelfareDailyDataQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<WelfareResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalClientController_WelfareDailyData.info(
        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useExternalMeasurementsController_GetMeasurements = (
    cageId: number,
    queryParams: ExternalMeasurementsController_GetMeasurementsQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<string>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalMeasurementsController_GetMeasurements.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useExternalMeasurementsController_GetMeasurements.info = (
    cageId: number,
    queryParams: ExternalMeasurementsController_GetMeasurementsQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ExternalMeasurementsController_GetMeasurements.key, cageId, queryParams] as QueryKey,
        fun: () =>
            ExternalMeasurementsController_GetMeasurements(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useExternalMeasurementsController_GetMeasurements.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: ExternalMeasurementsController_GetMeasurementsQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<string>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useExternalMeasurementsController_GetMeasurements.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useFeedbackController_ImageFeedback = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: ImageFeedbackDto }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return FeedbackController_ImageFeedback(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useJwksController_getJwks = (
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useJwksController_getJwks.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useJwksController_getJwks.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [JwksController_getJwks.key] as QueryKey,
        fun: () => JwksController_getJwks(configOverride),
    };
};
useJwksController_getJwks.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useJwksController_getJwks.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLaksvelController_GetLaksvelLast500Fish = (
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<Last500fishResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLaksvelController_GetLaksvelLast500Fish.info(
        cageId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLaksvelController_GetLaksvelLast500Fish.info = (
    cageId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LaksvelController_GetLaksvelLast500Fish.key, cageId] as QueryKey,
        fun: () =>
            LaksvelController_GetLaksvelLast500Fish(
                cageId,

                configOverride
            ),
    };
};
useLaksvelController_GetLaksvelLast500Fish.prefetch = (
    client: QueryClient,
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<Last500fishResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLaksvelController_GetLaksvelLast500Fish.info(
        cageId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLaksvelController_laksvelDaily = (
    cageId: number,
    queryParams?: LaksvelController_laksvelDailyQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DailyLaksvelData>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLaksvelController_laksvelDaily.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLaksvelController_laksvelDaily.info = (
    cageId: number,
    queryParams?: LaksvelController_laksvelDailyQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LaksvelController_laksvelDaily.key, cageId, queryParams] as QueryKey,
        fun: () =>
            LaksvelController_laksvelDaily(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useLaksvelController_laksvelDaily.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams?: LaksvelController_laksvelDailyQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DailyLaksvelData>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLaksvelController_laksvelDaily.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLiceController_DailyLice = (
    cageId: number,
    queryParams: LiceController_DailyLiceQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DailyLiceCount[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_DailyLice.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLiceController_DailyLice.info = (
    cageId: number,
    queryParams: LiceController_DailyLiceQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LiceController_DailyLice.key, cageId, queryParams] as QueryKey,
        fun: () =>
            LiceController_DailyLice(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useLiceController_DailyLice.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: LiceController_DailyLiceQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DailyLiceCount[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_DailyLice.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLiceController_GetImage = (
    cageId: number,
    id: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_GetImage.info(
        cageId,
        id,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLiceController_GetImage.info = (
    cageId: number,
    id: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LiceController_GetImage.key, cageId, id] as QueryKey,
        fun: () =>
            LiceController_GetImage(
                cageId,
                id,

                configOverride
            ),
    };
};
useLiceController_GetImage.prefetch = (
    client: QueryClient,
    cageId: number,
    id: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_GetImage.info(
        cageId,
        id,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLiceController_GetTemperature = (
    cageId: number,
    queryParams: LiceController_GetTemperatureQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<TemperatureResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_GetTemperature.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLiceController_GetTemperature.info = (
    cageId: number,
    queryParams: LiceController_GetTemperatureQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LiceController_GetTemperature.key, cageId, queryParams] as QueryKey,
        fun: () =>
            LiceController_GetTemperature(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useLiceController_GetTemperature.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: LiceController_GetTemperatureQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<TemperatureResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_GetTemperature.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLiceController_Images = (
    cageId: number,
    queryParams: LiceController_ImagesQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<LiceImageResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_Images.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLiceController_Images.info = (
    cageId: number,
    queryParams: LiceController_ImagesQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LiceController_Images.key, cageId, queryParams] as QueryKey,
        fun: () =>
            LiceController_Images(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useLiceController_Images.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: LiceController_ImagesQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<LiceImageResult[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_Images.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLiceController_Last200Lice = (
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<Last3DaysResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_Last200Lice.info(
        cageId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLiceController_Last200Lice.info = (cageId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [LiceController_Last200Lice.key, cageId] as QueryKey,
        fun: () =>
            LiceController_Last200Lice(
                cageId,

                configOverride
            ),
    };
};
useLiceController_Last200Lice.prefetch = (
    client: QueryClient,
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<Last3DaysResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_Last200Lice.info(
        cageId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLiceController_WeeklyLice = (
    cageId: number,
    queryParams: LiceController_WeeklyLiceQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<WeeklyLiceCount[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_WeeklyLice.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLiceController_WeeklyLice.info = (
    cageId: number,
    queryParams: LiceController_WeeklyLiceQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LiceController_WeeklyLice.key, cageId, queryParams] as QueryKey,
        fun: () =>
            LiceController_WeeklyLice(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useLiceController_WeeklyLice.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: LiceController_WeeklyLiceQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<WeeklyLiceCount[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLiceController_WeeklyLice.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLicenseController_GetLocationsLicense = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<LocationsLicenseOverview[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_GetLocationsLicense.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_GetLocationsLicense.info = (
    clientId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LicenseController_GetLocationsLicense.key, clientId] as QueryKey,
        fun: () =>
            LicenseController_GetLocationsLicense(
                clientId,

                configOverride
            ),
    };
};
useLicenseController_GetLocationsLicense.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<LocationsLicenseOverview[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_GetLocationsLicense.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLicenseController_addContractLicense = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { id: number; requestBody: CreateNewContractLicenseInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                id,
                requestBody,

                configOverride,
            } = _o || {};

            return LicenseController_addContractLicense(
                id,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useLicenseController_availableLicenses = (
    options?: SwaggerTypescriptUseQueryOptions<LicenseInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_availableLicenses.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_availableLicenses.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [LicenseController_availableLicenses.key] as QueryKey,
        fun: () => LicenseController_availableLicenses(configOverride),
    };
};
useLicenseController_availableLicenses.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<LicenseInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_availableLicenses.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLicenseController_createContract = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { requestBody: CreateNewContractInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return LicenseController_createContract(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_createNewLicenseForClient = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { requestBody: CreateNewLicenseForClientInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return LicenseController_createNewLicenseForClient(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_createNewLicenseForUser = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { requestBody: CreateNewLicenseForUserInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return LicenseController_createNewLicenseForUser(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_deleteClientLicenses = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { id: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                id,

                configOverride,
            } = _o || {};

            return LicenseController_deleteClientLicenses(
                id,

                configOverride
            );
        },
        ...options,
    });
};

export const useLicenseController_deleteContract = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { id: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                id,

                configOverride,
            } = _o || {};

            return LicenseController_deleteContract(
                id,

                configOverride
            );
        },
        ...options,
    });
};

export const useLicenseController_deleteContractLicense = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { licenseId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                licenseId,

                configOverride,
            } = _o || {};

            return LicenseController_deleteContractLicense(
                licenseId,

                configOverride
            );
        },
        ...options,
    });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_deleteUserLicenses = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { id: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                id,

                configOverride,
            } = _o || {};

            return LicenseController_deleteUserLicenses(
                id,

                configOverride
            );
        },
        ...options,
    });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_getAllClientUser = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getAllClientUser.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_getAllClientUser.info = (
    clientId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LicenseController_getAllClientUser.key, clientId] as QueryKey,
        fun: () =>
            LicenseController_getAllClientUser(
                clientId,

                configOverride
            ),
    };
};
useLicenseController_getAllClientUser.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getAllClientUser.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_getAllClientWithUser = (
    options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getAllClientWithUser.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_getAllClientWithUser.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [LicenseController_getAllClientWithUser.key] as QueryKey,
        fun: () => LicenseController_getAllClientWithUser(configOverride),
    };
};
useLicenseController_getAllClientWithUser.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getAllClientWithUser.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_getAllUser = (
    options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getAllUser.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_getAllUser.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [LicenseController_getAllUser.key] as QueryKey,
        fun: () => LicenseController_getAllUser(configOverride),
    };
};
useLicenseController_getAllUser.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<{ [x in string | number]: any }[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getAllUser.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLicenseController_getClientContracts = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<Contract[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getClientContracts.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_getClientContracts.info = (
    clientId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LicenseController_getClientContracts.key, clientId] as QueryKey,
        fun: () =>
            LicenseController_getClientContracts(
                clientId,

                configOverride
            ),
    };
};
useLicenseController_getClientContracts.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<Contract[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getClientContracts.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_getClientLicenses = (
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<LicenseResponse[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getClientLicenses.info(
        clientId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_getClientLicenses.info = (
    clientId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LicenseController_getClientLicenses.key, clientId] as QueryKey,
        fun: () =>
            LicenseController_getClientLicenses(
                clientId,

                configOverride
            ),
    };
};
useLicenseController_getClientLicenses.prefetch = (
    client: QueryClient,
    clientId: number,
    options?: SwaggerTypescriptUseQueryOptions<LicenseResponse[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getClientLicenses.info(
        clientId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLicenseController_getLicense = (
    options?: SwaggerTypescriptUseQueryOptions<LicenseInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getLicense.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_getLicense.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [LicenseController_getLicense.key] as QueryKey,
        fun: () => LicenseController_getLicense(configOverride),
    };
};
useLicenseController_getLicense.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<LicenseInfo[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getLicense.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_getUserLicenses = (
    userId: number,
    options?: SwaggerTypescriptUseQueryOptions<UserLicenseResponse[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getUserLicenses.info(
        userId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLicenseController_getUserLicenses.info = (
    userId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LicenseController_getUserLicenses.key, userId] as QueryKey,
        fun: () =>
            LicenseController_getUserLicenses(
                userId,

                configOverride
            ),
    };
};
useLicenseController_getUserLicenses.prefetch = (
    client: QueryClient,
    userId: number,
    options?: SwaggerTypescriptUseQueryOptions<UserLicenseResponse[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLicenseController_getUserLicenses.info(
        userId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const useLicenseController_updateClientLicense = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { id: number; requestBody: UpdateClientLicenseInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                id,
                requestBody,

                configOverride,
            } = _o || {};

            return LicenseController_updateClientLicense(
                id,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useLicenseController_updateContract = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { id: number; requestBody: CreateNewContractInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                id,
                requestBody,

                configOverride,
            } = _o || {};

            return LicenseController_updateContract(
                id,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useLicenseController_updateContractLicense = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { licenseId: number; requestBody: CreateNewContractLicenseInput },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                licenseId,
                requestBody,

                configOverride,
            } = _o || {};

            return LicenseController_updateContractLicense(
                licenseId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useLocationController_CageTypes = (
    options?: SwaggerTypescriptUseQueryOptions<CageType[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_CageTypes.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLocationController_CageTypes.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [LocationController_CageTypes.key] as QueryKey,
        fun: () => LocationController_CageTypes(configOverride),
    };
};
useLocationController_CageTypes.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<CageType[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_CageTypes.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLocationController_CagesWithHistoricalBioscope = (
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<Cages[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_CagesWithHistoricalBioscope.info(
        locationId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLocationController_CagesWithHistoricalBioscope.info = (
    locationId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LocationController_CagesWithHistoricalBioscope.key, locationId] as QueryKey,
        fun: () =>
            LocationController_CagesWithHistoricalBioscope(
                locationId,

                configOverride
            ),
    };
};
useLocationController_CagesWithHistoricalBioscope.prefetch = (
    client: QueryClient,
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<Cages[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_CagesWithHistoricalBioscope.info(
        locationId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLocationController_CreateCage = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: NewCage }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return LocationController_CreateCage(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useLocationController_CreateLocation = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: NewLocation }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return LocationController_CreateLocation(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useLocationController_DeleteCage = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { cageId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,

                configOverride,
            } = _o || {};

            return LocationController_DeleteCage(
                cageId,

                configOverride
            );
        },
        ...options,
    });
};

export const useLocationController_DeleteLocation = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { locationId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                locationId,

                configOverride,
            } = _o || {};

            return LocationController_DeleteLocation(
                locationId,

                configOverride
            );
        },
        ...options,
    });
};

export const useLocationController_LocationBioscopeStatus = (
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<LocationBioscopeStatusDto>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_LocationBioscopeStatus.info(
        locationId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLocationController_LocationBioscopeStatus.info = (
    locationId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LocationController_LocationBioscopeStatus.key, locationId] as QueryKey,
        fun: () =>
            LocationController_LocationBioscopeStatus(
                locationId,

                configOverride
            ),
    };
};
useLocationController_LocationBioscopeStatus.prefetch = (
    client: QueryClient,
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<LocationBioscopeStatusDto>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_LocationBioscopeStatus.info(
        locationId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLocationController_LocationBioscopes = (
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<CagesWithActiveBioscopesByLocationDto[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_LocationBioscopes.info(
        locationId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLocationController_LocationBioscopes.info = (
    locationId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LocationController_LocationBioscopes.key, locationId] as QueryKey,
        fun: () =>
            LocationController_LocationBioscopes(
                locationId,

                configOverride
            ),
    };
};
useLocationController_LocationBioscopes.prefetch = (
    client: QueryClient,
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<CagesWithActiveBioscopesByLocationDto[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_LocationBioscopes.info(
        locationId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLocationController_LocationWeightStatus = (
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<LocationWeightStatusDto>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_LocationWeightStatus.info(
        locationId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useLocationController_LocationWeightStatus.info = (
    locationId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [LocationController_LocationWeightStatus.key, locationId] as QueryKey,
        fun: () =>
            LocationController_LocationWeightStatus(
                locationId,

                configOverride
            ),
    };
};
useLocationController_LocationWeightStatus.prefetch = (
    client: QueryClient,
    locationId: number,
    options?: SwaggerTypescriptUseQueryOptions<LocationWeightStatusDto>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useLocationController_LocationWeightStatus.info(
        locationId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useLocationController_MarkHistorical = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { cageId: number; queryParams: LocationController_MarkHistoricalQueryParams },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,

                queryParams,
                configOverride,
            } = _o || {};

            return LocationController_MarkHistorical(
                cageId,

                queryParams,
                configOverride
            );
        },
        ...options,
    });
};

export const useLocationController_UpdateLocation = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { locationId: number; requestBody: UpdateLocation },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                locationId,
                requestBody,

                configOverride,
            } = _o || {};

            return LocationController_UpdateLocation(
                locationId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useMoveBioscopeController_DeleteLastMove = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { bioscopeId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return MoveBioscopeController_DeleteLastMove(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

export const useMoveBioscopeController_MoveBioscope = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { bioscopeId: number; requestBody: MoveBioscopeQueryParams },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,
                requestBody,

                configOverride,
            } = _o || {};

            return MoveBioscopeController_MoveBioscope(
                bioscopeId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useMqttJwtController_generateToken = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: JwtPayloadDto }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return MqttJwtController_generateToken(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useReportsController_CreateReport = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { cageId: number; requestBody: NewReport },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,
                requestBody,

                configOverride,
            } = _o || {};

            return ReportsController_CreateReport(
                cageId,
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useReportsController_DeleteReport = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { cageId: number; reportId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                cageId,
                reportId,

                configOverride,
            } = _o || {};

            return ReportsController_DeleteReport(
                cageId,
                reportId,

                configOverride
            );
        },
        ...options,
    });
};

export const useReportsController_Report = (
    cageId: number,
    reportId: number,
    options?: SwaggerTypescriptUseQueryOptions<ReportDetail>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useReportsController_Report.info(
        cageId,
        reportId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useReportsController_Report.info = (
    cageId: number,
    reportId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [ReportsController_Report.key, cageId, reportId] as QueryKey,
        fun: () =>
            ReportsController_Report(
                cageId,
                reportId,

                configOverride
            ),
    };
};
useReportsController_Report.prefetch = (
    client: QueryClient,
    cageId: number,
    reportId: number,
    options?: SwaggerTypescriptUseQueryOptions<ReportDetail>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useReportsController_Report.info(
        cageId,
        reportId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useReportsController_ReportList = (
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<ReportList[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useReportsController_ReportList.info(
        cageId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useReportsController_ReportList.info = (cageId: number, configOverride?: AxiosRequestConfig) => {
    return {
        key: [ReportsController_ReportList.key, cageId] as QueryKey,
        fun: () =>
            ReportsController_ReportList(
                cageId,

                configOverride
            ),
    };
};
useReportsController_ReportList.prefetch = (
    client: QueryClient,
    cageId: number,
    options?: SwaggerTypescriptUseQueryOptions<ReportList[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useReportsController_ReportList.info(
        cageId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useSmsController_SmsWebhook = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: SmsInput }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return SmsController_SmsWebhook(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

/**
 *
 * Check stream quotas for all active streams
 */
export const useStreamQuotaCheckController_checkQuotas = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { headerParams?: { 'x-api-key': string } },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const { headerParams, configOverride } = _o || {};

            return StreamQuotaCheckController_checkQuotas(headerParams, configOverride);
        },
        ...options,
    });
};

export const useUserController_DeleteUser = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { userId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                userId,

                configOverride,
            } = _o || {};

            return UserController_DeleteUser(
                userId,

                configOverride
            );
        },
        ...options,
    });
};

export const useUserController_EndImpersonate = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const { configOverride } = _o || {};

            return UserController_EndImpersonate(configOverride);
        },
        ...options,
    });
};

export const useUserController_ImpersonateUser = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { userId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                userId,

                configOverride,
            } = _o || {};

            return UserController_ImpersonateUser(
                userId,

                configOverride
            );
        },
        ...options,
    });
};

export const useUserController_InviteUser = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: InviteForm }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return UserController_InviteUser(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useUserController_Login = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { queryParams: UserController_LoginQueryParams },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const { queryParams, configOverride } = _o || {};

            return UserController_Login(queryParams, configOverride);
        },
        ...options,
    });
};

export const useUserController_Logout = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const { configOverride } = _o || {};

            return UserController_Logout(configOverride);
        },
        ...options,
    });
};

export const useUserController_Me = (
    options?: SwaggerTypescriptUseQueryOptions<Me>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useUserController_Me.info(configOverride);
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useUserController_Me.info = (configOverride?: AxiosRequestConfig) => {
    return {
        key: [UserController_Me.key] as QueryKey,
        fun: () => UserController_Me(configOverride),
    };
};
useUserController_Me.prefetch = (
    client: QueryClient,
    options?: SwaggerTypescriptUseQueryOptions<Me>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useUserController_Me.info(configOverride);

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useUserController_RegisterUser = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: RegisterUserForm }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return UserController_RegisterUser(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useUserController_ResetPassword = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { requestBody: ResetPassword }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return UserController_ResetPassword(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

export const useUserController_ResetPasswordRequest = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        any,
        { requestBody: ResetPasswordRequest },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                requestBody,

                configOverride,
            } = _o || {};

            return UserController_ResetPasswordRequest(
                requestBody,

                configOverride
            );
        },
        ...options,
    });
};

/**
 *
 * Get daily streaming quota exceeded info
 */
export const useVideoStreamController_getBioscopeQuotaCheck = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        QuotaCheckResponseDto,
        { bioscopeId: number },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return VideoStreamController_getBioscopeQuotaCheck(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

/**
 *
 * Get current stream for a bioscope
 */
export const useVideoStreamController_getBioscopeStream = (
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<StreamResponseDto>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useVideoStreamController_getBioscopeStream.info(
        bioscopeId,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useVideoStreamController_getBioscopeStream.info = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [VideoStreamController_getBioscopeStream.key, bioscopeId] as QueryKey,
        fun: () =>
            VideoStreamController_getBioscopeStream(
                bioscopeId,

                configOverride
            ),
    };
};
useVideoStreamController_getBioscopeStream.prefetch = (
    client: QueryClient,
    bioscopeId: number,
    options?: SwaggerTypescriptUseQueryOptions<StreamResponseDto>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useVideoStreamController_getBioscopeStream.info(
        bioscopeId,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};

/**
 *
 * Start a new stream or join existing stream
 */
export const useVideoStreamController_startStream = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<StreamResponseDto, { bioscopeId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return VideoStreamController_startStream(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

/**
 *
 * Stop streaming or leave stream
 */
export const useVideoStreamController_stopStream = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { bioscopeId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return VideoStreamController_stopStream(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

/**
 *
 * Update viewer activity timestamp
 */
export const useVideoStreamController_updateActivity = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<any, { bioscopeId: number }, TExtra>
) => {
    return useMutation({
        mutationFn: (_o) => {
            const {
                bioscopeId,

                configOverride,
            } = _o || {};

            return VideoStreamController_updateActivity(
                bioscopeId,

                configOverride
            );
        },
        ...options,
    });
};

/**
 *
 * Handle Mux webhook notifications
 */
export const useVideoStreamWebhookController_handleMuxWebhook = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        { [x in string | number]: any },
        { headerParams?: { 'mux-signature': string } },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const { headerParams, configOverride } = _o || {};

            return VideoStreamWebhookController_handleMuxWebhook(headerParams, configOverride);
        },
        ...options,
    });
};

/**
 *
 * Handle Mux webhook notifications
 */
export const useVideoStreamWebhookNewController_handleMuxWebhook = <TExtra,>(
    options?: SwaggerTypescriptUseMutationOptions<
        { [x in string | number]: any },
        { headerParams?: { 'mux-signature': string } },
        TExtra
    >
) => {
    return useMutation({
        mutationFn: (_o) => {
            const { headerParams, configOverride } = _o || {};

            return VideoStreamWebhookNewController_handleMuxWebhook(headerParams, configOverride);
        },
        ...options,
    });
};

export const useWelfareController_FullWelfare = (
    cageId: number,
    queryParams: WelfareController_FullWelfareQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullWelfareResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_FullWelfare.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useWelfareController_FullWelfare.info = (
    cageId: number,
    queryParams: WelfareController_FullWelfareQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [WelfareController_FullWelfare.key, cageId, queryParams] as QueryKey,
        fun: () =>
            WelfareController_FullWelfare(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useWelfareController_FullWelfare.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: WelfareController_FullWelfareQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullWelfareResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_FullWelfare.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useWelfareController_FullWelfareColor = (
    cageId: number,
    queryParams: WelfareController_FullWelfareColorQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullWelfareColorResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_FullWelfareColor.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useWelfareController_FullWelfareColor.info = (
    cageId: number,
    queryParams: WelfareController_FullWelfareColorQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [WelfareController_FullWelfareColor.key, cageId, queryParams] as QueryKey,
        fun: () =>
            WelfareController_FullWelfareColor(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useWelfareController_FullWelfareColor.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: WelfareController_FullWelfareColorQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullWelfareColorResult>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_FullWelfareColor.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useWelfareController_GetFullSizeImage = (
    cageId: number,
    id: string,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_GetFullSizeImage.info(
        cageId,
        id,

        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useWelfareController_GetFullSizeImage.info = (
    cageId: number,
    id: string,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [WelfareController_GetFullSizeImage.key, cageId, id] as QueryKey,
        fun: () =>
            WelfareController_GetFullSizeImage(
                cageId,
                id,

                configOverride
            ),
    };
};
useWelfareController_GetFullSizeImage.prefetch = (
    client: QueryClient,
    cageId: number,
    id: string,
    options?: SwaggerTypescriptUseQueryOptions<any>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_GetFullSizeImage.info(
        cageId,
        id,

        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useWelfareController_allImages = (
    cageId: number,
    queryParams?: WelfareController_allImagesQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullSizeImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_allImages.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useWelfareController_allImages.info = (
    cageId: number,
    queryParams?: WelfareController_allImagesQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [WelfareController_allImages.key, cageId, queryParams] as QueryKey,
        fun: () =>
            WelfareController_allImages(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useWelfareController_allImages.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams?: WelfareController_allImagesQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullSizeImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_allImages.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useWelfareController_dayWithImages = (
    cageId: number,
    queryParams?: WelfareController_dayWithImagesQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DaysWithImages[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_dayWithImages.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useWelfareController_dayWithImages.info = (
    cageId: number,
    queryParams?: WelfareController_dayWithImagesQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [WelfareController_dayWithImages.key, cageId, queryParams] as QueryKey,
        fun: () =>
            WelfareController_dayWithImages(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useWelfareController_dayWithImages.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams?: WelfareController_dayWithImagesQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<DaysWithImages[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_dayWithImages.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
export const useWelfareController_imagesByDay = (
    cageId: number,
    queryParams: WelfareController_imagesByDayQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullSizeImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_imagesByDay.info(
        cageId,

        queryParams,
        configOverride
    );
    return useQuery({
        queryKey: key,
        queryFn: fun,
        ...options,
    });
};
useWelfareController_imagesByDay.info = (
    cageId: number,
    queryParams: WelfareController_imagesByDayQueryParams,
    configOverride?: AxiosRequestConfig
) => {
    return {
        key: [WelfareController_imagesByDay.key, cageId, queryParams] as QueryKey,
        fun: () =>
            WelfareController_imagesByDay(
                cageId,

                queryParams,

                configOverride
            ),
    };
};
useWelfareController_imagesByDay.prefetch = (
    client: QueryClient,
    cageId: number,
    queryParams: WelfareController_imagesByDayQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<FullSizeImage[]>,
    configOverride?: AxiosRequestConfig
) => {
    const { key, fun } = useWelfareController_imagesByDay.info(
        cageId,

        queryParams,
        configOverride
    );

    return client.getQueryData(key)
        ? Promise.resolve()
        : client.prefetchQuery({
              queryKey: key,
              queryFn: () => fun(),
              ...options,
          });
};
