import type { CageType } from '@/services/types';

/**
 *
 * @param cageType
 * @param alarmType
 * @returns boolean
 *
 * TODO test this function with JEST
 */

interface SupressDepthAlarmProps {
    cageType: CageType;
    alarmType: string;
}

export const supressDepthAlarm = ({ cageType, alarmType }: SupressDepthAlarmProps) => {
    const cageTypesNotCompliant = ['submerged', 'landbased', 'smolt_production'];
    const alarmTypesNotCompliant = ['maxDepth', 'minDepth'];
    return (
        cageTypesNotCompliant.includes(cageType.name) && alarmTypesNotCompliant.includes(alarmType)
    );
};

interface SupressStatusMessagesProps {
    cageType: CageType;
    statusTexts: string[];
}

export const supressStatusMessages = ({ cageType, statusTexts }: SupressStatusMessagesProps) => {
    const filteredStatusMessages = statusTexts.filter((text) => {
        return !supressDepthAlarm({ cageType, alarmType: text });
    });

    return filteredStatusMessages.length > 0 ? filteredStatusMessages : ['allgood'];
};
