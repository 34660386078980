import styles from './UmerBoxElementImage.module.scss';

export interface UmerBoxElementImageProps {
    imagePath?: string;
}

const UmerBoxElementImage = ({ imagePath = '' }: UmerBoxElementImageProps) => {
    return (
        <div className={styles.umerboxelementimage}>
            <img className={styles.image} src={imagePath} alt={'Fish Image'} />
        </div>
    );
};

export default UmerBoxElementImage;
export { UmerBoxElementImage };
