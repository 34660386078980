import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/atoms/Loader';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import UmerBox from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import useCage from '@/contexts/cage-context';
import { ImageState } from '@/hooks/image-state';
import { ImageFeedbackDto } from '@/services/types';
import { ImageViewContainer } from 'components/organisms/ImageViewer';

import styles from './CageImagePageContent.module.scss';

export interface CageImagePageContentProps {
    source: string;
    detectionImageState: ImageState;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    cageId?: number;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
}

const CageImagePageContent = ({
    detectionImageState,
    source,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
}: CageImagePageContentProps) => {
    const { t } = useTranslation();
    const { cage } = useCage();
    const isColor = source === 'color';

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Images')} />
            <div className={styles.pageBoxLayout}>
                <div>
                    <UmerBox>
                        {detectionImageState.loading && <Loader />}
                        {!detectionImageState.loading && (
                            <>
                                {detectionImageState.dayswithimages.length === 0 ? (
                                    <NoComponentDataAvailable />
                                ) : (
                                    <ImageViewContainer
                                        key="catchoftheday"
                                        instanceKey="catchoftheday"
                                        isColor={isColor}
                                        isOptoScaleAdmin={isOptoscaleAdmin}
                                        onCommitImageFeedbackDto={onCommitFalseDetection}
                                        viewerConfig={{
                                            showMagnifyer: false,
                                            scrollActivated: true,
                                            showPois: false,
                                            zoomDisabled: true,
                                            showWoundLabel: false,
                                            magnifyerDisabled: false,
                                            showHelpText: true,
                                        }}
                                        {...detectionImageState}
                                    />
                                )}
                            </>
                        )}
                    </UmerBox>
                </div>
                <div>
                    <UmerBox>
                        <UmerBoxElementHeader
                            headerText={t('Todays catch')}
                            description={t(
                                'A random selection of todays images'
                            )}></UmerBoxElementHeader>
                    </UmerBox>
                </div>
            </div>
        </>
    );
};

export default CageImagePageContent;
export { CageImagePageContent };
