import styles from './HelpPageUserManual.module.scss';
interface HelpPageUserManualProps {
    linkList: {
        title: string;
        link: string;
    }[];
    linkListWinch?: {
        title: string;
        link: string;
    }[];
    heading: any;
    description: string;
}

const HelpPageUserManual = ({
    linkList,
    linkListWinch = [],
    heading,
    description,
}: HelpPageUserManualProps) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <h2 className={styles.helpHeading}>{heading}</h2>
                <p>{description}</p>
                <div className="row">
                    <div className="col-md-6">
                        <ul className={styles.helpLinks}>
                            {linkList.map((list, index) => {
                                return (
                                    <li key={index}>
                                        <a href={list.link} target="_blank" rel="noreferrer">
                                            {list.title}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul className={styles.helpLinks}>
                            {linkListWinch.map((list, index) => {
                                return (
                                    <li key={index}>
                                        <a href={list.link} target="_blank" rel="noreferrer">
                                            {list.title}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HelpPageUserManual;
