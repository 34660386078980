import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Button,
    InputGroup,
    InputGroupText,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import cn from 'classnames';
import GenericIcon from '@/components/atoms/icons/GenericIcon';
import { LockIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import ChangeLanguage from 'components/molecules/ChangeLanguage/ChangeLanguage';

import '../public.scss';

interface ResetPassword2Props {
    resetPassword: (props: { newPassword: string }) => Promise<any>;
    hasToken: boolean;
    onSuccessfulReset: () => void;
}

export const ResetPassword2 = ({
    resetPassword,
    hasToken,
    onSuccessfulReset,
}: ResetPassword2Props) => {
    const [newPassword, setNewPassword] = React.useState('');
    const [error, setError] = React.useState(null);
    const { t } = useTranslation();
    const onSubmit = async () => {
        try {
            await resetPassword({ newPassword });
            onSuccessfulReset();
        } catch (e: any) {
            console.log(e);
            setError(e?.message?.message ?? 'Something went wrong');
        }
    };
    return (
        <div className="loginContainer">
            <video autoPlay loop muted className="backgroundVideo">
                <source src="./loginVideoBg.mp4" type="video/mp4" />
            </video>

            <div className="loginRow">
                <div className="leftColumn">
                    <div className="overlayContent">
                        <h1>{t('Discover Precision')}</h1>
                        <p>{t('Get full control of fish weight...')}</p>
                    </div>
                </div>
                <div className="rightColumn">
                    <div className="topSection">
                        <img src="/logo.png" alt="" className="logo" />
                        <div className="languageBox">
                            <ChangeLanguage />
                        </div>
                    </div>
                    <div className="loginBox">
                        {hasToken ? (
                            <>
                                <FormGroup>
                                    <Label className="text-white">{t('New password')}</Label>
                                    <InputGroup className="inputGroup">
                                        <InputGroupText className="inputGroupText">
                                            <GenericIcon icon={LockIconSVG} />
                                        </InputGroupText>
                                        <Input
                                            type="password"
                                            name="password"
                                            data-testid="password"
                                            placeholder={t('Password')}
                                            className="formControl"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                {error ? <Alert color="danger">{error}</Alert> : ''}
                                <div className="mt-2 d-flex">
                                    <Button
                                        onClick={onSubmit}
                                        className="btn-block"
                                        data-testid="submitButton">
                                        {t('Save new password')}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Alert color="danger">{t('Invalid token')}</Alert>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
