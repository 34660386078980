import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import classnames from 'classnames';
import { t } from 'i18next';
import moment from 'moment-timezone';

import { Button } from '@/components/atoms/Buttons';
import { CheckBox } from '@/components/atoms/CheckBox';
import { DatePickerListItem } from '@/components/atoms/DatePickerListItem';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    CalendarIconSVG,
    CheckMarkIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import {
    DatePickerListItems,
    DatePickerListItemsProps,
} from '@/components/molecules/DatePicker/DatePickerListItems';
import settings from '@/config/settings';
import { DISPLAY_DAY_FORMAT } from '@/utils/formattedDay';

import IconButton from '../../atoms/Buttons/IconButton';

import styles from './DatePicker.module.scss';

export interface OverrideObject {
    text?: string;
    dateFrom?: string;
    dateTo?: string;
}

export interface DateReturnObject {
    dateFrom?: string;
    dateTo?: string;
    shouldStick?: boolean;
}

export interface DatePickerProps {
    onDateChange?: (data: DateReturnObject) => void;
    fromDefault?: string;
    toDefault?: string;
}

const DatePicker = ({ onDateChange = () => {}, fromDefault, toDefault }: DatePickerProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [datePickerButtonText, setDatePickerButtonText] = useState<string | undefined>(
        'Last 4 weeks'
    );

    const [fromFocused, setFromFocused] = useState<boolean>();
    const [toFocused, setToFocused] = useState<boolean>();
    const [from, setFrom] = useState<string | undefined>(fromDefault ?? undefined);
    const [to, setTo] = useState<string | undefined>(toDefault ?? undefined);
    const [tempFrom, setTempFrom] = useState<string | undefined>(fromDefault ?? undefined);
    const [tempTo, setTempTo] = useState<string | undefined>(toDefault ?? undefined);
    const [showOnAllPages, setShowOnAllPages] = useState<boolean>(false);
    const [datePickerListItems, setDatePickerListItems] = useState(DatePickerListItems);
    const [currentSelectedOverride, setCurrentSelectedOverride] = useState<
        DatePickerListItemsProps | undefined
    >(undefined);

    const hasChangedDates = useRef<boolean>(false);
    const override = useRef<OverrideObject | undefined>(undefined);
    const isUsingPresets = useRef<boolean>(true);

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdownOpen(!dropdownOpen);
    };

    const ref = useRef<HTMLDivElement>(null);

    const handleDatePickerListItems = (
        inputItem: string,
        overrideObj: OverrideObject | undefined
    ) => {
        const temp = DatePickerListItems.map((item) => {
            let tempSelected = false;

            if (item.leftSideText === inputItem) {
                tempSelected = true;
                setCurrentSelectedOverride(item);

                if (currentSelectedOverride === item) {
                    tempSelected = false;
                    setCurrentSelectedOverride(undefined);
                }
            }

            return {
                ...item,
                selected: tempSelected,
            };
        });
        setDatePickerListItems(temp);

        override.current = overrideObj;
    };

    const handleClickOnPreset = (input: string, overrideObj: OverrideObject | undefined) => {
        handleDatePickerListItems(input, overrideObj);

        hasChangedDates.current = true;
        isUsingPresets.current = true;
    };

    const handleClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            onCancel();
        }
    };

    const handleSetFromDate = (date) => {
        hasChangedDates.current = true;
        isUsingPresets.current = false;

        setFrom(from);

        setTempFrom(date ? date.format('YYYY-MM-DD') : undefined);
        setFromFocused(true);
    };

    const handleSetToDate = (date) => {
        hasChangedDates.current = true;
        isUsingPresets.current = false;

        setTo(to);

        setTempTo(date ? date.format('YYYY-MM-DD') : undefined);
        setToFocused(true);
    };

    const handleFromFocusChange = useCallback(({ focused }) => {
        setFromFocused(focused);
    }, []);

    const handleToFocusChange = useCallback(({ focused }) => {
        setToFocused(focused);
    }, []);

    const onCancel = () => {
        setDropdownOpen(false);
    };
    const handleResetFilters = () => {
        setFrom(undefined);
        setTo(undefined);
        setTempFrom(undefined);
        setTempTo(undefined);

        const initialText = 'Last 4 weeks';
        const temp = DatePickerListItems.find((item) => item.leftSideText === initialText);

        handleDatePickerListItems(initialText, temp?.overrideDateObject);

        onDateChange({
            dateFrom: undefined,
            dateTo: undefined,
            shouldStick: showOnAllPages,
        });
    };

    const handleConfirm = () => {
        setFrom(tempFrom);
        setTo(tempTo);
        setDropdownOpen(false);

        onDateChange({
            dateFrom: tempFrom,
            dateTo: tempTo,
            shouldStick: showOnAllPages,
        });
    };

    useEffect(() => {
        if (toDefault || fromDefault) return;

        const initialText = 'Last 4 weeks';
        const temp = DatePickerListItems.find((item) => item.leftSideText === initialText);

        handleDatePickerListItems(initialText, temp?.overrideDateObject);
    }, []);

    useEffect(() => {
        let dateFrom = undefined;
        let dateTo = undefined;

        if (override.current === undefined) {
            dateFrom = from;
            dateTo = to;
        } else {
            dateFrom = override.current.dateFrom;
            dateTo = override.current.dateTo;
        }

        if (override.current === undefined) {
            setDatePickerButtonText(
                `${moment(dateFrom).format('DD-MM-YYYY')} - ${moment(dateTo).format('DD-MM-YYYY')}`
            );
        } else {
            setDatePickerButtonText(override.current.text);
        }

        if (!hasChangedDates.current) return;

        onDateChange({
            dateFrom: dateFrom,
            dateTo: dateTo,
            shouldStick: showOnAllPages,
        });
    }, [from, to, override.current, currentSelectedOverride, showOnAllPages]);

    useEffect(() => {
        if (isUsingPresets.current) return;

        handleDatePickerListItems('', undefined);
    }, [from, to]);

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        const date = datePickerListItems.find((item) => {
            return (
                item.overrideDateObject.dateFrom === moment(from).format('YYYY-MM-DD') &&
                item.overrideDateObject.dateTo === moment(to).format('YYYY-MM-DD')
            );
        });

        if (date !== undefined) {
            handleDatePickerListItems(date.overrideDateObject.text, date.overrideDateObject);
        }
    }, []);

    return (
        <div className={styles.datepicker}>
            <IconButton
                variant={'secondary'}
                size={'medium'}
                onlyIcon={false}
                onClick={toggleDropdown}
                aria-label={'Open date picker'}
                buttonText={t(datePickerButtonText)}
                iconPos={'left'}>
                <GenericIcon
                    icon={showOnAllPages ? CheckMarkIconSVG : CalendarIconSVG}
                    color={showOnAllPages ? settings.primaryColor : 'black'}
                />
            </IconButton>
            <div
                className={classnames(styles.dropdownMenu, dropdownOpen ? styles.open : '')}
                ref={ref}>
                <div className={`${styles.sizeMaintainer} ${styles.title}`}>{t('Date Picker')}</div>
                <div className={styles.datePickerContainer}>
                    <SingleDatePicker
                        date={tempFrom ? moment(tempFrom) : null}
                        placeholder={t('fromFilterPlaceholder')}
                        isOutsideRange={() => false}
                        onDateChange={handleSetFromDate}
                        focused={fromFocused}
                        onFocusChange={handleFromFocusChange}
                        numberOfMonths={1}
                        displayFormat={DISPLAY_DAY_FORMAT}
                        showClearDate={false}
                        customInputIcon={
                            <div className={styles.iconContainer}>
                                <GenericIcon icon={CalendarIconSVG} size={20} />
                            </div>
                        }
                        inputIconPosition={'after'}
                    />
                </div>
                <div className={styles.datePickerContainer}>
                    <SingleDatePicker
                        date={tempTo ? moment(tempTo) : null}
                        placeholder={t('toFilterPlaceholder')}
                        isOutsideRange={() => false}
                        onDateChange={handleSetToDate}
                        focused={toFocused}
                        required
                        onFocusChange={handleToFocusChange}
                        numberOfMonths={1}
                        displayFormat={DISPLAY_DAY_FORMAT}
                        showClearDate={false}
                        customInputIcon={
                            <div className={styles.iconContainer}>
                                <GenericIcon icon={CalendarIconSVG} size={20} />
                            </div>
                        }
                        inputIconPosition={'after'}
                    />
                </div>
                <div className={styles.sizeMaintainer}>
                    <Button size={'large'} variant="secondary" onClick={handleResetFilters}>
                        <div className={styles.resetFilterButton}>{t('Reset filters')}</div>
                    </Button>
                </div>
                <div className={styles.sizeMaintainer}>
                    <Button size={'large'} onClick={handleConfirm}>
                        <div className={styles.resetFilterButton}>{t('Confirm dates')}</div>
                    </Button>
                </div>
                <div className={`${styles.sizeMaintainer} ${styles.title}`}>{t('Presets')}</div>
                {datePickerListItems.map((item, index) => {
                    return (
                        <DatePickerListItem
                            key={`${index}-${item.leftSideText}`}
                            selected={item.selected}
                            rightSideText={item.rightSideText}
                            leftSideText={t(item.leftSideText)}
                            overrideDates={item.overrideDateObject}
                            onClick={handleClickOnPreset}
                        />
                    );
                })}

                {
                    // Removed functionality for now.
                    /*
                    <div className={styles.divider}/>
                    <div className={styles.checkBoxContainer}>
                    <CheckBox
                        label={t('Show on all pages')}
                        value={showOnAllPages}
                        onChange={() => setShowOnAllPages(!showOnAllPages)}
                    />
                    </div>
                 
                     */
                }
            </div>
        </div>
    );
};

export default DatePicker;
export { DatePicker };
