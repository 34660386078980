import React from 'react';

import { CageFormattedOverviewProvider } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
import { CageGraphDataProvider } from '@/routes/Client/Cage/components/cage-graph-data-context';
import { CageOverviewContextProvider } from '@/routes/Client/Cage/components/cage-overview-context';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';

import { CageWelfareFishSpeedContainer } from './CageWelfareFishSpeedContainer';

function CageWelfareFishSpeedProviderContainer({
    clientId,
    locationId,
    cageId,
}: CagePageInterface) {
    return (
        <CageOverviewContextProvider>
            <CageFormattedOverviewProvider>
                <CageGraphDataProvider>
                    <CageWelfareFishSpeedContainer
                        clientId={clientId}
                        locationId={locationId}
                        cageId={cageId}
                    />
                </CageGraphDataProvider>
            </CageFormattedOverviewProvider>
        </CageOverviewContextProvider>
    );
}

export default CageWelfareFishSpeedProviderContainer;
export { CageWelfareFishSpeedProviderContainer };
