import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

import { AutoCompleteInput } from '@/components/atoms/AutocompleteInput/AutoCompleteInput';
import Loader from '@/components/atoms/Loader';
import { FishType, LiceType } from '@/services/types';

interface AddNewLocationInput {
    name: string;
    timezone: string;
    aquacultureSiteId?: number;
    latitude?: number;
    longitude?: number;
    id?: number;
    fishtypeid: number;
    licetypeid: number;
}
type NewOrModify =
    | { modifyOrNew: 'new'; initialState?: undefined }
    | { modifyOrNew: 'modify'; initialState: AddNewLocationInput };

type AddLocationModalProps = {
    onSubmit: (props: AddNewLocationInput) => Promise<unknown>;
    fetchAquaCultureSites: (input: string) => Promise<
        {
            id: number;
            value: string;
            municipality: string;
            county: string;
            latitude?: number;
            longitude?: number;
        }[]
    >;
    fishTypesList: FishType[];
    liceTypesList: LiceType[];
    onClose: () => void;
    isOpen: boolean;
} & NewOrModify;

export const AddLocationModal = ({
    onSubmit,
    onClose,
    isOpen,
    fetchAquaCultureSites,
    modifyOrNew,
    initialState,
    fishTypesList = [] as FishType[],
    liceTypesList = [] as LiceType[],
}: AddLocationModalProps) => {
    const { t } = useTranslation();

    const defaultInitialState = {
        name: '',
        timezone: 'Europe/Oslo',
        aquacultureSiteId: null as unknown as number,
        latitude: null as unknown as number,
        longitude: null as unknown as number,
        fishtypeid: -1 as unknown as number,
        licetypeid: -1 as unknown as number,
    } as AddNewLocationInput;
    const defaultValues = initialState
        ? ({
              name: initialState.name,
              timezone: initialState.timezone,
              aquacultureSiteId: initialState.aquacultureSiteId ?? null,
              latitude: initialState.latitude,
              longitude: initialState.longitude,
              id: initialState.id,
              fishtypeid: initialState.fishtypeid,
              licetypeid: initialState.licetypeid,
          } as AddNewLocationInput)
        : defaultInitialState;

    const {
        register: rawRegister,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm({ defaultValues });

    console.log(getValues());
    //The following function is needed to to Reactstrap requiring refs to be passed to
    //"innerRef" instead of "ref"
    const register = (...params: Parameters<typeof rawRegister>) => {
        const { ref, ...attributes } = rawRegister(...params);
        return {
            ...attributes,
            innerRef: ref,
        };
    };
    const tzNames = moment.tz.names().map((tz) => ({ value: tz }));
    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <Form
                onSubmit={handleSubmit((e) => {
                    onSubmit(e).then(onClose);
                })}>
                <ModalHeader toggle={onClose}>Legg til anlegg</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="name">Navn</Label>
                        <Input
                            {...register('name', {
                                required: true,
                                minLength: 1,
                            })}
                            placeholder="Munkholmen"
                            autoComplete="off"
                        />
                        <Label for="timezone">Timezone</Label>
                        <Input
                            {...register('timezone', {
                                required: true,
                                minLength: 1,
                            })}
                            type="select"
                            required
                            autoComplete="off">
                            {tzNames.map((tz) => (
                                <option key={tz.value} value={tz.value}>
                                    {tz.value}
                                </option>
                            ))}
                        </Input>
                        <Label for="autocompleteinput">Søk akvakulturid</Label>

                        <AutoCompleteInput
                            list="aquaCultureSiteList"
                            placeholder="MUNKHOLMEN"
                            fetchData={fetchAquaCultureSites}
                            renderer={(data) =>
                                `${data.value} - ${data.municipality}, ${data.county}`
                            }
                            onAutoCompleteSelected={(datum) => {
                                setValue('aquacultureSiteId', datum?.id ?? null);
                                setValue('latitude', datum?.latitude ?? null);
                                setValue('longitude', datum?.longitude);
                            }}
                            autoComplete="off"
                        />
                        <Label for="aquaCultureSites">Akvakulturid</Label>
                        <Input {...register('aquacultureSiteId', { required: false })} />
                        <Label for="latitude">Latitude</Label>
                        <Input {...register('latitude', { required: false })} />
                        <Label for="longitude">Longitude</Label>
                        <Input {...register('longitude', { required: false })} />
                        <Label for="fishtypeid">Fish type</Label>
                        <Input
                            {...register('fishtypeid', { required: true })}
                            type="select"
                            required>
                            <option value={-1}>{`--- ${t('choose fish type')} ---`}</option>
                            {fishTypesList?.map((fishType) => {
                                return (
                                    <option key={fishType.id} value={fishType.id as number}>
                                        {t(fishType.name)}
                                    </option>
                                );
                            })}
                        </Input>

                        <Label for="licetypeid">Lice type</Label>
                        <Input
                            {...register('licetypeid', { required: true })}
                            type="select"
                            required>
                            <option value={-1}>{`--- ${t('choose lice type')} ---`}</option>
                            {liceTypesList?.map((liceType) => {
                                return (
                                    <option key={liceType.id} value={liceType.id as number}>
                                        {t(liceType.name)}
                                    </option>
                                );
                            })}
                        </Input>
                    </FormGroup>
                    {Object.keys(errors).length > 0 ? (
                        <Alert color="danger">{JSON.stringify(errors)}</Alert>
                    ) : (
                        ''
                    )}
                </ModalBody>
                <ModalFooter>
                    {isSubmitting ? (
                        <div className="mr-2">
                            <Loader size={'30px'} />
                        </div>
                    ) : null}
                    <Button color="primary" type="submit">
                        {modifyOrNew === 'new' ? 'Legg til' : 'Oppdater'}
                    </Button>{' '}
                    <Button color="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
