import { useTranslation } from 'react-i18next';
import { useCage } from 'contexts/cage-context';
import { ImageFeedbackDto } from 'services/types';

import Loader from '@/components/atoms/Loader';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import { CombinedFishHealthWithTemperatureData } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { useCageFilter } from '@/contexts/cage-filter-context';
import { useMe } from '@/contexts/meContext';
import type { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import { placeHolder } from '@/services/config';
import {
    useCombinedWelfareController_FullWelfare,
    useFeedbackController_ImageFeedback,
    useWelfareController_allImages,
} from '@/services/hooks';
import tsToString from '@/utils/tsToString';

import { CageWelfareMaturationDetectionsPageContent } from './CageWelfareMaturationDetectionsPageContent';

const CageWelfareMaturationDetectionsContainer = ({
    clientId,
    locationId,
    cageId,
}: CagePageInterface) => {
    const { t } = useTranslation();
    const { isOptoscaleAdmin, state } = useMe();

    const { timezone, loading: cageLoading, hasColorWelfare, cage } = useCage();
    const { normalizedFrom, normalizedTo, daypartTag, setFilter } = useCageFilter();
    const from = normalizedFrom ? tsToString(normalizedFrom, timezone) : undefined;
    const to = normalizedTo ? tsToString(normalizedTo, timezone) : undefined;

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    // Load list of dates with images
    // color-welfare-image
    const type = hasColorWelfare ? 'color-welfare-image' : 'bw-welfare';

    // Load fish health data to get the percentage of maturation
    const { data: fishHealth, isLoading: fishHealthLoading } =
        useCombinedWelfareController_FullWelfare(
            cageId,
            { from: from, to: to, daypartTag: daypartTag },
            { staleTime: 5 * 60 * 1000 }
        );

    const combinedFishHealthData = hasColorWelfare ? fishHealth?.data.color : fishHealth?.data.bw;
    const combinedFishHealthWithTemperatureData = combinedFishHealthData
        ? CombinedFishHealthWithTemperatureData({
              combinedFishHealthData: combinedFishHealthData,
              temperature: [],
          })
        : undefined;

    const { data: imagesdata, isLoading: imagesdataLoading } = useWelfareController_allImages(
        cageId,
        { type },
        { placeholderData: placeHolder([]), keepPreviousData: true }
    );

    const loading = cageLoading || imagesdataLoading || fishHealthLoading;

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }

        if (cageId) {
            feedbackDto = { ...feedbackDto, cageId: cageId };
        }

        const { status, data } = await reportFeedbackAsync({ requestBody: feedbackDto });
        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Detections maturation')}></PageTitleRow>
            {loading && <Loader />}
            {!loading && (
                <CageWelfareMaturationDetectionsPageContent
                    source={fishHealth.data.source as unknown as string}
                    fishHealthData={combinedFishHealthWithTemperatureData as FishHealthData}
                    imagesData={imagesdataLoading ? null : imagesdata.data}
                    isOptoscaleAdmin={isOptoscaleAdmin}
                    onCommitFalseDetection={onCommitImageFeedbackDto}
                    cageId={cageId}
                    timezone={timezone}
                />
            )}
        </>
    );
};

export default CageWelfareMaturationDetectionsContainer;
export { CageWelfareMaturationDetectionsContainer };
