import React, { useEffect, useState } from 'react';
import i18n, { t } from 'i18next';
import isNumberOk from 'utils/isNumberOk';

import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { TrendIconEnum } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend/TrendIconUtils';
import { UmerBoxElementText } from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { RestFormattedOverview } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';

import styles from './WeightStatus.module.scss';

interface WeightStatusProps {
    formattedOverview: RestFormattedOverview;
    horizontal?: boolean;
    weightUnit?: string;
}

const KFACTOR_GROWTH_DIFF_THRESHOLD = 0;

const WeightStatus = ({
    formattedOverview,
    horizontal = false,
    weightUnit = 'g',
}: WeightStatusProps) => {
    const [kFactor, setKFactor] = useState<string>('');
    const [kFactorGrowthExplanation, setKFactorGrowthExplanation] = useState<string>('');
    const [kFactorGrowth, setKFactorGrowth] = useState<TrendIconEnum>(TrendIconEnum.TrendNone);

    const [cv, setCV] = useState<string>('');
    const [sgr, setSgr] = useState<string>('');

    const [rgi, setRgi] = useState<string>('');

    const [finishedLoading, setFinishedLoading] = useState<boolean>(false);

    useEffect(() => {
        if (formattedOverview && !formattedOverview.loading && formattedOverview.data) {
            setKFactor(
                localeFormatNumber(
                    Math.abs(Number(formattedOverview.data.kfactor)),
                    2,
                    i18n.language,
                    '-'
                )
            );

            if (isNumberOk(formattedOverview.data.kfactorGrowth)) {
                const kfactorGrowth = Math.abs(Number(formattedOverview.data.kfactorGrowth));

                if (kfactorGrowth > KFACTOR_GROWTH_DIFF_THRESHOLD) {
                    setKFactorGrowth(TrendIconEnum.TrendNegativeUp);
                } else if (kfactorGrowth < KFACTOR_GROWTH_DIFF_THRESHOLD) {
                    setKFactorGrowth(TrendIconEnum.TrendPositiveDown);
                }
            } else {
                setKFactor('-');
                setKFactorGrowth(TrendIconEnum.TrendNone);
            }

            if (formattedOverview.data.cv) {
                setCV(
                    `${localeFormatNumber(
                        Math.abs(Number(formattedOverview.data.cv)),
                        2,
                        i18n.language,
                        '-'
                    )}%`
                );
            } else {
                setCV('-');
            }

            if (formattedOverview.data.sgr) {
                setSgr(
                    `${localeFormatNumber(
                        Math.abs(Number(formattedOverview.data.sgr)),
                        2,
                        i18n.language,
                        '-'
                    )}%`
                );
            } else {
                setSgr('-');
            }

            if (formattedOverview.data.rgi) {
                setRgi(
                    localeFormatNumber(
                        Math.abs(Number(formattedOverview.data.rgi)),
                        2,
                        i18n.language,
                        '-'
                    )
                );
            } else {
                setRgi('-');
            }

            setFinishedLoading(true);

            if (formattedOverview.data.weight && formattedOverview.data.expectedKFactor) {
                const weight = formattedOverview.data.weight;
                const expectedKFactor = localeFormatNumber(
                    Math.abs(Number(formattedOverview.data.expectedKFactor)),
                    2,
                    i18n.language,
                    '-'
                );
                setKFactorGrowthExplanation(
                    t('kFactorDetails', {
                        weight,
                        weightUnit,
                        expectedKFactor,
                    })
                );
            }
        }
    }, [formattedOverview]);

    const getWeightStatusElements = () => {
        return (
            <>
                {finishedLoading && (
                    <>
                        <UmerBoxElementStatusInfoWithTrend
                            title={'CV'}
                            info={cv}
                            toolTipContent={t('descriptions.weightAndGrowthCV')}
                            textAlignment={horizontal ? 'center' : 'left'}
                            toolTipNubDirection={'down-left'}
                        />
                        <UmerBoxElementStatusInfoWithTrend
                            title={'SGR'}
                            info={sgr}
                            toolTipContent={t('descriptions.weightAndGrowthSGR')}
                            textAlignment={horizontal ? 'center' : 'left'}
                            toolTipNubDirection={'down-right'}
                        />
                        <UmerBoxElementStatusInfoWithTrend
                            title={'RGI'}
                            info={rgi}
                            toolTipContent={t('descriptions.weightAndGrowthRGI')}
                            textAlignment={horizontal ? 'center' : 'left'}
                            toolTipNubDirection={'down-left'}
                        />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('K-factor')}
                            info={kFactor}
                            toolTipContent={t('descriptions.weightAndGrowthK')}
                            textAlignment={horizontal ? 'center' : 'left'}
                            trend={kFactorGrowth}
                            toolTipNubDirection={'down-right'}
                        />
                        {!horizontal && (
                            <>
                                <div></div>
                                <UmerBoxElementText
                                    text={kFactorGrowthExplanation}
                                    variant={'footer'}
                                />
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {horizontal ? (
                <div className={styles.horizontalLayout}>{getWeightStatusElements()}</div>
            ) : (
                <div className={styles.twoColumns}> {getWeightStatusElements()}</div>
            )}
        </>
    );
};

export default WeightStatus;
