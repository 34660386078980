import { useCallback, useEffect, useState } from 'react';
import i18n, { t } from 'i18next';

import { InformationLineSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Loader } from '@/components/atoms/Loader';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import FishHealthFilter from '@/components/molecules/FishHealthFilter';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import { OptoColors } from '@/config/settings';
import { localizedDateFormat } from '@/helpers/date';
import { fullSizeWoundImageMapper, ImageState, useImageState } from '@/hooks/image-state';
import { FullSizeImage, ImageFeedbackDto } from '@/services/types';
import { UmerBox } from 'components/atoms/UmerBox';
import { FILTER_TAGS } from 'components/organisms/ImageViewer';

import styles from './CageWelfareWoundDetections.module.scss';

export interface CageWelfareWoundDetectionsPageContentProps {
    imageSource: string;
    isOptoscaleAdmin?: boolean;
    imagesData: FullSizeImage[];
    cageId: number;
    timezone: string;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
}

export const CageWelfareWoundDetectionsPageContent = ({
    imageSource,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    cageId,
    timezone,
    imagesData,
}: CageWelfareWoundDetectionsPageContentProps) => {
    const imageStateByTag = FILTER_TAGS.reduce(
        (acc, tag) => {
            acc[tag.tag] = useImageState({
                loading: false,
                // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
                mapper: useCallback(fullSizeWoundImageMapper(cageId), [cageId]),
                data: imagesData,
                queryParam: 'imageId',
                timezone: timezone,
                filter: tag.filter,
            });
            return acc;
        },
        {} as Record<string, ImageState>
    );

    const filterTagsList = FILTER_TAGS.filter((filter) => filter.tag !== 'maturation_tag');

    const [firstSetup, setFirstSetup] = useState(false);

    /* Preparing data for ImageViewer */
    const [selectedImageFilterTag, setSelectedImagedFilterTag] = useState<string>(
        FILTER_TAGS[0].tag
    );

    const imageState = imageStateByTag[selectedImageFilterTag];
    let lastDay: any = null;
    // biome-ignore lint/correctness/useExhaustiveDependencies: We want an effect whenever selectedImageFilterTag changes
    useEffect(() => {
        lastDay = imageState.dayswithimages.findLast(
            (day) => day.day <= imageState.selectedImageDate
        );
        imageState.onAfterTimelineChangeHandler(
            lastDay?.day ?? imageState.dayswithimages.at(0)?.day ?? ''
        );
    }, [selectedImageFilterTag]);

    const last = imageState.dayswithimages[imageState.dayswithimages.length - 1];
    const onFilterGroupChangeHandler = (tag: string) => {
        setSelectedImagedFilterTag(tag);
    };

    useEffect(() => {
        if (!firstSetup) {
            setFirstSetup(true);
            setSelectedImagedFilterTag('all');
        }
    }, [imageState]);

    const isColor = imageSource === 'color-welfare-image';

    return (
        <div className={styles.pageBoxLayout}>
            <UmerBox>
                {imageState.loading && <Loader />}
                {!imageState.loading && (
                    <div>
                        <ImageViewContainer
                            key="detections"
                            instanceKey="detections"
                            isOptoScaleAdmin={isOptoscaleAdmin}
                            isColor={isColor}
                            onCommitImageFeedbackDto={onCommitFalseDetection}
                            viewerConfig={{
                                showWoundLabel: true,
                                showLiceLabel: false,
                                showMagnifyer: true,
                                showHelpText: false,
                            }}
                            {...imageState}
                        />

                        <div>
                            <FishHealthFilter
                                filterStateList={filterTagsList}
                                onFilterGroupChange={onFilterGroupChangeHandler}
                                currentFilterStateList={selectedImageFilterTag}
                            />
                        </div>
                    </div>
                )}
            </UmerBox>
            <UmerBox>
                <UmerBoxElementHeader
                    headerText={t('Information') as string}
                    headerIcon={InformationLineSVG}
                    headerIconColor={OptoColors.OceanGreen}
                />
                {last && (
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Last measurement') as string}
                        info={localizedDateFormat({
                            dateString: last.day,
                            onlydate: true,
                            locale: i18n.language,
                        })}
                    />
                )}
            </UmerBox>
        </div>
    );
};
