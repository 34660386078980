import React from 'react';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { hexToRGBA } from '@/config/settings';

import styles from './UmerBoxElementHeader.module.scss';

export interface UmerBoxElementHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    rightSideContent?: React.ReactNode;
    headerText?: string;
    description?: string | React.ReactNode;
    level?: 'h2' | 'h3' | 'h4';
    headerIcon?: string;
    headerIconColor?: string;
}

const UmerBoxElementHeader = ({
    children = <></>,
    rightSideContent = <></>,
    headerText = '',
    description = '',
    level = 'h4',
    headerIcon,
    headerIconColor = '#000000',
}: UmerBoxElementHeaderProps) => {
    const HeadingTag = level;
    return (
        <div className={styles.umerBoxElementHeader}>
            <div className={styles.topPartContainer}>
                {headerIcon && (
                    <div
                        className={styles.iconContainer}
                        style={{ backgroundColor: hexToRGBA(headerIconColor, 0.1) }}>
                        <GenericIcon icon={headerIcon} color={headerIconColor} size={20} />
                    </div>
                )}
                <div className={styles.topPartAligner}>
                    <HeadingTag className={styles.removeMargin}>{headerText}</HeadingTag>
                    <div>
                        {rightSideContent}
                        {children}
                    </div>
                </div>
            </div>
            <div className={styles.description}>{description}</div>
        </div>
    );
};

export default UmerBoxElementHeader;
export { UmerBoxElementHeader };
