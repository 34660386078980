import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurmentOverviewResult } from 'services/types';

import { InformationLineSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import UmerBox from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { UmerBoxElementText } from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import Options, {
    addXYunitis,
    autoTimeScale,
    chartTitle,
    minMax,
    weightAndLengthUnitConverter,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    OverViewToFishSpeedChartMapper,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { OptoColors } from '@/config/settings';
import { ALL_DAYPART_TAGS, DEFAULT_DAYPART_TAG } from '@/contexts/cage-filter-context';
import { useMe } from '@/contexts/meContext';
import { localizedDateFormat } from '@/helpers/date';
import OptoMixedChart, {
    ChartActionButtons,
} from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

import styles from './CageWelfareFishSpeed.module.scss';

interface FishSpeedPageContentProps {
    loading: boolean;
    weightUnitTag: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%';
    daypartTag: string;
    data: MeasurmentOverviewResult;
    disabeledDayTags: string[];
    timeUnitTag: string;
    actionButtons?: ChartActionButtons;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
}

const CageWelfareFishSpeedPageContent = ({
    loading = false,
    data,
    cageFilterHandler,
    inputDateObject,
}: FishSpeedPageContentProps) => {
    const { t, i18n } = useTranslation();

    const { isOptoscaleAdmin } = useMe();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const lastMeasurement = data?.days?.findLast((x) => x.data?.speed);

    const [locale, setLocale] = useState('nb-NO');

    const inativeLedgends = ALL_DAYPART_TAGS.filter((x) => x !== DEFAULT_DAYPART_TAG);

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    return (
        <>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onDateChange={cageFilterHandler}
                    fromDefault={inputDateObject.dateFrom}
                    toDefault={inputDateObject.dateTo}
                />
            </div>
            {locale && (
                <div className={styles.pageBoxLayout}>
                    <UmerBox>
                        <OptoMixedChart
                            chartName={t('Swim Speed')}
                            chartData={OverViewToFishSpeedChartMapper({
                                overview: data,
                                inactiveSetLegend: inativeLedgends,
                                graphedDayPartTags: isOptoscaleAdmin
                                    ? ALL_DAYPART_TAGS
                                    : [DEFAULT_DAYPART_TAG],
                            })}
                            actionButtons={{
                                copyAsPng: true,
                                resetZoom: true,
                                downloadAsPng: true,
                                fullScreen: true,
                                timeScaleSwitch: false,
                            }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                chartTitle({ title: t('Swim Speed') }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: 'number',
                                    fromUnit: 'bl/s',
                                    toUnit: 'bl/s',
                                }),
                                addXYunitis({ y: t('swimspeed'), x: 'days' }),
                                minMax({
                                    min: 0,
                                    max: 2.5,
                                })
                            )}
                            loading={loading}
                        />
                    </UmerBox>
                    <UmerBox doublePadding={true}>
                        <UmerBoxElementHeader
                            headerText={t('Information')}
                            headerIcon={InformationLineSVG}
                            headerIconColor={OptoColors.OceanGreen}
                        />

                        <div className={styles.rightSideBox}>
                            <UmerBoxElementText text={t('swimspeed.description')} />
                            <UmerBoxElementStatusInfoWithTrend
                                title={t('Last measurement day')}
                                info={
                                    lastMeasurement
                                        ? `${localizedDateFormat({
                                              dateString: lastMeasurement.day,
                                              onlydate: true,
                                              locale: i18n.language,
                                          })}`
                                        : t('No data')
                                }
                            />
                        </div>
                    </UmerBox>
                </div>
            )}
        </>
    );
};

export default CageWelfareFishSpeedPageContent;
export { CageWelfareFishSpeedPageContent };
