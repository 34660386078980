import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import * as features from 'config/features';
import { useMe } from 'contexts/meContext';

import { PageTitleForAnalytics } from '@/components/Layout/PageTitle/PageTitle';
import StickyAlert from '@/components/molecules/StickyAlert';
import CageWelfareWoundsLayerContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/CageWelfareWoundsLayerContainer';
import LaksvelLayerContainer from '@/routes/Client/Cage/pages/Laksvel/LaksvelLayerContainer';
import LocationLayerContainer from '@/routes/Client/Location/LocationLayerContainer';
import RegionHelpContainer from '@/routes/Client/Overview/RegionHelp';
import RegionLiceReport from '@/routes/Client/Overview/RegionLiceReport';
import RegionMoveBioscopeContainer from '@/routes/Client/Overview/RegionMoveBioscope';
import RegionOverviewContainer from '@/routes/Client/Overview/RegionOverview';
import RegionUserManagementContainer from '@/routes/Client/Overview/RegionUsers';
import { useUserController_EndImpersonate } from '@/services/hooks';
import { Alert } from 'components/atoms/Alert';
import { Button } from 'components/atoms/Buttons';
import { HowMuchIsTheFish } from 'components/molecules/HowMuchIsTheFish';
import HeaderContainer from 'components/organisms/Header/HeaderContainer';

import { CageLayerContainer } from './Cage';
const Client = ({ match }) => {
    const baseUrl = match.url;
    const location = useLocation();

    const queryClient = useQueryClient();

    const { mutateAsync: endImpersonate } = useUserController_EndImpersonate({
        onSuccess: () => {
            queryClient.invalidateQueries();
            window.location.replace('/');
        },
    });
    const { state, hasUserFeature, isClientAdmin, isOptoscaleAdmin } = useMe();
    const { t } = useTranslation();

    const client = state.clients.find((client) => client.id === parseInt(match.params.clientId));

    useEffect(() => {}, [state]);

    // Create a new function for the onClick event
    const handleSwitchBackToOwnAccount = async (e) => {
        e.preventDefault();
        await endImpersonate();
    };

    if (!client) {
        return <Alert color="danger">{t('Unknown client')}</Alert>;
    }

    const canMoveBioscopes = isOptoscaleAdmin || isClientAdmin(parseInt(match?.params?.clientId));
    const isImpersonator = state?.user?.impersonatorUser?.id;
    return (
        <>
            {isImpersonator && (
                <StickyAlert position="top">
                    <Alert color="neutral" size="small" contentAlign="center">
                        <span>
                            <b>{`${state.user?.impersonatorUser?.firstName} ${state.user?.impersonatorUser?.lastName}`}</b>
                            {' you are acting as '}
                            <b>{`${state.user?.firstName} ${state.user?.lastName}`}</b>.
                        </span>
                        <Button size="small" onClick={handleSwitchBackToOwnAccount}>
                            Switch to your own account
                        </Button>
                    </Alert>
                </StickyAlert>
            )}

            <HeaderContainer />

            <Switch>
                <Route
                    exact
                    path={`${match.path}/help`}
                    component={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title="Help" />
                                <RegionHelpContainer clientId={client.id} />
                            </>
                        );
                    }}
                />

                <Route
                    exact
                    path={`${match.path}`}
                    render={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title={'Site overview'} />
                                <RegionOverviewContainer clientId={client.id} />
                            </>
                        );
                    }}
                />

                <Route
                    path={`${match.path}/location/:locationId/pen/:cageId/welfare/wounds`}
                    render={({ match }) => (
                        <CageWelfareWoundsLayerContainer
                            clientId={client.id}
                            locationId={parseInt(match?.params?.locationId)}
                            cageId={parseInt(match?.params?.cageId)}
                        />
                    )}
                />

                <Route
                    path={`${match.path}/location/:locationId/pen/:cageId/welfare/laksvel`}
                    render={({ match }) => (
                        <LaksvelLayerContainer
                            clientId={client.id}
                            locationId={parseInt(match?.params?.locationId)}
                            cageId={parseInt(match?.params?.cageId)}
                        />
                    )}
                />

                <Route
                    path={`${match.path}/location/:locationId/pen/:cageId`}
                    render={({ match }) => (
                        <CageLayerContainer
                            clientId={client.id}
                            locationId={parseInt(match?.params?.locationId)}
                            cageId={parseInt(match?.params?.cageId)}
                        />
                    )}
                />

                <Route
                    path={`${match.path}/location/:locationId`}
                    render={({ match }) => (
                        <>
                            <PageTitleForAnalytics
                                category="global"
                                title={'Single location dashboard'}
                            />
                            <LocationLayerContainer
                                clientId={client.id}
                                locationId={parseInt(match?.params?.locationId)}
                            />
                        </>
                    )}
                />

                <Route
                    exact
                    path={match.path}
                    render={() => <Redirect to={`${baseUrl}/cages/v3${location.search}`} />}
                />
                <Route
                    exact
                    path={`${match.path}/cages/v2`}
                    render={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title={'Site overview'} />
                                <Redirect to={`${match.path}/`} />
                            </>
                        );
                    }}
                />

                <Route
                    exact
                    path={`${match.path}/cages/v3`}
                    render={() => {
                        return (
                            <>
                                <PageTitleForAnalytics category="global" title={'Site overview'} />
                                <RegionOverviewContainer clientId={client.id} />
                            </>
                        );
                    }}
                />

                <Route
                    exact
                    path={`${match.path}/lice-report`}
                    render={({ match }) => (
                        <>
                            <PageTitleForAnalytics category="global" title="Lice report" />
                            <RegionLiceReport clientId={client.id} />
                        </>
                    )}
                />

                {/*DEPRECATED, can be removed*/}
                <Route
                    exact
                    path={`${match.path}/cages/lice-report`}
                    render={({ match }) => (
                        <>
                            <PageTitleForAnalytics category="global" title="Lice report" />
                            <RegionLiceReport clientId={parseInt(match?.params?.clientId)} />
                        </>
                    )}
                />

                <Route
                    exact
                    path={`${match.path}/cages/alternative`}
                    render={() => <Redirect to={`${baseUrl}`} />}
                />

                <Route
                    path={`${match.path}/cages/v1/:cageId`}
                    render={() => <Redirect to={`${baseUrl}`} />}
                />
                <Route
                    path={`${match.path}/cages/v2/:cageId`}
                    render={() => <Redirect to={`${baseUrl}`} />}
                />
                <Route
                    path={`${match.path}/cages/v3/:cageId`}
                    render={() => <Redirect to={`${baseUrl}`} />}
                />
                <Route
                    path={`${match.path}/cages/v3-new/:cageId`}
                    render={() => <Redirect to={`${baseUrl}`} />}
                />
                {/* <Route
                    path={match.path + '/compare'}
                    component={cProps => (
                        <CageContainer cageId={parseInt(match?.params?.cageId)} />
                    )}
                /> */}

                <Route
                    path={`${match.path}/cages/alternative/:cageId`}
                    render={({ match }) => <Redirect to={`${baseUrl}`} />}
                />
                <Route
                    path={`${match.path}/cages/:cageId`}
                    render={({ match }) => {
                        return (
                            <Redirect
                                to={`${baseUrl}/cages/v2/${match.params?.cageId}${location.search}`}
                            />
                        );
                    }}
                />

                <Route
                    path={`${match.path}/users`}
                    component={(cProps) => (
                        <>
                            <PageTitleForAnalytics title="Users" category="global" />
                            <RegionUserManagementContainer {...cProps} clientId={client.id} />
                        </>
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/move-bioscope`}
                    component={(cProps) => (
                        <>
                            <PageTitleForAnalytics title="Move bioscope" category="global" />
                            <RegionMoveBioscopeContainer {...cProps} clientId={client.id} />
                        </>
                    )}
                />
                <Redirect to={baseUrl + location.search} />
            </Switch>

            {hasUserFeature(features.HOW_MUCH_IS_THE_FISH) && <HowMuchIsTheFish />}
        </>
    );
};

export default Client;
