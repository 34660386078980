import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { SuspendedLazy } from '@/utils/suspended-lazy';

import { Box } from '../../../../components/Layout';

import HelpPageUserManual from './HelpPageUserManual';
import HelpPageVideos from './HelpPageVideos';

const HelpPageDetail = SuspendedLazy(() => import('./HelpPageDetail'));

function HelpPageContainer() {
    const { t } = useTranslation();

    const USER_MANUAL_LIST = [
        {
            title: t('helpPageUserManual.User Manual in English'),
            link: 'https://storage.googleapis.com/optoscale-public/Bioscope-User-Manual-English.pdf',
        },
        {
            title: t('helpPageUserManual.User Manual in Norwegian'),
            link: 'https://storage.googleapis.com/optoscale-public/Bioskop-brukermanual-Norsk.pdf',
        },
        {
            title: t('helpPageUserManual.User Manual in Spanish'),
            link: 'https://storage.googleapis.com/optoscale-public/1001-MAN-I-003%20Bioscope%20P1%20userrmanual%20Spanish%20rev.4%20Rev%20PIbarra%20110922%20EDITED%20(FINAL).pdf',
        },
    ];

    const USER_MANUAL_LIST_WINCH = [
        {
            title: t('helpPageUserManual.User Manual for Winch in Norwegian'),
            link: 'https://docs.optoscale.no/winch',
        },
    ];

    const VIDEO_LIST_BIOSCOPE1 = [
        {
            title: t('helpPageVIDEO.Video in English'),
            link: 'https://storage.googleapis.com/optoscale-public/Installation-videos/Engelsk-bioskop-1-1280-Com.mp4',
        },
        {
            title: t('helpPageVIDEO.Video in Norwegian'),
            link: 'https://storage.googleapis.com/optoscale-public/Installation-videos/Norsk-bioskop-1-1280-Com.mp4',
        },
        {
            title: t('helpPageVIDEO.Video in Spanish'),
            link: 'https://storage.googleapis.com/optoscale-public/Installation-videos/Spansk-bioskop-1-1280-Com.mp4',
        },
    ];

    const VIDEO_LIST_BIOSCOPE2 = [
        {
            title: t('helpPageVIDEO.Video in English'),
            link: 'https://storage.googleapis.com/optoscale-public/Installation-videos/Engelsk-bioskop-2-1280-Com.mp4',
        },
        {
            title: t('helpPageVIDEO.Video in Norwegian'),
            link: 'https://storage.googleapis.com/optoscale-public/Installation-videos/Norsk-bioskop-2-1280-Com.mp4',
        },
        {
            title: t('helpPageVIDEO.Video in Spanish'),
            link: 'https://storage.googleapis.com/optoscale-public/Installation-videos/Spansk-bioskop-2-1280-Com.mp4',
        },
    ];

    const FAQ_LIST = [
        {
            title: t('helpPageFAQ.FAQ in English'),
            link: 'https://storage.googleapis.com/optoscale-public/FAQ-Canada-ENGLISH.pdf',
        },
        {
            title: t('helpPageFAQ.FAQ in Norwegian'),
            link: 'https://storage.googleapis.com/optoscale-public/FAQ-Norge-NORSK.pdf',
        },
        {
            title: t('helpPageFAQ.FAQ in Spanish'),
            link: 'https://storage.googleapis.com/optoscale-public/FAQ-Chile-SPANISH.pdf',
        },
    ];

    return (
        <div>
            <PageTitleRow title={t('Help')}></PageTitleRow>
            <Row>
                <Col>
                    <Box>
                        <HelpPageUserManual
                            heading={
                                <>
                                    <i className="fas fa-book" />
                                    {t('User Manual')}
                                </>
                            }
                            description={t(
                                'Click on the link below to see or download the user manual.'
                            )}
                            linkList={USER_MANUAL_LIST}
                            linkListWinch={USER_MANUAL_LIST_WINCH}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <HelpPageVideos
                            heading={
                                <>
                                    <i className="fas fa-video" />
                                    {t('Installation videos')}
                                </>
                            }
                            description={t(
                                'Click on the link below to see the installation videos.'
                            )}
                            linkListBioscope1={VIDEO_LIST_BIOSCOPE1}
                            linkListBioscope2={VIDEO_LIST_BIOSCOPE2}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <HelpPageUserManual
                            heading={
                                <>
                                    <i className="fas fa-question-circle" />
                                    {t('FAQ')}
                                </>
                            }
                            description={t('Click on the link below to see or download the FAQ.')}
                            linkList={FAQ_LIST}
                        />
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box>
                        <HelpPageDetail />
                    </Box>
                </Col>
            </Row>
        </div>
    );
}

HelpPageContainer.propTypes = {};

export default HelpPageContainer;
