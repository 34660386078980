import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuxPlayer from '@mux/mux-player-react';
import videoThumbnail from 'svg/videoThumbnail.jpg';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    AlarmIconSVG,
    CloseCircleIconSVG,
    TimeDurationIconSVG,
    VideoLoadingIconSVG,
    WifiOffIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import settings from '@/config/settings';

import styles from './VideoBox.module.scss';
export interface VideoBoxProps {
    cageName: string;
    videoState: string;
    videoSrc?: string;
    timeRemaining?: number;
    userId?: number;
    videoId?: string;
}

const VideoBox = ({
    cageName = '',
    videoState = '',
    videoSrc = '',
    timeRemaining = 0,
    userId = 0,
    videoId = '',
}: VideoBoxProps) => {
    const { t } = useTranslation();
    const [videoCountDown, setVideoCountDown] = useState<string>('');
    useEffect(() => {
        const interval = setInterval(() => {
            if (timeRemaining > 0) {
                const minutes = Math.floor(timeRemaining / 60);
                const seconds = timeRemaining % 60;
                setVideoCountDown(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
                timeRemaining--;
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timeRemaining]);

    const renderContent = () => {
        if (videoState === 'offline') {
            return (
                <>
                    <div className={styles.stateContainer}>
                        <GenericIcon
                            icon={WifiOffIconSVG}
                            color={settings.darkGrayColor}
                            size={52}
                        />
                        <div className={styles.message}>{t('The bioscope is offline.')}</div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                    </div>
                </>
            );
        }
        if (
            // videoState === 'idle' ||
            videoState === 'connected' ||
            videoState === 'starting' ||
            videoState === 'recording'
        ) {
            return (
                <>
                    <div className={styles.muxPlayerWrapper}>
                        <div className={styles.stateContainer}>
                            <img className={styles.videoThumbnail} src={videoThumbnail} alt="" />
                        </div>
                        <div className={styles.overlayContent}>
                            <GenericIcon icon={VideoLoadingIconSVG} color={'#ffffff'} size={52} />
                            <div className={styles.message}>
                                {t('The Live Stream is loading...')}
                            </div>
                        </div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                    </div>
                </>
            );
        }
        // if (videoState === 'disabled') {
        //     return (
        //         <>
        //             <div className={styles.stateContainer}>
        //                 <GenericIcon icon={VideoLoadingIconSVG} color={'#ffffff'} size={52} />
        //                 <div className={styles.message}>{t('Setting up the video...')}</div>
        //             </div>
        //             <div className={styles.videoOverlay}>
        //                 <div>{cageName}</div>
        //             </div>{' '}
        //         </>
        //     );
        // }
        if (videoState === 'active') {
            return (
                <>
                    <MuxPlayer
                        playbackId={videoSrc}
                        accentColor={settings.primaryColor}
                        autoPlay={true}
                        metadata={{
                            video_id: videoId,
                            video_title: cageName,
                            viewer_user_id: userId,
                        }}
                    />
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                        <div className={styles.timer}>
                            <GenericIcon icon={TimeDurationIconSVG} color={'#ffffff'} size={20} />
                            <span>{videoCountDown}</span>
                        </div>
                    </div>
                </>
            );
        }
        if (videoState === 'error' || videoState === 'disconnected') {
            return (
                <>
                    <div className={styles.stateContainer}>
                        <GenericIcon
                            icon={CloseCircleIconSVG}
                            color={settings.darkGrayColor}
                            size={52}
                        />
                        <div className={styles.message}>{t('Error occured')}</div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                    </div>
                </>
            );
        }
        if (videoState === 'timeout') {
            return (
                <>
                    <div className={styles.stateContainer}>
                        <GenericIcon icon={AlarmIconSVG} color={settings.darkGrayColor} size={52} />
                        <div className={styles.message}>
                            {t(
                                'The allocated 10 minutes of viewing time for today has been reached!'
                            )}
                        </div>
                    </div>
                    <div className={styles.videoOverlay}>
                        <div>{cageName}</div>
                        <div className={styles.timer}>
                            <GenericIcon
                                icon={TimeDurationIconSVG}
                                color={settings.warningColor}
                                size={20}
                            />
                            <span className={styles.timeout}>{0}</span>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <div className={styles.muxPlayerWrapper}>
                    <div className={styles.stateContainer}>
                        <img className={styles.videoThumbnail} src={videoThumbnail} alt="" />
                    </div>
                    <div className={styles.overlayContent}>
                        <GenericIcon icon={VideoLoadingIconSVG} color={'#ffffff'} size={52} />
                        <div className={styles.message}>{t('The Live Stream is loading...')}</div>
                    </div>
                </div>
                <div className={styles.videoOverlay}>
                    <div>{cageName}</div>
                </div>
            </>
        );
    };
    // console.log('videoId', videoId);
    return <div className={styles.videoBox}>{renderContent()}</div>;
};

export default VideoBox;
export { VideoBox };
