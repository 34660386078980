import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';

import Loader from '@/components/atoms/Loader/Loader';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as icons from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { IconButton } from '@/components/atoms/Buttons/IconButton/IconButton';
import { GlassHourIcon } from '@/components/atoms/icons/GlassHourIcon/GlassHourIcon';
import { ReloadIcon } from '@/components/atoms/icons/ReloadIcon/ReloadIcon';
import { ErrorAlertIcon } from '@/components/atoms/icons/ErrorAlertIcon/ErrorAlertIcon';
import { ViewModeIcon } from '@/components/atoms/icons/ViewModeIcon/ViewModeIcon';
import { VideoIcon } from '@/components/atoms/icons/VideoIcon/VideoIcon';
import { SkeletonLoader } from '@/components/atoms/SkeletonLoader/SkeletonLoader';
import { OptoColors } from '@/config/settings';
import { UmerBox } from '@/components/atoms/UmerBox';
import { VideoLoadingTopBaner } from '@/components/molecules/VideoLoadingTopBaner';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import VideoBox from '@/components/molecules/VideoBox';
import SomethingHappened from '@/components/SomethingHappened';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { useMe } from '@/contexts/meContext';
import { useMqtt } from '@/contexts/mqtt-provider-context';
import {
    useClientController_Overview,
    useVideoStreamController_startStream,
    useVideoStreamController_stopStream,
    useVideoStreamController_getBioscopeQuotaCheck,
} from '@/services/hooks';
import type { OverviewCage } from '@/services/types';
import videoStreamImage from 'svg/vidoe-stream.jpg';
import { mqttTopicsVideo } from './topics';
import styles from './LocationVideo.module.scss';
import VideoLoaderSkeleton from '@/components/atoms/VideoLoaderSkeleton';
import { ErrorState } from '@/components/molecules/VideoBox/VideoBox.stories';
import { Button } from '@/components/atoms/Buttons';
import StickyAlert from '@/components/molecules/StickyAlert';

const TIMEOUT = 5000;
interface OverviewCageWithMuxData extends OverviewCage {
    muxData?: any;
    online: boolean;
    isButtonDisabled: boolean;
    isButtonLoading: boolean;
    icon: 'Loading' | 'Streaming' | 'Error' | 'Offline' | 'Online';
    isButtonSelected: boolean;
    isOutOfQuota: boolean;
    isStreaming: boolean;
}

interface LocationVideoState {
    activeCages: OverviewCageWithMuxData[];
    locationName: string;
    isAllButtonSelected: boolean;
    isAllButtonDisabled: boolean;
    viewModeEnabled: boolean;
    isLocationCageStreaming: boolean;
}

const initialState: LocationVideoState = {
    activeCages: [] as OverviewCageWithMuxData[],
    locationName: '',
    viewModeEnabled: false,
    isAllButtonSelected: false,
    isAllButtonDisabled: false,
    isLocationCageStreaming: false as boolean,
};

function reducer(state: LocationVideoState, action): LocationVideoState {
    switch (action.type) {
        case 'INIT':
            return {
                ...state,
                isLocationCageStreaming: false,
                activeCages: action.payload.activeCages.map((cage) => ({
                    ...cage,
                    online: false,
                    isButtonDisabled: true,
                    isButtonLoading: false,
                    icon: 'Offline',
                    isButtonSelected: false,
                    isStreaming: false,
                })),
            };

        case 'TOGGLE_BUTTON': {
            const updatedCages = state.activeCages.map((cage) =>
                cage.id === action.payload
                    ? { ...cage, isButtonSelected: !cage.isButtonSelected, isButtonDisabled: true }
                    : cage
            );
            const s = updatedCages.some((cage) => cage.isButtonSelected);
            return { ...state, activeCages: updatedCages, isLocationCageStreaming: s };
        }

        case 'SET_QUOTA':
            return {
                ...state,
                activeCages: state.activeCages.map((cage) =>
                    cage.id === action.payload.cageId
                        ? { ...cage, isOutOfQuota: action.payload.value }
                        : cage
                ),
            };

        case 'ENABLE_BUTTON':
            return {
                ...state,
                activeCages: state.activeCages.map((cage) =>
                    cage.id === action.payload ? { ...cage, isButtonDisabled: false } : cage
                ),
            };
        case 'CAGE_LOADING':
            return {
                ...state,
                activeCages: state.activeCages.map((cage) =>
                    cage.id === action.payload.cageId
                        ? { ...cage, isButtonLoading: action.payload.isLoading }
                        : cage
                ),
            };

        case 'SET_CAGE_STREAMING':
            return {
                ...state,
                activeCages: state.activeCages.map((cage) =>
                    cage.id === action.payload.cageId
                        ? { ...cage, isStreaming: action.payload.value }
                        : cage
                ),
            };

        case 'SET_BUTTON':
            return {
                ...state,
                activeCages: state.activeCages.map((cage) =>
                    cage.id === action.payload.cageId && cage.online
                        ? {
                              ...cage,
                              isButtonSelected: action.payload.value,
                              isButtonDisabled: true,
                          }
                        : cage
                ),
            };

        case 'RESET_BUTTON': {
            const updatedCages = state.activeCages.map((cage) =>
                cage.id === action.payload
                    ? { ...cage, isButtonSelected: false, isQuotaReached: true }
                    : cage
            );
            const s = updatedCages.some((cage) => cage.isButtonSelected);
            return { ...state, activeCages: updatedCages, isLocationCageStreaming: s };
        }

        case 'SET_IS_STREAMING':
            return { ...state, isLocationCageStreaming: action.payload };

        case 'SET_BUTTON_BASED_ON_USER': {
            const updatedCages = state.activeCages.map((cage) =>
                cage.activeBioscopes[0] === action.payload.bioscopeId
                    ? { ...cage, isButtonSelected: action.payload.value }
                    : cage
            );
            const s = updatedCages.some((cage) => cage.isButtonSelected);
            return { ...state, activeCages: updatedCages, isLocationCageStreaming: s };
        }

        case 'TOGGLE_ALL_BUTTON':
            return {
                ...state,
                isAllButtonSelected: !state.isAllButtonSelected,
                isAllButtonDisabled: true,
            };

        case 'ENABLE_ALL_BUTTON':
            return { ...state, isAllButtonDisabled: false };

        case 'UPDATE_MUX_DATA': {
            const activeCages = state.activeCages.map((cage) => {
                if (cage.activeBioscopes[0] === action.payload.bioscopeId) {
                    return {
                        ...cage,
                        muxData: {
                            streamId: action.payload.streamId,
                            status: action.payload.status,
                            muxStatus: action.payload.muxStatus,
                            timestamp: action.payload.timestamp,
                            userId: action.payload.userId || null,
                            playback_id: action.payload.playbackId || null,
                        },
                        isButtonSelected:
                            action.payload.muxStatus === 'idle' && state.viewModeEnabled
                                ? false
                                : cage.isButtonSelected,
                    };
                }
                return cage;
            });

            return {
                ...state,
                activeCages: activeCages,
            };
        }

        case 'VIEW_MODE_ENABLED':
            return { ...state, viewModeEnabled: true };

        case 'VIEW_MODE_DISABLED':
            return { ...state, viewModeEnabled: false };

        case 'UPDATE_BIOSCOPE_ONLINE_STATUS':
            return {
                ...state,
                activeCages: state.activeCages.map((cage) => {
                    if (cage.activeBioscopes[0] === action.payload.bioscopeId) {
                        return {
                            ...cage,
                            online: action.payload.status === 'online',
                            isButtonDisabled: action.payload.status === 'offline',
                        };
                    }
                    return cage;
                }),
            };

        default:
            return state;
    }
}

const LocationVideoContainer = ({ clientId, locationId }) => {
    const { t } = useTranslation();
    const user_id = useMe()?.state?.user?.id ?? null;
    const { client, isConnected, subscribe, unsubscribe } = useMqtt();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [locationName, setLocationName] = useState<string>('');
    const [backFromViewMode, setBackFromViewMode] = useState<number>(0);

    const { mutateAsync: startStream } = useVideoStreamController_startStream();
    const { mutateAsync: stopStream } = useVideoStreamController_stopStream();
    const { mutateAsync: quotaCheck } = useVideoStreamController_getBioscopeQuotaCheck();

    const {
        data: clientLocationsData,
        isLoading: isLoadingClientLocations,
        error: clientLocationsDataError,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const handleRefresh = (event) => {
        console.log('Streams will be closed');
        for (const cage of event.returnValue) {
            console.log('cage:', cage.id, 'bioscope:', cage.activeBioscopes[0]);
            stopStream({ bioscopeId: cage.activeBioscopes[0] });
        }
    };

    const updateQuotas = async (activeCages) => {
        for (const cage of activeCages) {
            const bioscopeId: number = cage.activeBioscopes[0];

            const data = await quotaCheck({ bioscopeId });

            if (data) {
                console.log('ret:', data);
                dispatch({
                    type: 'SET_QUOTA',
                    payload: { cageId: cage.id, value: data.data?.status === 'exceeded' },
                });
            }
        }
    };

    useEffect(() => {
        const location = clientLocationsData?.data?.locations?.find((x) => x.id === locationId);
        const activeCages = location?.cages?.filter(
            (cage) => cage.activeBioscopes && cage.activeBioscopes.length > 0
        );

        if (location) {
            setLocationName(location.name);
        }

        if (activeCages) {
            for (const cage of activeCages) {
                const bioscopeId: number = cage.activeBioscopes[0];
                const {
                    videoStatus: muxStatusTopic,
                    bioscopeStatus: bioscopeStatusTopic,
                    liveStream: bioscopeLivestream,
                } = mqttTopicsVideo(bioscopeId);

                subscribe(muxStatusTopic, { qos: 1 });
                subscribe(bioscopeLivestream, { qos: 1 });
                subscribe(bioscopeStatusTopic, { qos: 1 });
            }

            dispatch({ type: 'INIT', payload: { activeCages: activeCages } });

            updateQuotas(activeCages);

            return () => {
                for (const cage of activeCages) {
                    const bioscopeId: number = cage.activeBioscopes[0];
                    const { videoStatus: muxStatusTopic, bioscopeStatus: bioscopeStatusTopic } =
                        mqttTopicsVideo(bioscopeId);
                    unsubscribe(muxStatusTopic, { qos: 1 });
                    subscribe(bioscopeStatusTopic, { qos: 1 });
                }
            };
        }
    }, [clientLocationsData, subscribe, unsubscribe, backFromViewMode]);

    useEffect(() => {
        if (isConnected && client) {
            const handleMessage = async (topic: string, message: Buffer) => {
                const extractBioscopeIdFromTopic = (topic: string): number | null => {
                    const match = topic.match(/\/(\d+)\//);
                    return match ? parseInt(match[1], 10) : null;
                };

                console.log('topic:', topic, 'msg', message.toString());

                if (topic.startsWith('bioscope/status')) {
                    try {
                        const parsedMessage = JSON.parse(message.toString());
                        dispatch({ type: 'UPDATE_BIOSCOPE_ONLINE_STATUS', payload: parsedMessage });
                    } catch (error) {
                        console.error('Error parsing MQTT message:', error);
                    }
                }

                if (topic.startsWith('bioscope/livestream')) {
                    try {
                        const parsedMessage = JSON.parse(message.toString());
                        const bioscopeId = extractBioscopeIdFromTopic(topic);

                        if (!bioscopeId) {
                            console.error('Missing bioscopeId in topic:', topic);

                            const match = topic.match(/\d+/); // Find the first number
                            const m = match ? Number(match[0]) : null; // Convert to number or return null
                            const cage = state.activeCages.find(
                                (cage) => cage.activeBioscopes[0] === m
                            );

                            if (parsedMessage?.reason === 'MAX_CONTINUOUS_DURATION_EXCEEDED') {
                                // dispatch({ type: 'SET_QUOTA', payload: { reason: 'MAX_CONTINUOUS_DURATION_EXCEEDED', bioscopeId: m } });
                                dispatch({ type: 'RESET_BUTTON', payload: cage.id });
                            }

                            return;
                        }

                        if (parsedMessage.startedByUserId !== null) {
                            dispatch({
                                type: 'SET_BUTTON_BASED_ON_USER',
                                payload: { bioscopeId: bioscopeId, value: true },
                            });
                        }

                        dispatch({
                            type: 'UPDATE_MUX_DATA',
                            payload: {
                                bioscopeId,
                                streamId: parsedMessage.streamId,
                                status: parsedMessage.status,
                                muxStatus: parsedMessage.muxStatus,
                                timestamp: parsedMessage.timestamp,
                                userId: parsedMessage.startedByUserId || null,
                                playbackId: parsedMessage.playbackId || null,
                                user_id: user_id,
                            },
                        });
                    } catch (error) {
                        console.error('Error parsing MQTT message:', error);
                    }
                }
            };

            client.on('message', handleMessage);

            return () => {
                client.off('message', handleMessage);
            };
        }
    }, [isConnected, client]);

    const cageChanged = async (cageId) => {
        console.log('cageId:', cageId);
        dispatch({ type: 'TOGGLE_BUTTON', payload: cageId });

        for (const cage of state.activeCages) {
            if (cage.id === cageId) {
                if (cage.isButtonSelected) {
                    await stopStream({ bioscopeId: cage.activeBioscopes[0] });
                } else {
                    await startStream({ bioscopeId: cage.activeBioscopes[0] });
                    dispatch({ type: 'CAGE_LOADING', payload: { cageId, isLoading: true } });
                }
            }
        }
        setTimeout(() => {
            dispatch({ type: 'ENABLE_BUTTON', payload: cageId });
        }, TIMEOUT);
    };

    const allCagesChanged = async () => {
        console.log('allCagesChanged');
        const cagesToToggle = state.activeCages.filter(
            (cage) =>
                cage.isButtonSelected !== !state.isAllButtonSelected &&
                cage.online &&
                !cage.isOutOfQuota
        );
        const v = !state.isAllButtonSelected;
        dispatch({ type: 'TOGGLE_ALL_BUTTON' });
        for (const cage of cagesToToggle) {
            dispatch({ type: 'SET_BUTTON', payload: { cageId: cage.id, value: v } });
        }
        dispatch({ type: 'SET_IS_STREAMING', payload: v });
        for (const cage of cagesToToggle) {
            if (v) {
                await startStream({ bioscopeId: cage.activeBioscopes[0] });
            } else {
                await stopStream({ bioscopeId: cage.activeBioscopes[0] });
            }
            setTimeout(() => {
                dispatch({ type: 'ENABLE_BUTTON', payload: cage.id });
            }, TIMEOUT);
        }
        setTimeout(() => {
            dispatch({ type: 'ENABLE_ALL_BUTTON' });
        }, TIMEOUT);
    };

    const showSpinner = state.activeCages.some(
        (cage) => cage.isButtonSelected && cage.muxData?.muxStatus !== 'active'
    );

    const viewModeEnabled = state.activeCages.some(
        (cage) => cage.muxData?.userId && cage.muxData.userId !== user_id
    );

    if (state.viewModeEnabled === false && viewModeEnabled === true) {
        dispatch({ type: 'VIEW_MODE_ENABLED' });
    }

    if (state.viewModeEnabled === true && viewModeEnabled === false) {
        setBackFromViewMode(backFromViewMode + 1);
        dispatch({ type: 'VIEW_MODE_DISABLED' });
    }

    return (
        <>
            <section>
                <PageTitleRow title={t('Video')} />
                {!viewModeEnabled && (
                    <ChipsButtons
                        cageChangedHandler={cageChanged}
                        allCagesChangedHandler={allCagesChanged}
                        activeCagesButtons={state.activeCages}
                        allButton={state.isAllButtonSelected}
                        allButtonDisabled={state.isAllButtonDisabled}
                    />
                )}
                {viewModeEnabled && <Viewmode t={t} />}
                {showSpinner && <Spinner t={t} />}
                {<br />}
            </section>

            <UmerBox>
                <section>
                    <Screens activeCages={state.activeCages} />
                    {!state.isLocationCageStreaming && <Hello t={t} />}
                </section>
            </UmerBox>
        </>
    );
};

{
    /* <button
key={cage.id}
disabled={cage.isButtonDisabled || cage.isOutOfQuota}
onClick={() => cageChangedHandler(cage.id)}
data-isSelected={cage.isButtonSelected}
>
{!cage.online && <GenericIcon icon={icons.OfflineIconSVG} size={20} />}
{cage.isOutOfQuota && <GlassHourIcon width={20} height={20} color='black'/>}
{cage.name}-{cage.activeBioscopes[0]}-{cage.muxData?.userId}{' '}
{cage.show ? 'on' : 'off'} {cage.muxData?.muxStatus} {cage.muxData?.status}{' '}
{cage.online ? 'online' : 'offline'}
</button> */
}

const ChipsButtons = ({
    cageChangedHandler,
    allCagesChangedHandler,
    activeCagesButtons,
    allButton,
    allButtonDisabled,
}) => {
    return (
        <div className={styles.buttonWrapper}>
            <button
                disabled={allButtonDisabled}
                onClick={() => allCagesChangedHandler()}
                data-isSelected={allButton}>
                {'All'}
            </button>
            {activeCagesButtons?.map((cage) => (
                <button
                    key={cage.id}
                    disabled={cage.isButtonDisabled || cage.isOutOfQuota}
                    onClick={() => cageChangedHandler(cage.id)}
                    data-isSelected={cage.isButtonSelected}>
                    {/* {cage.isButtonLoading && (
                     <GenericIcon icon={icons.VideoLoadingIconSVG} size={20} />
                    )} */}
                    {!cage.online && !cage.isOutOfQuota && (
                        <GenericIcon icon={icons.OfflineIconSVG} size={20} />
                    )}
                    {cage.isOutOfQuota && (
                        <GenericIcon icon={icons.TimeDurationIconSVG} size={20} />
                    )}
                    {cage.name}
                </button>
            ))}
        </div>
    );
};

const Screens = ({ activeCages }) => {
    return (
        <div className={styles.videoGrid}>
            {activeCages?.map((cage) => {
                if (cage.isButtonSelected) {
                    const active =
                        cage.muxData?.status === 'active' && cage.muxData?.muxStatus === 'active';

                    return active ? (
                        <VideoBox
                            cageName={cage.name}
                            key={cage.activeBioscopes[0]}
                            userId={cage.muxData.userId}
                            videoState={cage.muxData.status || ''}
                            videoId={`video-id-${cage.activeBioscopes[0]}`}
                            videoSrc={cage?.muxData?.playback_id}
                            timeRemaining={300}
                        />
                    ) : (
                        <VideoLoaderSkeleton key={cage.id} />
                    );
                }
            })}
        </div>
    );
};

const Hello = ({ t }) => (
    <div className={styles.videoGridEmpty}>
        <img src={videoStreamImage} alt="" />
        <div className={styles.textContainer}>
            <div className={styles.headerContainer}>
                <VideoIcon height={30} width={30} color={OptoColors.OceanGreen} />
                <h2>{t('live_video_secion_title')}</h2>
            </div>
            <p>
                {t('live_vide_secion_part_1')} <b>{t('live_vide_secion_part_2')}</b>{' '}
                {t('live_vide_secion_part_3')}
            </p>
            <p>
                {t('live_vide_secion_part_4')} <b>{t('live_vide_secion_part_5')}</b>{' '}
                {t('live_vide_secion_part_6')}
            </p>
            <p>
                <b>{t('live_vide_secion_part_7')}</b> {t('live_vide_secion_part_8')}
            </p>
            <p className={styles.contactMsg}>
                <GenericIcon icon={icons.MailIconSVG} size={20} />
                {t('live_vide_secion_part_9')}{' '}
                <a href="mailto:support@optoscale.no">{t('live_vide_secion_part_10')}</a>{' '}
                {t('live_vide_secion_part_11')}
            </p>
        </div>
    </div>
);

const Viewmode = ({ t }) => (
    <div className={styles.videoLoadingTopBaner}>
        <VideoLoadingTopBaner isLoading={false}>
            <div className={styles.videoLoadingTextWithIcon}>
                <ViewModeIcon height={24} width={24} color={OptoColors.OceanGreen} />
                <h5>{t('Viewmode')}</h5>
            </div>
            <div className={styles.contentWrapper}>
                {t(
                    'Looks like a fellow fish-watcher in your company has the controls. You can watch along, but they are the director of this stream.'
                )}
            </div>
        </VideoLoadingTopBaner>
    </div>
);

const Spinner = ({ t }) => (
    <div className={styles.videoLoadingTopBaner}>
        <VideoLoadingTopBaner isLoading={true}>
            <div className={styles.videoLoadingTextWithIcon}>
                <GlassHourIcon height={24} width={24} color={OptoColors.OceanGreen} />
                <h5>{t('Loading live stream ... ')}</h5>
            </div>
            <div className={styles.contentWrapper}>
                {t('Just a few more seconds – the fish are lining up for the perfect shot.')}
            </div>
        </VideoLoadingTopBaner>
    </div>
);

export default LocationVideoContainer;
export { LocationVideoContainer };
