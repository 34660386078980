import { CageProvider } from '@/contexts/cage-context';
import { CageFilterProvider } from '@/contexts/cage-filter-context';
import LaksvelLayer from '@/routes/Client/Cage/pages/Laksvel/LaksveLayer';

interface CageLayerContainerProps {
    clientId: number;
    locationId: number;
    cageId: number;
}

const CageWelfareWoundsLayerContainer = ({
    clientId,
    locationId,
    cageId,
}: CageLayerContainerProps) => {
    return (
        <CageProvider cageId={cageId}>
            <CageFilterProvider>
                <LaksvelLayer clientId={clientId} locationId={locationId} cageId={cageId} />
            </CageFilterProvider>
        </CageProvider>
    );
};

export default CageWelfareWoundsLayerContainer;
export { CageWelfareWoundsLayerContainer };
