import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    useClientController_CreateNewClient,
    useClientController_GetClients,
} from 'services/hooks';

import { GenericSidebar } from '@/components/molecules/GenericSidebar';
import { internalSidebarOptions } from '@/routes/Internal';
import { Alert } from 'components/atoms/Alert';
import { Loader } from 'components/atoms/Loader';
import { Page, PageContent } from 'components/Layout';

import { Clients } from './Clients';

const ClientsContainer = (props) => {
    const { t } = useTranslation();

    const { data, isLoading, refetch, error } = useClientController_GetClients();
    const { mutateAsync: createClient } = useClientController_CreateNewClient({
        onSuccess: () => refetch(),
    });

    const onSubmitted = async (addClient) => {
        await createClient({ requestBody: addClient });
    };

    if (isLoading) return <Loader />;

    if (error) return <Alert color={'warning'}>Fail to fetch the list of Clients</Alert>;

    return (
        <Page title={'Clients'}>
            <GenericSidebar sideBarOptions={internalSidebarOptions} layerUrlPrefix={'/internal'} />

            <PageContent>
                <Clients {...props} clients={data?.data ?? []} onSubmitted={onSubmitted} />
            </PageContent>
        </Page>
    );
};

export default ClientsContainer;
