import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurmentOverviewResult } from 'services/types';

import { UmerBox } from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { ALL_DAYPART_TAGS, DEFAULT_DAYPART_TAG } from '@/contexts/cage-filter-context';
import { localizedDateFormat } from '@/helpers/date';
import { OptoMixedChart } from 'components/organisms/OptoGraphs';
import {
    autoTimeScale,
    Options,
    smoothLines,
    startYAxisAtZero,
    TypeNames,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    OverViewToGrowthChartMapper,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import { ChartActionButtons } from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

import styles from './CageWeightGrowth.module.scss';

export interface GraphsPageContentProps {
    loading: boolean;
    weightUnitTag: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%';
    data: MeasurmentOverviewResult;
    disabeledDayTags: string[];
    timeUnitTag: string;
    actionButtons?: ChartActionButtons;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
    isOptoscaleAdmin?: boolean;
    hasLivingWeightAllTagsEnabled?: boolean;
}

const CageWeightGraphsPageContent = ({
    disabeledDayTags = [],
    loading = false,
    data,
    weightUnitTag = 'g',
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
    cageFilterHandler,
    inputDateObject,
    isOptoscaleAdmin = false,
    hasLivingWeightAllTagsEnabled = false,
}: GraphsPageContentProps) => {
    const { t, i18n } = useTranslation();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    const days = data?.days.length > 0 ? data.days : [];

    const lastAllWeightMeasurement = days.findLast(
        (day) => day.daypartTag === 'all' && day.data?.weightGrowthAvg > 0
    );

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    return (
        <>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onDateChange={cageFilterHandler}
                    fromDefault={inputDateObject.dateFrom}
                    toDefault={inputDateObject.dateTo}
                />
            </div>
            {locale && (
                <div className={styles.pageBoxLayout}>
                    <OptoMixedChart
                        chartName={t('Growth')}
                        chartData={OverViewToGrowthChartMapper({
                            overview: data as MeasurmentOverviewResult,
                            inactiveSetLegend: [...disabeledDayTags],
                            graphedDayPartTags:
                                isOptoscaleAdmin || hasLivingWeightAllTagsEnabled
                                    ? ALL_DAYPART_TAGS
                                    : [DEFAULT_DAYPART_TAG],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            smoothLines({ tension: 0.4 }),
                            weightAndLengthUnitConverter({
                                locale: locale,
                                type: TypeNames.weight,
                                fromUnit: weightUnitTag,
                                toUnit: weightUnitTag,
                            }),
                            autoTimeScale({}),
                            startYAxisAtZero({})
                        )}
                        loading={loading}
                    />
                    <UmerBox doublePadding={true}>
                        <div className={styles.rightSideElementContainer}>
                            <UmerBoxElementHeader headerText={t('Growth')} />
                            <UmerBoxElementStatusInfoWithTrend
                                title={t('Last measurement day')}
                                info={
                                    lastAllWeightMeasurement
                                        ? `${localizedDateFormat({
                                              dateString: lastAllWeightMeasurement.day,
                                              onlydate: true,
                                              locale: i18n.language,
                                          })}`
                                        : t('No data')
                                }
                            />
                            <UmerBoxElementStatusInfoWithTrend
                                title={t('Last measurement')}
                                info={
                                    lastAllWeightMeasurement
                                        ? `${lastAllWeightMeasurement.data.weightGrowthAvg.toFixed(
                                              2
                                          )}${weightUnitTag}`
                                        : t('No data')
                                }
                            />
                        </div>
                    </UmerBox>
                </div>
            )}
        </>
    );
};
export default CageWeightGraphsPageContent;
export { CageWeightGraphsPageContent as PageContent };
