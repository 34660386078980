import { useTranslation } from 'react-i18next';
import { useCage } from 'contexts/cage-context';

import FishHealthSummary from '@/components/atoms/FishHealthSummary/FishHealthSummary';
import { InformationLineSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { TrendIconEnum } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend/TrendIconUtils';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { OptoColors } from '@/config/settings';
import { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';
import { ImageFeedbackDto } from '@/services/types';
import { UmerBox } from 'components/atoms/UmerBox';
import {
    autoTimeScale,
    chartTitle,
    formatAxsis,
    layoutPadding,
    Options,
    startYAxisAtZero,
    TypeNames,
    Unit,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    FishHealthToSkinSpecklesMapper,
    OptoMixedChart,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import type { ChartActionButtons } from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

import styles from './CageWelfareScaleloss.module.scss';

export interface CageWelfareScalelossPageContentProps {
    actionButtons?: ChartActionButtons;
    cageFilterHandler?: (data: DateReturnObject) => void;
    fishHealthData: FishHealthData;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
    source: string;
    inputDateObject?: DateReturnObject;
}

const DEFAULT_EQ_THRESHOLD = 0.02 / 7; //More than 2 % per week is considered a significant change

export const CageWelfareScalelossPageContent = ({
    source,
    fishHealthData,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
    healthType = 'wounds',
    cageFilterHandler,
    inputDateObject,
}: CageWelfareScalelossPageContentProps) => {
    const { isTrout } = useCage();
    const { t } = useTranslation();

    const { lastEstimate, slopePerDay } = fishHealthData;

    const getStatus = (value: number) => {
        if (value > DEFAULT_EQ_THRESHOLD) {
            return 'negative';
        } else if (value < -DEFAULT_EQ_THRESHOLD) {
            return 'positive';
        }
        return 'neutral';
    };
    const getDirection = (value: number) => {
        if (value > DEFAULT_EQ_THRESHOLD) {
            return TrendIconEnum.TrendPositiveUp;
        } else if (value < -DEFAULT_EQ_THRESHOLD) {
            return TrendIconEnum.TrendPositiveDown;
        }
        return TrendIconEnum.TrendNone;
    };

    const getDirectionOld = (value: number) =>
        value > DEFAULT_EQ_THRESHOLD ? 'up' : value < -DEFAULT_EQ_THRESHOLD ? 'down' : 'flat';

    const summary = {
        skinSpeckles: {
            value: lastEstimate.skinSpeckles,
            slope: slopePerDay.skinSpeckles,
            status: getStatus(slopePerDay.skinSpeckles),
            direction: getDirectionOld(slopePerDay.skinSpeckles),
        },
    } as const;

    return (
        <div className={styles.pageBoxLayout}>
            <div className={styles.leftSideBox}>
                {/*<GenericInfoBox*/}
                {/*    title={t('Scaleloss')}*/}
                {/*    icon={FirstAidKitSVG}*/}
                {/*    circleColor={OptoColors.OceanGreen}*/}
                {/*    info={formatPercentage(lastEstimate.skinSpeckles)}*/}
                {/*    unit={''}*/}
                {/*    grayBackground={false}*/}
                {/*    trend={getDirection(slopePerDay.skinSpeckles)}*/}
                {/*    tooltipText={'-'}*/}
                {/*/>*/}
                <UmerBox doublePadding={true}>
                    <FishHealthSummary
                        dataRate={summary.skinSpeckles.value} // what would be the scale loss percentage?
                        status={summary.skinSpeckles.status}
                        variant="inline"
                        direction={summary.skinSpeckles.direction}
                        label="skinSpeckles"
                    />
                </UmerBox>
                <UmerBox>
                    <OptoMixedChart
                        chartName={t('fishHealthIndicators.skinSpeckles')}
                        chartData={FishHealthToSkinSpecklesMapper({
                            fishHealth: fishHealthData,
                            inactiveSetLegend: [],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            chartTitle({
                                title: t('fishHealthIndicators.skinSpeckles'),
                            }),
                            startYAxisAtZero({}),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: Unit.percent,
                                toUnit: Unit.percent,
                            }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            }),
                            layoutPadding({ padding: 0 })
                        )}
                    />
                </UmerBox>
            </div>
            <UmerBox>
                <UmerBoxElementHeader
                    headerText={t('Information')}
                    headerIcon={InformationLineSVG}
                    headerIconColor={OptoColors.OceanGreen}
                />
            </UmerBox>
        </div>
    );
};
