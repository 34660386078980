import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultLayout from '@/components/atoms/layouts/DefaultLayout';
import { PageTitleForAnalytics } from '@/components/Layout/PageTitle/PageTitle';
import { GenericPage } from '@/components/molecules/GenericSidebar';

function Layout({ pages, layout, children }) {
    const Layout = layout ?? DefaultLayout;
    return <Layout pages={pages}>{children}</Layout>;
}

function LocationRoutes({ pages, clientId, locationId }) {
    const match = useRouteMatch();
    const isPageAvailable = (page: GenericPage) => {
        //TODO: might want to limit Lice here
        return true;
    };

    const availablePages = useMemo(() => {
        return (pages ?? []).filter((page) => isPageAvailable(page));
    }, [isPageAvailable, pages]);

    return (
        <Switch>
            {availablePages.map((page) => (
                <Route
                    key={page.key}
                    exact={page?.exact ?? page?.path === ''}
                    path={`${match.url}${page?.path ?? ''}`}
                    render={(props) => (
                        <>
                            <PageTitleForAnalytics
                                category="location"
                                title={page.label}
                                routeProps={props}
                            />
                            <Layout pages={pages} layout={page?.layout}>
                                {page?.page ? (
                                    <page.page
                                        {...props}
                                        clientId={clientId}
                                        locationId={locationId}
                                    />
                                ) : null}
                            </Layout>
                        </>
                    )}
                />
            ))}
        </Switch>
    );
}

export default LocationRoutes;
