import React, { useCallback } from 'react';

import { Loader } from '@/components/atoms/Loader';
import SomethingHappened from '@/components/SomethingHappened';
import useCage from '@/contexts/cage-context';
import useCageFilter from '@/contexts/cage-filter-context';
import { fullSizeImageMapper, useImageState } from '@/hooks/image-state';
import { CageFormattedOverviewProvider } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
import { CageGraphDataProvider } from '@/routes/Client/Cage/components/cage-graph-data-context';
import { CageOverviewContextProvider } from '@/routes/Client/Cage/components/cage-overview-context';
import CageWelfareDashboardPageContent from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareDashboard/CageWelfareDashboardPageContent';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import { placeHolder } from '@/services/config';
import {
    useClientController_Overview,
    useCombinedWelfareController_FullWelfare,
    useLaksvelController_laksvelDaily,
    useWelfareController_allImages,
} from '@/services/hooks';
import { DailyLaksvelData, OverviewLocation } from '@/services/types';
import tsToString from '@/utils/tsToString';

const CageWelfareDashboardContainer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const { hasColorWelfare, timezone } = useCage();

    const { normalizedFrom, normalizedTo, daypartTag, setFilter } = useCageFilter();
    const from = normalizedFrom ? tsToString(normalizedFrom, timezone) : undefined;
    const to = normalizedTo ? tsToString(normalizedTo, timezone) : undefined;

    const {
        isLoading: clientOverviewIsLoading,
        data: clientOverviewData,
        error: clientOverviewError,
        refetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const { data: laksvelMetaData, isLoading: laksvelDailyIsLoading } =
        useLaksvelController_laksvelDaily(
            cageId,
            {},
            {
                placeholderData: placeHolder({ dailyData: [] } as DailyLaksvelData),
                keepPreviousData: true,
            }
        );

    const type = hasColorWelfare ? 'color-welfare-image' : 'bw-welfare';
    const detections = useWelfareController_allImages(cageId, { type, numberOfImages: '1' });
    const detectionImageState = useImageState({
        loading: detections.isLoading && !detections.error,
        data: detections.data?.data,
        mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
        queryParam: 'imageId',
        timezone,
    });

    const { data: fishHealth, isLoading: fishHealthLoading } =
        useCombinedWelfareController_FullWelfare(
            cageId,
            { from: from, to: to, daypartTag: daypartTag },
            { staleTime: 5 * 60 * 1000 }
        );

    const loading = clientOverviewIsLoading;
    const error = clientOverviewError;

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <SomethingHappened />;
    }

    return (
        <CageOverviewContextProvider>
            <CageFormattedOverviewProvider>
                <CageGraphDataProvider>
                    <CageWelfareDashboardPageContent
                        clientId={clientId}
                        locationId={locationId}
                        cageId={cageId}
                        images={detectionImageState.loading ? [] : detectionImageState.images}
                        location={
                            clientOverviewData?.data?.locations?.find(
                                (x) => x.id === locationId
                            ) as OverviewLocation
                        }
                        detectionsLoading={detectionImageState.loading}
                        lastLaksvelDateWithMeasurements={
                            laksvelMetaData?.data
                                ? laksvelMetaData?.data?.dailyData.find((x) => x.cnt > 0)
                                : null
                        }
                        fishHealthData={fishHealthLoading ? null : fishHealth.data}
                    />
                </CageGraphDataProvider>
            </CageFormattedOverviewProvider>
        </CageOverviewContextProvider>
    );
};

export default CageWelfareDashboardContainer;
