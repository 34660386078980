import { HeartBeat2FillSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { GenericPage, SidebarType } from '@/components/molecules/GenericSidebar';
import { SidebarPageOptionObject } from '@/components/molecules/GenericSidebar/SidebarPageOptionObjects';
import LICENSES from '@/config/licenses';
import CageWelfareGraphsContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/pages/CageWelfareGraphs/CageWelfareGraphsContainer';
import CageWelfareWoundDetectionsContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/pages/CageWelfareWoundDetections';
import CageWelfareWoundsDashboardContainer from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/pages/CageWelfareWoundsDashboard/CageWelfareWoundsDashboardContainer';

export type Pages = GenericPage[];

const pages: Pages = [
    {
        id: 1,
        path: '/',
        label: 'Wound insight',
        exact: true,
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareWoundsDashboardContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        image: HeartBeat2FillSVG,
    },
    {
        parentId: 1,
        id: 2,
        path: '/detections',
        label: 'Wound detections',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareWoundDetectionsContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        exact: true,
    },

    {
        parentId: 1,
        id: 4,
        path: '/graphs',
        label: 'Graphs',
        sidebarToShowFor: SidebarType.cageLevel,
        page: CageWelfareGraphsContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        exact: true,
    },
].map((page, index) => ({ key: index, ...page }));

export const cageWelfareWoundsSidebarOptions: SidebarPageOptionObject[] = pages.map((item) => {
    return {
        page: item,
        url: '',
        active: false,
    };
});

export default pages;
export { pages };
