import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import styles from './OptoTooltip.module.scss';

export interface OptoTooltipProps {
    children?: React.ReactNode;
    content: string | React.ReactNode;
    subHeading?: string;
    heading?: string;
    customClass?: string;
    maxWidth?: number;
    active?: boolean;
    timeout?: number;
    textAlign?: 'left' | 'center' | 'right';
    nub:
        | 'up-left'
        | 'up-right'
        | 'up-center'
        | 'down-center'
        | 'down-left'
        | 'left-center'
        | 'right-center'
        | 'down-right';
}

const OptoTooltip = ({
    children,
    subHeading = '',
    heading = '',
    content = 'content',
    nub = 'right-center',
    customClass = '',
    maxWidth = undefined,
    active = false,
    timeout = 0,
    textAlign = 'left',
}: OptoTooltipProps) => {
    const tipRef = useRef<HTMLDivElement>(null);
    const { hash } = useLocation();
    const selector = `div[data-type="tip"]`;

    const contentIsString = typeof content === 'string';

    const [isActive, setIsActive] = useState<boolean>(hash === '#tip' ? true : active);

    const setActive = (tip: HTMLDivElement) => {
        tip.querySelector(selector)?.setAttribute('data-state', 'active');
        setIsActive(true);
    };

    const setInactive = (tip: HTMLDivElement) => {
        tip.querySelector(selector)?.removeAttribute('data-state');
        setIsActive(false);
    };

    useEffect(() => {
        if (timeout > 0) {
            setTimeout(() => {
                setIsActive(false);
            }, timeout);
        }

        if (tipRef.current) {
            const tip = tipRef.current;

            !contentIsString
                ? tip.querySelector(selector)?.classList.add(styles['component-content'])
                : null;
            tip.addEventListener('mouseenter', setActive.bind(null, tip));
            tip.addEventListener('mouseleave', setInactive.bind(null, tip));

            /*
            if (!contentIsString) {
                const dimmensions = tip.querySelector('[data-type="body"]').getBoundingClientRect();
                tip.style.setProperty('--tip-height', `${dimmensions.height}px`);
            }
        

            if (tipRef.current.querySelector('[data-type="heading"]')) {
                const result = calculatePosition({
                    target: tipRef.current.querySelector('[data-type="heading"]'),
                    container: tipRef.current,
                    arrow: nub,
                    position: 'top',
                });
            }
              */
        }

        return () => {
            if (tipRef.current) {
                const tip = tipRef.current;
                tip.removeEventListener('mouseenter', setActive.bind(null, tip));
                tip.removeEventListener('mouseleave', setInactive.bind(null, tip));
            }
        };
    }, [tipRef.current]);

    const styleObject = { maxWidth: maxWidth !== undefined ? `${maxWidth}px` : 'initial' };

    return (
        <div className={classNames(styles.optotooltip, nub)} ref={tipRef} data-nub={nub}>
            <div
                style={styleObject}
                data-type="tip"
                data-reactnode={!contentIsString}
                data-state={isActive ? 'active' : ''}>
                {heading && <div data-type="heading">{heading}</div>}
                {subHeading && <div data-type="subHeading">{subHeading}</div>}
                <div data-type="body" style={{ textAlign: `${textAlign}` }}>
                    {content}
                </div>
            </div>
            <div className={classNames(customClass.length > 0 ? customClass : '')}>{children}</div>
        </div>
    );
};

export default OptoTooltip;
export { OptoTooltip };
