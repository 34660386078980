import { matchPath, useLocation } from 'react-router-dom';

import SomethingHappened from '@/components/SomethingHappened';
import { BioscopeDetailContainer } from '@/containers/BioscopeDetailContainer';

const CageBioscopeDetailContainer = ({
    clientId,
    locationId,
    cageId,
}: {
    clientId: number;
    locationId?: number;
    cageId?: number;
}) => {
    const location = useLocation();
    const locationMatch = matchPath<{
        clientId: string;
        locationId: string;
        cageId: string;
        bioscopeId: string;
    }>(location.pathname, {
        path: '/c/:clientId/location/:locationId/pen/:cageId/bioscope/:bioscopeId',
        exact: false,
        strict: true,
    });

    const bioscopeId = locationMatch ? parseInt(locationMatch.params.bioscopeId) : null;

    if (!bioscopeId) {
        return <SomethingHappened />;
    }

    return <BioscopeDetailContainer bioscopeId={bioscopeId} />;
};

export default CageBioscopeDetailContainer;
export { CageBioscopeDetailContainer };
