/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useCage } from 'contexts/cage-context';
import { useCageFilter } from 'contexts/cage-filter-context';

import { RequestError } from '@/services/config';
import { useCageController_Distributions } from '@/services/hooks';
import {
    DistributionResult,
    DistributionResultData,
    DistributionResultHistogram,
} from '@/services/types';

const DEFAULT_DISTRIBUTION_TYPE = 'agg';

// biome-ignore lint/complexity/noBannedTypes: <explanation>
type WeightDistribution = {
    days: DistributionResult[];
    dayToIndex: any;
    currentIndex: number;
    datum: DistributionResultData;
    histogram: DistributionResultHistogram[];
    distributionType: string;
    setDistributionType: (distributionType: string) => void;
    loading: boolean;
    error: Error | RequestError | null;
    day: string;
    daypartTag: string;
    weightUnitTag: string;
    setCurrentIndex: (index: number) => void;
};

const WeightDistributionContext = createContext<WeightDistribution>(null);

function useWeightDistribution() {
    return React.useContext(WeightDistributionContext);
}

function WeightDistributionProvider({ children }) {
    const { daypartTag, weightUnitTag, from, to } = useCageFilter();
    const { cageId } = useCage();

    const {
        data,
        isLoading: loading,
        error,
    } = useCageController_Distributions(cageId, {
        daypartTag,
        weightUnitTag,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        from: (from as any)?.format('YYYY-MM-DD'),
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        to: (to as any)?.format('YYYY-MM-DD'),
    });

    const [preferredIndex, setPreferredIndex] = useState<number>();
    const [distributionType, setDistributionType] = useState(DEFAULT_DISTRIBUTION_TYPE);

    // reset preferred index when cage filter distribution is loaded
    useEffect(() => {
        if (loading) setPreferredIndex(undefined);
    }, [loading]);

    // use separate memo to avoid sorting it when distributionType or index is changed
    const days = useMemo(
        () =>
            data?.data
                ?.filter((dd) => dd.daypartTag === daypartTag && dd.weightUnitTag === weightUnitTag)
                ?.sort((a, b) => (a.day > b.day ? 1 : a.day < b.day ? -1 : 0)),
        [data, daypartTag, weightUnitTag]
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const context = useMemo(() => {
        const dayToIndex = days?.reduce((acc, cur, index) => {
            acc[cur.day] = index;
            return acc;
        }, {});

        const currentIndex =
            days?.length > 0
                ? preferredIndex === undefined ||
                  preferredIndex === null ||
                  preferredIndex >= days.length
                    ? days.length - 1
                    : preferredIndex
                : undefined;

        const current = days?.[currentIndex];

        const datum = current?.data?.find(
            (datum) => datum?.dailyDistributionTypeTag === distributionType
        );

        const histogram = current?.histograms?.filter(
            (histogram) => histogram?.dailyDistributionTypeTag === distributionType
        );

        return {
            days,
            dayToIndex,
            currentIndex,
            datum,
            histogram,
            distributionType,
            setDistributionType,
            loading,
            error,
            day: current?.day,
            daypartTag: current?.daypartTag,
            weightUnitTag: current?.weightUnitTag,
            setCurrentIndex: setPreferredIndex,
        };
    }, [days, distributionType, error, loading, preferredIndex]);

    return (
        <WeightDistributionContext.Provider value={context}>
            {children}
        </WeightDistributionContext.Provider>
    );
}

export default useWeightDistribution;
export { WeightDistributionProvider };
