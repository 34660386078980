import React, { useCallback, useEffect, useState } from 'react';

import { Loader } from '@/components/atoms/Loader';
import SomethingHappened from '@/components/SomethingHappened';
import useCage from '@/contexts/cage-context';
import { useMe } from '@/contexts/meContext';
import { createCageUrl } from '@/helpers/urlGenerator';
import { fullSizeImageMapper, useImageState } from '@/hooks/image-state';
import {
    CageFormattedOverviewProvider,
    useRestFormattedOverview,
} from '@/routes/Client/Cage/components/cage-formatted-overview-context';
import { CageGraphDataProvider } from '@/routes/Client/Cage/components/cage-graph-data-context';
import { CageOverviewContextProvider } from '@/routes/Client/Cage/components/cage-overview-context';
import CageDashboardPageContent from '@/routes/Client/Cage/pages/CageDashboard/CageDashboard/CageDashboardPageContent';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import {
    useCageController_Status,
    useClientController_Overview,
    useLiceController_Last200Lice,
    useWelfareController_allImages,
} from '@/services/hooks';
import { OverviewLocation } from '@/services/types';

const CageDashboardContainer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const { isOptoscaleAdmin } = useMe();
    const { hasColorWelfare, timezone } = useCage();

    const { loading: formattedOverviewIsLoading, data: formattedOverviewData } =
        useRestFormattedOverview();

    const { isLoading: cagesStatusIsLoading, data: cageStatusData } = useCageController_Status(
        cageId,
        { staleTime: 60 * 1000 }
    );

    const {
        isLoading: clientOverviewIsLoading,
        data: clientOverviewData,
        error: clientOverviewError,
        refetch,
    } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const type = hasColorWelfare ? 'full-size-image' : 'bw-welfare';
    const detections = useWelfareController_allImages(cageId ?? 0, { type, numberOfImages: '1' });
    const detectionImageState = useImageState({
        loading: detections.isLoading && !detections.error,
        data: detections.data?.data,
        mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
        queryParam: 'imageId',
        timezone,
    });

    const [licePageUrl, setLicePageUrl] = useState<string>(
        `${createCageUrl(clientId, locationId, cageId)}/lice`
    );

    useEffect(() => {
        if (clientId && locationId && cageId) {
            setLicePageUrl(`${createCageUrl(clientId, locationId, cageId)}/lice`);
        }
    }, [clientId, locationId, cageId]);

    const last200 = useLiceController_Last200Lice(cageId, {
        staleTime: 5 * 60000,
    });

    const defaultMeasurmentSource = 'legacy';

    const [measurementSource, setMeasurementSource] = useState<'raw' | 'legacy'>(
        defaultMeasurmentSource
    );

    const loading = last200.isLoading;
    const error = last200.error;

    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <SomethingHappened />;
    }

    function mapData<T extends typeof last200.data.data>(data: T): T {
        return {
            ...data,
            caligusElongatusAvg:
                measurementSource === 'legacy' // Now reporting / simulated
                    ? data.caligusElongatusLegacyAvg
                    : data.caligusElongatusAvg,
            movingStageAvg:
                measurementSource === 'legacy' ? data.movingStageLegacyAvg : data.movingStageAvg,
            femaleAvg: measurementSource === 'legacy' ? data.femaleLegacyAvg : data.femaleAvg,
        };
    }

    const mapDataLast200 = (data: typeof last200.data.data): typeof last200.data.data => {
        if (!data) {
            return;
        }
        const initalMap = mapData(data);

        return {
            ...initalMap,
            caligusElongatusAvgDiff:
                measurementSource === 'legacy'
                    ? data.caligusElongatusLegacyAvgDiff
                    : data.caligusElongatusAvgDiff,
            femaleAvgDiff:
                measurementSource === 'legacy' ? data.femaleLegacyAvgDiff : data.femaleAvgDiff,
            movingStageAvgDiff:
                measurementSource === 'legacy'
                    ? data.movingStageLegacyAvgDiff
                    : data.movingStageAvgDiff,
        };
    };

    return (
        <CageOverviewContextProvider>
            <CageFormattedOverviewProvider>
                <CageGraphDataProvider>
                    <CageDashboardPageContent
                        clientId={clientId}
                        locationId={locationId}
                        cageId={cageId}
                        last200={
                            last200?.data?.data ? mapDataLast200(last200?.data?.data) : undefined
                        }
                        licePageUrl={licePageUrl}
                        images={detectionImageState.loading ? [] : detectionImageState.images}
                        cageStatus={cagesStatusIsLoading ? null : cageStatusData?.data}
                        isOptoscaleAdmin={isOptoscaleAdmin}
                        location={
                            clientOverviewData?.data?.locations?.find(
                                (x) => x.id === locationId
                            ) as OverviewLocation
                        }
                        detectionsLoading={detectionImageState.loading}
                    />
                </CageGraphDataProvider>
            </CageFormattedOverviewProvider>
        </CageOverviewContextProvider>
    );
};

export default CageDashboardContainer;
