// import { Loader } from 'components/atoms/Loader';
import { t } from 'i18next';
import { MeasurmentOverviewResult } from 'services/types';
import { getTimeUnitFromTimeRange } from 'utils';

import Loader from '@/components/atoms/Loader';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import useCage from '@/contexts/cage-context';
import useCageFilter from '@/contexts/cage-filter-context';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import { dayTagValues } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';
import SomethingHappened from 'components/SomethingHappened';

import useGraphData from '../../../components/cage-graph-data-context';

import CageWelfareFishSpeedPageContent from './CageWelfareFishSpeedPageContent';

const CageWelfareFishSpeedContainer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const { daypartTag = 'day', error, loading, weightUnitTag, data } = useGraphData();
    const { weightUnitTag: tag, daypartTag: dayPart, to, from, setFilter } = useCageFilter();
    const { timezone, loading: cageLoading } = useCage();

    // Fetch chosen daytag from menu date time filter.
    const disabeledDayTags = dayTagValues.filter((dayTag) => dayTag !== dayPart);

    const pageComponentData = {
        disabeledDayTags,
        loading,
        data: data as MeasurmentOverviewResult,
        weightUnitTag: tag as 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%',
        daypartTag: dayPart,
        timeUnitTag: getTimeUnitFromTimeRange(from, to),
        /* NB NB - server does not return the correct dataset in staging. Not able to return from last year */
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        setFilter({
            from: dateReturn.dateFrom,
            to: dateReturn.dateTo,
        });
    };

    return (
        <>
            <PageTitleRow title={t('Fish Speed')}></PageTitleRow>
            <CageWelfareFishSpeedPageContent
                {...pageComponentData}
                cageFilterHandler={handleSetCageFilter}
                inputDateObject={{
                    dateFrom: from,
                    dateTo: to,
                    shouldStick: false,
                }}
            />
        </>
    );
};

export default CageWelfareFishSpeedContainer;
export { CageWelfareFishSpeedContainer };
