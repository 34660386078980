import { useState } from 'react';
import { useCage } from 'contexts/cage-context';
import FileSaver from 'file-saver';
import { get } from 'lodash';
import formattedTs from 'utils/formattedTs';
import isObject from 'utils/isObject';

import { sanitizeFileName } from '@/utils/fileNameFormatting';

import { useRestOverview } from '../routes/Client/Cage/components/cage-overview-context';
const TIME_FORMAT = 'YYYYMMDD';
function noop(...props) {
    return props?.length === 1 ? props[0] : props?.length > 1 ? [...props] : undefined;
}

function SaveCSV(filename, header, rows, extension) {
    const blob = new Blob(
        [[`#${header.join(',')}`, ...rows.map((row) => row.join(','))].join('\n')],
        {
            type: 'data:text/csv;charset=utf-8',
        }
    );

    FileSaver.saveAs(blob, `${filename}.${extension}`);
}
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = 'xlsx';

async function SaveXLSX(filename, header, rows) {
    const XLSX = await import('xlsx');
    const ws = XLSX.utils.json_to_sheet(rows);
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });
    const wb = {
        Sheets: { data: ws },
        SheetNames: ['data'],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${filename}.${fileExtension}`);
}

interface DownloadCSVXLSXProps {
    data: any[];
    nameToInclude?: string;
    COLUMNS?: any[];
}
export const useDownloadCSVXLSX = ({ data, nameToInclude, COLUMNS = [] }: DownloadCSVXLSXProps) => {
    const { cage, location } = useCage();
    const [loadingCSV, setLoadingCSV] = useState(false);
    const [loadingXLSX, setLoadingXLSX] = useState(false);
    const [error, setError] = useState(false);
    const overview = useRestOverview();
    const joinedFilename = [
        location?.name ?? '',
        cage?.name ?? '',
        nameToInclude ? nameToInclude : '',
        formattedTs(overview?.data?.days.at(-1)?.day, undefined, { format: TIME_FORMAT }) ?? '',
    ]
        .filter((x) => x)
        .join('_');
    const filename = sanitizeFileName(joinedFilename);
    const allColumnNames =
        COLUMNS.length > 0 ? COLUMNS.map((col) => (isObject(col) ? col?.name : col)) : [];

    const header = allColumnNames?.map((e) => e.replace('day.', ''));
    const rows =
        data?.map((day) => {
            const data = {
                location,
                cage,
                day,
            };
            return COLUMNS.map((item) => {
                const [col, factory] = isObject(item)
                    ? [item?.name, item?.factory ?? noop]
                    : [item, noop];
                return factory(get(data, col)) ?? null;
            });
        }) ?? [];
    const downloadCSV = () => {
        const extension = 'csv';
        setLoadingCSV(true);
        Promise.resolve(SaveCSV(filename, header, rows, extension))
            .catch((err) => setError(err))
            .finally(() => setLoadingCSV(false));
    };

    const downloadXLSX = () => {
        setLoadingXLSX(true);
        Promise.resolve(SaveXLSX(filename, header, rows))
            .catch((err) => setError(err))
            .finally(() => setLoadingXLSX(false));
    };

    return {
        loadingCSV,
        loadingXLSX,
        error,
        downloadCSV,
        downloadXLSX,
    };
};
