import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import english from './en.translation.json';
import spanish from './es.translation.json';
import norwegian from './nb.translation.json';

const resources = {
    en: {
        translation: english,
    },
    nb: {
        translation: norwegian,
    },
    es: {
        translation: spanish,
    },
};

// Create a Set to store missing keys
const missingKeys = new Set<string>();

const isValidLocale = (locale: string): boolean => {
    try {
        new Intl.Locale(locale);
        return true;
    } catch (e) {
        return false;
    }
};

// https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    try {
        // Get current language from i18n instance
        const currentLang = i18n.language;
        const localeStr = isValidLocale(currentLang) ? currentLang : 'en';
        const locale = new Intl.Locale(localeStr);
        // console.log(`Capitalizing ${str} in ${currentLang}`);
        return (
            str.toLocaleUpperCase(locale.toString()).charAt(0) +
            str.slice(1).toLocaleLowerCase(locale.toString())
        );
    } catch (e) {
        // Fallback to simple capitalization if anything goes wrong
        console.warn(`Capitalization failed, falling back to simple case`, e);
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .use({
        type: 'postProcessor',
        name: 'capitalizeFirstLetter',
        process: (value: string, key: any, options: any, translator: any) => {
            return capitalizeFirstLetter(value);
        },
    })
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        // saveMissing: true,
        // missingKeyHandler: (lng, ns, key, fallbackValue) => {
        //     if (lng[0] === 'en') {
        //         missingKeys.add(key);
        //         // Log to console in development
        //         if (process.env.NODE_ENV === 'development') {
        //             console.warn(`Missing translation key: ${key}`);
        //             console.log('Current missing keys:', Array.from(missingKeys));
        //         }
        //     }
        // }
    });

export default i18n;
