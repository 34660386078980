import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useBioscopeImageController_GetImageList,
    useFeedbackController_ImageFeedback,
} from 'services/hooks';
import { BioscopeDetail, ImageFeedbackDto } from 'services/types';

import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import { useMe } from '@/contexts/meContext';
import { bioscopeImageBioscopeMapper, useImageState } from '@/hooks/image-state';
import { placeHolder } from '@/services/config';
import { Loader } from 'components/atoms/Loader';
import { Page } from 'components/Layout';
import { ImageViewContainer } from 'components/organisms/ImageViewer';

interface BioscopeImageCardContainerProps {
    bioscope: BioscopeDetail;
}

const BioscopeImageCardContainer = ({ bioscope }: BioscopeImageCardContainerProps) => {
    const { t } = useTranslation();

    const bioscopeImages = useBioscopeImageController_GetImageList(bioscope.id, {
        placeholderData: placeHolder<[]>([]),
    });

    const { isOptoscaleAdmin, state } = useMe();

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }
        if (bioscope?.id) {
            feedbackDto = { ...feedbackDto, bioscopeId: bioscope.id };
        }

        const { status, headers, data } = await reportFeedbackAsync({ requestBody: feedbackDto });

        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    const bioscopeImageState = useImageState({
        timezone: bioscope?.lastLocation?.cage?.location?.timezone ?? 'Europe/Oslo',
        loading: bioscopeImages.isLoading,
        data: bioscopeImages.data?.data,
        mapper: useCallback(bioscopeImageBioscopeMapper(bioscope.id), [bioscope.id]),
        queryParam: 'bioscopeImageId',
    });

    return (
        <Loader loading={bioscopeImages.isLoading}>
            <Page title="Bioscope Images">
                <ImageViewContainer
                    key="bioscope"
                    instanceKey="bioscope"
                    isColor={false}
                    viewerConfig={{
                        scrollActivated: true,
                        showPois: false,
                        magnifyerDisabled: true,
                        showWoundLabel: false,
                        showHelpText: false,
                    }}
                    isOptoScaleAdmin={isOptoscaleAdmin}
                    onCommitImageFeedbackDto={onCommitImageFeedbackDto}
                    {...bioscopeImageState}
                />
            </Page>
        </Loader>
    );
};

export default BioscopeImageCardContainer;
export { BioscopeImageCardContainer };
