import { match } from 'ts-pattern';

import {
    OfflineIconSVG,
    OnlineIconSVG,
    OnTheMoveIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { WinchFrontendData } from '@/routes/Client/Location/pages/LocationWinch/MqttTopics';

const isPosition = (winchData: WinchFrontendData, positionName: string, tolerance: number) => {
    const position = winchData.positions.find((pos) => pos.name === positionName)?.position;
    if (!position) return false;
    return (
        isWithinTolerance(winchData.currentWinchPosition.position.x, position.x, tolerance) &&
        isWithinTolerance(winchData.currentWinchPosition.position.y, position.y, tolerance)
    );
};

/**
 * Prepares a formatted string representing the coordinates of the winch position.
 *
 * @param winchData - The data object containing information about the winch's current position.
 * @returns A formatted string with the coordinates and position type, or null if winchData is null.
 *
 * @example
 * ```typescript
 * const winchData = {
 *   currentWinchPosition: {
 *     position: { x: 10, y: 20 }
 *   }
 * };
 * const result = prepareCoordinatesText(winchData);
 * console.log(result); // Output: "[X: 10, Y: 20] in m"
 * ```
 */
export const prepareCoordinatesText = (
    winchData: WinchFrontendData | null,
    translatedUnit: string
) => {
    if (!winchData) return null;

    const preparedString = `[X: ${winchData.currentWinchPosition.position.x}, Y: ${winchData.currentWinchPosition.position.y}]  (${translatedUnit})`;

    if (isPosition(winchData, 'service position', 1)) {
        return `${preparedString} (Service position)`;
    }
    if (isPosition(winchData, 'measuring position', 1)) {
        return `${preparedString} (Measurement position)`;
    }

    return preparedString;
};

export const getStatusIcon = (winchStatus?: string, BioscopeStatus?: string) => {
    const status = `${winchStatus}-${BioscopeStatus}`;

    return match(status)
        .with('moving-online', () => OnTheMoveIconSVG)
        .with('idle-online', () => OnlineIconSVG)
        .with('idle-offline', () => OfflineIconSVG)
        .otherwise(() => OfflineIconSVG);
};

export const getStatusColor = (winchStatus?: string, BioscopeStatus?: string) => {
    const status = `${winchStatus}-${BioscopeStatus}`;

    return match(status)
        .with('moving-online', () => 'green')
        .with('idle-online', () => 'green')
        .with('idle-offline', () => 'red')
        .otherwise(() => 'red');
};

export const isWithinTolerance = (num1: number, num2: number, tolerance: number): boolean => {
    return Math.abs(num1 - num2) <= tolerance;
};
