import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { DailyLiceCount, Last3DaysResult, TemperatureResult } from 'services/types';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { ArrowRightSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Loader } from '@/components/atoms/Loader';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import UmerBox from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementImage } from '@/components/atoms/UmerBoxElements/UmerBoxElementImage';
import { Image } from '@/components/organisms/ImageViewer';
import {
    defaultBarChartOptions,
    OptoBarChart,
    OptoMixedChart,
} from '@/components/organisms/OptoGraphs';
import Options, {
    autoTimeScale,
    formatAxsis,
    formatTooltip,
    multipleYAxes,
    startYAxisAtZero,
    StepSizeAxsis,
    suggestedMaxMin,
    TypeNames,
    Unit,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import { DailyLiceCountToLiceCountBarChartMapper } from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import { defaultLineChartOptions } from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { LiceCountTemperatureMixedChartMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { createCageUrl } from '@/helpers/urlGenerator';
//import LiceOverview from './components/LiceOverview';
import LiceStatus from 'components/molecules/LiceStatus';

import styles from './CageLiceDashboard.module.scss';

//when changing the name in side GRAPH_LABELS_YAXIS array,
//should also change the name in AdultLiceTemperatureGraph.jsx file
const GRAPH_LABELS = [
    {
        label: 'Adult Female Lice',
        axis: 'y-axis-left',
        dataLabel: 'femaleAvg',
        licenseMaxRequired: false,
    },
    {
        label: 'Mobile Lice',
        alxis: 'y-axis-left',
        dataLabel: 'movingStageAvg',
        licenseMaxRequired: true,
    },
    {
        label: 'Caligus elongatus',
        axis: 'y-axis-left',
        dataLabel: 'caligusElongatusAvg',
        licenseMaxRequired: true,
    },
    {
        label: 'Temperature',
        axis: 'y-axis-right',
        dataLabel: 'temperature',
        licenseMaxRequired: true,
    },
] as const;

const COLORS = ['#5bb784', '#9dd4b6', '#354d78', 'rgba(203, 217, 252, 0.4)'];

interface CageLiceDashboardPageContentProps {
    clientId: number;
    locationId: number;
    cageId: number;
    last200?: Last3DaysResult & {
        count?: number;
        countNoLice?: number;
        dayPart?: string;
        daypartId?: number;
        total?: number;
    };
    showMaximumLiceInformation: boolean;
    liceCount: DailyLiceCount[];
    showLiceImagesForClient?: boolean;
    measurementSource?: 'raw' | 'legacy';
    licePageUrl?: string;
    images?: Image[];
    liceImagesLoading: boolean;
    temperature: TemperatureResult[];
    hasLiceLicense: boolean;
}

const CageLiceDashboardPageContent = ({
    clientId,
    locationId,
    cageId,
    last200,
    liceCount,
    showMaximumLiceInformation,
    licePageUrl,
    images,
    liceImagesLoading,
    temperature,
    hasLiceLicense,
}: CageLiceDashboardPageContentProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const graphLabels = GRAPH_LABELS.filter((licenseCheck) => {
        if (!licenseCheck.licenseMaxRequired) return true;
        if (showMaximumLiceInformation) return showMaximumLiceInformation;
        return false;
    });

    const stepSizeFishCount =
        liceCount?.reduce((maxTotal, count) => {
            return count.total > maxTotal ? count.total : maxTotal;
        }, 0) > 1000
            ? 500
            : 100;

    const weeklyStepSizeFishCount =
        liceCount?.reduce((maxTotal, count) => {
            return count.total > maxTotal ? count.total : maxTotal;
        }, 0) > 1000
            ? 500
            : 100;

    return (
        <div className={styles.pageBoxLayout}>
            <UmerBox doublePadding={true}>
                <div className={styles.liceStatusBox}>
                    <UmerBoxElementHeader
                        headerText={t('Average number of lice per fish')}
                        description={t(
                            'The numbers shows the averages from the count of the last 200+ fish.'
                        )}
                    />
                    <LiceStatus
                        liceInMin200Fish={last200}
                        licePageUrl={licePageUrl}
                        hasLiceLicense={hasLiceLicense}
                    />
                </div>
            </UmerBox>
            <UmerBox doublePadding={true}>
                <div className={styles.detectionBox}>
                    <UmerBoxElementHeader headerText={t('Detections')}>
                        <IconButton
                            variant={'secondary'}
                            buttonText={t('View')}
                            iconPos={'right'}
                            onClick={() =>
                                history.push(
                                    `${createCageUrl(clientId, locationId, cageId)}/lice/detections`
                                )
                            }>
                            <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                        </IconButton>
                    </UmerBoxElementHeader>
                    <div className={styles.smallBoxMaxWidth}>
                        {!liceImagesLoading && (
                            <>
                                {images.length > 0 ? (
                                    <UmerBoxElementImage
                                        imagePath={images[images.length - 1]?.path}
                                    />
                                ) : (
                                    <NoComponentDataAvailable />
                                )}
                            </>
                        )}
                        {liceImagesLoading && <Loader />}
                    </div>
                </div>
            </UmerBox>

            <UmerBox>
                <OptoMixedChart
                    chartName={t('Lice Count & Temperature')}
                    chartData={LiceCountTemperatureMixedChartMapper({
                        data: {
                            liceCount: liceCount,
                            temperature: temperature as TemperatureResult[],
                        },
                    })}
                    actionButtons={{
                        copyAsPng: true,
                        resetZoom: true,
                        downloadAsPng: true,
                        fullScreen: true,
                    }}
                    chartOptions={Options(defaultLineChartOptions).modify(
                        multipleYAxes({
                            right: {
                                text: t('Temperature'),
                                unit: Unit.celcius,
                            },
                            left: {
                                text: t('Lice count'),
                                unit: Unit.number,
                            },
                        }),
                        suggestedMaxMin({ min: 0, max: 20, axsisID: 'yAxisRight' }),
                        startYAxisAtZero({ axsisID: 'yAxisRight' }),
                        formatAxsis({
                            axsisID: 'yAxisLeft',
                            tickType: TypeNames.number,
                            descimals: 2,
                        }),
                        formatAxsis({
                            axsisID: 'yAxisRight',
                            tickType: TypeNames.celcius,
                            descimals: 0,
                        }),
                        autoTimeScale({}),
                        formatTooltip({
                            locale: i18n.language,
                            tickType: TypeNames.number,
                            descimals: 2,
                        })
                    )}
                />
            </UmerBox>

            <UmerBox>
                <OptoBarChart
                    chartData={DailyLiceCountToLiceCountBarChartMapper({
                        liceCount: liceCount,
                    })}
                    chartName={t('Fish count')}
                    chartDescription={t('Number of approved lice countings per day')}
                    showLegends={false}
                    actionButtons={{
                        copyAsPng: true,
                        resetZoom: true,
                        downloadAsPng: true,
                        fullScreen: true,
                        timeScaleSwitch: false,
                    }}
                    chartOptions={Options(defaultBarChartOptions).modify(
                        autoTimeScale({}),
                        StepSizeAxsis({
                            stepSize: weeklyStepSizeFishCount,
                            axsisID: 'y',
                        })
                    )}
                />
            </UmerBox>
        </div>
    );
};

export default CageLiceDashboardPageContent;
export { CageLiceDashboardPageContent };
