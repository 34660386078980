import React, { useState } from 'react';
import { Table } from 'reactstrap';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    HeaderGroup,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';

import styles from './table.module.scss';
export interface OptoTableProps<T extends object> {
    data: T[];
    columns: ColumnDef<T>[];
    initialSort?: SortingState;
    filter?: string;
    setFilter?: (filter: string) => void;
}

function HeaderColumns<T>({ headers }: { headers: HeaderGroup<T>[] }) {
    //We only support a single header group for now
    const headerGroup = headers[0];

    return (
        <>
            {headerGroup.headers
                .filter((header) => !header.isPlaceholder)
                .map((header) => {
                    return (
                        <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                            <span className="mr-1 text-nowrap">
                                {' '}
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {header.column.getIsSorted() === 'asc' ? (
                                    <i className="fa fa-fw fa-sort-down " />
                                ) : header.column.getIsSorted() === 'desc' ? (
                                    <i className="fa fa-fw fa-sort-up" />
                                ) : (
                                    <i className="fa fa-fw" />
                                )}
                            </span>
                        </th>
                    );
                })}
        </>
    );
}

export function OptoTable<T extends object>({
    data,
    columns,
    initialSort,
    filter,
}: OptoTableProps<T>) {
    const [sorting, setSorting] = useState<SortingState>(initialSort || []);
    const table = useReactTable<T>({
        data,
        columns,
        state: {
            sorting,
            globalFilter: filter,
        },
        enableFilters: true,
        enableGlobalFilter: true,

        getColumnCanGlobalFilter: (column) => filter !== '',
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),

        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        debugRows: false,
        debugCells: false,
    });
    return (
        <div className={styles.optoTable}>
            <Table hover>
                {/* responsive */}
                <thead>
                    <tr>
                        <HeaderColumns headers={table.getHeaderGroups()} />
                    </tr>
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td className="align-middle" key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}
