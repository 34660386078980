import React, { useEffect, useState } from 'react';

import { OverrideObject } from '@/components/molecules/DatePicker/DatePicker';

import styles from './DatePickerListItem.module.scss';

export interface DatePickerListItemProps {
    leftSideText?: string;
    rightSideText?: string;
    onClick?: (input: string, override: OverrideObject) => void;
    selected?: boolean;
    overrideDates?: OverrideObject;
}

const DatePickerListItem = ({
    leftSideText = '',
    rightSideText = '',
    onClick = () => {},
    selected = false,
    overrideDates,
}: DatePickerListItemProps) => {
    const [isSelected, setIsSelected] = useState<boolean>(selected);

    const handleOnClick = () => {
        onClick(leftSideText, overrideDates);
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    useEffect(() => {
        overrideDates.text = leftSideText;
    }, []);

    return (
        <div
            className={isSelected ? styles.datepickerlistitemselected : styles.datepickerlistitem}
            onClick={() => handleOnClick()}>
            <div className={styles.datepickerlistitemsubcontainer}>
                <div className={styles.rightsidemargin}>{leftSideText}</div>
                <div>{rightSideText}</div>
            </div>
        </div>
    );
};

export default DatePickerListItem;
export { DatePickerListItem };
