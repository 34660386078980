import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCage } from 'contexts/cage-context';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { ArrowRightSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementText } from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import SwitchButtons from '@/components/molecules/SwitchButtons';
import { defaultBarChartOptions, OptoBarChart } from '@/components/organisms/OptoGraphs';
import { CombinedWelfareResultMapper } from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import { FullMaturationMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { createCageUrl } from '@/helpers/urlGenerator';
import { ImageFeedbackDto } from '@/services/types';
import FishHealthSummary from 'components/atoms/FishHealthSummary/FishHealthSummary';
import { UmerBox } from 'components/atoms/UmerBox';
import {
    autoTimeScale,
    categoryScale,
    chartTitle,
    formatAxsis,
    layoutPadding,
    Options,
    smoothLines,
    stackedAxis,
    startYAxisAtZero,
    suggestedMaxMin,
    tooltipFooterCalculationModifier,
    TypeNames,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    MaturationDailyDevelopmentMapper,
    OptoMixedChart,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import type { ChartActionButtons } from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

import { FishHealthData } from '../CageWelfareWounds/components/FishHealthData';

import styles from './CageWelfareMaturation.module.scss';

export interface CageWelfareMaturationPageContentProps {
    clientId: number;
    locationId: number;
    cageId: number;
    source: string;
    fishHealthData: FishHealthData;
    actionButtons?: ChartActionButtons;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
}

const DEFAULT_EQ_THRESHOLD = 0.02 / 7; //More than 2 % per week is considered a significant change

export const CageWelfareMaturationPageContent = ({
    clientId,
    locationId,
    cageId,
    source,
    fishHealthData,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
    healthType = 'wounds',
    cageFilterHandler,
    inputDateObject,
}: CageWelfareMaturationPageContentProps) => {
    const { isTrout } = useCage();
    const { t } = useTranslation();
    const history = useHistory();

    const { lastEstimate, slopePerDay } = fishHealthData;
    const [switchValue, setSwitchValue] = useState<'weekly' | 'daily'>('weekly');
    const switchButtonsConfig = {
        switches: [
            {
                label: 'Weekly',
                value: 'weekly',
                selected: true,
            },
            {
                label: 'Daily',
                value: 'daily',
            },
        ],
        size: 'lg' as const,
        onClick: (e) => setSwitchValue(e.currentTarget.name as 'weekly' | 'daily'),
    };
    const isColor = source === 'color';
    const maturationValueCombined = isColor ? lastEstimate.fullyMature : lastEstimate.matureJaw;
    const maturationChangeCombined = isColor ? slopePerDay.fullyMature : slopePerDay.matureJaw;

    const getStatus = (value: number) => {
        if (value > DEFAULT_EQ_THRESHOLD) {
            return 'negative';
        } else if (value < -DEFAULT_EQ_THRESHOLD) {
            return 'positive';
        }
        return 'neutral';
    };
    const getDirection = (value: number) =>
        value > DEFAULT_EQ_THRESHOLD ? 'up' : value < -DEFAULT_EQ_THRESHOLD ? 'down' : 'flat';

    const summary = {
        wound: {
            value: lastEstimate.wound,
            slope: slopePerDay.wound,
            status: getStatus(slopePerDay.wound),
            direction: getDirection(slopePerDay.wound),
        },
        skinSpeckles: {
            value: lastEstimate.skinSpeckles,
            slope: slopePerDay.skinSpeckles,
            status: getStatus(slopePerDay.skinSpeckles),
            direction: getDirection(slopePerDay.skinSpeckles),
        },
        maturation: {
            value: maturationValueCombined,
            slope: maturationChangeCombined,
            status: getStatus(slopePerDay.fullyMature),
            direction: getDirection(slopePerDay.fullyMature),
        },
    } as const;

    const chartData = CombinedWelfareResultMapper({
        welfareResult: fishHealthData as FishHealthData,
        dayTags: [],
        inactiveSetLegend: ['noMaturation'],
    });

    const onlyWeekNumbers = (chartData?.labels || [])?.map((label) =>
        label.replace(/\bweek\b/g, '').trim()
    ); // remove 'week' from the label

    return (
        <div className={styles.pageBoxLayout}>
            {isColor && (
                <div className={styles.leftSideBox}>
                    <UmerBox doublePadding={true}>
                        <FishHealthSummary
                            dataRate={summary.maturation.value} // what would be the maturation percentage?
                            status={summary.maturation.status}
                            variant="inline"
                            label="maturation"
                            direction={summary.maturation.direction}
                        />
                    </UmerBox>
                    <UmerBox>
                        <div className={styles.switchWrapper}>
                            <SwitchButtons {...switchButtonsConfig} />
                        </div>
                        {switchValue === 'weekly' ? (
                            <OptoBarChart
                                chartData={chartData}
                                chartName={t('Weekly development of Maturation')}
                                chartOptions={Options(defaultBarChartOptions).modify(
                                    categoryScale({
                                        xAxsisUnitName: t('week'),
                                        labels: onlyWeekNumbers,
                                    }),
                                    suggestedMaxMin({ min: 0, max: 5 }),
                                    startYAxisAtZero({}),
                                    chartTitle({
                                        title: t('Weekly development of Maturation'),
                                    }),
                                    formatAxsis({
                                        axsisID: 'y',
                                        tickType: TypeNames.percent,
                                        descimals: 0,
                                    })
                                )}
                            />
                        ) : (
                            <>
                                <OptoMixedChart
                                    chartData={FullMaturationMapper({
                                        fishHealth: fishHealthData,
                                    })}
                                    chartName={t('Daily development of maturation')}
                                    actionButtons={{ ...actionButtons }}
                                    chartOptions={Options(defaultLineChartOptions).modify(
                                        autoTimeScale({}),
                                        weightAndLengthUnitConverter({
                                            type: 'weight',
                                            fromUnit: '%',
                                            toUnit: '%',
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                        }),
                                        stackedAxis({ x: true, y: false }),
                                        startYAxisAtZero({}),
                                        layoutPadding({ padding: 0 }),
                                        tooltipFooterCalculationModifier({
                                            t,
                                            operation: 'SUM',
                                            unit: '%',
                                            showTotalFish: true,
                                            fishHealthData: fishHealthData,
                                        }),
                                        suggestedMaxMin({ min: 0, max: 5 }),
                                        layoutPadding({ padding: 0 }),
                                        smoothLines({}),
                                        chartTitle({
                                            title: t('Daily development of maturation'),
                                        }),
                                        formatAxsis({
                                            axsisID: 'y',
                                            tickType: TypeNames.percent,
                                            descimals: 0,
                                        })
                                    )}
                                />
                            </>
                        )}
                    </UmerBox>
                </div>
            )}
            {!isColor && (
                <div className={styles.leftSideBox}>
                    <>
                        <UmerBox doublePadding={true}>
                            <FishHealthSummary
                                dataRate={summary.maturation.value} // what would be the maturation percentage?
                                status={summary.maturation.status}
                                direction={summary.maturation.direction}
                                variant="inline"
                                label="maturation"
                            />
                        </UmerBox>

                        <UmerBox>
                            <OptoMixedChart
                                chartName={t('Daily development of maturation')}
                                chartData={MaturationDailyDevelopmentMapper({
                                    fishHealth: fishHealthData as FishHealthData,
                                    source,
                                    inactiveSetLegend: [],
                                })}
                                actionButtons={{ ...actionButtons }}
                                chartOptions={Options(defaultLineChartOptions).modify(
                                    autoTimeScale({}),
                                    weightAndLengthUnitConverter({
                                        type: 'weight',
                                        fromUnit: '%',
                                        toUnit: '%',
                                    }),
                                    chartTitle({
                                        title: t('Daily development of maturation'),
                                    }),
                                    stackedAxis({ x: true, y: false }),
                                    startYAxisAtZero({}),
                                    suggestedMaxMin({ min: 0, max: 5 }),
                                    layoutPadding({ padding: 0 }),
                                    smoothLines({})
                                )}
                            />
                        </UmerBox>
                    </>
                </div>
            )}

            <UmerBox doublePadding={true}>
                <UmerBoxElementHeader headerText={t('Detections')}></UmerBoxElementHeader>
                <div className={styles.infoBoxContainer}>
                    <UmerBoxElementText text={t('')}></UmerBoxElementText>
                    <IconButton
                        variant={'secondary'}
                        buttonText={t('View')}
                        iconPos={'right'}
                        onClick={() =>
                            history.push(
                                `${createCageUrl(
                                    clientId,
                                    locationId,
                                    cageId
                                )}/welfare/maturation/detections`
                            )
                        }>
                        <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                    </IconButton>
                </div>
            </UmerBox>
        </div>
    );
};
