type MqttTopicsVideo = {
    bioscopeStatus: string;
    videoStatus: string;
    liveStream: string;
};

const mqttTopicsVideo = (bioscopeId: number | string): MqttTopicsVideo => {
    return {
        bioscopeStatus: `bioscope/status/${bioscopeId}`,
        videoStatus: `bioscope/livestream/${bioscopeId}/status`,
        liveStream: `bioscope/livestream/${bioscopeId}`,
    };
};

export { mqttTopicsVideo };
