import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import { MqttClient } from 'mqtt/*';

import { IconButton } from '@/components/atoms/Buttons';
import { WinchIconSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { UmerBox } from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import LocationWinchCard from '@/components/molecules/LocationWinchCard';
import { LocationWinchCardHeader } from '@/components/molecules/LocationWinchCard/LocationWinchCard';
import { OptoModal, OptoModalTemplateSettingsBioscope } from '@/components/molecules/OptoModal';
import {
    OptoModalTemplateMoveBioscope,
    OptoModalTemplateMoveBioscopeProps,
    OptoModalTemplateSettingsBioscopeProps,
} from '@/components/molecules/OptoModal/OptoModal';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { ToastInfo } from '@/components/molecules/ToastTemplate/ToastTemplate';
import { CagesWithActiveBioscopesByLocationDto } from '@/services/types';

import { ensureUpdatedValue } from './LocationWinchPageFunctions';
import { MqttTopics, transformToGUIWinchData, WinchData, WinchFrontendData } from './MqttTopics';

import styles from './LocationWinch.module.scss';

export interface LocationWinchPageContentProps {
    title?: string;
    mqttClient?: MqttClient | null;
    currentTopic?: {
        [key: number]: MqttTopics;
    };
    locationBioScopes?: CagesWithActiveBioscopesByLocationDto[];
}

const LocationWinchPageContent = ({
    title = '',
    mqttClient,
    currentTopic,
    locationBioScopes,
}: LocationWinchPageContentProps) => {
    const [t] = useTranslation();
    const [moveBioscopeModalOpen, setMoveBioscopeModalOpen] = useState<boolean>(false);
    const [currentMoveModal, setCurrentMoveModal] =
        useState<OptoModalTemplateMoveBioscopeProps | null>(null);
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
    const [currentSettingsModal, setCurrentSettingsModal] =
        useState<OptoModalTemplateSettingsBioscopeProps | null>(null);

    const [winchData, setWinchData] = useState<WinchFrontendData[] | null>(null);
    const [onlineBioscopes, setOnlineBioscopes] = useState<Set<number>>(new Set());
    const [resetAction, setResetAction] = useState<boolean>(false);

    const saveSettingsHandler = (payload: {
        distanceToPulley?: string;
        minDistanceX?: string;
        maxDistanceX?: string;
        maxDepthY?: string;
        minDepthY?: string;
    }) => {
        if (currentSettingsModal?.bioscopeId && currentTopic) {
            const currentTopicsForBioscope = currentTopic[currentSettingsModal.bioscopeId];

            const mergedPayload: WinchData['winch_settings'] = {
                distanceToPulley: ensureUpdatedValue(
                    payload.distanceToPulley,
                    currentSettingsModal?.settings?.distanceToPulley
                ),
                max: {
                    x: ensureUpdatedValue(
                        payload.maxDistanceX,
                        currentSettingsModal.settings?.max.x
                    ),
                    y: ensureUpdatedValue(payload.maxDepthY, currentSettingsModal.settings?.max.y),
                },
                min: {
                    x: ensureUpdatedValue(
                        payload.minDistanceX,
                        currentSettingsModal.settings?.min.x
                    ),
                    y: ensureUpdatedValue(payload.minDepthY, currentSettingsModal.settings?.min.y),
                },
            };

            mqttClient?.publish(
                currentTopicsForBioscope.publish_topics.winch_settings_set,
                JSON.stringify(mergedPayload)
            );
        }
    };

    const saveMoveToPositionHandler = (payload: { positionY?: string; positionX?: string }) => {
        if (currentMoveModal?.bioscopeId && currentTopic) {
            const currentTopicsForBioscope = currentTopic[currentMoveModal?.bioscopeId];
            const mergedPayload = {
                x: ensureUpdatedValue(payload.positionX, currentMoveModal?.position?.x as number),
                y: ensureUpdatedValue(payload.positionY, currentMoveModal?.position?.y as number),
            };

            mqttClient?.publish(
                currentTopicsForBioscope.publish_topics.winch_move,
                JSON.stringify(mergedPayload)
            );
        }
    };

    const openSettingsHandler = ({
        settings,
        cageId,
        cageName,
        winchId,
        bioscopeId,
    }: OptoModalTemplateSettingsBioscopeProps) => {
        setCurrentSettingsModal((prevState) => {
            return {
                ...prevState,
                settings: settings,
                cageId: cageId,
                cageName: cageName,
                winchId: winchId,
                bioscopeId: bioscopeId,
            };
        });
        if (settings !== null) {
            setSettingsModalOpen(true);
        } else {
            console.error('Settings is null', settings);
        }
    };

    const openMoveHandler = ({
        settings,
        position,
        cageId,
        cageName,
        winchId,
        bioscopeId,
        timestamp,
    }: OptoModalTemplateMoveBioscopeProps) => {
        setCurrentMoveModal((prevState) => {
            return {
                ...prevState,
                settings: settings,
                position: position,
                cageId: cageId,
                cageName: cageName,
                winchId: winchId,
                bioscopeId: bioscopeId,
                timestamp: timestamp,
            };
        });
        if (settings !== null && position !== null) {
            setMoveBioscopeModalOpen(true);
        } else {
            console.error('Move setting or position is null', settings, position);
        }
    };

    const cancelModalHandler = (modalName: string) => {
        if (modalName.toLocaleLowerCase() === 'settings') {
            setSettingsModalOpen(false);
        } else if (modalName.toLocaleLowerCase() === 'move') {
            setMoveBioscopeModalOpen(false);
        }

        setResetAction(() => {
            return true;
        });
    };

    const moveAllToMeasurePositionHandler = () => {
        if (currentTopic && mqttClient) {
            for (const key of Object.keys(currentTopic)) {
                if (onlineBioscopes.has(parseInt(key))) {
                    const currentTopicsForBioscope = currentTopic[parseInt(key)];

                    for (const winch of winchData || []) {
                        const measuringPosition = winch.positions.find(
                            (pos) => pos.name === 'measuring position'
                        );

                        if (!measuringPosition) {
                            console.error('Measuring position not found for winch', winch);
                            continue;
                        }

                        const payload = {
                            x: measuringPosition.position.x,
                            y: measuringPosition.position.y,
                        };

                        mqttClient.publish(
                            currentTopicsForBioscope.publish_topics.winch_move,
                            JSON.stringify(payload)
                        );

                        ToastInfo({
                            content: t('Flytter alle bioscoper til måleposisjon'),
                            type: 'success',
                            icon: 'winch',
                        });
                    }
                }
            }
        }
    };

    const moveAllToServicePositionHandler = () => {
        if (currentTopic && mqttClient) {
            for (const key of Object.keys(currentTopic)) {
                if (onlineBioscopes.has(parseInt(key))) {
                    const currentTopicsForBioscope = currentTopic[parseInt(key)];

                    for (const winch of winchData || []) {
                        const servicePosition = winch.positions.find(
                            (pos) => pos.name === 'service position'
                        );

                        if (!servicePosition) {
                            console.error('Measuring position not found for winch', winch);
                            continue;
                        }

                        const payload = {
                            x: servicePosition.position.x,
                            y: servicePosition.position.y,
                        };
                        mqttClient.publish(
                            currentTopicsForBioscope.publish_topics.winch_move,
                            JSON.stringify(payload)
                        );

                        ToastInfo({
                            content: t('Flytter alle bioscoper til serviceposisjon'),
                            type: 'success',
                            icon: 'winch',
                        });
                    }
                }
            }
        }
    };
    useEffect(() => {
        if (!currentTopic || !mqttClient) return;

        const handleMessage = (topic: string, message: Buffer) => {
            for (const key of Object.keys(currentTopic)) {
                const currentTopicsForBioscope = currentTopic[parseInt(key)];
                if (topic === currentTopicsForBioscope.subscribe_topics.winch_status_response) {
                    const winchStatusData = JSON.parse(message.toString());
                    const transformed = transformToGUIWinchData(winchStatusData);
                    setWinchData((prevState) => {
                        if (prevState) {
                            return prevState.map((data, index) => ({
                                ...data,
                                ...(transformed as unknown as WinchFrontendData[])[index],
                            })) as WinchFrontendData[];
                        }
                        return [transformed] as WinchFrontendData[];
                    });
                }

                if (topic === currentTopicsForBioscope.subscribe_topics.bioscope_status) {
                    const bioscopeStatusData = JSON.parse(message.toString());
                    setOnlineBioscopes((prevState) => {
                        const temp = new Set(prevState);
                        if (bioscopeStatusData.status === 'online') {
                            temp.add(parseInt(key));
                        } else if (bioscopeStatusData.status === 'offline') {
                            temp.delete(parseInt(key));
                        }
                        return temp;
                    });
                }
            }
        };

        for (const key of Object.keys(currentTopic)) {
            const currentTopicsForBioscope = currentTopic[parseInt(key)];
            if (currentTopicsForBioscope) {
                mqttClient.publish(
                    currentTopicsForBioscope.publish_topics.winch_status,
                    currentTopicsForBioscope.messages.winch_status_message,
                    { qos: 1 },
                    (err) => {
                        if (err) {
                            console.error(
                                `Failed to publish to ${currentTopicsForBioscope.publish_topics.winch_status}`,
                                err
                            );
                        }
                    }
                );
            }
        }

        mqttClient.on('message', handleMessage);

        return () => {
            for (const key of Object.keys(currentTopic)) {
                const currentTopicsForBioscope = currentTopic[parseInt(key)];
                if (currentTopicsForBioscope) {
                    mqttClient.unsubscribe(
                        currentTopicsForBioscope.subscribe_topics.winch_positions_get_response
                    );
                }
            }
            mqttClient.off('message', handleMessage);
        };
    }, [currentTopic, mqttClient]);

    return (
        <>
            {settingsModalOpen && currentSettingsModal && (
                <OptoModal
                    open={settingsModalOpen}
                    confirmHandler={saveSettingsHandler}
                    cancelHandler={cancelModalHandler}
                    title="Settings">
                    <OptoModalTemplateSettingsBioscope
                        settings={currentSettingsModal?.settings}
                        cageId={currentSettingsModal?.cageId || '0'}
                        cageName={currentSettingsModal?.cageName || ''}
                        winchId={currentSettingsModal?.winchId || '0'}
                    />
                </OptoModal>
            )}
            {moveBioscopeModalOpen && currentMoveModal && (
                <OptoModal
                    open={moveBioscopeModalOpen}
                    confirmHandler={saveMoveToPositionHandler}
                    cancelHandler={cancelModalHandler}
                    confirmButtonText={t('Move')}
                    icon={WinchIconSVG}
                    title="Move">
                    <OptoModalTemplateMoveBioscope
                        settings={currentMoveModal?.settings}
                        position={currentMoveModal?.position}
                        cageId={currentMoveModal?.cageId || '0'}
                        cageName={currentMoveModal?.cageName || ''}
                        winchId={currentMoveModal?.winchId || '0'}
                        timestamp={currentMoveModal.timestamp}
                    />
                </OptoModal>
            )}
            <PageTitleRow title={title} />
            <UmerBox>
                <UmerBoxElementHeader>
                    <div className={styles.responsiveBlock}>
                        <IconButton
                            disabled={onlineBioscopes.size === 0}
                            variant="secondary"
                            buttonText={t('Flytt alle til måleposisjon')}
                            onClick={moveAllToMeasurePositionHandler}
                        />
                        <IconButton
                            disabled={onlineBioscopes.size === 0}
                            variant="secondary"
                            buttonText={t('Flytt alle til serviceposisjon')}
                            onClick={moveAllToServicePositionHandler}
                        />
                    </div>
                </UmerBoxElementHeader>
                <section
                    className={styles.winchTableSection}
                    aria-label="winch table for status and actions on a bioscope winch">
                    <LocationWinchCardHeader />
                    {currentTopic &&
                        Object.keys(currentTopic).map((key) => {
                            const currentCage = locationBioScopes?.find((bioscope) => {
                                return bioscope.bioscopeId === parseInt(key);
                            });

                            if (currentCage !== undefined && currentTopic !== undefined) {
                                return (
                                    <LocationWinchCard
                                        key={currentCage.bioscopeId}
                                        cage={currentCage}
                                        client={mqttClient}
                                        bioscopeId={currentCage.bioscopeId}
                                        currentTopic={currentTopic[parseInt(key)]}
                                        openSettingsModal={openSettingsHandler}
                                        openMoveModal={openMoveHandler}
                                        resetAction={resetAction}
                                    />
                                );
                            }
                            return null;
                        })}
                </section>
            </UmerBox>
        </>
    );
};

export default LocationWinchPageContent;
export { LocationWinchPageContent };
