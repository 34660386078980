import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { IconButton } from '@/components/atoms/Buttons/IconButton';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import { ImageFeedbackDto } from '@/services/types';

import DropDownPanel from '../DropDownPanel';
import { DropDownPanelProps } from '../DropDownPanel/DropDownPanel';
import SmallDropDown from '../SmallDropdown';

import DropDownObjects from './ShareFalseDetectionDropDownObjects';

import styles from './ShareFalseDetectionDropdown.module.scss';

// This is a panel, so it implements the DropDownPanelProps interface
// making sure all DropDownPanels / panels are consistent
export interface ShareFalseDetectionDropdownProps extends DropDownPanelProps {
    children?: React.ReactNode;
    isOptoscaleAdmin?: boolean;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
    showLiceOptions: boolean;
    showHealthOptions: boolean;
}

const ShareFalseDetectionDropdown = ({
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    showLiceOptions = false,
    showHealthOptions = false,
    children = null,
    open = false,
}: ShareFalseDetectionDropdownProps) => {
    const { t } = useTranslation();

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(open);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [selectedDropdownTags, setSelectedDropdownTags] = useState('');
    const [buttonShouldBeActive, setButtonShouldBeActive] = useState(false);

    const allDropdownTags = DropDownObjects;

    const dropdownTags: any[] = [];

    if (showLiceOptions) {
        allDropdownTags
            .filter((x) => x.type === 'lice' && !x.onlyOptoScaleAdmin)
            .map((obj) => {
                dropdownTags.push(obj);
            });
    }
    if (showHealthOptions) {
        allDropdownTags
            .filter((x) => x.type === 'health' && !x.onlyOptoScaleAdmin)
            .map((obj) => {
                dropdownTags.push(obj);
            });
    }

    if (isOptoscaleAdmin) {
        allDropdownTags
            .filter((x) => x.onlyOptoScaleAdmin)
            .map((obj) => {
                dropdownTags.push(obj);
            });
    }

    const ref = useRef<HTMLDivElement>(null);

    const handleClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            // onCancel();
        }
    };

    const commitFalseDetection = () => {
        if (onCommitFalseDetection) {
            const feedbackDto: ImageFeedbackDto = {
                imageMetadataDto: undefined,
                tag: selectedDropdownTags,
            };

            onCommitFalseDetection(feedbackDto);
        }

        onCancel();
    };

    const onCancel = () => {
        setDropdownOpen(false);
        setSelectedDropdownTags('');
    };

    useEffect(() => {
        setButtonShouldBeActive(selectedDropdownTags.length > 0);
    }, [selectedDropdownTags]);

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        dropdownTags.length > 0 && (
            <>
                <div className={styles.sharefalsedetection}>
                    <ShareFalseDetectionTriggerButton
                        toggleDropdownHandler={() => {
                            toggle();
                        }}
                    />
                    <DropDownPanel open={dropdownOpen}>
                        <fieldset className={styles.sharefalsedetection_content}>
                            <legend>{t('Submit a report for the picture')}</legend>
                            <SmallDropDown
                                items={dropdownTags}
                                value={selectedDropdownTags}
                                buttonElipsis={false}
                                scrollable={false}
                                onChange={(value) => setSelectedDropdownTags(value)}
                                title={t('Select type of detection')}
                                className={classnames(styles.reportingDropdownMenu)}
                            />

                            <div className={classnames(styles.buttonContainer)}>
                                <IconButton
                                    buttonText={t('Send report')}
                                    disabled={!buttonShouldBeActive}
                                    variant="primary"
                                    size="medium"
                                    onClick={() => {
                                        commitFalseDetection();
                                    }}
                                />

                                <IconButton
                                    buttonText={t('Cancel')}
                                    disabled={!buttonShouldBeActive}
                                    variant="primary"
                                    size="medium"
                                    onClick={() => {
                                        onCancel();
                                    }}
                                />
                            </div>
                        </fieldset>
                    </DropDownPanel>
                </div>
            </>
        )
    );
};

export interface ShareFalseDetectionTriggerButtonProps {
    toggleDropdownHandler: () => void;
}

const ShareFalseDetectionTriggerButton = ({
    toggleDropdownHandler = () => {},
}: ShareFalseDetectionTriggerButtonProps) => {
    const { t } = useTranslation();
    return (
        <OptoTooltip content={t('Send a report about an error in the picture')} nub={'up-right'}>
            <IconButton
                name="feedback"
                variant={'secondary'}
                size={'small'}
                onlyIcon={true}
                onClick={toggleDropdownHandler}
                aria-label={'Open false detection dropdown'}
                buttonText={t('Wrong detection')}>
                <GenericIcon icon={iconUrls.FeedBackIconSVG} />
            </IconButton>
        </OptoTooltip>
    );
};

export default ShareFalseDetectionDropdown;
export { ShareFalseDetectionDropdown, ShareFalseDetectionTriggerButton };
