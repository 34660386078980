import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { useCageIsPageAvailable } from 'hooks/is-page-available-cage-layer';
import { t } from 'i18next';

import { PageContent } from '@/components/Layout';
import Page from '@/components/Layout/Page';
import { PageTitleForAnalytics } from '@/components/Layout/PageTitle/PageTitle';
import { GenericPage, GenericSidebar } from '@/components/molecules/GenericSidebar';
import useCage, { CageProvider } from '@/contexts/cage-context';
import { LaksvelProvider } from '@/contexts/laksvel-context';
import { useMe } from '@/contexts/meContext';
import { createCageUrl } from '@/helpers/urlGenerator';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';

import LaksvelPageContent from './LaksvelPageContent';
import pages, { laksvelSidebarOptions } from './LaksvelSubPages';

const LaksvelLayer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const match = useRouteMatch();
    const { client, dayparts, timezone, cage, loading: cageIsLoading } = useCage();
    const { isOptoscaleAdmin, state } = useMe();

    const lakvelLayerUrlPath = `${createCageUrl(clientId, locationId, cageId)}/welfare/laksvel`;

    const welfareUrlPath = `${createCageUrl(clientId, locationId, cageId)}/welfare`;
    const isPageAvailable = useCageIsPageAvailable();
    const checkPageAvailableLakvelLayer = (page: GenericPage) => {
        return isOptoscaleAdmin ? true : isPageAvailable(page);
    };

    const checkPageAvailableWhileLoading = (page: GenericPage) => {
        if (isOptoscaleAdmin) {
            return true;
        }
        return !(page.licenses?.length > 0);
    };

    const availablePages = useMemo(() => {
        return (pages ?? []).filter((page) => true);
    }, [isPageAvailable, pages]);

    return (
        <CageProvider cageId={cageId}>
            <Page title={'Lakvel'}>
                <GenericSidebar
                    sideBarOptions={laksvelSidebarOptions}
                    layerUrlPrefix={lakvelLayerUrlPath}
                    backOption={
                        welfareUrlPath
                            ? {
                                  url: welfareUrlPath,
                                  title: t('Welfare'),
                              }
                            : null
                    }
                    checkIsPageAvailable={
                        cageIsLoading
                            ? checkPageAvailableWhileLoading
                            : checkPageAvailableLakvelLayer
                    }
                />

                <PageContent>
                    <LaksvelProvider
                        cageId={cageId}
                        dayparts={dayparts}
                        timezone={timezone || 'UTC'}>
                        <Switch>
                            {availablePages.map((page) => (
                                <Route
                                    key={page.key}
                                    exact={true}
                                    path={`${match.url}${page?.path ?? ''}`}
                                    render={(props: RouteComponentProps) => {
                                        return (
                                            <>
                                                <PageTitleForAnalytics
                                                    title={`Cage - Laksvel - ${page.label}`}
                                                    category={'laksvel'}
                                                />
                                                <LaksvelPageContent
                                                    laksvelmenu={availablePages}
                                                    clientId={client?.id}
                                                    cageId={cageId}
                                                    isOptoscaleAdmin={isOptoscaleAdmin}
                                                    title={
                                                        page?.params?.i18n
                                                            ? t(page.params.i18n, {
                                                                  postProcess:
                                                                      'capitalizeFirstLetter',
                                                              })
                                                            : ''
                                                    }
                                                    routerProps={props}>
                                                    {page?.page ? <page.page /> : null}
                                                </LaksvelPageContent>
                                            </>
                                        );
                                    }}
                                />
                            ))}
                        </Switch>
                    </LaksvelProvider>
                </PageContent>
            </Page>
        </CageProvider>
    );
};

export default LaksvelLayer;
