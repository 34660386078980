import { Redirect, Route, Switch } from 'react-router-dom';

import { Login } from './Login/Login';
import { Login2 } from './Login/Login2';
import { ResetPasswordContainer } from './ResetPassword/ResetPasswordContainer';
import { ResetPassword2Container } from './ResetPassword2/ResetPassword2Container';
import { ResetPasswordRequestContainer } from './ResetPasswordRequest/ResetPasswordRequestContainer';
import { ResetPasswordRequest2Container } from './ResetPasswordRequest2/ResetPasswordRequest2Container';
import { UserRegisterContainer } from './UserRegister/UserRegisterContainer';

const Public = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/login2" component={Login2} />
            <Route exact path="/reset-password-request" component={ResetPasswordRequestContainer} />
            <Route
                exact
                path="/reset-password-request2"
                component={ResetPasswordRequest2Container}
            />
            <Route exact path="/reset-password" component={ResetPasswordContainer} />
            <Route exact path="/reset-password2" component={ResetPassword2Container} />
            <Route exact path="/register" component={UserRegisterContainer} />
            <Redirect to={`/login?redirectTo=${encodeURIComponent(window.location.href)}`} />
        </Switch>
    );
};

export default Public;
