import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedingConversionRateResult, MeasurmentOverviewResult } from 'services/types';

import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { ALL_DAYPART_TAGS, DEFAULT_DAYPART_TAG } from '@/contexts/cage-filter-context';
import {
    defaultBarChartOptions,
    OptoBarChart,
    OptoMixedChart,
    OverViewToBarChartMapper,
} from 'components/organisms/OptoGraphs';
import {
    autoTimeScale,
    formatAxsis,
    multipleYAxes,
    Options,
    smoothLines,
    startYAxisAtZero,
    StepSizeAxsis,
    suggestedMaxMin,
    timeScale,
    TypeNames,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    FeedingConverstionRateChartMapper,
    OverViewToCoefficientOfVariantChartMapper,
    OverViewToConditionFactorChartMapper,
    OverViewToGrowthChartMapper,
    OverViewToHeightAndLengthChartMapper,
    OverViewToLivingWeightChartMapper,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import { ChartActionButtons } from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

import styles from './CageWeightGraphs.module.scss';

export interface GraphsPageContentProps {
    isOptoscaleAdmin?: boolean;
    hasLivingWeightAllTagsEnabled?: boolean;
    loading: boolean;
    weightUnitTag: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%';
    daypartTag: string;
    data: MeasurmentOverviewResult;
    disabeledDayTags: string[];
    FeedingConversionRateGraphData: FeedingConversionRateResult;
    timeUnitTag: string;
    actionButtons?: ChartActionButtons;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
}

const CageWeightGraphsPageContent = ({
    isOptoscaleAdmin = false,
    hasLivingWeightAllTagsEnabled = false,
    disabeledDayTags = [],
    daypartTag = 'day',
    FeedingConversionRateGraphData,
    loading = false,
    data,
    timeUnitTag = 'day',
    weightUnitTag = 'g',
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
    cageFilterHandler,
    inputDateObject,
}: GraphsPageContentProps) => {
    const { t, i18n } = useTranslation();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    return (
        <>
            {locale && (
                <>
                    <div className={styles.datePickerContainer}>
                        <DatePicker
                            onDateChange={cageFilterHandler}
                            fromDefault={inputDateObject.dateFrom}
                            toDefault={inputDateObject.dateTo}
                        />
                    </div>
                    <section className={styles.graphsGridLayout}>
                        <OptoMixedChart
                            chartName={t('Living weight')}
                            chartData={OverViewToLivingWeightChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: ['all'],
                                inactiveSetLegend: [...disabeledDayTags],
                                graphedDayPartTags:
                                    isOptoscaleAdmin || hasLivingWeightAllTagsEnabled
                                        ? ALL_DAYPART_TAGS
                                        : [DEFAULT_DAYPART_TAG],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.weight,
                                    fromUnit: weightUnitTag,
                                    toUnit: weightUnitTag,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoBarChart
                            chartName={t('Measurements')}
                            chartData={OverViewToBarChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                                graphedDayPartTags:
                                    isOptoscaleAdmin || hasLivingWeightAllTagsEnabled
                                        ? ALL_DAYPART_TAGS
                                        : [DEFAULT_DAYPART_TAG],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultBarChartOptions).modify(
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.number,
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                    toUnit: '',
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Growth')}
                            chartData={OverViewToGrowthChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                inactiveSetLegend: [...disabeledDayTags],
                                graphedDayPartTags:
                                    isOptoscaleAdmin || hasLivingWeightAllTagsEnabled
                                        ? ALL_DAYPART_TAGS
                                        : [DEFAULT_DAYPART_TAG],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                smoothLines({ tension: 0.4 }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.weight,
                                    fromUnit: weightUnitTag,
                                    toUnit: weightUnitTag,
                                }),
                                autoTimeScale({}),
                                startYAxisAtZero({})
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Size')}
                            chartData={OverViewToHeightAndLengthChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                                graphedDayPartTags: isOptoscaleAdmin
                                    ? ALL_DAYPART_TAGS
                                    : [DEFAULT_DAYPART_TAG],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                timeScale({ unit: timeUnitTag }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.length,
                                    fromUnit: 'mm',
                                    toUnit: 'cm',
                                }),
                                StepSizeAxsis({ stepSize: 10, axsisID: 'yAxisRight' }),
                                StepSizeAxsis({ stepSize: 2, axsisID: 'yAxisLeft' }),
                                multipleYAxes({
                                    right: {
                                        text: t('Length'),
                                        unit: 'cm',
                                    },
                                    left: {
                                        text: t('Height'),
                                        unit: 'cm',
                                    },
                                }),
                                formatAxsis({
                                    axsisID: 'yAxisRight',
                                    tickType: 'height',
                                    descimals: 1,
                                    multiplier: 0.1,
                                }),
                                formatAxsis({
                                    axsisID: 'yAxisLeft',
                                    tickType: 'length',
                                    descimals: 1,
                                    multiplier: 0.1,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('CV')}
                            chartData={OverViewToCoefficientOfVariantChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                inactiveSetLegend: [...disabeledDayTags],
                                graphedDayPartTags:
                                    isOptoscaleAdmin || hasLivingWeightAllTagsEnabled
                                        ? ALL_DAYPART_TAGS
                                        : [DEFAULT_DAYPART_TAG],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: 'percent',
                                    fromUnit: '%',
                                    toUnit: '%',
                                }),
                                suggestedMaxMin({ min: 0, max: 0.5 }),
                                startYAxisAtZero({}),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: 'percent',
                                    descimals: 0,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Condition factor')}
                            chartData={OverViewToConditionFactorChartMapper({
                                overview: data as MeasurmentOverviewResult,
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                smoothLines({ tension: 0.2 }),
                                suggestedMaxMin({ min: 1.1, max: 1.5 }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: 'number',
                                    toUnit: '',
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Feeding conversion ratio')}
                            chartData={FeedingConverstionRateChartMapper({
                                conversionRate: FeedingConversionRateGraphData,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale({}),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: 'number',
                                    toUnit: '',
                                }),
                                startYAxisAtZero({}),
                                suggestedMaxMin({ min: 0.5, max: 1.5 })
                            )}
                            loading={loading}
                        />
                    </section>
                </>
            )}
        </>
    );
};
export default CageWeightGraphsPageContent;
export { CageWeightGraphsPageContent as PageContent };
