import React from 'react';
import styles from './VideoLoadingTopBaner.module.scss';

interface VideoLoadingTopBanerProps {
    isLoading: boolean;
    children?: React.ReactNode; // Add children prop
}

const VideoLoadingTopBaner = ({ isLoading = false, children }: VideoLoadingTopBanerProps) => {
    return (
        <div className={styles.loadingScreen}>
            <div className={styles.loadingTextContainer}>
                {children} {/* Render children here */}
            </div>
            {isLoading && <div className={styles.loadingBar}></div>}
        </div>
    );
};

export default VideoLoadingTopBaner;
export { VideoLoadingTopBaner };
