import React from 'react';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import {
    handleGetIconBasedOnTrend,
    TrendIconEnum,
} from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend/TrendIconUtils';
import { hexToRGBA } from '@/config/settings';

import styles from './GenericInfoBox.module.scss';

export interface GenericInfoBoxProps {
    title?: string;
    info?: string;
    unit?: string;
    icon?: string;
    circleColor?: string;
    grayBackground?: boolean;
    tooltipText?: string | React.ReactNode;
    shouldHaveTooltip?: boolean;
    trend?: TrendIconEnum;
    showTrend?: boolean;
}

const GenericInfoBox = ({
    title = '',
    info = '',
    unit = '',
    icon = '',
    circleColor = '',
    grayBackground,
    tooltipText = '',
    shouldHaveTooltip = false,
    trend = TrendIconEnum.TrendPositiveUp,
    showTrend = false,
}: GenericInfoBoxProps) => {
    return (
        <div
            className={`${styles.genericinfobox} ${
                grayBackground ? styles.boxbackgroundcolorgray : styles.boxbackgroundcolorwhite
            } ${styles.infoboxnotooltipcontainer}`}>
            {shouldHaveTooltip && (
                <OptoTooltip content={tooltipText} nub={'up-center'} maxWidth={300}>
                    <div className={`${styles.genericinfobox} ${styles.infoboxtooltipcontainer}`} />
                </OptoTooltip>
            )}
            <div className={styles.mainelementcontainer}>
                <div
                    className={`${styles.iconcontainer}`}
                    style={{ backgroundColor: hexToRGBA(circleColor, 0.1) }}>
                    <GenericIcon icon={icon} color={circleColor} size={20} />
                </div>
                <div className={styles.mainsubcontainer}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.infocontainer}>
                        <div className={styles.info}>{info}</div>
                        <div className={styles.unit}>{unit}</div>
                        {showTrend && (
                            <div className={styles.trendIconContainer}>
                                {handleGetIconBasedOnTrend(trend)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenericInfoBox;
export { GenericInfoBox };
