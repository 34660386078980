import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { DailyLiceCount, TemperatureResult } from 'services/types';

import { InformationLineSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import UmerBox from '@/components/atoms/UmerBox';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { UmerBoxElementText } from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { OptoMixedChart } from '@/components/organisms/OptoGraphs';
import Options, {
    autoTimeScale,
    formatAxsis,
    formatTooltip,
    multipleYAxes,
    startYAxisAtZero,
    StepSizeAxsis,
    suggestedMaxMin,
    TypeNames,
    Unit,
} from '@/components/organisms/OptoGraphs/optionsModifiers';
import { defaultLineChartOptions } from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { LiceCountTemperatureMixedChartMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { OptoColors } from '@/config/settings';
import { localizedDateFormat } from '@/helpers/date';

import styles from './CageLiceFemaleLice.module.scss';

//when changing the name in side GRAPH_LABELS_YAXIS array,
//should also change the name in AdultLiceTemperatureGraph.jsx file
const GRAPH_LABELS = [
    {
        label: 'Adult Female Lice',
        axis: 'y-axis-left',
        dataLabel: 'femaleAvg',
        licenseMaxRequired: false,
    },
    {
        label: 'Temperature',
        axis: 'y-axis-right',
        dataLabel: 'temperature',
        licenseMaxRequired: true,
    },
] as const;

const COLORS = ['#5bb784', '#9dd4b6', '#354d78', 'rgba(203, 217, 252, 0.4)'];

interface CageLiceFemaleLicePageContentProps {
    showMaximumLiceInformation: boolean;
    liceCount: DailyLiceCount[];
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
    temperature: TemperatureResult[];
}

const CageLiceFemaleLicePageContent = ({
    liceCount,
    showMaximumLiceInformation,
    cageFilterHandler,
    inputDateObject,
    temperature,
}: CageLiceFemaleLicePageContentProps) => {
    const { t } = useTranslation();

    const lastMeasurement = liceCount.findLast((x) => x.femaleAvg);

    return (
        <>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onDateChange={cageFilterHandler}
                    fromDefault={inputDateObject.dateFrom}
                    toDefault={inputDateObject.dateTo}
                />
            </div>
            <div className={styles.pageBoxLayout}>
                <UmerBox>
                    <OptoMixedChart
                        chartName={t('Lice Count & Temperature')}
                        chartData={LiceCountTemperatureMixedChartMapper({
                            data: {
                                liceCount: liceCount,
                                temperature: temperature as TemperatureResult[],
                            },
                            chartDataPoints: ['adult_female'],
                        })}
                        actionButtons={{
                            copyAsPng: true,
                            resetZoom: true,
                            downloadAsPng: true,
                            fullScreen: true,
                        }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            multipleYAxes({
                                right: {
                                    text: t('Temperature'),
                                    unit: Unit.celcius,
                                },
                                left: {
                                    text: t('Lice count'),
                                    unit: Unit.number,
                                },
                            }),
                            suggestedMaxMin({ min: 0, max: 20, axsisID: 'yAxisRight' }),
                            startYAxisAtZero({ axsisID: 'yAxisRight' }),
                            formatAxsis({
                                axsisID: 'yAxisLeft',
                                tickType: TypeNames.number,
                                descimals: 2,
                            }),
                            formatAxsis({
                                axsisID: 'yAxisRight',
                                tickType: TypeNames.celcius,
                                descimals: 0,
                            }),
                            StepSizeAxsis({ stepSize: 5, axsisID: 'yAxisRight' }),
                            autoTimeScale({}),
                            formatTooltip({
                                locale: i18n.language,
                                tickType: TypeNames.number,
                                descimals: 2,
                            })
                        )}
                    />
                </UmerBox>

                <UmerBox>
                    <div className={styles.rightSideElementContainer}>
                        <UmerBoxElementHeader
                            headerText={t('Information')}
                            headerIcon={InformationLineSVG}
                            headerIconColor={OptoColors.OceanGreen}
                        />
                        <UmerBoxElementText text={t('liceHelpText.adult')} />

                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Last measurement day')}
                            info={
                                lastMeasurement
                                    ? `${localizedDateFormat({
                                          dateString: lastMeasurement.day,
                                          onlydate: true,
                                          locale: i18n.language,
                                      })}`
                                    : t('No data')
                            }
                        />
                    </div>
                </UmerBox>
            </div>
        </>
    );
};

export default CageLiceFemaleLicePageContent;
export { CageLiceFemaleLicePageContent };
