import React from 'react';

import styles from './UmerBoxElementText.module.scss';

export interface UmerBoxElementTextProps {
    text: string;
    variant?: 'normal' | 'footer';
}

const UmerBoxElementText = ({ text = '', variant = 'normal' }: UmerBoxElementTextProps) => {
    return (
        <div data-variant={variant} className={styles.umerboxelementtext}>
            {text}
        </div>
    );
};

export default UmerBoxElementText;
export { UmerBoxElementText };
