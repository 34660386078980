import { useTranslation } from 'react-i18next';
import { Button, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { MeUser } from '@/services/types';

import { Logout } from './components/Logout';

import styles from './ClientNav.module.scss';

export interface ClientNavProps {
    match: {
        path: string;
    };
    user: MeUser;
    clientName: string;
    clients: {
        id: number;
        name: string;
        role: string;
        authLicenses: string[];
    }[];
    onLogout: () => Promise<unknown>;
}

export const ClientNav = ({ match, user, clients, clientName, onLogout }) => {
    const { t } = useTranslation();

    const isInternal = match.path === '/internal';

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
                <Button className={styles.clientNavButton} outline color="dark">
                    <div className={styles.userName}>
                        {user.firstName || user.lastName
                            ? `${user.firstName} ${user.lastName}`
                            : t('Profile')}
                    </div>
                </Button>
            </DropdownToggle>
            <DropdownMenu className={styles.dropdownMenu} right>
                {/*{!!user.systemRole && (*/}
                {/*    <>*/}
                {/*        <DropdownItem className={styles.dropdownItem} tag={Link} to="/internal">*/}
                {/*            {t('Internal Page')}*/}
                {/*        </DropdownItem>*/}
                {/*    </>*/}
                {/*)}*/}
                {/*{(!!user.systemRole || clients.length > 1) && (*/}
                {/*    <>*/}
                {/*        <ClientList clients={clients} />*/}
                {/*        <DropdownItem divider className={styles.dropdownItem} />*/}
                {/*    </>*/}
                {/*)}*/}
                <Logout onLogout={onLogout} />
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
