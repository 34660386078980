import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import i18n, { t } from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    ArrowRightSVG,
    BalanceFillSVG,
    FishFillSVG,
    FishHealthIconSVG,
    GrowthIconSVG,
    LaksvelShieldSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import Notice from '@/components/atoms/Notice/Notice';
import { ToolTipContent } from '@/components/atoms/ToolTipContent';
import { UmerBox } from '@/components/atoms/UmerBox';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementImage } from '@/components/atoms/UmerBoxElements/UmerBoxElementImage';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { TrendIconEnum } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend/TrendIconUtils';
import BioscopeStatusBox from '@/components/molecules/BioscopeStatusBox';
import { DownloadCsvXlsx } from '@/components/molecules/DownloadCsvXlsx';
import { GenericInfoBox } from '@/components/molecules/GenericInfoBox';
import LiceStatus from '@/components/molecules/LiceStatus';
import PageActionButtons from '@/components/molecules/PageActionButtons';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import WeightStatus from '@/components/molecules/WeightStatus';
import { Image } from '@/components/organisms/ImageViewer';
import { TroutNotice } from '@/components/organisms/TroutNotice/TroutNotice';
import LICENSES from '@/config/licenses';
import settings from '@/config/settings';
import useCage from '@/contexts/cage-context';
import { supressStatusMessages } from '@/helpers/alarmCheckers';
import { createCageUrl } from '@/helpers/urlGenerator';
import { useRestFormattedOverview } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
//
// import BioscopeStatusBox from './components/BioscopeStatusBox';
// import OverviewTour from './components/OverviewTour';
import { useRestOverview } from '@/routes/Client/Cage/components/cage-overview-context';
import {
    CageStatus,
    CageType,
    Last3DaysResult,
    OverviewCage,
    OverviewLocation,
} from '@/services/types';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';
import { formatPercentage } from '@/utils/formatPercentage';
import {
    calculateFishSpeedToolTip,
    getSpeedStatus,
} from '@/utils/SharedDataCalculation/swimSpeedCalculations';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import formattedWeightUnit from '../../../../../../utils/formattedWeightUnit';

import styles from './CageDashboard.module.scss';

const DEFAULT_DAYPART_TAG_FOR_DOWNLOAD = 'day';

interface CageDashboardPageContentProps {
    clientId: number;
    locationId: number;
    cageId: number;
    last200: Last3DaysResult & {
        count?: number;
        countNoLice?: number;
        dayPart?: string;
        daypartId?: number;
        total?: number;
    };
    licePageUrl?: string;
    cageStatus?: CageStatus;
    isOptoscaleAdmin?: boolean;
    images?: Image[];
    location: OverviewLocation;
    detectionsLoading: boolean;
}

const CageDashboardPageContent = ({
    clientId,
    locationId,
    cageId,
    last200,
    licePageUrl,
    cageStatus,
    isOptoscaleAdmin = false,
    images,
    location,
    detectionsLoading,
}: CageDashboardPageContentProps) => {
    const { loading, error, data: overview, weightUnitTag } = useRestOverview();
    const { cage, client, timezone, hasCageLicense, isTrout } = useCage();

    const hasLiceLicense = hasCageLicense(LICENSES.LICE_COUNT.MIN);
    const hasLaksvelLicense = hasCageLicense(LICENSES.LAKSVEL);
    const hasHealthLicense = hasCageLicense(LICENSES.FISH_HEALTH.MIN);
    const hasSpeedLicense = hasCageLicense(LICENSES.FISH_SPEED);

    const bioscopeInspectionFeatureFlags = client?.featureFlags?.find(
        (x) => x === 'BIOSCOPE_INSPECTION'
    );

    const hasBioscopeInspectionFeatureFlag = bioscopeInspectionFeatureFlags?.length > 0;
    const bioscopeInspectionLink = cageStatus?.currentBioscope?.id
        ? `${createCageUrl(clientId, locationId, cageId)}/bioscope/${cageStatus.currentBioscope.id}`
        : '';

    const formattedOverview = useRestFormattedOverview();

    const [estimatedWeight, setEstimatedWeight] = useState<string>('');
    const [estimatedWeightUnit, setEstimatedWeightUnit] = useState<string>('');

    const [growth, setGrowth] = useState<string>('');
    const [growthUnit, setGrowthUnit] = useState<string>('');

    const [bioscopeStatus, setBioscopeStatus] = useState<boolean>(false);
    const [bioscopeMessages, setBioscopeMessages] = useState<string[]>([]);

    const [laksvelInfoText, setLaksvelInfoText] = useState('');
    const [laksvelInfoTooltip, setLaksvelInfoTooltip] = useState('');
    const [woundsInfoText, setWoundsInfoText] = useState('');
    const [woundsInfoTooltip, setWoundsInfoTooltip] = useState('');
    const [woundsInfoTrend, setWoundsInfoTrend] = useState(TrendIconEnum.TrendNone);

    const [swimSpeedInfoText, setSwimSpeedInfoText] = useState('');
    const [swimSpeedToolTip, setSwimSpeedToolTip] = useState<React.ReactNode>(<></>);

    const history = useHistory();
    const queryParams = useQueryParams();
    const daypartTag = queryParams[0]?.d ? queryParams[0].d : DEFAULT_DAYPART_TAG_FOR_DOWNLOAD;
    const dailyOverview = overview?.days;
    const dailyOverviewForDownload =
        dailyOverview?.map((el) => ({
            date: el?.day,
            daypartTag: el?.daypartTag,
            measurementsCount: el?.data.measCnt,
            weightAvg: el?.data.weightAvg?.toFixed(2),
            weightTrend: el?.data.weightTrend?.toFixed(2),
            cfAvg: el?.data.cfAvg?.toFixed(2),
            cfTrend: el?.data.cfTrend?.toFixed(2),
            fishLengthAvg: el?.data.fishLengthAvg?.toFixed(2),
            fishHeightAvg: el?.data.fishHeightAvg?.toFixed(2),
            cv: el?.data.cv?.toFixed(2),
            weightGrowthAvg: el?.data.weightGrowthAvg?.toFixed(2),
            weightGrowthTrend: el?.data.weightGrowthTrend?.toFixed(2),
            depthMin: el?.data.depthMin?.toFixed(2),
            depthMax: el?.data.depthMax?.toFixed(2),
            depthAvg: el?.data.depthAvg?.toFixed(2),
        })) ?? [];
    const filteredByDayPartTag =
        dailyOverviewForDownload.filter((item) => item?.daypartTag === daypartTag) ?? [];

    // need to send columns names in this format
    const locationInfoColumns = [
        'location.name',
        'location.timezone',
        'location.sWeightPct',
        'cage.name',
    ];
    const dataColumnNames =
        dailyOverviewForDownload.length > 0 ? Object.keys(dailyOverviewForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    const parseFormattedOverviewBioscopeData = () => {
        const { statusText2, allGood2 } = formattedOverview.data;
        // Filter out status messages that should not be shown
        const alertMessages: string[] =
            !allGood2 && statusText2 && statusText2?.length > 0
                ? supressStatusMessages({
                      cageType: cage?.cageType as CageType,
                      statusTexts: statusText2,
                  })
                : ['allgood'];

        const alertMessageStrings = alertMessages.map((text) => {
            return t(`measurementStatusTexts.${text}`);
        });

        setBioscopeStatus(allGood2);
        setBioscopeMessages(alertMessageStrings);
    };

    const setupWelfareDataFromClientOverview = () => {
        if (location) {
            const cage = location.cages.find((x) => x.id === cageId) as OverviewCage;
            if (cage && cage.laksvel?.laksvel?.avg_laksvel) {
                const laksvelInfoText = `${formatPercentage(
                    cage.laksvel.laksvel.avg_laksvel / 100,
                    1
                )}`;
                setLaksvelInfoText(laksvelInfoText);
                setLaksvelInfoTooltip(
                    `${laksvelInfoText} ${t('Laksvel siteoverview tooltip heading')}`
                );
            } else {
                setLaksvelInfoText('-');
                setLaksvelInfoTooltip(t('No data found'));
            }
            if (cage && cage.speed && hasSpeedLicense) {
                const speedStatus = getSpeedStatus(cage.speed, t);
                setSwimSpeedInfoText(
                    `${localeFormatNumber(cage.speed, 2, i18n.language, '-')} ${t('swimspeed')}`
                );
                const speedToolTip = calculateFishSpeedToolTip(speedStatus, cage, t);
                const speedToolTipContent = <ToolTipContent content={speedToolTip} />;
                setSwimSpeedToolTip(speedToolTipContent);
            } else {
                setSwimSpeedInfoText('-');
                if (hasSpeedLicense) {
                    setSwimSpeedToolTip(t('No data found'));
                }
            }
            if (cage && cage.welfare) {
                const welfareWoundInfo = isNaN(cage.welfare.woundRate)
                    ? ''
                    : `${formatPercentage(cage.welfare.woundRate, 1)}`;
                const welfareWoundGrowth = isNaN(cage?.welfare?.woundSlope)
                    ? '---'
                    : `${formatPercentage(cage?.welfare?.woundSlope, 0)}`;

                setWoundsInfoText(welfareWoundInfo);

                const growth = cage?.welfare?.woundSlope;
                const growthLimit = settings.woundGrowthLimit;
                let helpText: string;
                if (cage?.welfare?.woundRate === undefined) {
                    helpText = t(`No data found`);
                } else if (growth > -growthLimit && growth < growthLimit) {
                    helpText = `${t('stableText')}`;
                } else if (growth >= growthLimit) {
                    helpText = `${t('Growing by')} ${welfareWoundGrowth} ${t('each week')}`;
                    setWoundsInfoTrend(TrendIconEnum.TrendNegativeUp);
                } else if (growth <= -growthLimit) {
                    helpText = `${t('Decreasing by')} ${welfareWoundGrowth} ${t('each week')}`;
                    setWoundsInfoTrend(TrendIconEnum.TrendPositiveDown);
                } else {
                    helpText = '';
                }
                setWoundsInfoTooltip(helpText);
            } else {
                setWoundsInfoText('-');
                setWoundsInfoTooltip(t('No data found'));
            }
        }
    };

    useEffect(() => {
        if (location) {
            setupWelfareDataFromClientOverview();
        }
    }, [location, i18n.language]);

    useEffect(() => {
        if (overview) {
            const { value, weightUnit } = formattedWeightUnit(
                overview.estimated_weight,
                weightUnitTag,
                0,
                false
            );
            if (value) {
                setEstimatedWeight(value);
                setEstimatedWeightUnit(weightUnit);
            } else {
                setEstimatedWeight('-');
                setEstimatedWeightUnit('');
            }
        }
    }, [overview]);

    useEffect(() => {
        if (formattedOverview && !formattedOverview.loading) {
            if (formattedOverview.data.weightGrowth) {
                const localizedGrowth = localeFormatNumber(
                    parseFloat(formattedOverview.data.weightGrowth),
                    1,
                    i18n.language
                );

                setGrowth(localizedGrowth);
                setGrowthUnit(`${t(`weightUnitTag.${weightUnitTag}`)}${t('/day')}`);
            } else {
                setGrowth('-');
                setGrowthUnit('');
            }

            parseFormattedOverviewBioscopeData();
        }
    }, [formattedOverview, i18n.language]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened error={error} />;
    }

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Overview')}>
                <PageActionButtons>
                    <DownloadCsvXlsx
                        label="CSV"
                        data={filteredByDayPartTag}
                        COLUMNS={allColumnNames}
                    />
                    <DownloadCsvXlsx
                        label="XLSX"
                        data={filteredByDayPartTag}
                        COLUMNS={allColumnNames}
                    />
                </PageActionButtons>
            </PageTitleRow>

            <div className={styles.pageBoxLayout}>
                <UmerBox doublePadding={true}>
                    <UmerBoxElementHeader headerText={t('Weight and growth')}>
                        <IconButton
                            variant={'secondary'}
                            buttonText={t('View')}
                            iconPos={'right'}
                            onClick={() =>
                                history.push(
                                    `${createCageUrl(clientId, locationId, cageId)}/weight`
                                )
                            }>
                            <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                        </IconButton>
                    </UmerBoxElementHeader>

                    <div className={styles.weightMainItemsContainer}>
                        <GenericInfoBox
                            title={t('Living weight')}
                            icon={BalanceFillSVG}
                            circleColor={'#FFD479'}
                            info={estimatedWeight}
                            unit={estimatedWeightUnit}
                            grayBackground={true}
                            tooltipText={t('EstimatedLivingWeight')}
                        />
                        <GenericInfoBox
                            title={t('Growth')}
                            icon={GrowthIconSVG}
                            circleColor={'#696999'}
                            info={growth}
                            unit={growthUnit}
                            grayBackground={true}
                            tooltipText={t('descriptions.weightAndGrowthGrowth')}
                        />
                    </div>
                    <WeightStatus formattedOverview={formattedOverview} horizontal={true} />
                </UmerBox>

                <UmerBox doublePadding={true}>
                    <UmerBoxElementHeader headerText={t('Todays catch')}>
                        <IconButton
                            variant={'secondary'}
                            buttonText={t('View')}
                            iconPos={'right'}
                            onClick={() =>
                                history.push(
                                    `${createCageUrl(
                                        clientId,
                                        locationId,
                                        cageId
                                    )}/dashboard/images`
                                )
                            }>
                            <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                        </IconButton>
                    </UmerBoxElementHeader>
                    <div className={styles.smallBoxMaxWidth}>
                        {!detectionsLoading && (
                            <>
                                {images.length > 0 ? (
                                    <UmerBoxElementImage
                                        imagePath={images[images.length - 1]?.path}
                                    />
                                ) : (
                                    <NoComponentDataAvailable />
                                )}
                            </>
                        )}
                        {detectionsLoading && <Loader />}
                    </div>
                </UmerBox>

                <UmerBox doublePadding={true}>
                    <div className={styles.smallSquareBoxContainer}>
                        <UmerBoxElementHeader headerText={t('Lice')}>
                            {hasLiceLicense && (
                                <IconButton
                                    variant={'secondary'}
                                    buttonText={t('View')}
                                    iconPos={'right'}
                                    onClick={() =>
                                        history.push(
                                            `${createCageUrl(clientId, locationId, cageId)}/lice`
                                        )
                                    }>
                                    <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                                </IconButton>
                            )}
                        </UmerBoxElementHeader>
                        <LiceStatus
                            liceInMin200Fish={last200 ? last200 : null}
                            licePageUrl={licePageUrl}
                            hasLiceLicense={hasLiceLicense}
                        />
                    </div>
                </UmerBox>

                {hasHealthLicense && (
                    <UmerBox doublePadding={true}>
                        <div className={styles.smallSquareBoxContainer}>
                            <UmerBoxElementHeader headerText={t('Welfare')}>
                                {hasHealthLicense && (
                                    <IconButton
                                        variant={'secondary'}
                                        buttonText={t('View')}
                                        iconPos={'right'}
                                        onClick={() =>
                                            history.push(
                                                `${createCageUrl(
                                                    clientId,
                                                    locationId,
                                                    cageId
                                                )}/welfare`
                                            )
                                        }>
                                        <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                                    </IconButton>
                                )}
                            </UmerBoxElementHeader>
                            {!isTrout ? (
                                <UmerBoxElementStatusInfoWithTrend
                                    title={t('LAKSVEL')}
                                    info={laksvelInfoText}
                                    optionalSymbolIcon={LaksvelShieldSVG}
                                    toolTipContent={laksvelInfoTooltip}
                                    optionalSymbolContainerColor={'#4579A3'}>
                                    {hasLaksvelLicense && (
                                        <IconButton
                                            onClick={() =>
                                                history.push(
                                                    `${createCageUrl(
                                                        client?.id,
                                                        location?.id,
                                                        cage.id
                                                    )}/welfare/laksvel`
                                                )
                                            }
                                            onlyIcon={true}
                                            variant={'tertiary'}>
                                            <GenericIcon icon={ArrowRightSVG} />
                                        </IconButton>
                                    )}
                                </UmerBoxElementStatusInfoWithTrend>
                            ) : (
                                ''
                            )}

                            <UmerBoxElementDivider />
                            <UmerBoxElementStatusInfoWithTrend
                                title={t('Wounds')}
                                info={woundsInfoText}
                                toolTipContent={woundsInfoTooltip}
                                trend={woundsInfoTrend}
                                optionalSymbolIcon={FishHealthIconSVG}
                                optionalSymbolContainerColor={'#5BB784'}>
                                {hasHealthLicense && (
                                    <IconButton
                                        onClick={() =>
                                            history.push(
                                                `${createCageUrl(
                                                    client?.id,
                                                    location?.id,
                                                    cage.id
                                                )}/welfare/wounds`
                                            )
                                        }
                                        onlyIcon={true}
                                        variant={'tertiary'}>
                                        <GenericIcon icon={ArrowRightSVG} />
                                    </IconButton>
                                )}
                            </UmerBoxElementStatusInfoWithTrend>
                            <UmerBoxElementDivider />
                            <UmerBoxElementStatusInfoWithTrend
                                title={t('Swim Speed')}
                                info={swimSpeedInfoText}
                                toolTipContent={swimSpeedToolTip}
                                optionalSymbolIcon={FishFillSVG}
                                optionalSymbolContainerColor={'#696999'}>
                                {hasSpeedLicense && (
                                    <IconButton
                                        onClick={() =>
                                            history.push(
                                                `${createCageUrl(
                                                    client?.id,
                                                    location?.id,
                                                    cage.id
                                                )}/welfare/speed`
                                            )
                                        }
                                        onlyIcon={true}
                                        variant={'tertiary'}>
                                        <GenericIcon icon={ArrowRightSVG} />
                                    </IconButton>
                                )}
                            </UmerBoxElementStatusInfoWithTrend>
                            <UmerBoxElementDivider />
                        </div>
                    </UmerBox>
                )}

                <UmerBox doublePadding={true}>
                    <UmerBoxElementHeader headerText={t('Bioscope status')}>
                        <IconButton
                            variant={'secondary'}
                            buttonText={t('View')}
                            iconPos={'right'}
                            onClick={() =>
                                history.push(
                                    `${createCageUrl(
                                        clientId,
                                        locationId,
                                        cageId
                                    )}/dashboard/bioscope`
                                )
                            }>
                            <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                        </IconButton>
                    </UmerBoxElementHeader>
                    {bioscopeMessages.length > 0 && (
                        <div className={styles.smallBoxMaxWidth}>
                            <Notice
                                icon={true}
                                type="status"
                                heading="Status"
                                message={bioscopeMessages}
                                variant={bioscopeStatus ? 'allgood' : 'warning'}
                            />
                            <UmerBoxElementDivider />
                        </div>
                    )}

                    <BioscopeStatusBox
                        cageStatus={cageStatus}
                        measurementCount={overview?.period.meas_cnt}
                        timezone={timezone}
                        isOptoscaleAdmin={isOptoscaleAdmin}
                        showBioscopeInspectionLink={hasBioscopeInspectionFeatureFlag}
                        bioscopeInspectionLink={bioscopeInspectionLink}
                    />
                </UmerBox>
            </div>
        </>
    );
};

export default CageDashboardPageContent;
