import { useCallback, useEffect, useState } from 'react';
import i18n, { t } from 'i18next';

import { InformationLineSVG } from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Loader } from '@/components/atoms/Loader';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import { OptoColors } from '@/config/settings';
import { localizedDateFormat } from '@/helpers/date';
import { fullSizeImageMapper, useImageState } from '@/hooks/image-state';
import type { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';
import { FullSizeImage, ImageFeedbackDto } from '@/services/types';
import { UmerBox } from 'components/atoms/UmerBox';
import { FILTER_TAGS } from 'components/organisms/ImageViewer';

import styles from './CageWelfareMaturationDetections.module.scss';

const SHOW_MATURATION_PERCENTAGE_THRESHOLD = 0.005;

export interface CageWelfareMaturationDetectionsPageContentProps {
    source: string;
    isOptoscaleAdmin?: boolean;
    imagesData: FullSizeImage[];
    fishHealthData: FishHealthData;
    cageId: number;
    timezone: string;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
}

export const CageWelfareMaturationDetectionsPageContent = ({
    source,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    cageId,
    timezone,
    imagesData,
    fishHealthData,
}: CageWelfareMaturationDetectionsPageContentProps) => {
    const filterTagsList = FILTER_TAGS.filter((filter) => filter.tag === 'maturation_tag');
    const tag = filterTagsList[0];

    const maturationImageState = useImageState({
        loading: false,
        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        mapper: useCallback(fullSizeImageMapper(cageId), [cageId]),
        data: imagesData,
        queryParam: 'imageId',
        timezone: timezone,
        filter: tag.filter,
    });

    const isColor = source === 'color';

    const [firstSetup, setFirstSetup] = useState(false);

    /* Preparing data for ImageViewer */
    const [selectedImageFilterTag, setSelectedImagedFilterTag] = useState<string>('initial');

    let lastDay: any = null;
    // biome-ignore lint/correctness/useExhaustiveDependencies: We want an effect whenever selectedImageFilterTag changes
    useEffect(() => {
        lastDay = maturationImageState.dayswithimages.findLast(
            (day) => day.day <= maturationImageState.selectedImageDate
        );
        maturationImageState.onAfterTimelineChangeHandler(
            lastDay?.day ?? maturationImageState.dayswithimages.at(0)?.day ?? ''
        );
    }, [selectedImageFilterTag]);

    const last =
        maturationImageState.dayswithimages[maturationImageState.dayswithimages.length - 1];

    useEffect(() => {
        if (!firstSetup) {
            setFirstSetup(true);
            setSelectedImagedFilterTag('all');
        }
    }, [maturationImageState]);
    const { lastEstimate } = fishHealthData;
    const maturationValueCombined = isColor ? lastEstimate.fullyMature : lastEstimate.matureJaw;

    console.log('iscolor', isColor);
    console.log('maturationValueCombined', maturationValueCombined);

    const showMaturationForLowMaturationValues =
        maturationValueCombined > SHOW_MATURATION_PERCENTAGE_THRESHOLD;

    return (
        <div className={styles.pageBoxLayout}>
            <UmerBox>
                {showMaturationForLowMaturationValues ? (
                    <div>
                        {maturationImageState.loading && <Loader />}
                        {!maturationImageState.loading && (
                            <div>
                                <ImageViewContainer
                                    key="detections"
                                    instanceKey="detections"
                                    isOptoScaleAdmin={isOptoscaleAdmin}
                                    isColor={isColor}
                                    viewerConfig={{
                                        showWoundLabel: false,
                                        showPois: false,
                                    }}
                                    onCommitImageFeedbackDto={onCommitFalseDetection}
                                    {...maturationImageState}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div>{t('There is no detected maturation')}</div>
                )}
            </UmerBox>
            <UmerBox>
                <UmerBoxElementHeader
                    headerText={t('Information')}
                    headerIcon={InformationLineSVG}
                    headerIconColor={OptoColors.OceanGreen}
                />
                {showMaturationForLowMaturationValues && last && (
                    <UmerBoxElementStatusInfoWithTrend
                        title={t('Last measurement')}
                        info={localizedDateFormat({
                            dateString: last.day,
                            onlydate: true,
                            locale: i18n.language,
                        })}
                    />
                )}
            </UmerBox>
        </div>
    );
};
