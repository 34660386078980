import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';

import { CageType, FishType, LiceType } from '@/services/types';
import { Button } from 'components/atoms/Buttons';
import { Box } from 'components/Layout';
import AddCageModal from 'components/molecules/AddCageModal';

import { ClientProps } from '../Client';

import { AddLocationModal as AddOrModifyLocationModal } from './AddLocationModal';
import DeleteLocationConfirm from './DeleteLocationConfirm';
import LocationCageGroupContainer from './LocationCageGroupContainer';

const ClientLocations = ({
    cageTypesList = [] as CageType[],
    fishTypesList = [] as FishType[],
    liceTypesList = [] as LiceType[],
    client,
    updateLocation,
    createNewCage,
    createNewLocation,
    refetch,
}: ClientProps) => {
    const { t } = useTranslation();
    const { locations } = client;
    const [expanded, setExpanded] = useState(
        locations.reduce((expanded, location) => {
            expanded[location.id] = false;
            return expanded;
        }, {})
    );

    const [selectedLocationForModal, setSelectedLocationForModal] = useState<null | number>(null);
    const [locationForUpdate, setLocationForUpdate] = useState(null);
    const [addLocationModalIsOpen, setAddLocationModalIsOpen] = useState(false);
    const [updateLocationModalIsOpen, setUpdateLocationModalIsOpen] = useState(false);
    const [deleteLocationModalIsOpen, setDeleteLocationModalIsOpen] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState(
        locations.reduce((selected, location) => {
            selected[location.id] = false;
            return selected;
        }, {})
    );

    const allSelected = Object.values(selectedLocations).every((on) => !!on);
    const someSelected = allSelected ? false : Object.values(selectedLocations).some((on) => !!on);

    const fetchAquaCultureSites = async (input: string) => {
        const result = await fetch(
            `https://api.fiskeridir.no/pub-aqua/api/v1/sites?name=${input}&range=0-9`
        );
        const parsedResult = await result.json();
        return parsedResult.map((site) => ({
            id: site?.siteNr,
            value: site?.name,
            municipality: site?.placement?.municipalityName,
            county: site?.placement?.countyName,
            latitude: site?.latitude,
            longitude: site?.longitude,
            fishTypeName: site?.fishTypeName,
            aquacultureSiteId: site?.siteNr.toString(), // Convert the aquacultureSiteId to string
        }));
    };
    const toggleSelectedLocations = (on) => {
        setSelectedLocations(
            Object.keys(selectedLocations).reduce((selected, key) => {
                selected[key] = on;
                return selected;
            }, {})
        );
    };

    return (
        <Box title={t('Location')}>
            <Table hover>
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                checked={allSelected}
                                ref={(el) => {
                                    if (el) {
                                        el.indeterminate = someSelected;
                                    }
                                }}
                                onChange={() =>
                                    toggleSelectedLocations(!someSelected && !allSelected)
                                }
                            />
                        </th>
                        <th />
                        <th colSpan={2}>{t('Name')}</th>
                        <th>{t('Timezone')}</th>
                        <th>{t('SiteNr')}</th>
                        <th>{t('Fish type')}</th>
                        <th>{t('Historical')}</th>
                        <th>{t('Actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {locations.map((location) => {
                        const isExpanded = !!expanded[location.id];
                        const isSelected = !!selectedLocations[location.id];

                        return (
                            <LocationCageGroupContainer
                                refetch={refetch}
                                key={location.id}
                                location={location as any}
                                fishTypesList={fishTypesList}
                                liceTypesList={liceTypesList}
                                cages={location?.cages}
                                onAddCageToLocation={(location) => {
                                    setLocationForUpdate(location);
                                    setSelectedLocationForModal(location);
                                }}
                                onDeleteCageToLocation={(location) => {
                                    setLocationForUpdate(location);
                                    setDeleteLocationModalIsOpen(true);
                                }}
                                onUpdateLocation={(location) => {
                                    setLocationForUpdate(location);
                                    setUpdateLocationModalIsOpen(true);
                                }}
                                expanded={isExpanded}
                                onToggle={() =>
                                    setExpanded({
                                        ...expanded,
                                        [location.id]: !isExpanded,
                                    })
                                }
                                locationSelected={isSelected}
                                onToggleLocationSelect={() =>
                                    setSelectedLocations({
                                        ...selectedLocations,
                                        [location.id]: !isSelected,
                                    })
                                }
                            />
                        );
                    })}
                </tbody>
            </Table>
            <Button onClick={() => setAddLocationModalIsOpen(true)}>{t('Add location')}</Button>

            <AddOrModifyLocationModal
                key={'new'}
                isOpen={addLocationModalIsOpen}
                fetchAquaCultureSites={fetchAquaCultureSites}
                onSubmit={createNewLocation}
                onClose={() => setAddLocationModalIsOpen(false)}
                modifyOrNew={'new'}
                fishTypesList={fishTypesList}
                liceTypesList={liceTypesList}
            />
            <AddCageModal
                cageTypesList={cageTypesList}
                locationId={selectedLocationForModal}
                createNewCage={createNewCage}
                onClose={() => setSelectedLocationForModal(null)}
            />
            {locationForUpdate && (
                <AddOrModifyLocationModal
                    isOpen={locationForUpdate && updateLocationModalIsOpen}
                    fishTypesList={fishTypesList}
                    liceTypesList={liceTypesList}
                    key={locationForUpdate.id}
                    fetchAquaCultureSites={fetchAquaCultureSites}
                    onSubmit={updateLocation}
                    onClose={() => setUpdateLocationModalIsOpen(false)}
                    modifyOrNew={'modify'}
                    initialState={locationForUpdate}
                />
            )}

            <DeleteLocationConfirm
                location={locationForUpdate}
                isOpen={deleteLocationModalIsOpen}
                onClose={() => setDeleteLocationModalIsOpen(false)}
                refetch={refetch}
            />
        </Box>
    );
};

export default ClientLocations;
