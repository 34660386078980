import styles from './VideoLoaderSkeleton.module.scss';

export interface VideoLoaderSkeletonProps {
    children?: React.ReactNode;
}

const VideoLoaderSkeleton = ({ children }: VideoLoaderSkeletonProps) => {
    return (
        <div className={styles.videoloaderskeleton}>
            <div className={styles.skeleton_loader}>
                <div className={styles.skeleton_image}>
                    <div className={styles.skeleton_image_inner_1}></div>
                    <div className={styles.skeleton_image_inner_2}></div>
                    <div className={styles.skeleton_image_inner_3}></div>
                </div>
                {/* <div className={styles.skeleton_text}></div>
                <div className={styles.skeleton_text}></div>
                <div className={styles.skeleton_text}></div> */}
            </div>
            {children} {/* Render children if provided */}
        </div>
    );
};

export default VideoLoaderSkeleton;
export { VideoLoaderSkeleton };
