import type { ChartData } from 'chart.js';
import { graphSettings, hexToRGBA } from 'config/settings';
import i18n from '@/i18n';
import { CombinedWelfareResult } from '@/services/types';

type WoundDescriptionToPieChartMapperType = ChartData<'pie'> & {
    extras?: {
        count: number;
        relativeCount: number;
    }[];
};

interface WoundDescriptionToPieChartMapperProps {
    data: number[];
    labels: string[];
}

const WoundDescriptionToPieChartMapper = ({
    data = [],
    labels = [],
}: WoundDescriptionToPieChartMapperProps): WoundDescriptionToPieChartMapperType => {
    const [noWound, active, healing] = data;
    const douchnutDataSets = {
        labels: labels.map((label) => i18n.t(label)),
        datasets: [
            {
                data: [noWound, active, healing],
                backgroundColor: [
                    hexToRGBA(graphSettings.fishHealthColors.small, 1.0),
                    hexToRGBA(graphSettings.fishHealthColors.medium, 1.0),
                    hexToRGBA(graphSettings.fishHealthColors.large, 1.0),
                ],
                hidden: false,
            },
        ],
    };

    return douchnutDataSets;
};

export interface MaturationDescriptionToPieChartMapperProps {
    welfareResult: CombinedWelfareResult;
    labels: string[];
    inactiveSetLegend?: string[];
}

type MaturationDescriptionToPieChartMapperType = ChartData<'pie'> & {
    extras?: {
        count: number;
        relativeCount: number;
    }[];
};

const MaturationDescriptionToPieChartMapper = ({
    welfareResult,
    labels,
    inactiveSetLegend = [],
}: MaturationDescriptionToPieChartMapperProps): MaturationDescriptionToPieChartMapperType => {
    const { color } = welfareResult;
    const { startedMaturation, fullyMature } = color.lastEstimate;
    const noMaturation = 1 - (startedMaturation - fullyMature);

    const douchnutDataSets = {
        labels: labels.map((label) => i18n.t(label)),
        datasets: [
            {
                data: [noMaturation, startedMaturation, fullyMature].map((part) => {
                    return part * 100;
                }),
                backgroundColor: [
                    graphSettings.maturationColors.no,
                    graphSettings.maturationColors.started,
                    graphSettings.maturationColors.full,
                ],
                hidden: false,
            },
        ],
    };

    return {
        datasets: [...douchnutDataSets.datasets],
        labels: [...douchnutDataSets.labels],
    };
};

export default WoundDescriptionToPieChartMapper;
export { WoundDescriptionToPieChartMapper, MaturationDescriptionToPieChartMapper };
