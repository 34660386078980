import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';

import { Button } from 'components/atoms/Buttons';

import useWeightDistribution from '../cage-weight-distribution-context';

function WeightDistributionPlayControl() {
    const [isPlaying, setIsPlaying] = useState(false);
    const { days, currentIndex, setCurrentIndex } = useWeightDistribution();
    const { t } = useTranslation();

    const disabled = !Array.isArray(days) || days.length < 2;
    const label = !disabled && isPlaying ? t('Stop') : t('Play');

    const timer = useRef(undefined);

    const startPlay = () => {
        if (isPlaying) return;

        if (
            currentIndex === undefined ||
            currentIndex === null ||
            currentIndex === days?.length - 1
        ) {
            setCurrentIndex(0); // reset
        }

        setIsPlaying(true);
    };

    const stopPlay = useCallback(() => {
        if (!isPlaying) return;
        if (timer.current) {
            clearInterval(timer.current);
            timer.current = undefined;
        }

        setIsPlaying(false);
    }, [isPlaying]);

    const togglePlay = () => {
        if (isPlaying) {
            stopPlay();
        } else {
            startPlay();
        }
    };

    const moveSlider = (value) => {
        stopPlay(false);
        setCurrentIndex(value);
    };

    useEffect(() => {
        if (!isPlaying) return;

        const interval = days?.length > 20 ? 5000 / days.length : 250;
        const timerID = setInterval(() => {
            if (!disabled && currentIndex < days?.length - 1) {
                setCurrentIndex((currentIndex) => currentIndex + 1);
                return;
            }

            stopPlay();
        }, interval);

        timer.current = timerID;

        // clearing interval
        return () => clearInterval(timerID);
    }, [currentIndex, days, disabled, isPlaying, setCurrentIndex, stopPlay]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
            <Button
                style={{ marginRight: 10, minWidth: 150 }}
                onClick={togglePlay}
                disabled={disabled}>
                {label}
            </Button>
            <Slider
                disabled={disabled}
                onChange={moveSlider}
                value={currentIndex}
                min={0}
                max={days?.length - 1}
            />
        </div>
    );
}

export default WeightDistributionPlayControl;
