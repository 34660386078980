import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { useCageIsPageAvailable } from 'hooks/is-page-available-cage-layer';

import { PageTitleForAnalytics } from '@/components/Layout/PageTitle/PageTitle';

interface CageRoutesProps {
    pages: any;
    clientId: number;
    locationId: number;
    cageId: number;
}

function CageRoutes({ pages, clientId, locationId, cageId }: CageRoutesProps) {
    const match = useRouteMatch();
    const isPageAvailable = useCageIsPageAvailable();

    const availablePages = useMemo(() => {
        return (pages ?? []).filter((page) => isPageAvailable(page));
    }, [isPageAvailable, pages]);

    return (
        <Switch>
            {availablePages.map((page) => (
                <Route
                    key={page.key}
                    exact={page?.exact ?? page?.path === ''}
                    path={`${match.url}${page?.path ?? ''}`}
                    render={(props: RouteComponentProps) => {
                        return (
                            <>
                                <PageTitleForAnalytics
                                    category="cage"
                                    title={page.label}
                                    routeProps={props}
                                />
                                {page?.page ? (
                                    <page.page
                                        {...props}
                                        clientId={clientId}
                                        locationId={locationId}
                                        cageId={cageId}
                                    />
                                ) : null}
                            </>
                        );
                    }}
                />
            ))}
        </Switch>
    );
}

export default CageRoutes;
