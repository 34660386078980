import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCage } from 'contexts/cage-context';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    ArrowDownCircleFillSVG,
    ArrowRightSVG,
    FirstAidKitSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Loader } from '@/components/atoms/Loader';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementImage } from '@/components/atoms/UmerBoxElements/UmerBoxElementImage';
import { UmerBoxElementStatusInfoWithTrend } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend';
import { GenericInfoBox } from '@/components/molecules/GenericInfoBox';
import { WoundDescription } from '@/components/molecules/WoundDescription';
import { Image } from '@/components/organisms/ImageViewer';
import { CombinedWelfareResultMapper } from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import { OptoColors } from '@/config/settings';
import { createCageUrl } from '@/helpers/urlGenerator';
import type { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';
import { formatPercentage } from '@/utils/formatPercentage';
import { UmerBox } from 'components/atoms/UmerBox';
import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import styles from './CageWelfareWoundsDashboard.module.scss';

const DEFAULT_EQ_THRESHOLD = 0.02 / 7; //More than 2 % per week is considered a significant change

export interface CageWelfareWoundsDashboardPageContentProps {
    clientId: number;
    locationId: number;
    cageId: number;
    source: string;
    fishHealthData: FishHealthData;
    images?: Image[];
    imagesLoading: boolean;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
}

export const CageWelfareWoundsDashboardPageContent = ({
    clientId,
    locationId,
    cageId,
    source,
    fishHealthData,
    images,
    imagesLoading,
    cageFilterHandler,
    inputDateObject,
}: CageWelfareWoundsDashboardPageContentProps) => {
    const history = useHistory();
    const { isTrout } = useCage();
    const { t } = useTranslation();

    const { lastEstimate, slopePerDay } = fishHealthData;

    const isColor = source === 'color';
    const maturationValueCombined = isColor ? lastEstimate.fullyMature : lastEstimate.matureJaw;
    const maturationChangeCombined = isColor ? slopePerDay.fullyMature : slopePerDay.matureJaw;

    const getStatus = (value: number) =>
        value > DEFAULT_EQ_THRESHOLD
            ? 'negative'
            : value < -DEFAULT_EQ_THRESHOLD
              ? 'positive'
              : 'neutral';
    const getDirection = (value: number) =>
        value > DEFAULT_EQ_THRESHOLD ? 'up' : value < -DEFAULT_EQ_THRESHOLD ? 'down' : 'flat';

    const summary = {
        wound: {
            value: lastEstimate.wound,
            slope: slopePerDay.wound,
            status: getStatus(slopePerDay.wound),
            direction: getDirection(slopePerDay.wound),
        },
        skinSpeckles: {
            value: lastEstimate.skinSpeckles,
            slope: slopePerDay.skinSpeckles,
            status: getStatus(slopePerDay.skinSpeckles),
            direction: getDirection(slopePerDay.skinSpeckles),
        },
        maturation: {
            value: maturationValueCombined,
            slope: maturationChangeCombined,
            status: getStatus(slopePerDay.fullyMature),
            direction: getDirection(slopePerDay.fullyMature),
        },
    } as const;
    function descimalToPercentage(value: number): number {
        return Number(value * 100);
    }

    const chartData = CombinedWelfareResultMapper({
        welfareResult: fishHealthData as FishHealthData,
        dayTags: [],
        inactiveSetLegend: ['noMaturation'],
    });

    return (
        <>
            {/* Show the date picker only to Lerøy Midt users
            https://optoscale.atlassian.net/browse/WB-1252 */}
            {clientId === 86 ? (
                <div className={styles.datePickerContainer}>
                    <DatePicker
                        onDateChange={cageFilterHandler}
                        fromDefault={inputDateObject.dateFrom}
                        toDefault={inputDateObject.dateTo}
                    />
                </div>
            ) : null}
            <div className={styles.pageBoxLayout}>
                <div className={styles.leftSideLayout}>
                    <div className={styles.infoBoxContainer}>
                        <GenericInfoBox
                            title={t('Total number of wounds')}
                            icon={FirstAidKitSVG}
                            circleColor={OptoColors.OceanGreen}
                            info={formatPercentage(summary.wound.value)}
                            unit={''}
                            grayBackground={false}
                            tooltipText={'-'}
                        />
                        <GenericInfoBox
                            title={t('Estimated downgrade')}
                            icon={ArrowDownCircleFillSVG}
                            circleColor={OptoColors.Red}
                            info={
                                lastEstimate?.notSuperior
                                    ? formatPercentage(lastEstimate?.notSuperior)
                                    : '-'
                            }
                            unit={''}
                            grayBackground={false}
                            shouldHaveTooltip={true}
                            tooltipText={t('downgradetip')}
                        />
                    </div>
                    <UmerBox doublePadding={true}>
                        <UmerBoxElementHeader headerText={t('Wound type')} />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('No wounds')}
                            info={
                                lastEstimate.hasActiveWounds
                                    ? formatPercentage(lastEstimate.noWound)
                                    : '-'
                            }
                        />
                        <UmerBoxElementDivider />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Active')}
                            info={
                                lastEstimate.hasActiveWounds
                                    ? formatPercentage(lastEstimate.hasActiveWounds)
                                    : '-'
                            }
                            toolTipContent={t('activeWoundsTip')}
                        />
                        <UmerBoxElementDivider />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('Healing')}
                            info={
                                lastEstimate.hasHealingAndNotActiveWounds
                                    ? formatPercentage(lastEstimate.hasHealingAndNotActiveWounds)
                                    : '-'
                            }
                            toolTipContent={t('healingWoundsTip')}
                        />
                        <UmerBoxElementDivider />
                    </UmerBox>
                    <UmerBox doublePadding={true}>
                        <UmerBoxElementHeader headerText={t('Wound description')} />
                        <WoundDescription
                            isColor={isColor}
                            woundsColor={{
                                noWounds: lastEstimate.noWound,
                                activeWounds: lastEstimate.hasActiveWounds ?? 0,
                                healingWounds: lastEstimate.hasHealingAndNotActiveWounds ?? 0,
                                bigWound: lastEstimate.bigWound,
                                mediumWound: lastEstimate.mediumWound,
                                smallWound: lastEstimate.smallWound,
                            }}
                        />
                    </UmerBox>
                </div>

                <div className={styles.rightSideLayout}>
                    <UmerBox doublePadding={true}>
                        <UmerBoxElementHeader headerText={t('Detections')}>
                            <IconButton
                                variant={'secondary'}
                                buttonText={t('View')}
                                iconPos={'right'}
                                onClick={() =>
                                    history.push(
                                        `${createCageUrl(
                                            clientId,
                                            locationId,
                                            cageId
                                        )}/welfare/wounds/detections`
                                    )
                                }>
                                <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                            </IconButton>
                        </UmerBoxElementHeader>
                        <div className={styles.smallBoxMaxWidth}>
                            {!imagesLoading && (
                                <>
                                    {images.length > 0 ? (
                                        <UmerBoxElementImage
                                            imagePath={images[images.length - 1]?.path}
                                        />
                                    ) : (
                                        <NoComponentDataAvailable />
                                    )}
                                </>
                            )}
                            {imagesLoading && <Loader />}
                        </div>
                    </UmerBox>
                    <UmerBox doublePadding={true}>
                        <UmerBoxElementHeader
                            headerText={t('Wound distribution')}></UmerBoxElementHeader>
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('fishHealthIndicators.noWound')}
                            info={formatPercentage(lastEstimate.noWound)}
                        />
                        <UmerBoxElementDivider />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('fishHealthIndicators.oneWound')}
                            info={formatPercentage(lastEstimate.oneWound)}
                        />
                        <UmerBoxElementDivider />
                        <UmerBoxElementStatusInfoWithTrend
                            title={t('fishHealthIndicators.moreThanOneWound')}
                            info={formatPercentage(lastEstimate.moreThanOneWound)}
                        />
                        <UmerBoxElementDivider />
                    </UmerBox>
                </div>
            </div>
        </>
    );
};
