import { useMemo } from 'react';
import { useCageIsPageAvailable } from 'hooks/is-page-available-cage-layer';
import { t } from 'i18next';

import { PageContent } from '@/components/Layout';
import Page from '@/components/Layout/Page';
import { GenericPage, GenericSidebar } from '@/components/molecules/GenericSidebar';
import useCage, { CageProvider } from '@/contexts/cage-context';
import { useMe } from '@/contexts/meContext';
import { createCageUrl } from '@/helpers/urlGenerator';
import CageWelfareWoundsRouter from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/CageWelfareWoundsRouter';
import pages, {
    cageWelfareWoundsSidebarOptions,
} from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/pages';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';

const CageWelflareWoundsLayer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const { loading: cageIsLoading } = useCage();
    const { isOptoscaleAdmin, state } = useMe();

    const cageWelfareWoundsLayerUrlPath = `${createCageUrl(
        clientId,
        locationId,
        cageId
    )}/welfare/wounds`;

    const welfareUrlPath = `${createCageUrl(clientId, locationId, cageId)}/welfare`;
    const isPageAvailable = useCageIsPageAvailable();
    const checkPageAvailableCageWelfareWoundsLayer = (page: GenericPage) => {
        return isOptoscaleAdmin ? true : isPageAvailable(page.page);
    };

    const checkPageAvailableWhileLoading = (page: GenericPage) => {
        if (isOptoscaleAdmin) {
            return true;
        }

        return !(page.licenses?.length > 0);
    };

    const availablePages = useMemo(() => {
        return (pages ?? []).filter((page) => true);
    }, [isPageAvailable, pages]);

    return (
        <CageProvider cageId={cageId}>
            <Page title={'Wounds'}>
                <GenericSidebar
                    sideBarOptions={cageWelfareWoundsSidebarOptions}
                    layerUrlPrefix={cageWelfareWoundsLayerUrlPath}
                    backOption={
                        welfareUrlPath
                            ? {
                                  url: welfareUrlPath,
                                  title: t('Welfare'),
                              }
                            : null
                    }
                    checkIsPageAvailable={
                        cageIsLoading
                            ? checkPageAvailableWhileLoading
                            : checkPageAvailableCageWelfareWoundsLayer
                    }
                />

                <PageContent>
                    <CageWelfareWoundsRouter
                        pages={pages}
                        clientId={clientId}
                        locationId={locationId}
                        cageId={cageId}
                    />
                </PageContent>
            </Page>
        </CageProvider>
    );
};

export default CageWelflareWoundsLayer;
