import React from 'react';

import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import {
    handleGetIconBasedOnTrend,
    TrendIconEnum,
} from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend/TrendIconUtils';
import { hexToRGBA } from '@/config/settings';

import styles from './UmerBoxElementStatusInfoWithTrend.module.scss';

export interface UmerBoxElementStatusInfoWithTrendProps
    extends React.HTMLAttributes<HTMLDivElement> {
    title?: string;
    textAlignment?: 'left' | 'center' | 'right';
    info?: string;
    trend?: TrendIconEnum;
    optionalSymbolIcon?: string;
    optionalSymbolContainerColor?: string;
    children?: React.ReactNode;
    toolTipContent?: string | React.ReactNode;
    toolTipNubDirection?:
        | 'up-left'
        | 'up-right'
        | 'up-center'
        | 'down-center'
        | 'down-left'
        | 'left-center'
        | 'right-center'
        | 'down-right';
}

const UmerBoxElementStatusInfoWithTrend = ({
    title = '',
    textAlignment = 'left',
    info = '',
    trend = TrendIconEnum.TrendNone,
    optionalSymbolIcon = '',
    children,
    optionalSymbolContainerColor = '',
    toolTipContent = '',
    toolTipNubDirection = 'left-center',
}: UmerBoxElementStatusInfoWithTrendProps) => {
    const constructMainPart = () => {
        return (
            <div className={styles.mainPartContainer}>
                {optionalSymbolIcon && (
                    <div
                        className={styles.symbolContainer}
                        style={{ backgroundColor: hexToRGBA(optionalSymbolContainerColor, 0.25) }}>
                        <GenericIcon
                            icon={optionalSymbolIcon}
                            size={16}
                            color={optionalSymbolContainerColor}
                        />
                    </div>
                )}
                <div className={styles.mainPartContainerSub}>
                    <div data-text-alignment={textAlignment} className={styles.titleText}>
                        {title}
                    </div>
                    <div
                        data-text-alignment={textAlignment}
                        className={styles.mainPartContainerSubSubPart}>
                        <div data-text-alignment={textAlignment} className={styles.infoContainer}>
                            {info}
                        </div>
                        {handleGetIconBasedOnTrend(trend)}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.umerBoxElementStatusInfoWithTrend}>
            {toolTipContent ? (
                <OptoTooltip
                    customClass={styles.customTooltip}
                    nub={toolTipNubDirection}
                    maxWidth={250}
                    content={toolTipContent}>
                    {constructMainPart()}
                </OptoTooltip>
            ) : (
                constructMainPart()
            )}

            <div className={styles.linkContainer}>{children}</div>
        </div>
    );
};

export default UmerBoxElementStatusInfoWithTrend;
export { UmerBoxElementStatusInfoWithTrend };
