import React from 'react';
import { useTranslation } from 'react-i18next';

import { LaksvelScoreAccumulatedResult } from '@/services/types';
import { OptoTooltip } from '@/components/atoms/OptoTooltip';
import styles from './ScoreTable.module.scss';

type ScoreTableData = {
    headers: string[];
    rows: string[][];
};

export interface ScoreTableProps {
    children?: React.ReactNode;
    data: ScoreTableData;
}

const ScoreTable = ({ data }: ScoreTableProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.scoretable}>
            <table>
                <thead>
                    <tr>
                        {data.headers.map((header, index) => (
                            <th key={index}>
                                <span>{t(header)}</span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>
                                    {cell === '-' ? (
                                        <span>
                                            <OptoTooltip
                                                nub={'up-center'}
                                                content={t('No data found')}>
                                                <span>{cell}</span>
                                            </OptoTooltip>
                                        </span>
                                    ) : (
                                        <span>
                                            {isNaN(Number(cell))
                                                ? t(cell, { postProcess: 'capitalizeFirstLetter' })
                                                : `${Number(cell).toFixed(1)}%`}
                                        </span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

type Score = {
    percent: number;
    raw: number;
} | null;

const laksvelToTabularData = (data: LaksvelScoreAccumulatedResult) => {
    if (!data) return { headers: [], rows: [] };

    const currentDay = data;

    const result = Object.entries(currentDay)
        .filter(([key]) => key !== 'day')
        .map(([key, value]: [string, Record<string, Score>]) => {
            const filteredValues = Object.entries(value)
                .filter(
                    ([key]) =>
                        key !== 'score_0' &&
                        key !== 'sum_score1_score2_score3_percent' &&
                        key !== 'show_image_viewer'
                )
                .map(([, score]) => {
                    if (score === undefined || score === null) {
                        return '-';
                    }
                    return score?.percent === 0 ? '0' : score?.percent?.toString();
                });

            const total = filteredValues.every((val) => val === '-')
                ? '-'
                : filteredValues
                      .reduce((acc, percent) => acc + (percent === '-' ? 0 : Number(percent)), 0)
                      .toFixed(1);

            return [key, ...filteredValues, total];
        });

    const tableStructure = {
        headers: ['Welfare parameters', 'Score 1', 'Score 2', 'Score 3', 'Total'],
        rows: result,
    };

    return tableStructure;
};

export default ScoreTable;
export { ScoreTable, laksvelToTabularData };
