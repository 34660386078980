// import { Loader } from 'components/atoms/Loader';
import { t } from 'i18next';
import { useBiomassController_GetFeedingConversionRate } from 'services/hooks';
import { FeedingConversionRateResult, MeasurmentOverviewResult } from 'services/types';
import { getTimeUnitFromTimeRange } from 'utils';
import dayToString from 'utils/dayToString';

import Loader from '@/components/atoms/Loader';
import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import useCage from '@/contexts/cage-context';
import useCageFilter from '@/contexts/cage-filter-context';
import { useMe } from '@/contexts/meContext';
import useGraphData from '@/routes/Client/Cage/components/cage-graph-data-context';
import NoData from 'components/NoData';
import { dayTagValues } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';
import SomethingHappened from 'components/SomethingHappened';

import CageGraphPageContent from './CageGraphPageContent';

const CageGraphContainer = () => {
    const { daypartTag = 'day', error, loading, weightUnitTag, data } = useGraphData();
    const { weightUnitTag: tag, daypartTag: dayPart, to, from, setFilter } = useCageFilter();
    const { cageId, timezone, loading: cageLoading, cage, client } = useCage();
    const { isOptoscaleAdmin } = useMe();

    //add following feature flag in clients table in db to enable this feature for any client
    /**
     * Checks if the "LIVING_WEIGHT_ALL_TAGS_ENABLED" feature flag is enabled for the client.
     *
     * @constant
     * @type {boolean}
     * @default false
     * @remarks
     * This flag determines whether the living weight for all tags feature is enabled.
     * It checks the client's feature flags array for the presence of the "LIVING_WEIGHT_ALL_TAGS_ENABLED" flag.
     * If the client or featureFlags is undefined, it defaults to false.
     * You can add this feature flag to the client's feature flags array in the database to enable this feature for the client.
     */
    const hasLivingWeightAllTagsEnabled =
        client?.featureFlags?.includes('LIVING_WEIGHT_ALL_TAGS_ENABLED') ?? false;

    const FeedingConversionRate = useBiomassController_GetFeedingConversionRate(cageId, {
        from: dayToString(from),
        to: dayToString(to),
        weight_unit_tag: weightUnitTag,
    });

    const FeedingConversionRateGraphData = FeedingConversionRate.data
        ?.data as unknown as FeedingConversionRateResult;

    // Fetch chosen daytag from menu date time filter.
    const disabeledDayTags = dayTagValues.filter((dayTag) => dayTag !== dayPart);

    const pageComponentData = {
        disabeledDayTags,
        FeedingConversionRateGraphData,
        loading,
        data: data as MeasurmentOverviewResult,
        weightUnitTag: tag as 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%',
        daypartTag: dayPart,
        isOptoscaleAdmin,
        timeUnitTag: getTimeUnitFromTimeRange(from, to),
        /* NB NB - server does not return the correct dataset in staging. Not able to return from last year */
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    if (!data) {
        return <NoData />;
    }

    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        setFilter({
            from: dateReturn.dateFrom,
            to: dateReturn.dateTo,
        });
    };

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Graphs')} />
            <CageGraphPageContent
                {...pageComponentData}
                disabeledDayTags={disabeledDayTags}
                hasLivingWeightAllTagsEnabled={hasLivingWeightAllTagsEnabled}
                daypartTag={dayPart}
                cageFilterHandler={handleSetCageFilter}
                inputDateObject={{
                    dateFrom: from,
                    dateTo: to,
                    shouldStick: false,
                }}
            />
        </>
    );
};

export default CageGraphContainer;
export { CageGraphContainer };
