import React from 'react';
import queryString from 'query-string';
import { useUserController_ResetPassword } from 'services/hooks';

import { ResetPassword2 } from './ResetPassword2';

export const ResetPassword2Container = () => {
    const query = queryString.parse(location.search); //eslint-disable-line

    let token = null;

    if (query.token) {
        token = query.token;
    }

    const { mutateAsync: resetPassword } = useUserController_ResetPassword();

    return (
        <ResetPassword2
            hasToken={!!token}
            resetPassword={({ newPassword }: { newPassword: string }) =>
                resetPassword({ requestBody: { newPassword, token } })
            }
            onSuccessfulReset={() => {
                window.location.replace('/');
            }}
        />
    );
};
