import GenericIcon from '@/components/atoms/icons/GenericIcon';
import {
    ArrowRightSVG,
    TrendDownSVG,
    TrendUpSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';

export enum TrendIconEnum {
    TrendNone = 0,
    TrendPositiveUp = 1,
    TrendNegativeUp = 2,
    TrendPositiveDown = 3,
    TrendNegativeDown = 4,
    TrendStable = 5,
}

export const handleGetIconBasedOnTrend = (trend: TrendIconEnum) => {
    switch (trend) {
        case TrendIconEnum.TrendNone:
            return <></>;
        case TrendIconEnum.TrendPositiveUp:
            return <GenericIcon icon={TrendUpSVG} size={20} color={'#5BB784'} />;
        case TrendIconEnum.TrendPositiveDown:
            return <GenericIcon icon={TrendDownSVG} size={20} color={'#5BB784'} />;
        case TrendIconEnum.TrendNegativeUp:
            return <GenericIcon icon={TrendUpSVG} size={20} color={'#C65461'} />;
        case TrendIconEnum.TrendNegativeDown:
            return <GenericIcon icon={TrendDownSVG} size={20} color={'#C65461'} />;
        case TrendIconEnum.TrendStable:
            return <GenericIcon icon={ArrowRightSVG} size={20} color={'gray'} />;
    }
};
