import React from 'react';

import { DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import useCageFilter from '@/contexts/cage-filter-context';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';

import { WeightDistributionProvider } from './cage-weight-distribution-context';
import CageWeightDistribution from './CageWeightDistribution';

function CageWeightDistributionContainer({ clientId, locationId, cageId }: CagePageInterface) {
    const { to, from, setFilter } = useCageFilter();
    const handleSetCageFilter = (dateReturn: DateReturnObject) => {
        setFilter({
            from: dateReturn.dateFrom,
            to: dateReturn.dateTo,
        });
    };

    return (
        <WeightDistributionProvider>
            <CageWeightDistribution
                cageFilterHandler={handleSetCageFilter}
                inputDateObject={{
                    dateFrom: from,
                    dateTo: to,
                    shouldStick: false,
                }}
            />
        </WeightDistributionProvider>
    );
}

export default CageWeightDistributionContainer;
