import type { ChartData, ChartOptions, ChartType, ChartTypeRegistry, Point } from 'chart.js';
import { convert } from 'convert';
import i18n from 'i18n';
import { t } from 'i18next';
import { mergeDeep } from 'immutable';
import moment from 'moment';
import type { MeasurmentOverviewResult, ReportDetail } from 'services/types';
import { match } from 'ts-pattern';
import { formattedWeightUnit, toFixed } from 'utils';
import type { FishHealthData } from '@/routes/Client/Cage/pages/CageWelfare/CageWelfareWounds/components/FishHealthData';
import { getMillisecondsFromDay } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';

export interface OptionsModifierProps {
    options: ChartOptions<'line' | 'bar' | 'doughnut' | 'pie' | 'polarArea'>;
    data: ReportDetail | MeasurmentOverviewResult;
}

export enum Unit {
    percent = '%',
    weight = 'g',
    length = 'cm',
    number = '',
    celcius = '°C',
    meter = 'm',
    height = 'cm',
    pixel = 'px',
    angle = '°',
}

export enum TypeNames {
    weight = 'weight',
    length = 'length',
    percent = 'percent',
    number = 'number',
    angle = 'angle',
    celcius = 'celcius',
    meter = 'meter',
}

export enum DayTags {
    all = 'all',
    day = 'day',
    short_day = 'short_day',
    night = 'night',
}

interface ConversionResult {
    value: number;
    unit: string;
    style?: string;
    maximumSignificantDigits?: number /*  */;
    minimumSignificantDigits?: number;
    maximumFractionDigits: number;
    minimumFractionDigits: number;
}

const reverseYaxsis = <OptionType extends ChartOptions>() => {
    return (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                y: {
                    reverse: true,
                },
            },
        });
};

interface UnitConverterProps {
    value: number;
    fromUnit: string;
    toUnit: string;
    type: string;
    locale?: string;
}

/**
 * Converts a value from one unit to another based on the provided conversion parameters.
 *
 * @param value - The value to be converted.
 * @param fromUnit - The unit to convert from. Default is 'g'.
 * @param toUnit - The unit to convert to. Default is 'kg'.
 * @param type - The type of conversion. Default is 'weight'.
 * @param locale - The locale to use for formatting. Default is the user's browser language.
 * @returns The converted value.
 */
const UnitConverter = ({ value, fromUnit, toUnit, type }: UnitConverterProps): ConversionResult => {
    const cannotConvert =
        toUnit === '°C' ||
        toUnit === '%' ||
        toUnit === '' ||
        toUnit === 'px' ||
        toUnit === 'bl/s' ||
        toUnit === '°';

    const outputValue = cannotConvert
        ? value
        : (convert(value, fromUnit as any).to(toUnit as any) as unknown as number);

    const outputUnit = match(toUnit)
        .with('g', () => 'gram')
        .with('kg', () => 'kilogram')
        .with('mm', () => 'millimeter')
        .with('cm', () => 'centimeter')
        .with('lb', () => 'pound')
        .with('oz', () => 'ounce')
        .with('m', () => 'meter')
        .with('%', () => 'percent')
        .with('bl/s', () => 'velocity')
        .with('°', () => 'angle')
        .otherwise(() => toUnit);
    const maximumFractionDigits: number = match(toUnit)
        .with('kg', () => 0)
        .with('m', () => 3)
        .with('%', () => 1)
        .with('g', () => 0)
        .with('cm', () => 1)
        .with('bl/s', () => 1)
        .with('°', () => 1)
        .otherwise(() => undefined);

    return {
        value: outputValue,
        unit: outputUnit,
        style: 'unit',
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits,
    };
};

interface StepSizeYAxsisProps {
    stepSize: number;
    axsisID?: 'yAxisRight' | 'yAxisLeft' | 'y';
}
/**
 * Sets the suggested maximum and minimum values for the y-axis of a chart.
 * @template OptionType - The type of the chart options.
 * @param {SuggestedMaxMinData} data - The data containing x and y values.
 * @returns {(options: OptionType) = OptionType} - A function that takes the chart options and returns the modified options.
 */
const StepSizeAxsis =
    <OptionType extends ChartOptions>({ stepSize, axsisID }: StepSizeYAxsisProps) =>
    (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                [axsisID ? axsisID : 'y']: {
                    ticks: {
                        stepSize: stepSize,
                    },
                },
            },
        });

interface SuggestedMaxMinData {
    min: number;
    max: number;
    axsisID?: 'yAxisRight' | 'yAxisLeft' | 'y';
}
/**
 * Sets the suggested maximum and minimum values for the y-axis of a chart.
 * @template OptionType - The type of the chart options.
 * @param {SuggestedMaxMinData} data - The data containing x and y values.
 * @returns {(options: OptionType) = OptionType} - A function that takes the chart options and returns the modified options.
 */
const suggestedMaxMin =
    <OptionType extends ChartOptions>({ min, max, axsisID }: SuggestedMaxMinData) =>
    (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                [axsisID ? axsisID : 'y']: {
                    suggestedMin: min,
                    suggestedMax: max,
                },
            },
        });

/**
 * Special modifier for the OptoLineChart component with height and length.
 * @returns {Function} - A function that takes the chart options and returns the modified options.
 */

export type AxisConfig = {
    unit: string;
    text?: string | undefined;
};

interface multipleYAxesProps {
    left?: AxisConfig;
    right?: AxisConfig;
    yAxisRightDisplay?: boolean;
    yAxisLeftDisplay?: boolean;
    yDisplay?: boolean;
}

const multipleYAxes = <OptionType extends ChartOptions>({
    left = { unit: '', text: '' },
    right = { unit: '', text: '' },
    yAxisRightDisplay = true,
    yAxisLeftDisplay = true,
    yDisplay = false,
}: multipleYAxesProps) => {
    return (options: OptionType) => {
        return mergeDeep(options, {
            scales: {
                yAxisRight: {
                    title: {
                        text: right.text,
                        display:
                            right?.text !== undefined && right?.text?.length > 0 ? true : false,
                        align: 'center',
                        font: {
                            size: 12,
                            weight: 'bold',
                        },
                    },
                    position: 'right',
                    display: yAxisRightDisplay,
                },
                yAxisLeft: {
                    display: yAxisLeftDisplay,
                    position: 'left',
                    title: {
                        text: left.text,
                        display: left?.text !== undefined && left?.text?.length > 0 ? true : false,
                        align: 'center',
                        font: {
                            size: 12,
                            weight: 'bold',
                        },
                    },

                    grid: {
                        drawOnChartArea: false,
                    },
                },

                y: { display: yDisplay },
            },
        });
    };
};

interface formatAxsisProps {
    axsisID: string;
    tickType:
        | 'percent'
        | 'weight'
        | 'length'
        | 'number'
        | 'celcius'
        | 'meter'
        | 'height'
        | 'pixel'
        | 'angle';
    descimals: number;
    multiplier?: number;
}
const formatAxsis = <OptionType extends ChartOptions>({
    axsisID,
    descimals,
    tickType,
    multiplier = 1,
}: formatAxsisProps) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                [axsisID]: {
                    ticks: {
                        callback: (value: number) => {
                            return `${toFixed(
                                UnitConverter({
                                    value,
                                    fromUnit: Unit[tickType],
                                    toUnit: Unit[tickType],
                                    type: tickType,
                                }).value * multiplier,
                                descimals
                            )} ${Unit[tickType]}`;
                        },
                    },
                },
            },
        });
};

interface formatTooltipProps {
    locale: string;
    tickType:
        | 'percent'
        | 'weight'
        | 'length'
        | 'number'
        | 'celcius'
        | 'meter'
        | 'height'
        | 'pixel'
        | 'angle';
    descimals: number;
    multiplier?: number;
}

const formatTooltip = <OptionType extends ChartOptions>({
    locale,
    descimals,
    tickType,
    multiplier = 1,
}: formatTooltipProps) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const reformatted = new Intl.NumberFormat(locale, {
                                maximumFractionDigits: descimals,
                                minimumFractionDigits: descimals,
                            }).format(
                                UnitConverter({
                                    value: context.raw.y,
                                    fromUnit: Unit[tickType],
                                    toUnit: Unit[tickType],
                                    type: tickType,
                                }).value * multiplier
                            );
                            return `${context.dataset.label} : ${reformatted} ${Unit[tickType]}`;
                        },
                    },
                },
            },
        });
};

interface titleData {
    title: string;
    position?: 'left' | 'top';
}

/**
 * Creates a function that modifies the options of a chart by adding a title.
 * @param {titleData} title - The title data for the chart.
 * @param {titleData} position - default is left. Set top for larger titles on top of the chart.
 * @returns A function that takes the options of a chart and returns the modified options.
 */
const chartTitle = <
    OptionType extends
        | ChartOptions<'pie'>
        | ChartOptions<'doughnut'>
        | ChartOptions<'line'>
        | ChartOptions<'bar'>
        | ChartData<'polarArea'>,
>({
    title,
    position = 'left',
}: titleData) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            plugins: {
                title: {
                    display: position === 'left' ? true : false,
                    text: title,
                    position: position,
                    color: '#5bb784',
                    font: {
                        weight: 'lighter',
                        size: '16',
                        padding: 2,
                    },
                },
            },
        });
};

interface ShowLegendData {
    showLegend?: boolean;
    disabled?: boolean;
    position?: string;
    displayLegendValue?: boolean;
}

/**
 * Modifies the options of a chart to show or hide the legend.
 * @param {ShowLegendData} showLegend - The data object containing the showLegend property.
 * @returns A function that takes the options object and returns the modified options.
 */
const showLegend = <
    OptionType extends
        | ChartOptions<'pie'>
        | ChartOptions<'doughnut'>
        | ChartOptions<'line'>
        | ChartOptions<'bar'>
        | ChartData<'polarArea'>,
>({
    showLegend = true,
    disabled = false,
    position = 'top',
    displayLegendValue = true,
}: ShowLegendData) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            plugins: {
                legend: {
                    display: false,
                },
                htmlLegend: {
                    showLegend: showLegend,
                    disabled: disabled,
                    position: position,
                    displayLegendValue: displayLegendValue,
                },
            },
        });
};

/**
 * Returns a function that modifies the given chart options by removing the dots from the points.
 * @returns A function that takes the options object and returns the modified options.
 */
const noDots = <OptionType extends ChartOptions>() => {
    return (options: OptionType) =>
        mergeDeep(options, {
            elements: {
                point: {
                    radius: 0,
                },
            },
        });
};

interface MinMaxData {
    min: number;
    max: number;
    scaleID?: string;
}
/**
 * Returns a function that modifies the given chart options by setting the minimum and maximum values for the y-axis scale.
 * @template OptionType - The type of the chart options.
 * @param {MinMaxData} param - The minimum and maximum values for the y-axis scale.
 * @returns  A function that takes the options object and returns the modified options.
 */
const minMax = <OptionType extends ChartOptions>({ min, max, scaleID = 'y' }: MinMaxData) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                [scaleID]: {
                    min: min,
                    max: max,
                },
            },
        });
};

interface TimeScaleData {
    unit: string | 'day' | 'week' | 'month' | 'year';
}

/**
 * Returns a function that modifies the options of a chart to include a time scale.
 * @template OptionType - The type of chart options.
 * @param {TimeScaleData} data - The time scale data.
 * A function that takes the options object and returns the modified options.
 */
const timeScale = <OptionType extends ChartOptions>({ unit }: TimeScaleData) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: unit,
                    },
                },
            },
        });
};

/**
 * Returns a function that modifies the options of a chart to include a time scale.
 * @template OptionType - The type of chart options.
 * @param {TimeScaleData} data - The time scale data.
 */
interface AutoTimeScaleProps {
    disabled?: boolean;
}

const autoTimeScale = <
    OptionType extends
        | ChartOptions<'pie'>
        | ChartOptions<'doughnut'>
        | ChartOptions<'line'>
        | ChartOptions<'bar'>
        | ChartData<'polarArea'>,
>({
    disabled = false,
}: AutoTimeScaleProps) => {
    if (disabled) {
        return (options: OptionType) => options;
    }

    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const MONTH = 31 * DAY;
    const YEAR = 356 * DAY;
    return (options: OptionType, data: ChartData<'line' | 'bar' | 'pie' | 'polarArea'>) => {
        const timestamps = data.datasets.flatMap((dataset) =>
            dataset.data.map((point) => (point as Point).x)
        );
        const min = Math.min(...timestamps);
        const max = Math.max(...timestamps);
        const duration = max - min;
        let unit: 'day' | 'week' | 'month' | 'year';

        if (duration < 3 * WEEK) {
            unit = 'day';
        } else if (duration < 3 * MONTH) {
            unit = 'week';
        } else if (duration < 3 * YEAR) {
            unit = 'month';
        } else {
            unit = 'year';
        }
        return timeScale({ unit })(options as ChartOptions) as OptionType;
    };
};

interface tooltipDateAndWeekProps {
    disabled?: boolean;
}
const tooltipDateAndWeek = <OptionType extends ChartOptions>({
    disabled = false,
}: tooltipDateAndWeekProps) => {
    if (disabled) {
        return (options: OptionType) => options;
    }

    return (options: OptionType) =>
        mergeDeep(options, {
            plugins: {
                tooltip: {
                    enabled: true,
                    position: 'nearest',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        title: (context) => {
                            const timeObj = context[0].raw as { x: number; y: number };
                            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            const dateFormatted = Intl.DateTimeFormat(i18n.language, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                timeZone: timeZone,
                            }).format(timeObj.x);

                            const timestamp = new Date(timeObj.x);
                            const weekNumber = moment(timestamp).week();

                            return `Week ${weekNumber} - ${dateFormatted}`;
                        },
                        label: (context) => {
                            return `${context.dataset.label} : ${context.formattedValue || ''}`;
                        },
                    },
                },
            },
        });
};

interface SmoothLinesData {
    tension?: number;
    borderWidth?: number;
}
/**
 * Applies smooth lines and modifies the options of a chart.
 * @template OptionType - The type of chart options.
 * @param {SmoothLinesData} smoothLinesData - The data for configuring smooth lines.
 */
const smoothLines = <OptionType extends ChartOptions>({
    tension = 0.4,
    borderWidth = 3,
}: SmoothLinesData) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            elements: {
                line: {
                    tension: tension,
                    borderWidth: borderWidth,
                },
                point: {
                    radius: 1,
                    pointStyle: 'circle',
                    hoverRadius: 7,
                },
            },
        });
};

interface LineAnnotationsData {
    annotations: {
        y: number;
        color?: string;
        label: string;
    }[];
}

/**
 * Adds annotations to the chart options.
 *
 * @template OptionType - The type of chart options.
 * @param {LineAnnotationsData} annotations - The annotations data.
 */
const withAnnotations = <OptionType extends ChartOptions>({ annotations }: LineAnnotationsData) => {
    const formattedAnnotations = {};
    annotations.forEach((annotation, index) => {
        formattedAnnotations[`line${index}`] = {
            type: 'line',
            yMin: annotation.y,
            yMax: annotation.y,
            borderColor: annotation.color ? annotation.color : 'rgb(255, 99, 132)',
            borderWidth: 3,
            label: {
                content: annotation.label,
                display: true,
            },
        };
    });

    return (options: OptionType) =>
        mergeDeep(options, {
            plugins: {
                annotation: {
                    annotations: { ...formattedAnnotations },
                },
            },
        });
};
interface FeedAnnotationData {
    lastFeedDay: string;
    slaughterDay: string;
}

/**
 * Creates a function that modifies the options of a chart by adding feed and slaughter day annotations.
 * @param data - The feed annotation data.
 */
const feedSlaughterAnnotation = <OptionType extends ChartOptions>(data: FeedAnnotationData) => {
    return (options: OptionType) => {
        const feedAndSlaughterDay = {
            slaughetrDay: data.slaughterDay,
            lastFeedDay: data.lastFeedDay,
        };

        const modifiedOptions = {
            ...options,
            plugins: {
                ...options.plugins,
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            value: getMillisecondsFromDay(feedAndSlaughterDay.lastFeedDay),
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 3,
                            scaleID: 'x',
                            label: {
                                content: 'Last feed day',
                                display: true,
                            },
                        },

                        line2: {
                            type: 'line',
                            scaleID: 'x',
                            value: getMillisecondsFromDay(feedAndSlaughterDay.slaughetrDay),
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 3,
                            label: {
                                content: 'Slaughter day',
                                display: true,
                            },
                        },
                    },
                },
            },
        };

        return modifiedOptions;
    };
};

interface XYChartUnitsData {
    x: string | null;
    y: string | null;
    digitsYaxis?: number;
}
/**
 * Adds XY units to the given chart options.
 *  DEPRECATED: Use weightAndLengthUnitConverter instead.
 * @param data - The XY chart units data.
 */
const addXYunitis = <OptionType extends ChartOptions>(data: XYChartUnitsData) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                y: {
                    ticks: {
                        callback: (value: number) => {
                            const reformatted = formattedWeightUnit(value, data.y);

                            if (reformatted.value === undefined) {
                                return `${toFixed(
                                    value,
                                    data.digitsYaxis ? data.digitsYaxis : 2
                                )} ${data.y}`;
                            }
                            return data.y
                                ? `${reformatted.value} ${reformatted.weightUnit}`
                                : toFixed(value, 2);
                        },
                    },
                },
            },
        });
};

interface MinMaxZoomData {
    min?: number | 'original';
    max?: number | 'original';
    minRange?: number;
}
/**
 * Applies minimum and maximum zoom options to the provided chart options.
 * @param min - The minimum zoom level. Defaults to 'original'.
 * @param max - The maximum zoom level. Defaults to 'original'.
 * @param minRange - The minimum zoom range in milliseconds. Defaults to 60 * 1000 (1 minute).
 * @returns {(options: OptionType) => OptionType} A function that takes chart options and returns modified options with applied zoom limits.
 */
const minMaxZoom = <OptionType extends ChartOptions>({
    min = 'original',
    max = 'original',
    minRange = 60 * 1000,
}: MinMaxZoomData) => {
    return (options: OptionType) => {
        const modifiedOptions = {
            ...options,
            plugins: {
                ...options.plugins,
                zoom: {
                    ...options.plugins?.zoom,
                    limits: {
                        y: { min: min, max: max, minRange: minRange },
                    },
                },
            },
        };

        return modifiedOptions;
    };
};

/**
 * Returns a function that modifies the given chart options to start the y-axis at zero.
 *
 * @returns A function that takes chart options as input and returns modified options with the y-axis starting at zero.
 * @returns {(options: OptionType) => OptionType} The type of chart options.
 */
interface startYAxisAtZeroProps {
    axsisID?: 'yAxisRight' | 'yAxisLeft' | 'y';
}
const startYAxisAtZero =
    <OptionType extends ChartOptions>({ axsisID = 'y' }: startYAxisAtZeroProps) =>
    (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                [axsisID ? axsisID : 'y']: {
                    beginAtZero: true,
                },
            },
        });

/**
 * Adds or remove padding on the chart canvas
 */
export interface LayoutPaddingData {
    padding: number;
}

const layoutPadding = <OptionType extends ChartOptions>({ padding = 10 }: LayoutPaddingData) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            layout: {
                padding: padding,
            },
        });
};

/**
 * Converts the weight and length units to the correct localized unit.
 * Example: 1 kg = 1000 g
 */
interface WeightAndLengthUnitConverterData {
    type:
        | 'percent'
        | 'weight'
        | 'length'
        | 'number'
        | 'celcius'
        | 'meter'
        | 'height'
        | 'pixel'
        | 'angle';
    toUnit?: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%' | '' | 'px' | 'bl/s' | '°' | '';
    fromUnit?: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%' | 'bl/s' | '°' | '';
    locale?: string;
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    scale?: string;
}

const divideValuesBy = <OptionType extends ChartOptions>({ value }: { value: number }) => {
    return (options: OptionType, data: ChartData<'line'>) => {
        for (const dataset of data.datasets) {
            for (const point of dataset.data) {
                if (typeof point !== 'number') {
                    point.y = point.y / value;
                }
            }
        }
        return options;
    };
};

/**
 * Converts weight and length units in the given options object.
 * @template OptionType - The type of the options object.
 * @param {WeightAndLengthUnitConverterData} data - The data for unit conversion.
 * @returns {(options: OptionType) = OptionType} - The modified options function.
 */
const weightAndLengthUnitConverter = <OptionType extends ChartOptions>({
    type = 'weight',
    fromUnit = 'g',
    toUnit = 'g',
    locale = 'en-US',
    maximumFractionDigits = 2,
    minimumFractionDigits = 2,
}: WeightAndLengthUnitConverterData) => {
    const formattedNumber = (value: number) => {
        if (type === 'number') {
            return Intl.NumberFormat(i18n.language, {
                maximumFractionDigits: maximumFractionDigits,
                minimumFractionDigits: minimumFractionDigits,
            }).format(value);
        }
        const converted = UnitConverter({
            value,
            fromUnit,
            toUnit,
            type,
            locale,
        });

        return Intl.NumberFormat(i18n.language, {
            ...converted,
        }).format(converted.value);
    };

    return (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                y: {
                    ticks: {
                        callback: (value, index, ticks) => formattedNumber(value),
                    },
                },
            },
            plugins: {
                tooltip: {
                    enabled: true,
                    position: 'nearest',
                    callbacks: {
                        title: (context) => {
                            const timeObj = context[0].raw as { x: number; y: number };
                            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            return Intl.DateTimeFormat(i18n.language, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                timeZone: timeZone,
                            }).format(timeObj.x);
                        },
                        label: (context) => {
                            const label = context.dataset.label || '';
                            let value = '';

                            if (context.dataset.name === 'Fish speed') {
                                value = `${context.parsed.y.toFixed(1)} ${t('bl/s')}`;
                            } else if (context.dataset.name === 'temperature') {
                                value = `${context.parsed.y.toFixed(2)} °C`;
                            } else if (
                                context.dataset.name === 'Pitch' ||
                                context.dataset.name === 'Roll'
                            ) {
                                value = `${context.parsed.y.toFixed(2)} °`;
                            } else {
                                value = formattedNumber(context.parsed.y);
                            }
                            return `${label} = ${value}`;
                        },
                    },
                },
            },
        });
};

interface StackedAxisProps {
    y: boolean;
    x: boolean;
}
const stackedAxis = <OptionType extends ChartOptions>({
    y = false,
    x = false,
}: StackedAxisProps) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            scales: {
                x: {
                    stacked: x,
                },
                y: {
                    stacked: y,
                },
            },
        });
};
interface TooltipFooterCalculationProps {
    operation: 'SUM' | 'AVERAGE' | '';
    unit?: string;
    showTotalFish?: boolean;
    fishHealthData: FishHealthData;
    t: (key: string) => string;
    exclude?: string[];
}

const tooltipFooterCalculationModifier = <OptionType extends ChartOptions>({
    operation = '',
    unit = Unit.percent,
    showTotalFish = false,
    fishHealthData,
    t,
    exclude = [],
}: TooltipFooterCalculationProps) => {
    return (options: OptionType) =>
        mergeDeep(options, {
            plugins: {
                tooltip: {
                    callbacks: {
                        footer: (context) => {
                            if (operation !== '') {
                                let sum = 0;
                                let fishCount = 0;
                                for (const item of context) {
                                    if (!exclude.includes(item.dataset?.name?.toLowerCase())) {
                                        const value = Number.parseFloat(item.raw.y);
                                        fishCount = fishHealthData.days.find(
                                            (day) => getMillisecondsFromDay(day.day) === item.raw.x
                                        )?.count;
                                        if (!Number.isNaN(value)) {
                                            sum += value;
                                        }
                                    }
                                }
                                let footerSum = '';
                                switch (operation) {
                                    case 'SUM':
                                        footerSum = `${t('Total')}: ${sum.toFixed(1)} ${unit}${
                                            showTotalFish
                                                ? ` ${t('of')} ${fishCount} ${t('fish')}`
                                                : ''
                                        }`;
                                        break;
                                    case 'AVERAGE': {
                                        const average =
                                            context.length > 0 ? sum / context.length : 0;
                                        footerSum = `Average: ${average.toFixed(1)} ${unit}${
                                            showTotalFish
                                                ? ` ${t('of')} ${fishCount} ${t('fish')}`
                                                : ''
                                        }`;
                                        break;
                                    }
                                    default: {
                                        footerSum = '';
                                        break;
                                    }
                                }
                                return footerSum;
                            }
                        },
                    },
                },
            },
        });
};

interface categoryScaleProps {
    xAxsisUnitName?: string;
    labels?: string[];
    disabled?: boolean;
    tooltipUnit?: string;
}

const LiceCountBarTooltipCallback = (context) => {
    const dateString = context.length > 0 ? context[0].dataset[context[0].dataIndex] : '';

    const date = new Date(dateString);

    const day = date.getDay();
    const diffToMonday = date.getDate() - day + (day === 0 ? -6 : 1);

    const startOfWeek = new Date(date.setDate(diffToMonday));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDayFormatted = Intl.DateTimeFormat(i18n.language, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: timeZone,
    }).format(new Date(startOfWeek));

    const endDayFormatted = Intl.DateTimeFormat(i18n.language, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: timeZone,
    }).format(new Date(endOfWeek));

    return `${startDayFormatted} - ${endDayFormatted}`;
};

const MaturationBarChartTooltipFormatter = (context) => {
    return `${context[0].label}`;
};

const categoryScale = <OptionType extends ChartOptions, DataType extends ChartType>({
    xAxsisUnitName = '',
    labels,
    disabled = false,
}: categoryScaleProps) => {
    return (options: OptionType) => {
        const modifiedOptions = {
            ...options,
            scales: {
                x: {
                    type: 'category',
                    labels: labels?.map((label) => `${xAxsisUnitName} ${label}`),
                },
                y: {
                    type: 'linear',
                    grace: '5%',
                },
            },
            plugins: {
                ...options.plugins,
                tooltip: {
                    enabled: true,
                    position: 'nearest',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        beforeTitle: (context) => {
                            return MaturationBarChartTooltipFormatter(context);
                        },
                        label: (context) => {
                            const reformatted = new Intl.NumberFormat('en-US', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }).format(context.raw);
                            return `${context.dataset.label} : ${reformatted || ''} % `;
                        },

                        title: (context) => {
                            return '';
                        },
                    },
                    // Using optoScales own tooltip handler
                    // external: externalTooltipHandler,
                },
            },
        };

        return !disabled ? { ...modifiedOptions } : { ...options };
    };
};

interface xAxsisCategoryScaleProps {
    labels?: string[];
    yAxisTicksUnit?: string;
    locale?: string;
}
const xAxsisCategoryScale = <OptionType extends ChartOptions, DataType extends ChartType>({
    yAxisTicksUnit = '%',
    locale = 'en-US',
}: xAxsisCategoryScaleProps = {}) => {
    return (options: OptionType, data: ChartData<DataType>) => {
        const labels = data.labels;

        return mergeDeep(options, {
            scales: {
                y: {
                    ticks: {
                        callback: (value: number) => {
                            return `${value} ${yAxisTicksUnit}`;
                        },
                    },
                },
                x: {
                    type: 'category',
                    labels: labels,
                },
            },
            plugins: {
                tooltip: {
                    enabled: true,
                    position: 'nearest',
                    callbacks: {
                        title: (context) => {
                            const timeObj = context[0].raw as { x: number; y: number };
                            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            return Intl.DateTimeFormat(locale, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                timeZone: timeZone,
                            }).format(timeObj.x);
                        },
                        label: (context) => {
                            const reformatted = new Intl.NumberFormat(locale, {
                                maximumFractionDigits: 1,
                                minimumFractionDigits: 1,
                            }).format(context.raw);
                            return `${context.dataset.label} : ${
                                reformatted || ''
                            } ${yAxisTicksUnit}`;
                        },
                    },
                },
            },
        });
    };
};

/**
 * Function that takes in a default options object and a list of modifiers
 * to create customized configuration for a chart.
 */

/**
 * Creates a function that allows modifying chart options based on data.
 * @param defaultOptions The default options for the chart.
 * @returns A function that can be used to modify the options based on data.
 * @returns {(options: OptionType) => OptionType} The type of data used by the chart.
 */
function Options<DataType extends keyof ChartTypeRegistry>(defaultOptions: ChartOptions<DataType>) {
    return {
        modify: (
            ...modifiers: ((
                options: ChartOptions<DataType>,
                data: ChartData<DataType>
            ) => ChartOptions<DataType>)[]
        ) => {
            return (data: ChartData<DataType>) => {
                const modifiedOptions = modifiers.reduce((acc, modifier) => {
                    return modifier(acc, data);
                }, defaultOptions);

                return modifiedOptions;
            };
        },
    };
}

export default Options;
export {
    minMax,
    smoothLines,
    suggestedMaxMin,
    withAnnotations,
    feedSlaughterAnnotation,
    addXYunitis,
    minMaxZoom,
    autoTimeScale,
    timeScale,
    showLegend,
    multipleYAxes,
    chartTitle,
    noDots,
    startYAxisAtZero,
    weightAndLengthUnitConverter,
    reverseYaxsis,
    layoutPadding,
    stackedAxis,
    xAxsisCategoryScale,
    Options,
    tooltipFooterCalculationModifier,
    formatTooltip,
    StepSizeAxsis,
    formatAxsis,
    categoryScale,
    /* Non modifiers */
    UnitConverter,
    divideValuesBy,
    tooltipDateAndWeek,
};
