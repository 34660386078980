import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useResizeDetector } from 'react-resize-detector';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PluginChartOptions,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import cn from 'classnames';
import { saveAs } from 'file-saver';
import { t } from 'i18next';
import { DatePicker, DateReturnObject } from '@/components/molecules/DatePicker/DatePicker';
import { IconButton } from '@/components/atoms/Buttons';
import GenericIcon from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';
import OverflowToDropdownlist from '@/components/atoms/OverflowToDropdownlist';
import PanelToolBar from '@/components/molecules/PanelToolBar';
import { SkeletonLoader } from 'components/atoms/SkeletonLoader';

import 'chartjs-adapter-moment';

import { htmlLegendPlugin } from '../plugins/htmlLegendPlugin';

import styles from './OptoMixedChart.module.scss';

ChartJS.register(
    LinearScale,
    TimeScale,
    CategoryScale,
    PointElement,
    LineElement,
    Legend,
    annotationPlugin,
    htmlLegendPlugin,
    Filler,
    zoomPlugin,
    Tooltip,
    Title
);

ChartJS.register({
    id: 'noData',
    afterDraw: (chart) => {
        if (chart.data.datasets.flatMap((x) => x.data).every((item) => item === 0)) {
            const ctx = chart.ctx;
            const width = chart.width;
            const height = chart.height;

            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(t('No data to display'), width / 2, height / 2);
            ctx.restore();
        }
    },
});

export interface ChartActionButtons {
    copyAsPng?: boolean;
    resetZoom?: boolean;
    downloadAsPng?: boolean;
    fullScreen?: boolean;
    timeScaleSwitch?: boolean;
    timeSwitchWeekly?: boolean;
}

export type OptoMixedChartProps = {
    chartName?: string;
    graphType?: 'line';
    chartData: ChartData<'line'>;
    chartOptions?: (data: ChartData<'line'>) => ChartOptions<'line'>;
    pluginOptions?: PluginChartOptions<'line'>;
    loading?: boolean;
    actionButtons?: ChartActionButtons;
    forceHeaderToTop?: boolean;
    showDatePicker?: boolean;
    cageFilterHandler?: (data: DateReturnObject) => void;
    inputDateObject?: DateReturnObject;
};

const OptoMixedChart = ({
    chartName = '',
    chartData,
    chartOptions,
    loading = false,
    forceHeaderToTop = true,
    cageFilterHandler,
    inputDateObject,
    showDatePicker = false,
}: /*
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
    */
OptoMixedChartProps) => {
    //console.log('Incomming data: ', chartData);

    const options = chartOptions ? chartOptions(chartData) : {};
    const chartRef = useRef<ChartJS<'line'>>(null);
    const chartBoundaries = useRef<HTMLDivElement>(null);

    const [chart, setChart] = useState<ChartJS<'line'>>();
    const [isFullScreen, setisFullscreen] = useState(false);
    const [topheading, setTopheading] = useState(forceHeaderToTop);
    const [lineOptions, setOptions] = useState(options);
    const { width } = useResizeDetector({
        handleWidth: true,
        targetRef: chartBoundaries,
        onResize: () => {
            if (forceHeaderToTop || (width && width > 600 && !topheading)) {
                setTopheading(true);
                if (chart?.options?.plugins?.title) {
                    chart.options.plugins.title.display = false;
                    console.log(chart.options);
                }

                chart?.update();
                chart?.resize();
            } else if (!forceHeaderToTop && width && width < 600 && topheading) {
                setTopheading(false);
                if (chart?.options?.plugins?.title) {
                    chart.options.plugins.title.display = true;
                }
                chart?.update();
                chart?.resize();
            }
        },
    });

    useEffect(() => {
        if (chart) {
            chart.options = options;
            chart?.update();
        }
    }, [options, chart]);

    const showAsFullScreen = () => {
        if (chartBoundaries.current) {
            isFullScreen ? document.exitFullscreen() : chartBoundaries.current.requestFullscreen();
            chartBoundaries.current.onfullscreenchange = () => {
                chart?.resize();
                setisFullscreen(!isFullScreen);
            };
            return;
        }
    };

    const switchButtonandler = (e) => {
        const { ticks, type } = options.scales.x;
        options.scales.x = {
            type,
            time: {
                unit: e.currentTarget.name,
            },
            ticks,
        };
        //setOptions(options);
        chart.update();
    };

    const downloadAsPngHandler = () => {
        const dateString = new Date().toISOString().split('T')[0];
        chart.canvas.toBlob((blob) =>
            saveAs(blob, chartName.length > 0 ? `${chartName}-${dateString}.png` : 'chart.png')
        );
    };

    const copyAsPngHandler = async (ev) => {
        if (!chart) {
            console.warn('No chart to copy');
            return;
        }
        const response = await chart.canvas.toBlob((blob) =>
            navigator.clipboard.write([new window.ClipboardItem({ 'image/png': blob })])
        );
        // TODO - show some kind of notification
        console.log('copied to clipboard', response);
    };

    const resetZoomHandler = () => {
        chart?.resetZoom();
    };

    /**
     * Modify chart options on mount
     */
    useEffect(() => {
        if (chartRef.current) {
            setChart(chartRef.current as ChartJS<'line'>);
            if (forceHeaderToTop) {
                setTopheading(true);
            } else {
                setTopheading(chartRef.current.options.plugins?.title?.position === 'top');
            }
            chartRef.current.update();
        }

        chartRef.current?.update();
    }, [chartRef, chartBoundaries, chartData, lineOptions]);

    return (
        <SkeletonLoader loading={loading} name={`${chartName} loading..`}>
            {!loading && chartData && (
                <div className={styles.optomixedchart} ref={chartBoundaries}>
                    <div className={styles.chartHeaderSection}>
                        <div className={styles.chartTitle}>
                            {topheading && <h4>{chartName}</h4>}
                        </div>
                        {showDatePicker && (
                            <DatePicker
                                onDateChange={cageFilterHandler}
                                fromDefault={inputDateObject.dateFrom}
                                toDefault={inputDateObject.dateTo}
                            />
                        )}
                        <OverflowToDropdownlist></OverflowToDropdownlist>
                        <PanelToolBar>
                            <IconButton
                                tooltip={t('Copy chart as PNG') as string}
                                onlyIcon={true}
                                variant="tertiary"
                                onClick={copyAsPngHandler}>
                                <GenericIcon icon={iconUrls.CopyIconSVG} />
                            </IconButton>
                            <IconButton
                                tooltip={t('Reset Zoom') as string}
                                onlyIcon={true}
                                variant="tertiary"
                                onClick={resetZoomHandler}>
                                <GenericIcon icon={iconUrls.ResetZoomIconSVG} />
                            </IconButton>
                            <IconButton
                                tooltip={t('Download chart as .png') as string}
                                onlyIcon={true}
                                variant="tertiary"
                                onClick={downloadAsPngHandler}>
                                <GenericIcon icon={iconUrls.DownloadFileIconSVG} />
                            </IconButton>
                            <IconButton
                                tooltip={t('Show chart in fullscreen') as string}
                                onlyIcon={true}
                                variant="tertiary"
                                onClick={showAsFullScreen}>
                                {isFullScreen ? (
                                    <GenericIcon icon={iconUrls.FullScreenExitconSVG} />
                                ) : (
                                    <GenericIcon icon={iconUrls.FullScreenIconSVG} />
                                )}
                            </IconButton>
                        </PanelToolBar>
                    </div>
                    <div className={styles.chartLegendSection}>
                        <div
                            data-title={chartName}
                            className={cn(styles.htmlLegendBar, 'legend-container')}
                        />
                    </div>

                    <div className={cn(styles.tooltipContainer, 'tooltip-container')} />

                    <Line
                        data={chartData}
                        aria-description="bioscope chart data"
                        aria-label="bioscope chart data"
                        plugins={[htmlLegendPlugin]}
                        options={lineOptions}
                        ref={chartRef}
                    />
                </div>
            )}
        </SkeletonLoader>
    );
};

export default OptoMixedChart;
export { OptoMixedChart };
