import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import i18n, { t } from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    ArrowRightSVG,
    FishFillSVG,
    FishHealthIconSVG,
    LaksvelShieldSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import { ToolTipContent } from '@/components/atoms/ToolTipContent';
import { UmerBox } from '@/components/atoms/UmerBox';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementImage } from '@/components/atoms/UmerBoxElements/UmerBoxElementImage';
import { TrendIconEnum } from '@/components/atoms/UmerBoxElements/UmerBoxElementStatusInfoWithTrend/TrendIconUtils';
import { UmerBoxElementText } from '@/components/atoms/UmerBoxElements/UmerBoxElementText';
import { GenericInfoBox } from '@/components/molecules/GenericInfoBox';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import { Image } from '@/components/organisms/ImageViewer';
import { TroutNotice } from '@/components/organisms/TroutNotice/TroutNotice';
import LICENSES from '@/config/licenses';
import settings, { OptoColors } from '@/config/settings';
import useCage from '@/contexts/cage-context';
import { localizedDateFormat } from '@/helpers/date';
import { createCageUrl } from '@/helpers/urlGenerator';
//
// import BioscopeStatusBox from './components/BioscopeStatusBox';
// import OverviewTour from './components/OverviewTour';
import { useRestOverview } from '@/routes/Client/Cage/components/cage-overview-context';
import {
    ColorValues,
    ColorValuesDay,
    CombinedWelfareResult,
    DailyLaksvelResult,
    OverviewCage,
    OverviewLocation,
} from '@/services/types';
import { localeFormatNumber } from '@/utils/decimalNumberFormatter';
import { formatPercentage } from '@/utils/formatPercentage';
import {
    calculateFishSpeedToolTip,
    getSpeedStatus,
} from '@/utils/SharedDataCalculation/swimSpeedCalculations';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import styles from './CageWelfareDashboard.module.scss';

const DEFAULT_DAYPART_TAG_FOR_DOWNLOAD = 'day';

interface CageWelfareDashboardPageContentProps {
    clientId: number;
    locationId: number;
    cageId: number;
    images?: Image[];
    location: OverviewLocation;
    detectionsLoading: boolean;
    lastLaksvelDateWithMeasurements?: DailyLaksvelResult;
    fishHealthData?: CombinedWelfareResult;
}

const CageWelfareDashboardPageContent = ({
    clientId,
    locationId,
    cageId,
    images,
    location,
    detectionsLoading,
    lastLaksvelDateWithMeasurements,
    fishHealthData,
}: CageWelfareDashboardPageContentProps) => {
    const { loading, error, data: overview } = useRestOverview();
    const { cage, hasCageLicense, isTrout } = useCage();

    const hasLaksvelLicense = hasCageLicense(LICENSES.LAKSVEL);
    const hasHealthLicense = hasCageLicense(LICENSES.FISH_HEALTH.MIN);

    const [laksvelInfoText, setLaksvelInfoText] = useState('');
    const [laksvelInfoTooltip, setLaksvelInfoTooltip] = useState('');
    const [woundsInfoText, setWoundsInfoText] = useState('');
    const [woundsInfoTooltip, setWoundsInfoTooltip] = useState('');
    const [woundsInfoTrend, setWoundsInfoTrend] = useState(TrendIconEnum.TrendNone);

    const [swimSpeedInfoText, setSwimSpeedInfoText] = useState('');
    const [swimSpeedToolTip, setSwimSpeedToolTip] = useState<React.ReactNode>(<></>);

    const [lastFishHealthDay, setLastFishHealthDay] = useState<ColorValuesDay>(null);
    const [lastEstimate, setLastEstimate] = useState<ColorValues>(null);

    const history = useHistory();
    const queryParams = useQueryParams();
    const daypartTag = queryParams[0]?.d ? queryParams[0].d : DEFAULT_DAYPART_TAG_FOR_DOWNLOAD;
    const dailyOverview = overview?.days;
    const dailyOverviewForDownload =
        dailyOverview?.map((el) => ({
            date: el?.day,
            daypartTag: el?.daypartTag,
            measurementsCount: el?.data.measCnt,
            weightAvg: el?.data.weightAvg?.toFixed(2),
            weightTrend: el?.data.weightTrend?.toFixed(2),
            cfAvg: el?.data.cfAvg?.toFixed(2),
            cfTrend: el?.data.cfTrend?.toFixed(2),
            fishLengthAvg: el?.data.fishLengthAvg?.toFixed(2),
            fishHeightAvg: el?.data.fishHeightAvg?.toFixed(2),
            cv: el?.data.cv?.toFixed(2),
            weightGrowthAvg: el?.data.weightGrowthAvg?.toFixed(2),
            weightGrowthTrend: el?.data.weightGrowthTrend?.toFixed(2),
            depthMin: el?.data.depthMin?.toFixed(2),
            depthMax: el?.data.depthMax?.toFixed(2),
            depthAvg: el?.data.depthAvg?.toFixed(2),
        })) ?? [];
    const filteredByDayPartTag =
        dailyOverviewForDownload.filter((item) => item?.daypartTag === daypartTag) ?? [];

    // need to send columns names in this format
    const locationInfoColumns = [
        'location.name',
        'location.timezone',
        'location.sWeightPct',
        'cage.name',
    ];
    const dataColumnNames =
        dailyOverviewForDownload.length > 0 ? Object.keys(dailyOverviewForDownload[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    const setupWelfareDataFromClientOverview = () => {
        if (location) {
            const cage = location.cages.find((x) => x.id === cageId) as OverviewCage;
            if (cage && cage.laksvel?.laksvel?.avg_laksvel) {
                const laksvelInfoText = `${formatPercentage(
                    cage.laksvel.laksvel.avg_laksvel / 100,
                    1
                )}`;
                setLaksvelInfoText(laksvelInfoText);
                setLaksvelInfoTooltip(
                    `${laksvelInfoText} ${t('Laksvel siteoverview tooltip heading')}`
                );
            } else {
                setLaksvelInfoText('-');
                setLaksvelInfoTooltip(t('No data found'));
            }
            if (cage && cage.speed) {
                const speedStatus = getSpeedStatus(cage.speed, t);
                setSwimSpeedInfoText(
                    `${localeFormatNumber(cage.speed, 2, i18n.language, '-')} ${t('swimspeed')}`
                );
                const speedToolTip = calculateFishSpeedToolTip(speedStatus, cage, t);
                const speedToolTipContent = <ToolTipContent content={speedToolTip} />;
                setSwimSpeedToolTip(speedToolTipContent);
            } else {
                setSwimSpeedInfoText('-');
                setSwimSpeedToolTip(t('No data found'));
            }
            if (cage && cage.welfare) {
                const welfareWoundInfo = isNaN(cage.welfare.woundRate)
                    ? ''
                    : `${formatPercentage(cage.welfare.woundRate, 1)}`;
                const welfareWoundGrowth = isNaN(cage?.welfare?.woundSlope)
                    ? '---'
                    : `${formatPercentage(cage.welfare.woundSlope, 0)}`;

                setWoundsInfoText(welfareWoundInfo);

                const growth = cage?.welfare?.woundSlope;
                const growthLimit = settings.woundGrowthLimit;

                let helpText: string;
                if (cage?.welfare?.woundRate === undefined) {
                    helpText = t(`No data found`);
                } else if (growth > -growthLimit && growth < growthLimit) {
                    helpText = `${t('stableText')}`;
                } else if (growth >= growthLimit) {
                    helpText = `${t('Growing by')} ${welfareWoundGrowth} ${t('each week')}`;
                    setWoundsInfoTrend(TrendIconEnum.TrendNegativeUp);
                } else if (growth <= -growthLimit) {
                    helpText = `${t('Decreasing by')} ${welfareWoundGrowth} ${t('each week')}`;
                    setWoundsInfoTrend(TrendIconEnum.TrendPositiveDown);
                } else {
                    helpText = '';
                }

                setWoundsInfoTooltip(helpText);
            } else {
                setWoundsInfoText('-');
                setWoundsInfoTooltip(t('No data found'));
            }
        }
    };

    useEffect(() => {
        if (location) {
            setupWelfareDataFromClientOverview();
        }
    }, [location]);

    const setupWelfareDataFromFishHealthData = () => {
        if (fishHealthData) {
            const lastDay = fishHealthData?.color?.days.findLast((x) => x.count > 0);
            if (lastDay) {
                setLastFishHealthDay(lastDay);
            }
            if (fishHealthData?.color?.lastEstimate) {
                setLastEstimate(fishHealthData.color.lastEstimate);
            }
        }
    };

    useEffect(() => {
        if (fishHealthData) {
            setupWelfareDataFromFishHealthData();
        }
    }, [fishHealthData]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened error={error} />;
    }

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Welfare')}></PageTitleRow>
            <div className={styles.weightMainItemsContainer}>
                {!isTrout ? (
                    <GenericInfoBox
                        title={t('LAKSVEL')}
                        icon={LaksvelShieldSVG}
                        circleColor={OptoColors.Riverside}
                        info={laksvelInfoText}
                        unit={''}
                        grayBackground={false}
                        tooltipText={laksvelInfoTooltip}
                    />
                ) : (
                    ''
                )}
                <GenericInfoBox
                    title={t('Wound insight')}
                    icon={FishHealthIconSVG}
                    circleColor={OptoColors.OceanGreen}
                    info={woundsInfoText}
                    unit={''}
                    grayBackground={false}
                    tooltipText={woundsInfoTooltip}
                    trend={woundsInfoTrend}
                    showTrend={true}
                />

                <GenericInfoBox
                    title={t('Swim Speed')}
                    icon={FishFillSVG}
                    circleColor={OptoColors.Lavender}
                    info={swimSpeedInfoText}
                    unit={''}
                    grayBackground={false}
                    tooltipText={swimSpeedToolTip}
                />
            </div>

            <div className={styles.pageBoxLayout}>
                <div className={styles.leftSideContainer}>
                    {!isTrout ? (
                        <UmerBox doublePadding={true}>
                            <UmerBoxElementHeader headerText={t('Laksvel')}>
                                <IconButton
                                    variant={'secondary'}
                                    buttonText={t('View')}
                                    iconPos={'right'}
                                    onClick={() =>
                                        history.push(
                                            `${createCageUrl(
                                                clientId,
                                                locationId,
                                                cageId
                                            )}/welfare/laksvel`
                                        )
                                    }>
                                    <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                                </IconButton>
                            </UmerBoxElementHeader>

                            {lastLaksvelDateWithMeasurements && (
                                <div className={styles.spaceBetweenFlex}>
                                    <UmerBoxElementText
                                        text={`${t('Number of measurements')} ${localizedDateFormat(
                                            {
                                                dateString: lastLaksvelDateWithMeasurements.day,
                                                onlydate: true,
                                                locale: i18n.language,
                                            }
                                        )}`}></UmerBoxElementText>
                                    <div className={styles.flowRight}>
                                        <UmerBoxElementText
                                            text={`${localeFormatNumber(
                                                lastLaksvelDateWithMeasurements.cnt,
                                                0
                                            )}`}></UmerBoxElementText>
                                    </div>
                                </div>
                            )}
                            {!lastLaksvelDateWithMeasurements && (
                                <div className={styles.laksvelPlaceholder}> </div>
                            )}
                        </UmerBox>
                    ) : (
                        ''
                    )}
                    <UmerBox doublePadding={true}>
                        <UmerBoxElementHeader headerText={t('Wound insight')}>
                            <IconButton
                                variant={'secondary'}
                                buttonText={t('View')}
                                iconPos={'right'}
                                onClick={() =>
                                    history.push(
                                        `${createCageUrl(
                                            clientId,
                                            locationId,
                                            cageId
                                        )}/welfare/wounds`
                                    )
                                }>
                                <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                            </IconButton>
                        </UmerBoxElementHeader>
                        <div>
                            <div className={styles.umerboxContentContainer}>
                                {lastFishHealthDay && (
                                    <div className={styles.spaceBetweenFlex}>
                                        <UmerBoxElementText
                                            text={`${t(
                                                'Number of measurements'
                                            )} ${localizedDateFormat({
                                                dateString: lastFishHealthDay.day,
                                                onlydate: true,
                                                locale: i18n.language,
                                            })}`}></UmerBoxElementText>
                                        <div className={styles.flowRight}>
                                            <UmerBoxElementText
                                                text={lastFishHealthDay.count.toFixed(
                                                    0
                                                )}></UmerBoxElementText>
                                        </div>
                                    </div>
                                )}

                                {lastEstimate && (
                                    <div className={styles.smallInfoTable}>
                                        <div className={styles.spaceBetweenFlex}>
                                            <UmerBoxElementText
                                                text={t('Acute')}
                                                variant={'footer'}></UmerBoxElementText>
                                            <UmerBoxElementText
                                                text={t('Healing')}
                                                variant={'footer'}></UmerBoxElementText>
                                            <UmerBoxElementText
                                                text={t('No wounds')}
                                                variant={'footer'}></UmerBoxElementText>
                                        </div>
                                        <UmerBoxElementDivider />
                                        <div className={styles.spaceBetweenFlex}>
                                            <UmerBoxElementText
                                                text={
                                                    !lastEstimate.wound
                                                        ? '-'
                                                        : formatPercentage(lastEstimate.wound)
                                                }></UmerBoxElementText>
                                            <UmerBoxElementText
                                                text={
                                                    !lastEstimate.hasHealingAndNotActiveWounds
                                                        ? '-'
                                                        : formatPercentage(
                                                              lastEstimate.hasHealingAndNotActiveWounds
                                                          )
                                                }></UmerBoxElementText>
                                            <UmerBoxElementText
                                                text={
                                                    !lastEstimate.noWound
                                                        ? '-'
                                                        : formatPercentage(lastEstimate.noWound)
                                                }></UmerBoxElementText>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </UmerBox>
                </div>
                <UmerBox doublePadding={true}>
                    <UmerBoxElementHeader headerText={t('Detections')}>
                        <IconButton
                            variant={'secondary'}
                            buttonText={t('View')}
                            iconPos={'right'}
                            onClick={() =>
                                history.push(
                                    `${createCageUrl(
                                        clientId,
                                        locationId,
                                        cageId
                                    )}/welfare/wounds/detections`
                                )
                            }>
                            <GenericIcon icon={ArrowRightSVG} size={20}></GenericIcon>
                        </IconButton>
                    </UmerBoxElementHeader>
                    <div className={styles.smallBoxMaxWidth}>
                        {!detectionsLoading && (
                            <>
                                {images.length > 0 ? (
                                    <UmerBoxElementImage
                                        imagePath={images[images.length - 1]?.path}
                                    />
                                ) : (
                                    <NoComponentDataAvailable />
                                )}
                            </>
                        )}
                        {detectionsLoading && <Loader />}
                    </div>
                </UmerBox>
            </div>
        </>
    );
};

export default CageWelfareDashboardPageContent;
