// <block:plugin:0>
import './wc-checkbox/checkbox';
import './wc-checkbox/opto-check';

const template = document.createElement('template');
template.innerHTML = `
    <style>
    .legend-container {
            display: flex;
            flex-wrap: wrap;  
            gap: 8px;  
            max-width: 100%;
            row-gap: 8px;  
        }
    </style> 
    <div class="legend-item">
        <opto-checkbox class="checkbox" showlabel="true" label="legendName" value="index" name="chartname">
        </opto-checkbox>
    </div>
`;
export interface HtmlLegendOptions {
    containerClass: string;
    showLegend?: boolean;
    disabled?: boolean;
    position?: string;
    displayLegendValue?: boolean;
}

const htmlLegendPlugin = {
    id: 'htmlLegend',
    beforeDraw(chart, args, options) {
        const { display, position, displayLegendValue } = options;
        const parentNode = chart?.canvas?.parentNode;

        if (parentNode && parentNode?.nodeName === 'DIV') {
            parentNode.setAttribute('show-legend', display);
            parentNode.setAttribute('data-legend-position', position);
            parentNode.setAttribute('data-showlegendvalue', displayLegendValue);
        }
    },
    afterUpdate(chart, args, options) {
        const { displayLegendValue, disabled } = options;
        const parentNode = chart?.canvas?.parentNode;

        const containerEl = parentNode?.querySelector('div[class~="legend-container"]');
        if (!containerEl) {
            console.warn('htmlLegend: No container element found');
            return;
        }
        for (const item of containerEl.querySelectorAll('opto-checkbox')) {
            item.setAttribute('disabled', disabled ? 'true' : 'false');
            item.setAttribute('data-showlegendvalue', displayLegendValue);
        }

        if (!chart) {
            console.warn('htmlLegend: No chart element found');
            return;
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach((item, index) => {
            const { type } = chart.config._config;
            const queryIndex =
                type === 'pie' || type === 'doughnut' || type === 'polarArea'
                    ? item.index
                    : item.datasetIndex;
            const queryIndexString = queryIndex.toString();

            let checkbox = containerEl.querySelector(`[name="${queryIndexString}"]`);

            if (!checkbox) {
                const legendItem = template.content.cloneNode(true) as HTMLElement;

                legendItem.querySelector('.checkbox')?.addEventListener('change', (e) => {
                    if (type === 'pie' || type === 'doughnut') {
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(
                            item.datasetIndex,
                            !chart.isDatasetVisible(item.datasetIndex)
                        );
                    }
                    chart.update();
                });
                checkbox = legendItem.querySelector('opto-checkbox') as HTMLElement;

                containerEl.appendChild(legendItem);
            }

            checkbox.style.setProperty(
                '--opto-checkbox-bgcolor',
                item?.fillStyle || item?.backgroundColor
            );
            checkbox.style.setProperty(
                '--opto-checkbox-border-color',
                item?.fillStyle || item?.borderColor
            );

            let labelString = item.text;
            if (
                displayLegendValue &&
                (type === 'pie' || type === 'doughnut' || type === 'polarArea')
            ) {
                const formattedValue = new Intl.NumberFormat(navigator.language, {
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                }).format(chart.data.datasets[0].data[item.index]);
                labelString = `${item.text}: ${formattedValue}%`;
            }

            checkbox.setAttribute('label', labelString);
            checkbox.setAttribute('name', queryIndexString);
            checkbox.setAttribute('value', queryIndexString);
            checkbox.setAttribute('showlabel', displayLegendValue);

            if (
                chart.config.type === 'pie' ||
                chart.config.type === 'doughnut' ||
                chart.config.type === 'polarArea'
            ) {
                checkbox.setAttribute('checked', item.hidden ? 'false' : 'true');
            } else if (chart.isDatasetVisible(queryIndex) === true) {
                checkbox.setAttribute('checked', 'true');
            }
        });
    },
    defaults: {
        display: true,
        position: 'top',
        displayLegendValue: true,
    },
    afterDestroy() {},
};

export default htmlLegendPlugin;
export { htmlLegendPlugin };
