import React, { createContext, useContext, useMemo } from 'react';

import { useRestOverview } from './cage-overview-context';

interface GraphDataContextValue {
    loading: boolean;
    error: any;
    data?: { [x in string | number]: any };
    weightUnitTag: 'kg' | 'g' | 'oz' | 'lb';
    daypartTag: string;
}

const contextDefaultValue: GraphDataContextValue = {
    loading: false,
    error: null,
    weightUnitTag: 'g',
    daypartTag: '',
    data: {
        days: [],
    },
};

export const GraphDataContext = createContext(contextDefaultValue);

function useGraphData() {
    return useContext(GraphDataContext);
}

function CageGraphDataProvider({ children }) {
    const { loading, error, data: overview, weightUnitTag, daypartTag } = useRestOverview();
    const context = useMemo(() => {
        // shortcut if no data
        if (!overview) {
            return {
                loading,
                error,
                weightUnitTag,
                daypartTag,
            } as GraphDataContextValue;
        }

        const data = overview ?? {};
        return {
            loading,
            error,
            data,
            weightUnitTag,
            daypartTag,
        } as GraphDataContextValue;
    }, [daypartTag, error, loading, overview, weightUnitTag]);

    return <GraphDataContext.Provider value={context}>{children}</GraphDataContext.Provider>;
}

export default useGraphData;
export { CageGraphDataProvider };
