import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import moment from 'moment';
import { CreateNewContractLicenseInput, LicenseInfo } from 'services/types';
import { DISPLAY_DAY_FORMAT } from 'utils/formattedDay';

import { Button } from 'components/atoms/Buttons';

import styles from './ModalUpdateLicense.module.scss';

interface UpdateLicenseInitialValues {
    licenseId: number;
    dayFrom: string | moment.Moment | null;
    dayTo: string | moment.Moment | null;
    licenseName?: string;
}

export interface UpdateLicenseProps {
    onSubmitted: (ev: CreateNewContractLicenseInput) => void;
    licenses: LicenseInfo[];
    licenseData: CreateNewContractLicenseInput;
}

const ModalUpdateLicense = ({ onSubmitted, licenses, licenseData }: UpdateLicenseProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const [formData, setFormData] = useState<Partial<UpdateLicenseInitialValues>>({
        ...licenseData,
        dayFrom: licenseData.dayFrom ? moment(licenseData.dayFrom) : null,
        dayTo: licenseData.dayTo ? moment(licenseData.dayTo) : null,
    });

    function updateForm<T extends keyof CreateNewContractLicenseInput>(key: T) {
        return (value: CreateNewContractLicenseInput[T]) =>
            setFormData((formData) => ({ ...formData, [key]: value }));
    }

    const [focused, setFocused] = useState<
        Partial<Record<keyof CreateNewContractLicenseInput, boolean>>
    >({});

    function updateFocused<T extends keyof CreateNewContractLicenseInput>(key: T) {
        return ({ focused }: { focused: boolean }) =>
            setFocused((formData) => ({ ...formData, [key]: focused }));
    }

    const isValid = !!formData?.dayFrom;
    const onFormDataSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        if (isValid) {
            onSubmitted(formData as CreateNewContractLicenseInput);
            setOpen(false);
        }
        ev.preventDefault();
    };

    return (
        <div className={styles.modalupdatelicense}>
            <Button
                variant={'secondary'}
                id="openUpdateLicenseModal"
                data-testid="openUpdateLicenseModal"
                onClick={() => setOpen(true)}>
                {t('Update')}
            </Button>
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>{t('Update Bioscope License')}</ModalHeader>
                <Form onSubmit={onFormDataSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="license">{t('License')}</Label>
                            <Input
                                type="select"
                                name="license"
                                placeholder="Select the license type"
                                value={formData?.licenseId ?? ''}
                                onChange={(ev) => {
                                    const selectedLicense = JSON.parse(ev.target.value);
                                    updateForm('licenseId')(selectedLicense.id);
                                    setFormData((formData) => ({
                                        ...formData,
                                        licenseName: selectedLicense.name,
                                    }));
                                }}>
                                <option value={formData.licenseName} disabled>
                                    {t('Select the license type')}
                                </option>
                                {licenses.map((license) => (
                                    <option key={license.id} value={JSON.stringify(license)}>
                                        {license?.name}
                                    </option>
                                ))}
                            </Input>
                            {formData?.licenseName && (
                                <div className={styles.selectedLicenseName}>
                                    {formData.licenseName}
                                </div>
                            )}
                            <Label for="from">{t('From')}</Label>
                            <SingleDatePicker
                                id="from"
                                required={true}
                                date={formData?.dayFrom}
                                placeholder={t('From')}
                                onDateChange={updateForm('dayFrom')}
                                focused={focused.dayFrom ?? false}
                                onFocusChange={updateFocused('dayFrom')}
                                block={true}
                                isOutsideRange={() => false}
                                numberOfMonths={1}
                                displayFormat={DISPLAY_DAY_FORMAT}
                                showClearDate={false}
                            />

                            <Label for="to">{t('To')}</Label>
                            <SingleDatePicker
                                id="to"
                                date={formData?.dayTo}
                                placeholder={t('To')}
                                onDateChange={updateForm('dayTo')}
                                focused={focused.dayTo ?? false}
                                onFocusChange={updateFocused('dayTo')}
                                block={true}
                                isOutsideRange={(day) => day.isBefore(formData?.dayFrom)}
                                numberOfMonths={1}
                                displayFormat={DISPLAY_DAY_FORMAT}
                                showClearDate={false}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!isValid}>
                            {t('Update')}
                        </Button>{' '}
                        <Button
                            color="secondary"
                            onClick={(ev) => {
                                setOpen(false);
                                ev.preventDefault();
                            }}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default ModalUpdateLicense;
export { ModalUpdateLicense };
