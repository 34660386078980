import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/atoms/Loader';
import { MeasurmentOverviewResult } from '@/services/types';

import Doughnut from './Doughnut';

// The scene labels showed on UI is controlled by table `scene_label`.
// Add/remove coresponding rows to table to use labels from bioscope.
// There is no need to have extra filter or sorts on UI.
// UI shows the lables it receives from backend and labels must be
// generated only for labels described in `scene_label` table.
// Good part is - it is a backend or service job.

const DEFAULT_DATA_RANGE_TAG = '2d';

const COLORS = {
    dew: '#953502',
    rope: '#b52a2c',
    net: '#c3265b',
    ufo: '#c4298c',
    backlight: '#b335bf',
    shadow: '#9848e2',
    badangle: '#7761f5',
    tooclose: '#5182f7',
    faraway: '#379ee7',
    empty: '#27b7ca',
    chaos: '#26caa7',
    singlefish: '#38d67d',
    school: '#55d960',
    lice: '#3366699',
};

// list of extra colors to use when there are not enough colors for all labels
const EXTRA_COLORS = ['#7bd54e'];

const MIN_RELATIVE_COUNT = 0.0001;

interface SceneLabelProps {
    overview: MeasurmentOverviewResult;
    isLoadingOverview: boolean;
}

function SceneLabels({ overview, isLoadingOverview }: SceneLabelProps) {
    const { t } = useTranslation();

    const [data, setData] = useState<number[]>([]);
    const [relativeCounts, setRelativeCounts] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [colors, setColors] = useState<string[]>([]);

    const constructSceneLabelDataOnDataChange = () => {
        if (!isLoadingOverview && overview) {
            const sortedLabels =
                overview?.labels
                    ?.filter((label) => label.relativeCount >= MIN_RELATIVE_COUNT)
                    ?.sort((a, b) => b.count - a.count) ?? [];

            let extraColorIndex = 0;
            const newColors = sortedLabels.map((label) => {
                let color = COLORS?.[label.sceneLabelTag];
                if (!color) {
                    color = EXTRA_COLORS[extraColorIndex % EXTRA_COLORS.length];
                    extraColorIndex++;
                }
                return color;
            });
            const newData = sortedLabels.map((label) => label.count);
            const newRelativeCounts = sortedLabels.map((label) => label.relativeCount);
            const newLabels = sortedLabels.map((label) =>
                t(`bioscopeImageClasses.${label.sceneLabelTag}`)
            );

            setData(newData);
            setRelativeCounts(newRelativeCounts);
            setLabels(newLabels);
            setColors(newColors);
        }
    };

    useEffect(() => {
        constructSceneLabelDataOnDataChange();
    }, [overview]);

    useEffect(() => {
        constructSceneLabelDataOnDataChange();
    }, []);

    return (
        <div data-tut="sceneLabels">
            <div className="position-absolute" style={{ top: '40px', right: '55px' }} />
            {isLoadingOverview && <Loader />}
            {!isLoadingOverview && data.length > 0 && (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ minHeight: '300px' }}>
                    <Doughnut
                        data={data}
                        colors={colors}
                        labels={labels}
                        relativeCounts={relativeCounts}
                    />
                </div>
            )}
            {!isLoadingOverview && data.length === 0 && (
                <div className="jumbotron p-3 mt-3 mb-0">
                    {t('No scene labels received the last')}{' '}
                    {t(`dataRangeTitle.${DEFAULT_DATA_RANGE_TAG}`)}{' '}
                </div>
            )}
        </div>
    );
}

export default SceneLabels;
