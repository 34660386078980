import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@/components/atoms/Loader';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import Notice from '@/components/atoms/Notice/Notice';
import UmerBox from '@/components/atoms/UmerBox';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import BioscopeStatusBox from '@/components/molecules/BioscopeStatusBox';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import useCage from '@/contexts/cage-context';
import { supressStatusMessages } from '@/helpers/alarmCheckers';
import { createCageUrl } from '@/helpers/urlGenerator';
import { ImageState } from '@/hooks/image-state';
import { useRestFormattedOverview } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
import { useRestOverview } from '@/routes/Client/Cage/components/cage-overview-context';
import SceneLabels from '@/routes/Client/Cage/pages/CageDashboard/CageBioscopeStatus/components/SceneLabels';
import { CageStatus, CageType, ImageFeedbackDto } from '@/services/types';
import { ImageViewContainer } from 'components/organisms/ImageViewer';

import styles from './CageBioscopeInspectionPageContent.module.scss';

export interface CageBioscopeInspectionPageContentProps {
    clientId: number;
    locationId: number;
    cageId: number;
    source: string;
    bioscopeImageState: ImageState;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    onCommitFalseDetection: (feedbackDto: ImageFeedbackDto) => void;
    cageStatus?: CageStatus;
}

const CageBioscopeInspectionPageContent = ({
    clientId,
    locationId,
    cageId,
    bioscopeImageState,
    source,
    isOptoscaleAdmin = false,
    onCommitFalseDetection = () => {},
    cageStatus,
}: CageBioscopeInspectionPageContentProps) => {
    const { t } = useTranslation();
    const { cage, timezone, client } = useCage();
    const { loading: isLoadingOverview, data: overview } = useRestOverview();
    const formattedOverview = useRestFormattedOverview();

    const isColor = source === 'color';

    const [bioscopeStatus, setBioscopeStatus] = useState<boolean>(false);
    const [bioscopeMessages, setBioscopeMessages] = useState<string[]>([]);

    const bioscopeInspectionFeatureFlags = client?.featureFlags?.find(
        (x) => x === 'BIOSCOPE_INSPECTION'
    );

    const hasBioscopeInspectionFeatureFlag = bioscopeInspectionFeatureFlags?.length > 0;
    const bioscopeInspectionLink = cageStatus?.currentBioscope?.id
        ? `${createCageUrl(clientId, locationId, cageId)}/bioscope/${cageStatus.currentBioscope.id}`
        : '';

    const parseFormattedOverviewBioscopeData = () => {
        const { statusText2, allGood2 } = formattedOverview.data;
        // Filter out status messages that should not be shown
        const alertMessages: string[] =
            !allGood2 && statusText2 && statusText2?.length > 0
                ? supressStatusMessages({
                      cageType: cage?.cageType as CageType,
                      statusTexts: statusText2,
                  })
                : ['allgood'];

        const alertMessageStrings = alertMessages.map((text) => {
            return t(`measurementStatusTexts.${text}`);
        });

        setBioscopeStatus(allGood2);
        setBioscopeMessages(alertMessageStrings);
    };

    useEffect(() => {
        if (formattedOverview && !formattedOverview.loading && formattedOverview.data) {
            parseFormattedOverviewBioscopeData();
        }
    }, [formattedOverview]);

    return (
        <>
            <PageTitleRow title={cage?.name} subTitle={t('Bioscope status')} />

            <div className={styles.pageBoxLayout}>
                <div>
                    <UmerBox>
                        {bioscopeImageState.loading && <Loader />}
                        {!bioscopeImageState.loading && (
                            <>
                                {bioscopeImageState.dayswithimages.length === 0 ? (
                                    <NoComponentDataAvailable />
                                ) : (
                                    <ImageViewContainer
                                        key="bioscope"
                                        instanceKey="bioscope"
                                        isOptoScaleAdmin={isOptoscaleAdmin}
                                        isColor={isColor}
                                        onCommitImageFeedbackDto={onCommitFalseDetection}
                                        viewerConfig={{
                                            showMagnifyer: false,
                                            scrollActivated: true,
                                            showPois: false,
                                            zoomDisabled: true,
                                            magnifyerDisabled: true,
                                            showWoundLabel: false,
                                        }}
                                        {...bioscopeImageState}
                                    />
                                )}
                            </>
                        )}
                    </UmerBox>
                </div>
                <div className={styles.horizontalGap}>
                    <UmerBox>
                        <UmerBoxElementHeader headerText={t('Scene labels')}></UmerBoxElementHeader>
                        <SceneLabels overview={overview} isLoadingOverview={isLoadingOverview} />
                    </UmerBox>

                    <UmerBox>
                        <UmerBoxElementHeader
                            headerText={t('Bioscope status')}></UmerBoxElementHeader>
                        {bioscopeMessages.length > 0 && (
                            <div className={styles.smallBoxMaxWidth}>
                                <Notice
                                    icon={true}
                                    type="status"
                                    heading="Status"
                                    message={bioscopeMessages}
                                    variant={bioscopeStatus ? 'allgood' : 'warning'}
                                />
                                <UmerBoxElementDivider />
                            </div>
                        )}
                        <BioscopeStatusBox
                            cageStatus={cageStatus}
                            measurementCount={overview?.period.meas_cnt}
                            timezone={timezone}
                            isOptoscaleAdmin={isOptoscaleAdmin}
                            showBioscopeInspectionLink={hasBioscopeInspectionFeatureFlag}
                            bioscopeInspectionLink={bioscopeInspectionLink}
                        />
                    </UmerBox>
                </div>
            </div>
        </>
    );
};

export default CageBioscopeInspectionPageContent;
export { CageBioscopeInspectionPageContent };
