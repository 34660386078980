import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCage } from 'contexts/cage-context';

import { getLabelByValue } from '@/components/molecules/ShareFalseDetectionDropdown/ShareFalseDetectionDropDownObjects';
import { ToastTemplateBackendFeedback } from '@/components/molecules/ToastTemplate';
import SomethingHappened from '@/components/SomethingHappened';
import { useMe } from '@/contexts/meContext';
import { bioscopeImageMapper, useImageState } from '@/hooks/image-state';
import { CageFormattedOverviewProvider } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
import { CageOverviewContextProvider } from '@/routes/Client/Cage/components/cage-overview-context';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';
import {
    useCageController_Status,
    useCageImageController_GetImageList,
    useFeedbackController_ImageFeedback,
} from '@/services/hooks';
import { ImageFeedbackDto } from '@/services/types';

import CageBioscopeInspectionPageContent from './CageBioscopeInspectionPageContent';

// eslint-disable-next-line no-undef
const CageBioscopeInspectionContainer = ({ clientId, locationId, cageId }: CagePageInterface) => {
    const { timezone, hasColorWelfare } = useCage();
    const { isOptoscaleAdmin, state } = useMe();
    const { t } = useTranslation();

    const { isLoading: cagesStatusIsLoading, data: cageStatusData } = useCageController_Status(
        cageId,
        { staleTime: 60 * 1000 }
    );

    const bioscopeImages = useCageImageController_GetImageList(cageId ?? 0);

    const bioscopeImageState = useImageState({
        loading: bioscopeImages.isLoading && !bioscopeImages.error,
        data: bioscopeImages.data?.data
            .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
            .reverse(),
        mapper: useCallback(bioscopeImageMapper(cageId), [cageId]),
        queryParam: 'bioscopeImageId',
        timezone,
    });

    const { mutateAsync: reportFeedbackAsync } = useFeedbackController_ImageFeedback();

    const onCommitImageFeedbackDto = async (feedbackDto: ImageFeedbackDto) => {
        if (state?.user?.id) {
            feedbackDto = { ...feedbackDto, reportingUserId: state.user.id };
        }
        if (cageId) {
            feedbackDto = { ...feedbackDto, cageId: cageId };
        }

        const { status, data } = await reportFeedbackAsync({ requestBody: feedbackDto });
        if (status) {
            ToastTemplateBackendFeedback({
                content: `Thank you ${
                    state?.user?.firstName ?? ''
                } for contributing to train our AI model on: ${t(getLabelByValue(data.tag) || '')}`,
                toastId: 'detection-feedback',
                httpStatusCode: status,
            });
        }
    };

    const source = hasColorWelfare ? 'color' : 'bw';

    if (bioscopeImages.isError) {
        return <SomethingHappened />;
    }

    return (
        <CageOverviewContextProvider>
            <CageFormattedOverviewProvider>
                <CageBioscopeInspectionPageContent
                    clientId={clientId}
                    locationId={locationId}
                    cageId={cageId}
                    bioscopeImageState={bioscopeImageState}
                    source={source}
                    isOptoscaleAdmin={isOptoscaleAdmin}
                    onCommitFalseDetection={onCommitImageFeedbackDto}
                    cageStatus={cagesStatusIsLoading ? null : cageStatusData?.data}
                />
            </CageFormattedOverviewProvider>
        </CageOverviewContextProvider>
    );
};

export default CageBioscopeInspectionContainer;
export { CageBioscopeInspectionContainer };
