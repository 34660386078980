import React from 'react';

import styles from './GlassHourIcon.module.scss';

export interface GlassHourIconProps {
    children?: React.ReactNode;
    height: number;
    width: number;
    color: string;
}

const GlassHourIcon = ({ height = 24, color }: GlassHourIconProps) => {
    const colorStyle = color ? color : 'currentColor';
    const widthValue = height;
    return (
        <div className={styles.glasshouricon}>
            <title>GlassHourIcon</title>
            <svg
                height={height}
                width={widthValue}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5165_29769)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4V5.85926C19 7.53103 18.1645 9.09219 16.7735 10.0195L13.8028 12L16.7735 13.9805C18.1645 14.9078 19 16.469 19 18.1407V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20V18.1407C5 16.469 5.83551 14.9078 7.2265 13.9805L10.1972 12L7.2265 10.0195C5.83551 9.09219 5 7.53103 5 5.85926V4ZM12 10.7981L15.6641 8.35542C16.4987 7.79902 17 6.86232 17 5.85926V4H7V5.85926C7 6.86232 7.5013 7.79902 8.3359 8.35542L12 10.7981ZM12 13.2018L8.3359 15.6446C7.5013 16.201 7 17.1377 7 18.1407V20H17V18.1407C17 17.1377 16.4987 16.201 15.6641 15.6446L12 13.2018Z"
                        fill={colorStyle}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_5165_29769">
                        <rect height={height} width={widthValue} fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default GlassHourIcon;
export { GlassHourIcon };
