import React, { useEffect, useState } from 'react';
import { useMe } from '@/contexts/meContext';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import i18n, { t } from 'i18next';
import moment from 'moment';
import { useLicenseController_GetLocationsLicense } from '@/services/hooks';
import { Loader } from '@/components/atoms/Loader';
import { OptoMixedChart } from '@/components/organisms/OptoGraphs';
import {
    ComparingDataMapper,
    defaultLineChartOptions,
} from '@/components/organisms/OptoGraphs/OptoMixedChart';
import { RequestError } from '@/services/config';
import { useClientController_Compare } from '@/services/hooks';
import { OverviewCage } from '@/services/types';
import { INTERNAL_DATE_FORMAT } from '@/utils/formattedDay';
import {
    autoTimeScale,
    formatAxsis,
    formatTooltip,
    layoutPadding,
    Options,
    startYAxisAtZero,
} from 'components/organisms/OptoGraphs/optionsModifiers';

interface urlDataTypes {
    [key: string]: string;
}

interface CompareContentProps {
    id: string;
    item: string;
    postfixForYAxis: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%' | 'bl/s' | '°' | '';
    unitType:
        | 'percent'
        | 'weight'
        | 'length'
        | 'number'
        | 'celcius'
        | 'meter'
        | 'height'
        | 'pixel'
        | 'angle';
    multiplier: number;
    descimals: number;
    hasAccess: boolean;
}

export interface LocationCompareGraphContainerInterfaceProps {
    clientId: number;
    locationId: number;
    cages: OverviewCage[];

    toDate: string | null;
    fromDate: string | null;
    dayartTagParam: string;
    containerIsLoading: boolean;
    containerError: Error | RequestError | null;
}

const LocationCompareGraphContainer = ({
    clientId,
    locationId,
    cages,
    toDate,
    fromDate,
    dayartTagParam,
    containerIsLoading,
    containerError,
}: LocationCompareGraphContainerInterfaceProps) => {
    const { isOptoscaleAdmin } = useMe();
    const {
        isLoading: isloadingLocationLicense,
        data: dataLocationLicense,
        error: errorLocationLicense,
    } = useLicenseController_GetLocationsLicense(clientId);

    const locationLicense = dataLocationLicense?.data?.find((x) => x.locationId === locationId);

    const hasAccessToBiomass =
        isOptoscaleAdmin || (locationLicense?.licenses.biomassLicense.hasValidLicense ?? false);
    const hasAccessToFishHealth =
        isOptoscaleAdmin || (locationLicense?.licenses.fishHealthLicense.hasValidLicense ?? false);
    const hasAccessToLice =
        isOptoscaleAdmin || (locationLicense?.licenses.liceLicense.hasValidLicense ?? false);
    const hasAccessToFishSpeed =
        isOptoscaleAdmin || (locationLicense?.licenses.fishSpeedLicense.hasValidLicense ?? false);

    const COMPARECONFIG: CompareContentProps[] = [
        {
            id: '1',
            item: 'wounds',
            postfixForYAxis: '%',
            unitType: 'percent',
            multiplier: 100,
            descimals: 2,
            hasAccess: hasAccessToFishHealth,
        },
        {
            id: '2',
            item: 'weight',
            postfixForYAxis: 'g',
            unitType: 'weight',
            multiplier: 1,
            descimals: 0,
            hasAccess: hasAccessToBiomass,
        },
        {
            id: '3',
            item: 'femaleLice',
            postfixForYAxis: '',
            unitType: 'number',
            multiplier: 1,
            descimals: 2,
            hasAccess: hasAccessToLice,
        },
        {
            id: '4',
            item: 'maturation',
            postfixForYAxis: '%',
            unitType: 'percent',
            multiplier: 1,
            descimals: 2,
            hasAccess: hasAccessToFishHealth,
        },
        {
            id: '5',
            item: 'activeWounds',
            postfixForYAxis: '%',
            unitType: 'percent',
            multiplier: 100,
            descimals: 2,
            hasAccess: hasAccessToFishHealth,
        },
        {
            id: '6',
            item: 'healingWounds',
            postfixForYAxis: '%',
            unitType: 'percent',
            multiplier: 100,
            descimals: 2,
            hasAccess: hasAccessToFishHealth,
        },
        {
            id: '7',
            item: 'smallWounds',
            postfixForYAxis: '%',
            unitType: 'percent',
            multiplier: 100,
            descimals: 2,
            hasAccess: hasAccessToFishHealth,
        },
        {
            id: '8',
            item: 'mediumWounds',
            postfixForYAxis: '%',
            unitType: 'percent',
            multiplier: 100,
            descimals: 2,
            hasAccess: hasAccessToFishHealth,
        },
        {
            id: '9',
            item: 'largeWounds',
            postfixForYAxis: '%',
            unitType: 'percent',
            multiplier: 100,
            descimals: 2,
            hasAccess: hasAccessToFishHealth,
        },
        {
            id: '10',
            item: 'movingLice',
            postfixForYAxis: '',
            unitType: 'number',
            multiplier: 1,
            descimals: 2,
            hasAccess: hasAccessToLice,
        },
        {
            id: '11',
            item: 'caligusLice',
            postfixForYAxis: '',
            unitType: 'number',
            multiplier: 1,
            descimals: 2,
            hasAccess: hasAccessToLice,
        },
        {
            id: '12',
            item: 'swimSpeed',
            postfixForYAxis: '',
            unitType: 'number',
            multiplier: 1,
            descimals: 2,
            hasAccess: hasAccessToFishSpeed,
        },
        {
            id: '13',
            item: 'conditionFactor',
            postfixForYAxis: '',
            unitType: 'number',
            multiplier: 1,
            descimals: 2,
            hasAccess: hasAccessToBiomass,
        },
        {
            id: '14',
            item: 'CV',
            postfixForYAxis: '',
            unitType: 'number',
            multiplier: 1,
            descimals: 2,
            hasAccess: hasAccessToBiomass,
        },
    ];

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }
    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(COMPARECONFIG[1]);

    const cageIds = cages.map((x) => x.id);

    const [dataWithCageNames, setDataWithCageNames] = useState({});

    const handleDropdownSelect = (option) => {
        const selectedConfigItem = COMPARECONFIG.find((item) => item.item === option);
        if (selectedConfigItem) {
            setSelectedOption(selectedConfigItem);
        }
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const {
        data: compareData,
        isLoading,
        error: compareError,
    } = useClientController_Compare({
        cages: cageIds,
        from: fromDate ? moment(fromDate, INTERNAL_DATE_FORMAT).toISOString() : null,
        to: toDate ? moment(toDate, INTERNAL_DATE_FORMAT).toISOString() : null,
        daypartTag: dayartTagParam || 'day',
    });

    useEffect(() => {
        if (!compareData?.data) {
            return;
        }

        const allData = { ...compareData.data };
        const tempDataWithCageNames: { [key: string]: any } = {};
        const cageIdToCageNameMapper = Object.fromEntries(
            cages.map((cage) => [cage.id, cage.name])
        );

        for (const key in allData) {
            if (Object.hasOwn(allData, key)) {
                const data = allData[key];
                const newData = {};

                for (const dataKey in data) {
                    if (Object.hasOwn(data, dataKey)) {
                        const value = data[dataKey];
                        const newName = cageIdToCageNameMapper[dataKey];

                        newData[newName] = value;
                    }
                }

                tempDataWithCageNames[key] = newData;
            }
        }
        setDataWithCageNames(tempDataWithCageNames);
    }, [compareData]);

    const upperCaseFirstLetter = (text: string) => {
        const translation = t(text);
        return `${translation[0].toUpperCase()}${translation.slice(1)}`;
    };

    return (
        <>
            {(containerIsLoading || isLoading || isloadingLocationLicense) && <Loader />}

            {!(
                containerIsLoading ||
                isLoading ||
                isloadingLocationLicense ||
                errorLocationLicense
            ) && (
                <>
                    <div className="pb-2 d-flex  justify-content-center align-items-center">
                        <h3 className="mb-0 border-0">{t('Comparing')}</h3>
                        <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ml-2">
                            <DropdownToggle caret>
                                <span style={{ textTransform: 'capitalize' }}>
                                    {t(selectedOption.item)}
                                </span>
                            </DropdownToggle>
                            <DropdownMenu>
                                {COMPARECONFIG.filter((option) => option.hasAccess).map(
                                    (option) => (
                                        <DropdownItem
                                            key={option.id}
                                            onClick={() => handleDropdownSelect(option.item)}>
                                            <span>{upperCaseFirstLetter(option.item)}</span>
                                        </DropdownItem>
                                    )
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <OptoMixedChart
                        chartName={t('Comparing')}
                        chartData={ComparingDataMapper({
                            data: dataWithCageNames[selectedOption.item],
                        })}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale({}),
                            layoutPadding({ padding: 0 }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: selectedOption.unitType,
                                descimals: selectedOption.descimals,
                                multiplier: selectedOption.multiplier,
                            }),
                            formatTooltip({
                                tickType: selectedOption.unitType,
                                multiplier: selectedOption.multiplier,
                                descimals: selectedOption.descimals,
                                locale: locale,
                            }),

                            startYAxisAtZero({
                                axsisID: 'y',
                            })
                        )}
                    />
                </>
            )}
        </>
    );
};

export default LocationCompareGraphContainer;
export { LocationCompareGraphContainer };
