import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageTitleRow } from '@/components/molecules/PageTitleRow';

import styles from './Laksvel.module.scss';

interface LaksevelPageContentProps {
    laksvelmenu: {
        key: number;
        label?: string;
        path?: string;
        icon?: undefined;
        page?: React.ComponentType;
        params?: {
            i18n?: string; // Maybe this is a good idea
        };
    }[];
    clientId: number | undefined;
    cageId: number | undefined;
    children?: React.ReactNode;
    routerProps: RouteComponentProps;
    isOptoscaleAdmin?: boolean;
    title: string;
}

const LaksvelPageContent = ({
    laksvelmenu,
    cageId,
    clientId,
    children,
    title,
}: LaksevelPageContentProps) => {
    return (
        <div className={styles.laksvelPageContent}>
            <PageTitleRow title={title}>
                {/* Comment back in when the export function is ready and wire back up <div>*/}
                {/*    <DownloadCsvXlsx label="CSV" data={[]} COLUMNS={[]} />*/}
                {/*    <DownloadCsvXlsx label="XLSX" data={[]} COLUMNS={[]} />*/}
                {/*</div>*/}
            </PageTitleRow>
            <div className={styles.laksvelPageContentGrid}>
                <section>{children}</section>
            </div>
        </div>
    );
};

export default LaksvelPageContent;
export { LaksvelPageContent };
