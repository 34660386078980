import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GrowthValue, LaksvelValue, LiceValue, WoundsValue } from 'helpers/textValueCalculator';
import { t } from 'i18next';

import { AmountRatioWithText } from '@/components/atoms/AmountRatioWithText';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import {
    AlertOctagonSVG,
    FishHealthIconSVG,
    GrowthIconSVG,
    LaksvelShieldSVG,
    LiceIconSVG,
} from '@/components/atoms/icons/GenericIcon/GenericIcons';
import { Loader } from '@/components/atoms/Loader';
import UmerBox from '@/components/atoms/UmerBox';
import UmerBoxElementDivider from '@/components/atoms/UmerBoxElements/UmerBoxElementDivider/UmerBoxElementDivider';
import { UmerBoxElementHeader } from '@/components/atoms/UmerBoxElements/UmerBoxElementHeader';
import { UmerBoxElementLeftRightInfo } from '@/components/atoms/UmerBoxElements/UmerBoxElementLeftRightInfo';
import { GenericInfoBox } from '@/components/molecules/GenericInfoBox';
import { PageTitleRow } from '@/components/molecules/PageTitleRow';
import SomethingHappened from '@/components/SomethingHappened';
import settings from '@/config/settings';
import { RequestError } from '@/services/config';
import {
    CageBioscopeStatus,
    DailyCageWeightDto,
    LocationBioscopeStatusDto,
    LocationsLicenseOverview,
    LocationWeightStatusDto,
    OverviewLocation,
} from '@/services/types';
import { SingleLocationBox } from 'components/organisms/SingleLocationBox';

import styles from './LocationDashboard.module.scss';

interface LocationProps {
    bioscopeStatus?: LocationBioscopeStatusDto | null;
    weightStatus?: LocationWeightStatusDto | null;
    clientId: number;
    containerError: Error | RequestError | null;
    containerLoading: boolean;
    hasAccessToMarkHistorical: boolean;
    isOptoscaleAdmin?: boolean;
    location: OverviewLocation;
    locationLicense: LocationsLicenseOverview;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    renderLazy: boolean;
}

const LocationDashboardPageContent = ({
    clientId,
    location,
    locationLicense,
    markHistorical,
    hasAccessToMarkHistorical,
    isOptoscaleAdmin = false,
    renderLazy = false,
    containerLoading,
    containerError,
    bioscopeStatus,
    weightStatus,
}: LocationProps) => {
    const hasAccessToFishHealth =
        isOptoscaleAdmin || (locationLicense?.licenses.fishHealthLicense.hasValidLicense ?? false);
    const hasAccessToLice =
        isOptoscaleAdmin || (locationLicense?.licenses.liceLicense.hasValidLicense ?? false);
    const hasAccessToLaksvel =
        isOptoscaleAdmin || (locationLicense?.licenses.LaksvelLicense.hasValidLicense ?? false);

    const history = useHistory();

    const [weightStatusRows, setWeightStatusRows] = React.useState<DailyCageWeightDto[]>([]);

    useEffect(() => {
        if (weightStatus?.dailyCageWeightRows.length > 0 && location?.cages?.length > 0) {
            const dailyCageWeights: DailyCageWeightDto[] = [];

            for (const dailyCageWeight of weightStatus.dailyCageWeightRows) {
                const cage = location.cages.find((x) => x.id == dailyCageWeight.cageId);
                if (cage?.weight?.livingWeight) {
                    const newDailyCageWeight = {
                        ...dailyCageWeight,
                        estimatedWeight: cage.weight.livingWeight,
                    };
                    dailyCageWeights.push(newDailyCageWeight);
                }
            }
            setWeightStatusRows(dailyCageWeights);
        } else {
            setWeightStatusRows([]);
        }
    }, [weightStatus, location]);

    const handleShouldShowWarningIcon = (bioscopeData: CageBioscopeStatus) => {
        if (bioscopeData.lastMeasurementDaysAgo > 0) {
            return <GenericIcon size={18} color={settings.warningColor} icon={AlertOctagonSVG} />;
        }

        return <></>;
    };

    const createCompareUrl = (cageIds: number[]) => {
        const cagesToCompare = location.cages.filter((c) => cageIds.includes(c.id));
        const queryString = cagesToCompare
            .map((item) => `${encodeURIComponent(item.name)}~${encodeURIComponent(item.id)}`)
            .join(',');
        return cagesToCompare.length > 1
            ? `/c/${clientId}/compare?data=${queryString}&daypartTag=day`
            : '#';
    };

    const goToCageBioscopeStatus = (cageId) => {
        history.push(`/c/${clientId}/location/${location.id}/pen/${cageId}/dashboard/bioscope`);
    };

    return (
        <>
            <PageTitleRow title={location ? location.name : ''} subTitle={t('Dashboard')} />
            {(containerLoading || location == null) && <Loader />}
            {containerError && <SomethingHappened />}

            {!containerError && !containerLoading && location && (
                <React.Fragment>
                    <div className={styles.infoBoxContainer}>
                        <GenericInfoBox
                            info={GrowthValue(location.averages.growth, t('daypartTag.day'))}
                            title={t('Growth')}
                            icon={GrowthIconSVG}
                            circleColor={settings.purpleColor}
                            grayBackground={false}
                            tooltipText={'Growth test tooltip'}
                            shouldHaveTooltip={false}
                        />

                        {hasAccessToLice ? (
                            <GenericInfoBox
                                info={LiceValue(location.averages.femaleLice)}
                                title={t('Lice')}
                                icon={LiceIconSVG}
                                circleColor={settings.orangeColor}
                                grayBackground={false}
                                tooltipText={'Lice test tooltip'}
                                shouldHaveTooltip={false}
                            />
                        ) : (
                            ''
                        )}
                        {hasAccessToFishHealth && location.fishTypes.shortName !== 'trout' ? (
                            <GenericInfoBox
                                info={WoundsValue(location?.averages?.wounds)}
                                title={t('Wound')}
                                icon={FishHealthIconSVG}
                                circleColor={settings.primaryColor}
                                grayBackground={false}
                                tooltipText={'Wound test tooltip'}
                                shouldHaveTooltip={false}
                            />
                        ) : (
                            ''
                        )}
                    </div>

                    <div className={styles.gridLayout}>
                        <UmerBox id={location.name.replace(/\s+/g, '')} doublePadding={true}>
                            <>
                                <h4 className={`${styles.header}`}>
                                    <span data-type="name">{t('Cage')}</span>
                                </h4>
                                <SingleLocationBox
                                    key={location.id}
                                    location={location}
                                    locationLicense={locationLicense as LocationsLicenseOverview}
                                    createCompareUrl={createCompareUrl}
                                    hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                                    isOptoscaleAdmin={isOptoscaleAdmin}
                                    markHistorical={markHistorical}
                                    hideCompare={true}
                                    hideMarkHistorical={false}
                                    clientId={clientId}
                                />
                            </>
                        </UmerBox>
                        <div className={styles.verticalLayoutContainer}>
                            <UmerBox>
                                <div className={styles.boxGap}>
                                    <UmerBoxElementHeader
                                        headerText={t('Bioscope status')}
                                        description={
                                            bioscopeStatus && (
                                                <AmountRatioWithText
                                                    leftSideAmount={
                                                        bioscopeStatus.numberOfBioscopesMeasuring
                                                    }
                                                    rightSideAmount={
                                                        bioscopeStatus.numberOfBioscopes
                                                    }
                                                    text={'Bioscopes measuring'}
                                                />
                                            )
                                        }
                                    />
                                    {bioscopeStatus?.errorCagesBioscopes?.length > 0 &&
                                        bioscopeStatus?.errorCagesBioscopes.map(
                                            (cageBioscopeStatusError, index) => (
                                                <div
                                                    key={`ibmeta_${index}`}
                                                    className={styles.clickableCageInfoBoxes}
                                                    onClick={() =>
                                                        goToCageBioscopeStatus(
                                                            cageBioscopeStatusError.cageId
                                                        )
                                                    }>
                                                    <UmerBoxElementLeftRightInfo
                                                        title={`${cageBioscopeStatusError.cageName}`}
                                                        info={[
                                                            {
                                                                leftSide: `${t('Bioscope')} ID:`,
                                                                rightSide:
                                                                    cageBioscopeStatusError.bioscopeId,
                                                                icon: <></>,
                                                            },
                                                            {
                                                                leftSide: t('Last measurement:'),
                                                                rightSide: `${
                                                                    cageBioscopeStatusError.lastMeasurementDaysAgo
                                                                } ${t('days ago')}`,
                                                                icon: handleShouldShowWarningIcon(
                                                                    cageBioscopeStatusError
                                                                ),
                                                            },
                                                        ]}
                                                    />
                                                    {index + 1 <
                                                        bioscopeStatus.errorCagesBioscopes
                                                            .length && <UmerBoxElementDivider />}
                                                </div>
                                            )
                                        )}
                                </div>
                            </UmerBox>

                            {
                                <UmerBox>
                                    <div className={styles.boxGap}>
                                        <UmerBoxElementHeader
                                            headerText={t('Weight measurements')}
                                            description={t('Total measurements yesterday')}
                                        />
                                        {weightStatusRows?.length > 0 &&
                                            weightStatusRows.map((weightStatusRow, index) => (
                                                <>
                                                    <UmerBoxElementLeftRightInfo
                                                        key={`weight_status_${index}`}
                                                        title={`${weightStatusRow.cageName}`}
                                                        info={[
                                                            {
                                                                leftSide: t('Weight'),
                                                                rightSide: `${Math.round(
                                                                    weightStatusRow.estimatedWeight
                                                                )} g`,
                                                                icon: <></>,
                                                            },
                                                            {
                                                                leftSide: t('Measurements'),
                                                                rightSide: `${weightStatusRow.numberOfMeasurements}`,
                                                                icon: <></>,
                                                            },
                                                        ]}
                                                    />
                                                    {index + 1 < weightStatusRows.length && (
                                                        <UmerBoxElementDivider />
                                                    )}
                                                </>
                                            ))}
                                    </div>
                                </UmerBox>
                            }
                        </div>
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

export default LocationDashboardPageContent;
