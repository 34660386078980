import React from 'react';

import { CageFormattedOverviewProvider } from '@/routes/Client/Cage/components/cage-formatted-overview-context';
import { CageGraphDataProvider } from '@/routes/Client/Cage/components/cage-graph-data-context';
import { CageOverviewContextProvider } from '@/routes/Client/Cage/components/cage-overview-context';
import { CagePageInterface } from '@/routes/Client/Cage/utils/cage-pages-interface';

import CageGraphContainer from './CageGraphContainer';

function CageGraphProviderContainer({ clientId, locationId, cageId }: CagePageInterface) {
    return (
        <CageOverviewContextProvider>
            <CageFormattedOverviewProvider>
                <CageGraphDataProvider>
                    <CageGraphContainer />
                </CageGraphDataProvider>
            </CageFormattedOverviewProvider>
        </CageOverviewContextProvider>
    );
}

export default CageGraphProviderContainer;
