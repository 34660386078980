import React, { type MouseEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';

import { IconButton } from '@/components/atoms/Buttons';
import { GenericIcon } from '@/components/atoms/icons/GenericIcon';
import * as iconUrls from '@/components/atoms/icons/GenericIcon/GenericIcons';

//import { t } from 'i18next';
import styles from './PanelToolBar.module.scss';
// ref?: React.Ref<HTMLButtonElement>;
export interface PanelToolBarProps {
    children?: React.ReactNode;
    hide?: boolean;
}

/**
 * Figma URL: https://www.figma.com/design/5iEE9GfUK0LJ2d8DkieG9m/OptoScale-%E2%80%93-Project-file?node-id=3067-42314&m=dev
 * @param param0
 * @returns
 */

const PanelToolBar = ({ children, hide = false }: PanelToolBarProps) => {
    const [actionMenuOpen, setActionMenuOpen] = useState(false);
    const actionWrappper = useRef<HTMLDivElement>(null);
    const menuButton = useRef<HTMLButtonElement>(null);

    const handleToggleActionMenu = (ev: MouseEvent<HTMLButtonElement>) => {
        setActionMenuOpen(!actionMenuOpen);
    };

    const handleActionMenuClose = (ev) => {
        setTimeout(() => {
            setActionMenuOpen(false);
        }, 500);
    };

    useEffect(() => {
        if (actionWrappper?.current) {
            actionWrappper?.current?.addEventListener('click', handleActionMenuClose);

            return () => {
                if (actionWrappper?.current) {
                    actionWrappper?.current?.removeEventListener('click', handleActionMenuClose);
                }
            };
        }
    }, [actionWrappper.current]);

    return (
        <>
            {hide ? null : (
                <div className={styles.paneltoolbar} data-type="toolbar">
                    <span ref={menuButton}>
                        <IconButton
                            tooltip={t('Show action buttons')}
                            onClick={handleToggleActionMenu}
                            variant="tertiary"
                            iconPos="right"
                            onlyIcon={true}>
                            <GenericIcon icon={iconUrls.ElipsisIconSVG} />
                        </IconButton>
                    </span>
                    <div
                        data-isopen={actionMenuOpen}
                        data-type="panel"
                        ref={actionWrappper}
                        className={classNames(actionMenuOpen ? styles.expandAction : '')}>
                        <div data-type="panel-elements">{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PanelToolBar;
export { PanelToolBar };
